import { db, auth } from '../../../firebaseConfig';
import { collection, doc, getDocs, query, where, getDoc, or, and } from 'firebase/firestore';

const getAgencyRef = async () => {
    const user = auth.currentUser;
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    return doc(db, 'agencies', userDoc.data().agencyId);
};

export const getFleetSchedule = async (startDate, endDate) => {
    try {
        const agencyRef = await getAgencyRef();
        const travelsRef = collection(agencyRef, 'travels');
        const vehiclesRef = collection(agencyRef, 'vehicles');

        // Primeiro, buscar todos os veículos ativos
        const vehiclesQuery = query(
            vehiclesRef,
            where('estaAtivo', '==', true)
        );
        const vehiclesSnapshot = await getDocs(vehiclesQuery);
        const vehicles = vehiclesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));

        // Depois, buscar todas as viagens no período
        const travelsQuery = query(
            travelsRef,
            and(
                where('estaAtivo', '==', true),
                or(
                    where('dataIda', '>=', startDate),
                    where('dataRetorno', '>=', startDate)
                )
            )
        );

        const travelsSnapshot = await getDocs(travelsQuery);

        if (travelsSnapshot.empty) {
            return { vehicles, schedules: [] };
        }

        const travels = travelsSnapshot.docs
            .map(doc => {
                const travel = { id: doc.id, ...doc.data() };

                const departureDate = new Date(`${travel.dataIda}T${travel.horarioIda}`);
                const returnDate = travel.somenteIda
                    ? null
                    : new Date(`${travel.dataRetorno}T${travel.horarioRetorno}`);

                // Verifica se a viagem está dentro do período solicitado
                const startDateObj = new Date(startDate);
                const endDateObj = new Date(endDate);
                endDateObj.setHours(23, 59, 59, 999);

                const isRelevant = (
                    (departureDate >= startDateObj && departureDate <= endDateObj) ||
                    (returnDate && returnDate >= startDateObj && returnDate <= endDateObj) ||
                    (returnDate && departureDate <= endDateObj && returnDate >= startDateObj)
                );

                if (!isRelevant) {
                    return null;
                }

                // Buscar informações do veículo associado
                const vehicle = vehicles.find(v => v.id === travel.veiculoId);

                return {
                    id: travel.id,
                    vehicleId: travel.veiculoId,
                    vehicleInfo: vehicle ? {
                        identificadorVeiculo: vehicle.identificadorVeiculo,
                        placa: vehicle.placa,
                        empresa: vehicle.empresa
                    } : null,
                    identificador: travel.identificador,
                    origem: travel.origem,
                    destino: travel.destino,
                    departureDate,
                    returnDate,
                    dataIda: travel.dataIda,
                    horarioIda: travel.horarioIda,
                    dataRetorno: travel.dataRetorno,
                    horarioRetorno: travel.horarioRetorno,
                    informacoesAdicionais: travel.informacoesAdicionais,
                    somenteIda: travel.somenteIda,
                    status: travel.status
                };
            })
            .filter(travel =>
                travel !== null &&
                !['Cancelada'].includes(travel.status)
            )
            .sort((a, b) => a.departureDate - b.departureDate);

        // Agrupar as viagens por veículo
        const schedules = vehicles.map(vehicle => ({
            vehicleId: vehicle.id,
            vehicleInfo: {
                identificadorVeiculo: vehicle.identificadorVeiculo,
                placa: vehicle.placa,
                empresa: vehicle.empresa
            },
            travels: travels.filter(travel => travel.vehicleId === vehicle.id)
        }));

        return { vehicles, schedules };

    } catch (error) {
        console.error('Erro ao buscar agenda da frota:', error);
        throw error;
    }
};