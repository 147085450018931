import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, IconButton, Snackbar, Alert, Typography, CircularProgress, Button, Paper, Tooltip, Tabs, Tab } from '@mui/material';
import { ArrowBack, Payment, MonetizationOn } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';

import TransactionForm from './TransactionForm';
import TransactionList from './components/TransactionList';
import TransacitionSummary from './components/TransacitionSummary';
import BudgetPanel from './components/BudgetPanel';
import TravelTransactionsHelp from './helps/TravelTransactionsHelp';
import TravelLoading from '../../shared/common/TravelLoading';

import { useAuth } from '../../core/contexts/useAuthState';
import { usePermissions } from '../../hooks/usePermissions';
import { TRAVEL_TYPES } from '../Travel/services/TravelService';
import { getCostsByTravelId, addCost, updateCost, deleteCost } from './services/TravelTransactionsService';
import { getBudgetPayments, addBudgetPayment, updateBudgetPayment, deleteBudgetPayment, updateTravelBudget } from './services/BudgetService';
import { calculateTotalsByTravelId } from '../Allocation/services/OrderService';
import { getBankAccounts } from '../BankAccount/services/BankAccountService';
import { getTravelById } from '../Travel/services/TravelService';

const TravelTransactionsPage = () => {
  const { travelId } = useParams();
  const navigate = useNavigate();
  const [costs, setCosts] = useState([]);
  const [editingCost, setEditingCost] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [totalCosts, setTotalCosts] = useState(0);
  const [totalReceivings, setTotalReceivings] = useState(0);
  const [totalOrderReceived, setTotalOrderReceived] = useState(0);
  const [totalOrderReceivable, setTotalOrderReceivable] = useState(0);
  const [openCostModal, setOpenCostModal] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [budget, setBudget] = useState(0);
  const [budgetPayments, setBudgetPayments] = useState([]);
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);
  const [travelType, setTravelType] = useState(null);
  const { currentUser } = useAuth();
  const { checkPermission } = usePermissions();
  const isGuide = currentUser?.role === 'guide';

  const paymentMethods = ['Dinheiro', 'Cartão', 'Transferência', 'Pix', 'Boleto', 'Outro'];
  const transactionTypes = ['Custo', 'Recebimento'];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [
          costsData,
          bankAccountsData,
          payments,
          travelData
        ] = await Promise.all([
          getCostsByTravelId(travelId),
          getBankAccounts(),
          getBudgetPayments(travelId),
          getTravelById(travelId)
        ]);

        setTravelType(travelData.type || TRAVEL_TYPES.REGULAR);

        setCosts(costsData);
        setBankAccounts(bankAccountsData);
        setBudgetPayments(payments);
        setBudget(travelData.budget || 0);

        const { totalReceived, totalReceivable } = await calculateTotalsByTravelId(travelId);

        const calculatedTotalCosts = costsData
          .filter((cost) => cost.type === 'Custo')
          .reduce((acc, curr) => acc + parseFloat(curr.amount), 0);

        const calculatedTotalReceivings = costsData
          .filter((cost) => cost.type === 'Recebimento')
          .reduce((acc, curr) => acc + parseFloat(curr.amount), 0);

        setTotalCosts(calculatedTotalCosts);
        setTotalReceivings(calculatedTotalReceivings);
        setTotalOrderReceived(totalReceived);
        setTotalOrderReceivable(totalReceivable);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setSnackbarMessage('Erro ao carregar dados da viagem');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [travelId]);

  const getVisibleTabs = () => {
    if (travelType === TRAVEL_TYPES.FREIGHT) {
      if (isGuide) {
        return ['transactions'];
      }
      return ['budget'];
    } else {
      if (isGuide) {
        return ['transactions'];
      }
      return ['transactions', 'budget'];
    }
  };

  const visibleTabs = getVisibleTabs();

  const canShowTab = (tabName) => {
    return visibleTabs.includes(tabName);
  };

  const canShowBudget = () => {
    if (travelType === TRAVEL_TYPES.FREIGHT) {
      return !isGuide && checkPermission('travels_transactions', 'view');
    }
    return checkPermission('travels_transactions', 'view');
  };

  const handleOpenAddCost = () => {
    setEditingCost(null);
    setOpenCostModal(true);
  };

  const handleOpenEditCost = (cost) => {
    setEditingCost(cost);
    setOpenCostModal(true);
  };

  const handleCloseCostModal = () => {
    setOpenCostModal(false);
    setEditingCost(null);
  };

  const handleSubmitCost = async (costData) => {
    if (!costData.description || !costData.amount || !costData.type || !costData.date || !costData.paymentMethod) {
      setSnackbarMessage('Por favor, preencha todos os campos obrigatórios.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    try {
      let newCosts;

      if (editingCost) {
        await updateCost(travelId, costData.id, costData);
        newCosts = costs.map((cost) =>
          cost.id === costData.id ? costData : cost
        );
      } else {
        const addedCost = await addCost(travelId, costData);
        newCosts = [...costs, addedCost];
      }

      setCosts(newCosts);

      const newTotalCosts = newCosts
        .filter((cost) => cost.type === 'Custo')
        .reduce((acc, curr) => acc + parseFloat(curr.amount || 0), 0);

      const newTotalReceivings = newCosts
        .filter((cost) => cost.type === 'Recebimento')
        .reduce((acc, curr) => acc + parseFloat(curr.amount || 0), 0);

      setTotalCosts(newTotalCosts);
      setTotalReceivings(newTotalReceivings);
      setSnackbarMessage(editingCost ? 'Transação atualizada com sucesso!' : 'Transação adicionada com sucesso!');
      setSnackbarSeverity('success');
      handleCloseCostModal();
    } catch (error) {
      console.error('Erro ao salvar transação:', error);
      setSnackbarMessage(
        `Erro ao ${editingCost ? 'atualizar' : 'adicionar'} transação: ${error.message || 'Erro desconhecido'}`
      );
      setSnackbarSeverity('error');
    }
    setSnackbarOpen(true);
  };

  const handleDeleteCost = async (costId) => {
    try {
      await deleteCost(travelId, costId);
      const updatedCosts = costs.filter((cost) => cost.id !== costId);
      setCosts(updatedCosts);

      const calculatedTotalCosts = updatedCosts
        .filter((cost) => cost.type === 'Custo')
        .reduce((acc, curr) => acc + parseFloat(curr.amount), 0);
      const calculatedTotalReceivings = updatedCosts
        .filter((cost) => cost.type === 'Recebimento')
        .reduce((acc, curr) => acc + parseFloat(curr.amount), 0);

      setTotalCosts(calculatedTotalCosts);
      setTotalReceivings(calculatedTotalReceivings);
      setSnackbarMessage('Transação excluída com sucesso!');
      setSnackbarSeverity('success');
    } catch (error) {
      setSnackbarMessage('Erro ao excluir transação.');
      setSnackbarSeverity('error');
    }
    setSnackbarOpen(true);
  };

  const handleBudgetChange = async (newBudget) => {
    try {
      await updateTravelBudget(travelId, newBudget);
      setBudget(newBudget);
    } catch (error) {
      setSnackbarMessage('Erro ao atualizar orçamento');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleAddBudgetPayment = async (payment) => {
    try {
      const newPayment = await addBudgetPayment(travelId, payment);
      setBudgetPayments([...budgetPayments, newPayment]);
    } catch (error) {
      setSnackbarMessage('Erro ao adicionar pagamento');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditBudgetPayment = async (payment) => {
    try {
      const updatedPayment = await updateBudgetPayment(travelId, payment.id, payment);

      setBudgetPayments(prevPayments =>
        prevPayments.map(p =>
          p.id === payment.id ? updatedPayment : p
        )
      );

      setSnackbarMessage('Pagamento atualizado com sucesso!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Erro ao atualizar pagamento:', error);
      setSnackbarMessage('Erro ao atualizar pagamento');
      setSnackbarSeverity('error');
    }
    setSnackbarOpen(true);
  };

  const handleDeleteBudgetPayment = async (paymentId) => {
    try {
      await deleteBudgetPayment(travelId, paymentId);

      setBudgetPayments(prevPayments =>
        prevPayments.filter(p => p.id !== paymentId)
      );

      setSnackbarMessage('Pagamento excluído com sucesso!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Erro ao excluir pagamento:', error);
      setSnackbarMessage('Erro ao excluir pagamento');
      setSnackbarSeverity('error');
    }
    setSnackbarOpen(true);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleTabChange = (event, newValue) => {
    const tabName = newValue === 0 ? 'transactions' : 'budget';
    if (canShowTab(tabName)) {
      setSelectedTab(newValue);
    }
  };

  if (loading) {
    return <TravelLoading />;
  }

  return (
    <Box>
      {/* Header */}
      <Box sx={{ p: 0 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: 2,
            mb: 3,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={handleGoBack} sx={{ mr: 1 }}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">Financeiro</Typography>
            <Box component="span" sx={{ ml: 1 }}>
              <TravelTransactionsHelp />
            </Box>
          </Box>

          <TransacitionSummary
            totalCosts={totalCosts || 0}
            totalReceivings={totalReceivings + totalOrderReceived || 0}
            totalOrderReceived={totalOrderReceived || 0}
            totalOrderReceivable={totalOrderReceivable || 0}
            travelType={travelType}
            isGuide={isGuide}
          />
        </Box>

        {/* Tabs - Only show allowed tabs */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            {canShowTab('transactions') && <Tab label="Transações" />}
            {canShowTab('budget') && <Tab label="Orçamento" />}
          </Tabs>
        </Box>
      </Box>

      {/* Transaction Form Modal */}
      <TransactionForm
        open={openCostModal && canShowTab('transactions')}
        onClose={handleCloseCostModal}
        onSubmit={handleSubmitCost}
        initialData={editingCost}
        bankAccounts={bankAccounts}
        transactionTypes={transactionTypes}
        paymentMethods={paymentMethods}
        loading={loading}
      />

      {/* Tab Content */}
      {selectedTab === 0 && canShowTab('transactions') && (
        <TransactionList
          costs={costs}
          handleEditClick={handleOpenEditCost}
          handleDeleteCost={handleDeleteCost}
          handleOpenAddCost={handleOpenAddCost}
          transactionTypes={transactionTypes}
          bankAccounts={bankAccounts}
          paymentMethods={paymentMethods}
          totalOrderReceived={totalOrderReceived}
          totalOrderReceivable={totalOrderReceivable}
          canEdit={travelType === TRAVEL_TYPES.FREIGHT ?
            isGuide :
            checkPermission('travels_transactions', 'edit')
          }
          canDelete={travelType === TRAVEL_TYPES.FREIGHT ?
            isGuide :
            checkPermission('travels_transactions', 'delete')
          }
          canCreate={travelType === TRAVEL_TYPES.FREIGHT ?
            isGuide :
            checkPermission('travels_transactions', 'create')
          }
        />
      )}
      {((travelType === TRAVEL_TYPES.FREIGHT && !isGuide && selectedTab === 0) ||
        (travelType !== TRAVEL_TYPES.FREIGHT && selectedTab === 1)) &&
        canShowTab('budget') &&
        canShowBudget() && (
          <BudgetPanel
            budget={budget}
            onBudgetChange={handleBudgetChange}
            budgetPayments={budgetPayments}
            onAddPayment={handleAddBudgetPayment}
            onEditPayment={handleEditBudgetPayment}
            onDeletePayment={handleDeleteBudgetPayment}
            paymentMethods={paymentMethods}
            bankAccounts={bankAccounts}
            canView={travelType === TRAVEL_TYPES.FREIGHT ?
              !isGuide :
              checkPermission('travels_transactions', 'view')
            }
            canEdit={travelType === TRAVEL_TYPES.FREIGHT ?
              !isGuide :
              checkPermission('travels_transactions', 'edit')
            }
            canCreate={travelType === TRAVEL_TYPES.FREIGHT ?
              !isGuide :
              checkPermission('travels_transactions', 'create')
            }
            canDelete={travelType === TRAVEL_TYPES.FREIGHT ?
              !isGuide :
              checkPermission('travels_transactions', 'delete')
            }
          />
        )}

      {/* Snackbar */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TravelTransactionsPage;