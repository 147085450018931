import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  InputAdornment,
  Grid,
  FormControl,
  InputLabel,
  Select,
  CircularProgress
} from '@mui/material';
import { useCurrencyInput, currencyNames } from '../../shared/utils/formatCurrency';

const TransactionForm = ({
  open,
  onClose,
  onSubmit,
  initialData,
  bankAccounts = [], 
  transactionTypes,
  paymentMethods,
  loading
}) => {
  const [formData, setFormData] = useState({
    description: '',
    amount: '',
    type: '',
    date: new Date().toISOString().split('T')[0],
    paymentMethod: '',
    bankAccountId: ''
  });

  const {
    value: amountValue,
    handleChange: handleAmountChange,
    handleBlur: handleAmountBlur,
    toNumber: getAmountNumber,
    setValue: setAmountValue
  } = useCurrencyInput(formData.amount);

  useEffect(() => {
    if (initialData) {
      setFormData({
        ...initialData,
        date: initialData.date.split('T')[0]
      });
      setAmountValue(initialData.amount.toString());
    } else {
      setFormData({
        description: '',
        amount: '',
        type: '',
        date: new Date().toISOString().split('T')[0],
        paymentMethod: '',
        bankAccountId: ''
      });
      setAmountValue('');
    }
  }, [initialData, setAmountValue, open]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const submitData = {
      ...formData,
      amount: getAmountNumber()
    };
    onSubmit(submitData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {initialData ? 'Editar Transação' : 'Nova Transação'}
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                label="Descrição"
                name="description"
                value={formData.description}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Valor"
                value={amountValue}
                onChange={handleAmountChange}
                onBlur={handleAmountBlur}
                fullWidth
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {currencyNames['BRL'].symbol}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  inputMode: 'numeric'
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Data"
                name="date"
                type="date"
                value={formData.date}
                onChange={handleChange}
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth required>
                <InputLabel>Tipo</InputLabel>
                <Select
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  label="Tipo"
                >
                  {transactionTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth required>
                <InputLabel>Método de Pagamento</InputLabel>
                <Select
                  name="paymentMethod"
                  value={formData.paymentMethod}
                  onChange={handleChange}
                  label="Método de Pagamento"
                >
                  {paymentMethods.map((method) => (
                    <MenuItem key={method} value={method}>
                      {method}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Conta Bancária</InputLabel>
                <Select
                  name="bankAccountId"
                  value={formData.bankAccountId}
                  onChange={handleChange}
                  label="Conta Bancária"
                >
                  <MenuItem value="">
                    <em>Nenhuma</em>
                  </MenuItem>
                  {bankAccounts.map((account) => (
                    <MenuItem key={account.id} value={account.id}>
                      {account.name} - {account.bank}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
          <Button
            onClick={onClose}
            variant="outlined"
            disabled={loading}
            sx={{ borderRadius: '50px' }}
          >
            Voltar
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            sx={{ borderRadius: '50px' }}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : initialData ? (
              'Salvar'
            ) : (
              'Adicionar'
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TransactionForm;