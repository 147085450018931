import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  IconButton,
  Stack,
  Typography,
  Box,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';
import { format, isAfter, addDays } from 'date-fns';
import TruncatedCell from '../../../shared/common/TruncatedCell';

import { usePermissions } from '../../../hooks/usePermissions';


const VehicleDocumentsList = ({ documents, onEdit, onDelete }) => {
  const { checkPermission } = usePermissions();

  const canEdit = checkPermission('vehicles', 'edit');
  const canDelete = checkPermission('vehicles', 'delete');

  const getStatusChip = (dataVencimento) => {
    const today = new Date();
    const data = new Date(dataVencimento);
    const thirtyDays = addDays(today, 30);

    if (isAfter(today, data)) {
      return <Chip label="Vencido" color="error" icon={<WarningIcon />} />;
    } else if (isAfter(thirtyDays, data)) {
      return <Chip label="Próximo ao vencimento" color="warning" icon={<WarningIcon />} />;
    } else {
      return <Chip label="Regular" color="success" />;
    }
  };

  if (!documents || documents.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', py: 3 }}>
        <Typography variant="body1">
          Nenhum documento encontrado
        </Typography>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Veículo</TableCell>
            <TableCell>Documento</TableCell>
            <TableCell>Número</TableCell>
            <TableCell>Vencimento</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Observações</TableCell>
            {(canEdit || canDelete) && <TableCell align="right">Ações</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map((doc) => (
            <TableRow key={doc.id}>
              <TruncatedCell text={doc.vehicle?.identificadorVeiculo} maxChars={30} />
              <TruncatedCell text={doc.nome} maxChars={30} />
              <TruncatedCell text={doc.numero} maxChars={25} />
              <TableCell>
                {format(new Date(doc.dataVencimento), 'dd/MM/yyyy')}
              </TableCell>
              <TableCell>{getStatusChip(doc.dataVencimento)}</TableCell>
              <TruncatedCell text={doc.observacoes} maxChars={40} />
              {(canEdit || canDelete) && (
                <TableCell align="right">
                  <Stack direction="row" spacing={1} justifyContent="flex-end">
                    {canEdit && (
                      <IconButton
                        size="small"
                        onClick={() => onEdit(doc)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    {canDelete && (
                      <IconButton
                        size="small"
                        onClick={() => onDelete(doc)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Stack>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VehicleDocumentsList;