import React, { useState } from 'react';
import { Box, Typography, Paper, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination, MenuItem, TextField, InputAdornment, Collapse, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Edit, Delete, Search, FilterList as FilterListIcon, MonetizationOn, Payment } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';

import TruncatedCell from '../../../shared/common/TruncatedCell';

import { formatDate, normalizeValue } from '../../../shared/utils/utils';
import { currencyNames } from '../../../shared/utils/formatCurrency';

const ITEMS_PER_PAGE = 10;
const DESCRIPTION_MAX_LENGTH = 30;

const TransactionList = ({
  costs,
  handleEditClick,
  handleDeleteCost,
  handleOpenAddCost,
  transactionTypes,
  paymentMethods,
  bankAccounts = [],
  totalOrderReceived = 0,
  totalOrderReceivable = 0,
  loading = false,
  canEdit = false,
  canDelete = false,
  canCreate = false
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterPaymentMethod, setFilterPaymentMethod] = useState('');
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedCost, setSelectedCost] = useState(null);
  const theme = useTheme();

  const getBankAccountName = (bankAccountId) => {
    if (!bankAccountId) return 'Nenhuma';
    const account = bankAccounts.find(account => account.id === bankAccountId);
    return account ? `${account.name} - ${account.bank}` : 'Não encontrada';
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const applyFilters = () => {
    return costs
      .filter((cost) => cost.description.toLowerCase().includes(searchTerm.toLowerCase()))
      .filter((cost) => (filterType ? cost.type === filterType : true))
      .filter((cost) => (filterPaymentMethod ? cost.paymentMethod === filterPaymentMethod : true));
  };

  const filteredCosts = applyFilters();
  const filteredExpenses = filteredCosts.filter((cost) => cost.type === 'Custo');
  const filteredIncomes = filteredCosts.filter((cost) => cost.type === 'Recebimento');
  const totalExpenses = filteredExpenses.reduce((sum, cost) => sum + parseFloat(cost.amount || 0), 0);
  const totalIncomes = filteredIncomes.reduce((sum, cost) => sum + parseFloat(cost.amount || 0), 0);

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentCosts = filteredCosts.slice(startIndex, endIndex);

  const handleOpenDeleteDialog = (cost) => {
    setSelectedCost(cost);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setSelectedCost(null);
  };

  const confirmDeleteCost = () => {
    if (selectedCost) {
      handleDeleteCost(selectedCost.id);
    }
    handleCloseDeleteDialog();
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 3,
        flexWrap: 'wrap',
        gap: 2
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6">Transações</Typography>
          <IconButton onClick={() => setFiltersVisible(!filtersVisible)}>
            <FilterListIcon />
          </IconButton>
          {canCreate && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOpenAddCost}
              sx={{ borderRadius: '50px' }}
            >
              Adicionar
            </Button>
          )}
        </Box>

        <Paper elevation={1} sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          p: 1.5,
          borderRadius: '8px',
          bgcolor: theme.palette.background.default
        }}>
          <Typography variant="caption" color="text.secondary" sx={{ fontStyle: 'italic' }}>
            Totais das transações filtradas:
          </Typography>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body2" color={theme.palette.text.primary}>
                <strong>Custos:</strong>
              </Typography>
              <Typography variant="body1" >
                R$ {normalizeValue(totalExpenses.toFixed(2))}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body2" color={theme.palette.text.primary}>
                <strong>Recebimentos:</strong>
              </Typography>
              <Typography variant="body1" >
                R$ {normalizeValue(totalIncomes.toFixed(2))}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>

      <Collapse in={filtersVisible}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3, flexWrap: 'wrap', gap: 2 }}>
          <TextField
            label="Buscar por Descrição"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ flex: 1, minWidth: '200px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="action" />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Filtrar por Tipo"
            select
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            sx={{ flex: 1, minWidth: '200px' }}
          >
            <MenuItem value="">Todos</MenuItem>
            {transactionTypes.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Filtrar por Método de Pagamento"
            select
            value={filterPaymentMethod}
            onChange={(e) => setFilterPaymentMethod(e.target.value)}
            sx={{ flex: 1, minWidth: '200px' }}
          >
            <MenuItem value="">Todos</MenuItem>
            {paymentMethods.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Collapse>

      {filteredCosts.length === 0 ? (
        <Paper elevation={1} sx={{ p: 3, mt: 4, textAlign: 'center' }}>
          <Typography variant="h6" color="textSecondary">
            Nenhuma transação encontrada
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {canCreate ?
              'Ajuste os filtros ou adicione uma nova transação para vê-la aqui.' :
              'Ajuste os filtros para visualizar as transações.'
            }
          </Typography>
        </Paper>
      ) : (
        <>
          <TableContainer component={Paper} elevation={2}>
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    'Descrição',
                    'Valor',
                    'Data',
                    'Tipo',
                    'Método de Pagamento',
                    'Conta Bancária',
                    // Only show Actions column if user has edit or delete permissions
                    ...(canEdit || canDelete ? ['Ações'] : [])
                  ].map((header) => (
                    <TableCell
                      key={header}
                      align={header === 'Ações' ? 'center' : 'left'}
                      sx={{ fontWeight: 'bold' }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentCosts.map((cost) => (
                  <TableRow key={cost.id}>
                    <TruncatedCell text={cost.description} maxChars={DESCRIPTION_MAX_LENGTH} />
                    <TableCell>{currencyNames['BRL'].symbol} {normalizeValue(cost.amount)}</TableCell>
                    <TableCell>{formatDate(cost.date)}</TableCell>
                    <TableCell>{cost.type}</TableCell>
                    <TableCell>{cost.paymentMethod}</TableCell>
                    <TruncatedCell text={getBankAccountName(cost.bankAccountId)} maxChars={30} />
                    {/* Only show actions cell if user has permissions */}
                    {(canEdit || canDelete) && (
                      <TableCell align="center">
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                          {canEdit && (
                            <Tooltip title="Editar" arrow>
                              <IconButton onClick={() => handleEditClick(cost)}>
                                <Edit />
                              </IconButton>
                            </Tooltip>
                          )}
                          {canDelete && (
                            <Tooltip title="Excluir" arrow>
                              <IconButton onClick={() => handleOpenDeleteDialog(cost)}>
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Pagination
              count={Math.ceil(filteredCosts.length / ITEMS_PER_PAGE)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              shape="rounded"
              sx={{ borderRadius: '50px' }}
            />
          </Box>
        </>
      )}

      {/* Only render delete dialog if delete permission exists */}
      {canDelete && (
        <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirmar Exclusão</DialogTitle>
          <DialogContent>
            <Typography>Tem certeza de que deseja excluir esta transação?</Typography>
            {selectedCost && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2">
                  <strong>Descrição:</strong> {selectedCost.description}
                </Typography>
                <Typography variant="body2">
                  <strong>Valor:</strong> R$ {selectedCost.amount}
                </Typography>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} variant="outlined" sx={{ borderRadius: '50px' }}>
              Voltar
            </Button>
            <Button
              onClick={confirmDeleteCost}
              color="error"
              variant="contained"
              sx={{ color: 'white', borderRadius: '50px' }}
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default TransactionList;