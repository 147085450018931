import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Box, Typography, Tooltip, IconButton, Avatar, Divider } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import BusinessIcon from '@mui/icons-material/Business';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

import { usePermissions } from '../../hooks/usePermissions';
import { formatPlate } from '../../shared/utils/utils';

const VehicleCard = ({ vehicle, onEdit, onDelete }) => {
  const { checkPermission } = usePermissions();
  const navigate = useNavigate();

  const canEdit = checkPermission('vehicles', 'edit');
  const canDelete = checkPermission('vehicles', 'delete');

  const handleCardClick = () => {
    navigate(`/veiculos/${vehicle.id}`);
  };

  return (
    <Card
      onClick={handleCardClick}
      sx={{
        cursor: 'pointer',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        position: 'relative',
        transition: 'transform 0.2s, box-shadow 0.2s',
        '&:hover': { transform: 'scale(1.02)', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)' },
        mb: 1,
        borderRadius: 2,
        width: '100%',
      }}
    >
      <CardContent sx={{ padding: '8px !important' }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 1,
          flexWrap: 'nowrap',
          minWidth: 0
        }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            minWidth: 0,
            flex: 1
          }}>
            <Avatar sx={{
              bgcolor: 'primary.main',
              mr: 1,
              width: 28,
              height: 28,
              flexShrink: 0
            }}>
              <DirectionsBusIcon fontSize="small" />
            </Avatar>
            <Box sx={{ minWidth: 0 }}>
              <Tooltip title={vehicle.identificadorVeiculo} arrow placement="top" disableHoverListener={vehicle.identificadorVeiculo.length <= 20}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: 'primary.main',
                    maxWidth: { xs: '120px', sm: '150px', md: '200px' },
                  }}
                >
                  {vehicle.identificadorVeiculo}
                </Typography>
              </Tooltip>
              <Typography variant="caption" color="textSecondary">
                Placa: {formatPlate(vehicle.placa)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            ml: 1,
            flexShrink: 0
          }}>
            {canEdit && onEdit && (
              <Tooltip title="Editar">
                <IconButton
                  size="small"
                  edge="end"
                  aria-label="edit"
                  onClick={(e) => { e.stopPropagation(); onEdit(vehicle); }}
                  sx={{ mr: 0.5 }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {canDelete && onDelete && (
              <Tooltip title="Excluir">
                <IconButton
                  size="small"
                  edge="end"
                  aria-label="delete"
                  onClick={(e) => { e.stopPropagation(); onDelete(vehicle); }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Divider sx={{ mb: 1 }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <BusinessIcon fontSize="small" />
            <Typography variant="caption" sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              Empresa: {vehicle.empresa}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <EventSeatIcon fontSize="small" />
            <Typography variant="caption" sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {vehicle.layout ? (
                `Layout: ${vehicle.layout.name || 'Sem nome'} - ${vehicle.layout.assentosTotais} assentos`
              ) : (
                'Nenhum layout associado'
              )}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <TravelExploreIcon fontSize="small" />
            <Typography variant="caption">Viagens Alocadas: {vehicle.numTravels || 0}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default VehicleCard;