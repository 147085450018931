import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { styled, useTheme } from '@mui/material/styles';
import ContactSvg from '../../../assets/landingpage/contact-us-animate.svg';
import { init, sendForm } from 'emailjs-com';

// Inicializa o EmailJS com seu User ID
init("8hcdiwwyR5OXExsP2");

const contactInfo = [
  {
    icon: <EmailRoundedIcon fontSize="large" />,
    title: 'E-mail',
    description: 'travell.suporte@gmail.com',
    href: 'mailto:travell.suporte@gmail.com',
  },
  {
    icon: <LocationOnRoundedIcon fontSize="large" />,
    title: 'Localização',
    description: 'Ponta Grossa, Paraná, Brasil',
  },
];

const AnimatedContainer = styled(Box)(({ theme }) => ({
  opacity: 0,
  transform: 'translateY(50px)',
  transition: 'opacity 0.6s ease-out, transform 0.6s ease-out',
  '&.visible': {
    opacity: 1,
    transform: 'translateY(0)',
  }
}));

const useScrollReveal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return [ref, isVisible];
};

export default function Contact() {
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const [containerRef, isContainerVisible] = useScrollReveal();
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    telefone: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    telefone: false,
    message: false,
  });

  const [formFeedback, setFormFeedback] = useState('');
  const [success, setSuccess] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateForm = (data) => {
    const errors = {
      name: data.name.trim() === '',
      email: !/\S+@\S+\.\S+/.test(data.email),
      telefone: data.telefone.trim() === '',
      message: data.message.trim() === '',
    };
    setFormErrors(errors);
    return !Object.values(errors).some(Boolean);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm(formValues)) {
      sendForm("default_service", "template_67ky9kq", "#contact-form")
        .then(() => {
          setSuccess(true);
          setFormFeedback("Mensagem enviada com sucesso!");
          setFormValues({ name: '', email: '', telefone: '', message: '' });
          setFormErrors({ name: false, email: false, telefone: false, message: false });
        })
        .catch(() => {
          setSuccess(false);
          setFormFeedback("Erro ao enviar a mensagem. Tente novamente.");
        });
    } else {
      setFormFeedback('Por favor, corrija os erros no formulário.');
    }
  };

  return (
    <AnimatedContainer
      ref={containerRef}
      id="contato"
      className={isContainerVisible ? 'visible' : ''}
      sx={{
        pt: { xs: 2, sm: 6 },
        pb: { xs: 4, sm: 8 },
        color: theme.palette.text.primary,
        bgcolor: theme.palette.background.default,
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 1, sm: 3 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
            mb: 2,
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            Contato
          </Typography>
          <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
            Entre em contato conosco para mais informações sobre nossos serviços ou caso tenha dúvidas. Estamos prontos para ajudar!
          </Typography>
        </Box>

        {/* Informações de contato */}
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          sx={{
            textAlign: 'center',
            mb: 2,
            opacity: isContainerVisible ? 1 : 0,
            transform: isContainerVisible ? 'translateY(0)' : 'translateY(20px)',
            transition: 'opacity 0.6s ease-out 0.2s, transform 0.6s ease-out 0.2s'
          }}
        >
          {contactInfo.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              key={index}
              sx={{
                opacity: isContainerVisible ? 1 : 0,
                transform: isContainerVisible ? 'translateY(0)' : 'translateY(20px)',
                transition: `opacity 0.6s ease-out ${0.3 + index * 0.1}s, transform 0.6s ease-out ${0.3 + index * 0.1}s`
              }}
            >
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                sx={{
                  color: theme.palette.text.primary,
                  p: 2,
                  height: '100%',
                  borderColor: 'hsla(220, 25%, 25%, 0.3)',
                  backgroundColor: theme.palette.mode === 'dark' ? 'grey.800' : 'grey.100',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                    transition: 'background-color 0.3s',
                  },
                }}
              >
                <Box sx={{ opacity: '50%', fontSize: 'small' }}>{item.icon}</Box>
                <div>
                  {item.href ? (
                    <a href={item.href} style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
                      <Typography gutterBottom sx={{ fontWeight: 'medium', fontSize: 'small' }}>
                        {item.title}
                      </Typography>
                      <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontSize: 'small' }}>
                        {item.description}
                      </Typography>
                    </a>
                  ) : (
                    <>
                      <Typography gutterBottom sx={{ fontWeight: 'medium', fontSize: 'small' }}>
                        {item.title}
                      </Typography>
                      <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontSize: 'small' }}>
                        {item.description}
                      </Typography>
                    </>
                  )}
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>

        {/* Layout para Tabs e Imagem */}
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{
            mt: 2,
            opacity: isContainerVisible ? 1 : 0,
            transform: isContainerVisible ? 'translateY(0)' : 'translateY(20px)',
            transition: 'opacity 0.6s ease-out 0.5s, transform 0.6s ease-out 0.5s'
          }}
        >
          {/* Coluna com a imagem */}
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={ContactSvg}
              alt="Contact illustration"
              sx={{
                width: '100%',
                maxWidth: 300,
                mx: 'auto',
                display: { xs: 'block', md: 'block' },
                height: 'auto',
              }}
            />
          </Grid>

          {/* Coluna com as Tabs */}
          <Grid item xs={12} md={6}>
            <Box>
              <Tabs value={tabValue} onChange={handleTabChange} centered variant="fullWidth">
                <Tab label="WhatsApp" />
                <Tab label="Formulário" />
              </Tabs>

              {/* Conteúdo da aba WhatsApp */}
              {tabValue === 0 && (
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                  <Button
                    variant="contained"
                    color="success"
                    size="large"
                    startIcon={<WhatsAppIcon />}
                    href="https://wa.me/554288333458?text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20seus%20servi%C3%A7os%21"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Fale Conosco no WhatsApp
                  </Button>
                </Box>
              )}

              {/* Conteúdo da aba Formulário */}
              {tabValue === 1 && (
                <Box
                  component="form"
                  id="contact-form"
                  onSubmit={handleSubmit}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    mt: 4,
                  }}
                >
                  <TextField
                    label="Nome"
                    variant="outlined"
                    fullWidth
                    required
                    name="name"
                    value={formValues.name}
                    onChange={handleInputChange}
                    error={formErrors.name}
                    helperText={formErrors.name ? 'Campo obrigatório' : ''}
                  />
                  <TextField
                    label="E-mail"
                    variant="outlined"
                    fullWidth
                    required
                    name="email"
                    value={formValues.email}
                    onChange={handleInputChange}
                    error={formErrors.email}
                    helperText={formErrors.email ? 'E-mail inválido' : ''}
                  />
                  <TextField
                    label="Telefone"
                    variant="outlined"
                    fullWidth
                    required
                    name="telefone"
                    value={formValues.telefone}
                    onChange={handleInputChange}
                    error={formErrors.telefone}
                    helperText={formErrors.telefone ? 'Campo obrigatório' : ''}
                  />
                  <TextField
                    label="Mensagem"
                    variant="outlined"
                    fullWidth
                    required
                    name="message"
                    value={formValues.message}
                    onChange={handleInputChange}
                    error={formErrors.message}
                    helperText={formErrors.message ? 'Campo obrigatório' : ''}
                  />
                  <Button variant="contained" color="primary" size="large" type="submit">
                    Enviar Mensagem
                  </Button>

                  {formFeedback && (
                    <Typography
                      variant="body2"
                      sx={{ mt: 2, color: success ? 'green' : 'red', textAlign: 'center' }}
                    >
                      {formFeedback}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </AnimatedContainer>
  );
}