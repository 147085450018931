import { db, auth } from '../../../firebaseConfig';
import { collection, doc, getDocs, getDoc, query, where, orderBy, limit, startAfter } from 'firebase/firestore';
import { getBankAccounts } from '../../BankAccount/services/BankAccountService';
import { adaptPayablesData } from '../adapters/PayablesAdapter';

const getAgencyRef = async () => {
  const user = auth.currentUser;
  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  return doc(db, 'agencies', userDoc.data().agencyId);
};

export const getPaginatedPayables = async (pageSize = 5, lastDoc = null, filters = {}, currentPage = 1, getAllRecords = false) => {
  try {
    const agencyRef = await getAgencyRef();
    const travelsRef = collection(agencyRef, 'travels');

    const queryConstraints = [
      where('estaAtivo', '==', true),
      where('status', '!=', 'Cancelada')
    ];

    const baseQuery = query(travelsRef, ...queryConstraints);
    const travelsSnapshot = await getDocs(baseQuery);
    const bankAccounts = await getBankAccounts();

    const travelsData = await Promise.all(
      travelsSnapshot.docs.map(async (travelDoc) => {
        const travel = { id: travelDoc.id, ...travelDoc.data() };

        const costsRef = collection(agencyRef, 'costs');
        const costsQuery = query(costsRef, where('travelId', '==', travel.id));
        const costsSnapshot = await getDocs(costsQuery);
        travel.costs = costsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        return travel;
      })
    );

    const adaptedData = adaptPayablesData(
      travelsData,
      bankAccounts,
      pageSize,
      currentPage,
      filters,
      getAllRecords 
    );

    return {
      payables: adaptedData.items,
      totalCount: adaptedData.totalItems,
      totalPages: adaptedData.totalPages
    };
  } catch (error) {
    console.error('Error fetching payables:', error);
    throw error;
  }
};