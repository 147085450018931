import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

const BankAccountForm = ({ onSave, initialAccount, onCancel }) => {
  const [formData, setFormData] = useState({
    name: '',
    bank: '',
    agency: '',
    account: '',
    accountType: 'checking',
    pixKey: '',
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (initialAccount) {
      setFormData({
        name: initialAccount.name || '',
        bank: initialAccount.bank || '',
        agency: initialAccount.agency || '',
        account: initialAccount.account || '',
        accountType: initialAccount.accountType || 'checking',
        pixKey: initialAccount.pixKey || '',
      });
    }
  }, [initialAccount]);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name?.trim()) {
      newErrors.name = 'Nome é obrigatório';
    }
    if (!formData.bank?.trim()) {
      newErrors.bank = 'Banco é obrigatório';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: undefined
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    try {
      const accountData = {
        ...formData,
        ...(initialAccount?.id && { id: initialAccount.id })
      };
      
      await onSave(accountData);
    } catch (error) {
      console.error('Erro ao salvar conta bancária:', error);
    } finally {
      setLoading(false);
    }
  };

  const getAccountTypeLabel = (type) => {
    switch (type) {
      case 'checking':
        return 'Conta Corrente';
      case 'savings':
        return 'Conta Poupança';
      case 'investment':
        return 'Conta Investimento';
      default:
        return type;
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', maxWidth: 600 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {initialAccount ? 'Editar Conta Bancária' : 'Nova Conta Bancária'}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nome de exibição da conta"
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
            disabled={loading}
            required
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Banco"
            name="bank"
            value={formData.bank}
            onChange={handleChange}
            error={!!errors.bank}
            helperText={errors.bank}
            disabled={loading}
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Agência"
            name="agency"
            value={formData.agency}
            onChange={handleChange}
            error={!!errors.agency}
            helperText={errors.agency}
            disabled={loading}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Conta"
            name="account"
            value={formData.account}
            onChange={handleChange}
            error={!!errors.account}
            helperText={errors.account}
            disabled={loading}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="tipo-conta-label">Tipo de Conta</InputLabel>
            <Select
              labelId="tipo-conta-label"
              name="accountType"
              value={formData.accountType}
              onChange={handleChange}
              label="Tipo de Conta"
              disabled={loading}
            >
              <MenuItem value="checking">Conta Corrente</MenuItem>
              <MenuItem value="savings">Conta Poupança</MenuItem>
              <MenuItem value="investment">Conta Investimento</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Chave PIX"
            name="pixKey"
            value={formData.pixKey}
            onChange={handleChange}
            disabled={loading}
          />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between', mt: 2 }}>
            <Button
              variant="outlined"
              onClick={onCancel}
              disabled={loading}
              sx={{ borderRadius: '50px' }}
            >
              Voltar
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              sx={{ borderRadius: '50px' }}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : initialAccount ? (
                'Salvar'
              ) : (
                'Adicionar'
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BankAccountForm;