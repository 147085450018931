import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Typography, Button, Box, CircularProgress, Snackbar, Alert, IconButton, Modal, Fade, Dialog, DialogActions, DialogContent, Tooltip, DialogContentText, DialogTitle, TextField, Pagination, MenuItem, Select, FormControl, InputLabel, InputAdornment, Collapse } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import SearchIcon from '@mui/icons-material/Search';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import TravelForm from './TravelForm';
import TravelCard from '../../components/Travel/TravelCard';
import TravelList from '../../components/Travel/TravelList';
import TravelPageHelp from './helps/TravelPageHelp';
import TravelLoading from '../../shared/common/TravelLoading';

import { addTravel, getPaginatedTravels, updateTravel, deleteTravel, cancelTravel } from './services/TravelService';
import { usePermissions } from '../../hooks/usePermissions';
import { formatDate } from '../../shared/utils/utils';
import { useMasterPassword } from '../../shared/hooks/useMasterPassword';
import { getReservationsByTravelId } from '../Allocation/services/OrderService';
import { getSavedViewType, saveViewType } from '../../shared/utils/localStorageUtils';

const TravelPage = () => {
  const { checkPermission } = usePermissions();
  const [travels, setTravels] = useState([]);
  const [filteredTravels, setFilteredTravels] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [order, setOrder] = useState('nearest');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);
  const [openModal, setOpenModal] = useState(false);
  const [editingTravel, setEditingTravel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [travelToDelete, setTravelToDelete] = useState(null);
  const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);
  const [travelToCancel, setTravelToCancel] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [masterPassword, setMasterPassword] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [reservationsCount, setReservationsCount] = useState(0);
  const [viewMode, setViewMode] = useState(getSavedViewType());
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [searchInputValue, setSearchInputValue] = useState('');
  const {
    isVerifying,
    error: masterPasswordError,
    clearError,
    masterPasswordActive,
    showPassword,
    togglePasswordVisibility,
    verifyMasterPassword
  } = useMasterPassword();

  const canCreate = checkPermission('travels', 'create');
  const canEdit = checkPermission('travels', 'edit');
  const canDelete = checkPermission('travels', 'delete');
  const canCancel = checkPermission('travels', 'cancel');

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    saveViewType(mode);
  };

  useEffect(() => {
    const fetchTravelsData = async () => {
      setLoading(true);
      try {
        const filters = {
          searchTerm,
          startDate,
          endDate,
          status: statusFilter,
          order,
          type: 'regular'
        };

        const result = await getPaginatedTravels(
          itemsPerPage,
          null,
          filters
        );

        setTravels(result.travels);
        setLastDoc(result.lastDoc);
        setHasMore(result.hasMore);
        setTotalCount(result.totalCount);
      } catch (error) {
        setError('Erro ao buscar viagens: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTravelsData();
  }, []);

  useEffect(() => {
    if (order || statusFilter || startDate || endDate || searchTerm) {
      fetchTravels(true);
    }
  }, [order, statusFilter, startDate, endDate, searchTerm]);

  const fetchTravels = useCallback(async (isNewFilter = false) => {
    setLoading(true);
    try {
      if (isNewFilter) {
        setTravels([]);
        setLastDoc(null);
        setCurrentPage(1);
      }

      const filters = {
        searchTerm,
        startDate,
        endDate,
        status: statusFilter,
        order,
        type: 'regular'
      };

      const result = await getPaginatedTravels(
        itemsPerPage,
        isNewFilter ? null : lastDoc,
        filters
      );

      setTravels(prev => isNewFilter ? result.travels : [...prev, ...result.travels]);
      setLastDoc(result.lastDoc);
      setHasMore(result.hasMore);
      setTotalCount(result.totalCount);
    } catch (error) {
      setError('Erro ao buscar viagens: ' + error.message);
    } finally {
      setLoading(false);
    }
  }, [searchTerm, startDate, endDate, statusFilter, order, lastDoc, itemsPerPage]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    if (newPage > currentPage && hasMore) {
      fetchTravels(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchInputValue(event.target.value);
  };

  const handleSearch = useCallback(() => {
    setSearchTerm(searchInputValue);
    fetchTravels(true);
  }, [searchInputValue, fetchTravels]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const clearSearch = useCallback(() => {
    setSearchInputValue('');
    setSearchTerm('');
    fetchTravels(true);
  }, [fetchTravels]);

  const paginatedTravels = useMemo(() => {
    const startIdx = (currentPage - 1) * itemsPerPage;
    return travels.slice(startIdx, startIdx + itemsPerPage);
  }, [travels, currentPage, itemsPerPage]);

  const handleOpenModal = () => {
    setEditingTravel(null);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSave = async (travelData) => {
    setLoading(true);
    try {
      if (editingTravel) {
        await updateTravel(editingTravel.id, { ...travelData, type: 'regular' });
      } else {
        await addTravel({ ...travelData, type: 'regular' });
      }
      await fetchTravels(true);
      handleCloseModal();
      setLoading(false);
    } catch (err) {
      setError('Erro ao salvar viagem. Por favor, tente novamente.');
      setLoading(false);
    }
  };

  const startEditing = (travel) => {
    setEditingTravel(travel);
    setOpenModal(true);
  };

  const openConfirmDeleteDialog = (travel) => {
    setTravelToDelete(travel);
    setConfirmDeleteOpen(true);
  };

  const closeConfirmDeleteDialog = () => {
    setConfirmDeleteOpen(false);
    setTravelToDelete(null);
    setMasterPassword('');
    clearError();
  };

  // Função para confirmar exclusão da viagem
  const confirmDelete = async () => {
    if (travelToDelete) {
      setLoading(true);
      try {
        if (masterPasswordActive) {
          const isValid = await verifyMasterPassword(masterPassword);
          if (!isValid) {
            setSnackbarMessage('Senha master incorreta');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            setLoading(false);
            return;
          }
        }

        await deleteTravel(travelToDelete.id);
        await fetchTravels(true);
        setSnackbarMessage('Viagem excluída com sucesso!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (err) {
        setError('Erro ao excluir a viagem. Por favor, tente novamente.');
        setSnackbarMessage('Erro ao excluir viagem: ' + err.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
        closeConfirmDeleteDialog();
      }
    }
  };

  const openConfirmCancelDialog = async (travel) => {
    setTravelToCancel(travel);
    try {
      const reservations = await getReservationsByTravelId(travel.id);
      setReservationsCount(reservations.length);
    } catch (error) {
      setReservationsCount(0);
      console.error("Erro ao buscar reservas:", error);
    }
    setConfirmCancelOpen(true);
  };

  const closeConfirmCancelDialog = () => {
    setConfirmCancelOpen(false);
    setTravelToCancel(null);
    setMasterPassword('');
    setReservationsCount(0);
  };

  const confirmCancel = async () => {
    if (travelToCancel) {
      setLoading(true);
      try {
        if (masterPasswordActive) {
          const isValid = await verifyMasterPassword(masterPassword);
          if (!isValid) {
            setSnackbarMessage('Senha master incorreta');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            setLoading(false);
            return;
          }
        }

        await cancelTravel(travelToCancel.id);
        await fetchTravels(true);
        setSnackbarMessage('Viagem cancelada com sucesso!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (err) {
        setError('Erro ao cancelar a viagem. Por favor, tente novamente.');
        setSnackbarMessage('Erro ao cancelar viagem: ' + err.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
        closeConfirmCancelDialog();
      }
    }
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const clearButton = (clearFunction) => (
    <InputAdornment position="end">
      <IconButton onClick={clearFunction} edge="end">
        <ClearIcon />
      </IconButton>
    </InputAdornment>
  );

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
          <Typography variant="h6" component="div">
            Viagens
            <Box component="span" sx={{ ml: 1 }}>
              <TravelPageHelp />
            </Box>
          </Typography>
          {canCreate && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleOpenModal}
              sx={{ borderRadius: '50px' }}
            >
              Adicionar
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            startIcon={<FilterListIcon />}
            onClick={() => setFiltersVisible(!filtersVisible)}
            sx={{ borderRadius: '50px' }}
          >
            {filtersVisible ? 'Ocultar Filtros' : 'Mostrar Filtros'}
          </Button>
        </Box>

        <Box>
          <IconButton
            color={viewMode === 'card' ? 'primary' : 'default'}
            onClick={() => handleViewModeChange('card')}
          >
            <Tooltip title="Visualização em Cartões">
              <ViewModuleIcon />
            </Tooltip>
          </IconButton>
          <IconButton
            color={viewMode === 'list' ? 'primary' : 'default'}
            onClick={() => handleViewModeChange('list')}
          >
            <Tooltip title="Visualização em Lista">
              <ViewListIcon />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>
      <Collapse in={filtersVisible}>
        <Box sx={{ display: 'flex', gap: 2, marginBottom: 2, alignItems: 'center', flexWrap: 'wrap' }}>
          <FormControl variant="outlined" sx={{ width: '100%' }}>
            <InputLabel id="order-select-label">Ordenar Por</InputLabel>
            <Select
              labelId="order-select-label"
              id="order-select"
              value={order}
              label="Ordenar Por"
              onChange={e => setOrder(e.target.value)}
            >
              <MenuItem value="nearest">Mais Próxima</MenuItem>
            </Select>
          </FormControl>
          <TextField
            sx={{ flexGrow: 1, minWidth: '240px' }}
            label="Busca"
            variant="outlined"
            value={searchInputValue}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            placeholder="Digite qualquer informação para buscar viagens"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchInputValue && (
                    <IconButton onClick={clearSearch} size="small">
                      <ClearIcon />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={handleSearch}
                    sx={{ ml: searchInputValue ? 1 : 0 }}
                    size="small"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
            autoComplete="off"
          />
          <FormControl variant="outlined" sx={{ width: '100%' }}>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status-select"
              value={statusFilter}
              label="Status"
              onChange={handleStatusChange}
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="Em andamento">Em andamento</MenuItem>
              <MenuItem value="Próxima">Próxima</MenuItem>
              <MenuItem value="Criada">Criada</MenuItem>
              <MenuItem value="Encerrada">Encerrada</MenuItem>
              <MenuItem value="Cancelada">Cancelada</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Data de Ida"
            type="date"
            value={startDate}
            onChange={e => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ width: '100%' }}
            InputProps={{
              endAdornment: startDate ? clearButton(() => setStartDate('')) : null
            }}
            autoComplete="off"
          />
          <TextField
            label="Data de Retorno"
            type="date"
            value={endDate}
            onChange={e => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ width: '100%' }}
            InputProps={{
              endAdornment: endDate ? clearButton(() => setEndDate('')) : null
            }}
            autoComplete="off"
          />
        </Box>
      </Collapse>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <TravelLoading disableAlignItems />
        </Box>
      ) : (
        <>
          {viewMode === 'card' ? (
            <TravelCard
              travels={paginatedTravels}
              startEditing={canEdit ? startEditing : null}
              handleDelete={canDelete ? openConfirmDeleteDialog : null}
              handleCancel={canCancel ? openConfirmCancelDialog : null}
              loading={loading}
            />
          ) : (
            <TravelList
              travels={paginatedTravels}
              startEditing={canEdit ? startEditing : null}
              handleDelete={canDelete ? openConfirmDeleteDialog : null}
              handleCancel={canCancel ? openConfirmCancelDialog : null}
              loading={loading}
            />
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Pagination
              count={Math.ceil(totalCount / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              disabled={loading}
            />
          </Box>
          {loading && currentPage > 1 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <TravelLoading disableAlignItems />
            </Box>
          )}
        </>
      )}
      {(canCreate || canEdit) && (
        <Modal open={openModal} onClose={handleCloseModal} closeAfterTransition>
          <Fade in={openModal}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
              <TravelForm travel={editingTravel} saveTravel={handleSave} cancelForm={handleCloseModal} />
            </Box>
          </Fade>
        </Modal>
      )}
      {canDelete && (
        <Dialog open={confirmDeleteOpen} onClose={closeConfirmDeleteDialog}>
          <DialogTitle>Confirmar Exclusão</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem certeza que deseja excluir a viagem para <strong>{travelToDelete?.destino}</strong>?
              <br />
              Data de Ida: <strong>{formatDate(travelToDelete?.dataIda)}</strong>
              <br />
              {!travelToDelete?.somenteIda && (
                <>
                  Data de Retorno: <strong>{formatDate(travelToDelete?.dataRetorno)}</strong>
                  <br />
                </>
              )}
              Essa ação excluirá todas as reservas e pedidos relacionados a essa viagem. Isso não pode ser desfeito.
            </DialogContentText>
            {masterPasswordActive && (
              <TextField
                margin="normal"
                fullWidth
                label="Senha Master"
                type={showPassword ? 'text' : 'password'}
                value={masterPassword}
                onChange={(e) => setMasterPassword(e.target.value)}
                error={!!masterPasswordError}
                helperText={masterPasswordError}
                InputProps={{
                  autoComplete: 'new-password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                autoComplete="off"
                disabled={loading || isVerifying}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeConfirmDeleteDialog} variant="outlined" disabled={loading} sx={{ borderRadius: '50px' }} >
              Voltar
            </Button>
            <Button
              onClick={confirmDelete}
              variant="contained"
              color="error"
              autoFocus
              disabled={(masterPasswordActive && !masterPassword) || loading || isVerifying}
              sx={{ color: 'white', borderRadius: '50px' }}
            >
              {loading || isVerifying ? <CircularProgress size={24} /> : 'Excluir'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {canCancel && (
        <Dialog open={confirmCancelOpen} onClose={closeConfirmCancelDialog}>
          <DialogTitle>Confirmar Cancelamento</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem certeza que deseja cancelar a viagem para <strong>{travelToCancel?.destino}</strong>?
              <br />
              Data de Ida: <strong>{formatDate(travelToCancel?.dataIda)}</strong>
              <br />
              {!travelToCancel?.somenteIda && (
                <>
                  Data de Retorno: <strong>{formatDate(travelToCancel?.dataRetorno)}</strong>
                  <br />
                </>
              )}
              {reservationsCount > 0 && (
                <strong>Essa viagem tem {reservationsCount} reserva(s) associada(s).</strong>
              )}
              <br />
              Cancelar essa viagem também cancelará todas as reservas e pedidos associados. Essa ação não pode ser desfeita.
            </DialogContentText>
            {masterPasswordActive && (
              <TextField
                margin="normal"
                fullWidth
                label="Senha Master"
                type={showPassword ? 'text' : 'password'}
                value={masterPassword}
                onChange={(e) => setMasterPassword(e.target.value)}
                error={!!masterPasswordError}
                helperText={masterPasswordError}
                InputProps={{
                  autoComplete: 'new-password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                autoComplete="off"
                disabled={loading || isVerifying}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeConfirmCancelDialog} variant="outlined" disabled={loading} sx={{ borderRadius: '50px' }} >
              Voltar
            </Button>
            <Button
              onClick={confirmCancel}
              variant="contained"
              color="error"
              autoFocus
              disabled={(masterPasswordActive && !masterPassword) || loading || isVerifying}
              sx={{ color: 'white', borderRadius: '50px' }}
            >
              {loading || isVerifying ? <CircularProgress size={24} /> : 'Cancelar viagem'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TravelPage;