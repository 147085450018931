import React, { useState } from 'react';
import {
  Typography, Box, IconButton, Menu, MenuItem, Button,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Tooltip
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListIcon from '@mui/icons-material/List';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { usePermissions } from '../../hooks/usePermissions';
import { formatDate } from '../../shared/utils/utils';

const TruncatedCell = ({ text, maxChars = 20 }) => {
  if (!text) return <TableCell>-</TableCell>;

  const shouldTruncate = text.length > maxChars;
  const displayText = shouldTruncate ? `${text.substring(0, maxChars)}...` : text;

  return (
    <TableCell>
      <Tooltip title={shouldTruncate ? text : ''} arrow>
        <Box sx={{
          maxWidth: 200,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}>
          {displayText}
        </Box>
      </Tooltip>
    </TableCell>
  );
};

const statusStyles = (theme) => ({
  'Cancelada': { color: theme.palette.error.light, text: 'Cancelada' },
  'Em andamento': { color: theme.palette.success.main, text: 'Em andamento' },
  'Próxima': { color: theme.palette.info.main, text: 'Próxima' },
  'Encerrada': { color: theme.palette.grey[600], text: 'Encerrada' },
  'Criada': { color: theme.palette.info.light, text: 'Criada' },
  'Indefinido': { color: theme.palette.grey[500], text: 'Indefinido' }
});

function TravelList({ travels, startEditing, handleDelete, handleCancel, loading }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentTravel, setCurrentTravel] = useState(null);
  const { checkPermission } = usePermissions();

  // Permission checks using the permissions system
  const canEdit = checkPermission('travels', 'edit');
  const canDelete = checkPermission('travels', 'delete');
  const canCancel = checkPermission('travels', 'cancel');
  const canViewFinancial = checkPermission('travels_transactions', 'view');

  const handleViewReservations = (travelId, e) => {
    e.stopPropagation();
    navigate(`/viagens/${travelId}/reservas`);
  };

  const handleViewCosts = (travelId, e) => {
    e.stopPropagation();
    navigate(`/viagens/${travelId}/custos`);
  };

  const handleOpenMenu = (e, travel) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setCurrentTravel(travel);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCurrentTravel(null);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    startEditing(currentTravel);
    handleCloseMenu();
  };

  const handleCancelTravel = (e) => {
    e.stopPropagation();
    handleCancel(currentTravel);
    handleCloseMenu();
  };

  const handleDeleteTravel = (e) => {
    e.stopPropagation();
    handleDelete(currentTravel);
    handleCloseMenu();
  };

  const appliedStatusStyles = statusStyles(theme);

  if (!loading && travels.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', p: 3 }}>
        <Typography variant="body1">
          Nenhuma viagem encontrada.
        </Typography>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper} sx={{ width: '100%', borderRadius: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Origem</TableCell>
            <TableCell>Destino</TableCell>
            <TableCell>Datas</TableCell>
            <TableCell>Veículo</TableCell>
            <TableCell>Assentos</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {travels.map(travel => {
            const status = appliedStatusStyles[travel.status] || appliedStatusStyles['Indefinido'];
            const totalSeats = travel.totalSeats || 0;
            const occupiedSeats = travel.numReservas || 0;
            const vehicleInfo = travel.veiculo ?
              `${travel.veiculo.identificadorVeiculo} - ${travel.veiculo.placa}` :
              'Não associado';

            return (
              <TableRow
                key={travel.id}
                hover
                onClick={() => navigate(`/viagens/${travel.id}`)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>
                  {travel.identificador && (
                    <Chip
                      label={travel.identificador}
                      size="small"
                      color="primary"
                      variant="outlined"
                    />
                  )}
                </TableCell>
                <TruncatedCell text={travel.origem} maxChars={25} />
                <TruncatedCell text={travel.destino} maxChars={25} />
                <TableCell>
                  <Typography variant="body2">Ida: {formatDate(travel.dataIda)}</Typography>
                  {!travel.somenteIda && (
                    <Typography variant="body2">Volta: {formatDate(travel.dataRetorno)}</Typography>
                  )}
                </TableCell>
                <TruncatedCell text={vehicleInfo} maxChars={30} />
                <TableCell>
                  <Typography variant="body2">{`${occupiedSeats}/${totalSeats}`}</Typography>
                </TableCell>
                <TableCell>
                  <Chip
                    label={status.text}
                    size="small"
                    sx={{ backgroundColor: status.color }}
                    color="primary"
                  />
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {checkPermission('travels', 'view') && (
                      <Button
                        size="small"
                        variant="outlined"
                        startIcon={<ListIcon />}
                        onClick={(e) => handleViewReservations(travel.id, e)}
                        sx={{ fontWeight: 'medium', borderRadius: '50px' }}
                      >
                        Reservas
                      </Button>
                    )}
                    {canViewFinancial && (
                      <Button
                        size="small"
                        variant="outlined"
                        startIcon={<AttachMoneyIcon />}
                        onClick={(e) => handleViewCosts(travel.id, e)}
                        sx={{ fontWeight: 'medium', borderRadius: '50px' }}
                      >
                        Financeiro
                      </Button>
                    )}
                    <IconButton
                      size="small"
                      onClick={(e) => handleOpenMenu(e, travel)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
            backgroundColor: theme.palette.background.paper,
          },
        }}
      >
        {currentTravel && currentTravel.status !== 'Cancelada' && canEdit && (
          <MenuItem onClick={handleEdit}>
            <EditIcon fontSize="small" sx={{ mr: 1 }} /> Editar
          </MenuItem>
        )}
        {currentTravel && currentTravel.status !== 'Encerrada' &&
          currentTravel.status !== 'Cancelada' && canCancel && (
            <MenuItem onClick={handleCancelTravel}>
              <CancelIcon fontSize="small" sx={{ mr: 1 }} /> Cancelar
            </MenuItem>
          )}
        {currentTravel && canDelete && (
          <MenuItem onClick={handleDeleteTravel}>
            <DeleteIcon fontSize="small" sx={{ mr: 1 }} /> Excluir
          </MenuItem>
        )}
      </Menu>
    </TableContainer>
  );
}

export default TravelList;