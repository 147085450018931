import React from 'react';
import { Box, Paper, Typography, IconButton, Tooltip } from '@mui/material';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import InfoIcon from '@mui/icons-material/Info';
import { format, isSameDay, isWithinInterval } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const AvailableVehicles = ({
    selectedDate,
    scheduleData,
    navigate,
}) => {
    if (!selectedDate) return null;

    // Função para verificar se o veículo está ocupado em uma data específica
    const isVehicleBusy = (vehicleId, date) => {
        for (const schedule of scheduleData.schedules) {
            if (schedule.vehicleId === vehicleId) {
                for (const travel of schedule.travels) {
                    const travelDeparture = new Date(`${travel.dataIda}T${travel.horarioIda}`);
                    const travelReturn = travel.somenteIda ? null : new Date(`${travel.dataRetorno}T${travel.horarioRetorno}`);

                    if (isSameDay(date, travelDeparture) ||
                        (travelReturn && isSameDay(date, travelReturn)) ||
                        (!travel.somenteIda && travelReturn && isWithinInterval(date, {
                            start: travelDeparture,
                            end: travelReturn
                        }))) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    // Filtrar veículos disponíveis
    const availableVehicles = scheduleData.vehicles.filter(
        vehicle => !isVehicleBusy(vehicle.id, selectedDate)
    );

    return (
        <Box sx={{ mt: 3, flex: 1, ml: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Veículos Disponíveis em {format(selectedDate, "dd 'de' MMMM", { locale: ptBR })}
            </Typography>

            {availableVehicles.length === 0 ? (
                <Typography variant="body2" color="text.secondary">
                    Nenhum veículo disponível nesta data.
                </Typography>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {availableVehicles.map((vehicle) => (
                        <Paper
                            key={vehicle.id}
                            sx={{
                                p: 2,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2
                            }}
                        >
                            <DirectionsBusIcon color="primary" />
                            <Box sx={{ flex: 1 }}>
                                <Tooltip title={vehicle.identificadorVeiculo.length > 25 ? vehicle.identificadorVeiculo : ""} arrow>
                                    <Typography variant="subtitle1" sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '200px'
                                    }}>
                                        {vehicle.identificadorVeiculo}
                                    </Typography>
                                </Tooltip>
                                <Typography variant="body2" color="text.secondary">
                                    Placa: {vehicle.placa} - {vehicle.empresa}
                                </Typography>
                            </Box>
                            <Tooltip title="Ver detalhes do veículo">
                                <IconButton
                                    size="small"
                                    onClick={() => navigate(`/veiculos/${vehicle.id}`)}
                                >
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </Paper>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default AvailableVehicles;