import { db, auth } from '../../../firebaseConfig';
import { collection, doc, getDocs, query, where, getDoc, or, and } from 'firebase/firestore';

const getAgencyRef = async () => {
    const user = auth.currentUser;
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    return doc(db, 'agencies', userDoc.data().agencyId);
};

export const getVehicleSchedule = async (vehicleId, startDate, endDate) => {
    try {
        const agencyRef = await getAgencyRef();
        const travelsRef = collection(agencyRef, 'travels');

        // Usando and() para agrupar os filtros
        const q = query(
            travelsRef,
            and(
                where('veiculoId', '==', vehicleId),
                where('estaAtivo', '==', true),
                or(
                    where('dataIda', '>=', startDate),
                    where('dataRetorno', '>=', startDate)
                )
            )
        );

        const snapshot = await getDocs(q);

        if (snapshot.empty) {
            return [];
        }

        const travels = snapshot.docs
            .map(doc => {
                const travel = { id: doc.id, ...doc.data() };

                const departureDate = new Date(`${travel.dataIda}T${travel.horarioIda}`);
                const returnDate = travel.somenteIda
                    ? null
                    : new Date(`${travel.dataRetorno}T${travel.horarioRetorno}`);

                // Verifica se a viagem está dentro do período solicitado
                const startDateObj = new Date(startDate);
                const endDateObj = new Date(endDate);
                endDateObj.setHours(23, 59, 59, 999);

                const isRelevant = (
                    (departureDate >= startDateObj && departureDate <= endDateObj) ||
                    (returnDate && returnDate >= startDateObj && returnDate <= endDateObj) ||
                    (returnDate && departureDate <= endDateObj && returnDate >= startDateObj)
                );

                if (!isRelevant) {
                    return null;
                }

                return {
                    id: travel.id,
                    identificador: travel.identificador,
                    origem: travel.origem,
                    destino: travel.destino,
                    departureDate,
                    returnDate,
                    dataIda: travel.dataIda,
                    horarioIda: travel.horarioIda,
                    dataRetorno: travel.dataRetorno,
                    horarioRetorno: travel.horarioRetorno,
                    informacoesAdicionais: travel.informacoesAdicionais,
                    somenteIda: travel.somenteIda,
                    status: travel.status
                };
            })
            .filter(travel =>
                travel !== null &&
                !['Cancelada'].includes(travel.status)
            )
            .sort((a, b) => a.departureDate - b.departureDate);

        return travels;

    } catch (error) {
        console.error('Erro ao buscar agenda do veículo:', error);
        throw error;
    }
};

export const checkVehicleAvailability = async (vehicleId, dataIda, horarioIda, dataRetorno, horarioRetorno, somenteIda = false) => {
    try {
        const agencyRef = await getAgencyRef();
        const travelsRef = collection(agencyRef, 'travels');

        const q = query(
            travelsRef,
            where('veiculoId', '==', vehicleId),
            where('estaAtivo', '==', true),
            where('status', 'not-in', ['Cancelada'])
        );

        const snapshot = await getDocs(q);

        if (snapshot.empty) {
            return true;
        }

        const requestStart = new Date(`${dataIda}T${horarioIda}`);
        const requestEnd = somenteIda
            ? requestStart
            : new Date(`${dataRetorno}T${horarioRetorno}`);

        for (const doc of snapshot.docs) {
            const travel = doc.data();
            const travelStart = new Date(`${travel.dataIda}T${travel.horarioIda}`);
            const travelEnd = travel.somenteIda
                ? travelStart
                : new Date(`${travel.dataRetorno}T${travel.horarioRetorno}`);

            const hasConflict = (
                (requestStart >= travelStart && requestStart <= travelEnd) ||
                (requestEnd >= travelStart && requestEnd <= travelEnd) ||
                (requestStart <= travelStart && requestEnd >= travelEnd)
            );

            if (hasConflict) {
                return false;
            }
        }

        return true;

    } catch (error) {
        console.error('Erro ao verificar disponibilidade do veículo:', error);
        throw error;
    }
};

export const getNextTravel = async (vehicleId) => {
    try {
        const agencyRef = await getAgencyRef();
        const travelsRef = collection(agencyRef, 'travels');
        const today = new Date().toISOString().split('T')[0];

        const q = query(
            travelsRef,
            where('veiculoId', '==', vehicleId),
            where('estaAtivo', '==', true),
            where('status', 'not-in', ['Cancelada']),
            where('dataIda', '>=', today)
        );

        const snapshot = await getDocs(q);

        if (snapshot.empty) {
            return null;
        }

        const now = new Date();
        let nextTravel = null;
        let closestDate = null;

        snapshot.docs.forEach(doc => {
            const travel = { id: doc.id, ...doc.data() };
            const travelDate = new Date(`${travel.dataIda}T${travel.horarioIda}`);

            if (travelDate >= now && (!closestDate || travelDate < closestDate)) {
                closestDate = travelDate;
                nextTravel = {
                    id: travel.id,
                    identificador: travel.identificador,
                    origem: travel.origem,
                    destino: travel.destino,
                    dataIda: travel.dataIda,
                    horarioIda: travel.horarioIda,
                    dataRetorno: travel.dataRetorno,
                    horarioRetorno: travel.horarioRetorno,
                    somenteIda: travel.somenteIda,
                    informacoesAdicionais: travel.informacoesAdicionais,
                    status: travel.status
                };
            }
        });

        return nextTravel;

    } catch (error) {
        console.error('Erro ao buscar próxima viagem programada:', error);
        throw error;
    }
};