import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, TextField, Snackbar, Alert, Pagination, InputAdornment, FormControl, InputLabel, Select, MenuItem, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, IconButton, Grid, Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import ReservationCard from '../../components/Reservation/ReservationCard';
import OrderCard from '../../components/Order/OrderCard';
import OrderList from '../../components/Order/OrderList';
import ReservationList from '../../components/Reservation/ReservationList';
import ReservationDetails from '../../components/Reservation/ReservationDetails';
import OrderDetails from '../../components/Order/OrderDetails';
import TravelLoading from '../../shared/common/TravelLoading';

import { getSavedViewType, saveViewType } from '../../shared/utils/localStorageUtils';
import { getPaginatedReservationsAndOrders } from '../Payments/services/PaymentService';
import { cancelOrder, cancelReservation } from '../Allocation/services/OrderService';
import { formatCPF } from '../../shared/utils/utils';
import { useMasterPassword } from '../../shared/hooks/useMasterPassword';

const OrdersAndReservationsListPage = () => {
  const [viewMode, setViewMode] = useState(getSavedViewType());
  const { travelId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [paymentStatusFilter, setPaymentStatusFilter] = useState('');
  const [filterType, setFilterType] = useState('reservas');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [travels, setTravels] = useState({});
  const [passengers, setPassengers] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [cancelOrderId, setCancelOrderId] = useState(null);
  const [cancelReservationId, setCancelReservationId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [travelIdState, setTravelIdState] = useState(null);
  const [masterPassword, setMasterPassword] = useState('');
  const [cancelLoading, setCancelLoading] = useState(false);
  const {
    isVerifying,
    error: masterPasswordError,
    clearError,
    masterPasswordActive,
    showPassword,
    togglePasswordVisibility,
    verifyMasterPassword
  } = useMasterPassword();

  // Fetch inicial dos dados e status da senha master
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await fetchData();
      } catch (error) {
        setError('Erro ao carregar configurações iniciais: ' + error.message);
      }
    };

    fetchInitialData();
  }, []);

  // Função para buscar dados
  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await getPaginatedReservationsAndOrders({
        page: currentPage,
        filterType,
        searchTerm,
        paymentStatusFilter,
        travelId
      });

      setData(result.data);
      setTotalPages(result.totalPages);
      setTravels(result.travels);
      setPassengers(result.passengers); // Define os passageiros que vieram junto
      setError('');
    } catch (err) {
      console.error('Erro ao buscar dados:', err);
      setError('Falha ao carregar dados: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  // Atualizar dados quando os filtros mudam
  useEffect(() => {
    fetchData();
  }, [currentPage, filterType, searchTerm, paymentStatusFilter, travelId]);

  const confirmCancelOrder = async () => {
    setCancelLoading(true);
    try {
      if (masterPasswordActive) {
        const isValid = await verifyMasterPassword(masterPassword);
        if (!isValid) {
          setSnackbarMessage('Senha master incorreta');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setCancelLoading(false);
          return;
        }
      }

      if (cancelReservationId) {
        await cancelReservation(travelIdState, cancelOrderId, cancelReservationId);
        setSnackbarMessage('Reserva cancelada com sucesso.');
      } else {
        await cancelOrder(travelIdState, cancelOrderId);
        setSnackbarMessage('Pedido cancelado com sucesso.');
      }

      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      await fetchData();
      handleCloseCancelDialog();
    } catch (err) {
      setSnackbarMessage(err.message.includes('master') ? 'Senha master incorreta' : 'Erro ao cancelar: ' + err.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setCancelLoading(false);
    }
  };

  const handleCloseCancelDialog = () => {
    setCancelDialogOpen(false);
    setCancelReservationId(null);
    setCancelOrderId(null);
    setTravelIdState(null);
    setMasterPassword('');
    clearError();
  };

  const handleEditReservation = (reservation, orderId) => {
    navigate(`/viagens/${reservation.travelId}/pedido/${orderId}`, {
      state: {
        editingReservation: reservation,
        editingOrderId: orderId,
      },
    });
  };

  const handleEditOrder = (order) => {
    navigate(`/viagens/${order.travelId}/pedido/${order.id}`, {
      state: {
        editingOrderId: order.id,
        travelId: order.travelId,
        orderDetails: order,
      },
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  const handlePaymentStatusFilterChange = (e) => {
    setPaymentStatusFilter(e.target.value);
    setCurrentPage(1); // Reset to first page when filtering
  };

  const handleFilterTypeChange = (e) => {
    setFilterType(e.target.value);
    setCurrentPage(1); // Reset to first page when changing type
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleClearSearch = () => setSearchTerm('');

  const handleCancelOrder = (orderId, travelId) => {
    setCancelOrderId(orderId);
    setTravelIdState(travelId);
    setCancelDialogOpen(true);
  };

  const handleCancelReservation = (reservationId, orderId, travelId) => {
    if (!reservationId || !orderId || !travelId) {
      console.error('Dados inválidos para cancelamento:', { reservationId, orderId, travelId });
      setSnackbarMessage('Erro: Dados inválidos para cancelamento');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    setCancelOrderId(orderId);
    setCancelReservationId(reservationId);
    setTravelIdState(travelId);
    setCancelDialogOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
    setSnackbarSeverity('success'); // Reset para o valor padrão
  };

  const handleOpenModal = (data) => {
    setSelectedData(data);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedData(null);
  };

  const handleClickShowMasterPassword = () => setShowMasterPassword(!showMasterPassword);

  const renderCancelDialogContent = () => {
    // Verifica se está cancelando uma reserva
    if (cancelReservationId) {
      const reservation = filterType === 'reservas'
        ? data.find(res => res.id === cancelReservationId)
        : data.find(order => order.reservations?.some(res => res.id === cancelReservationId))
          ?.reservations?.find(res => res.id === cancelReservationId);

      if (!reservation) {
        return null;
      }

      const passenger = reservation.passenger || {};

      return (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Informações do Passageiro</Typography>
          <Typography variant="body2">Nome: {passenger?.nome || 'Não informado'}</Typography>
          <Typography variant="body2">CPF: {passenger?.cpf ? formatCPF(passenger.cpf) : 'Não informado'}</Typography>
          <Typography variant="body2">RG: {passenger?.rg || 'Não informado'}</Typography>
          <Typography variant="body2">Passaporte: {passenger?.passaporte || 'Não informado'}</Typography>
        </Box>
      );
    }

    // Verifica se está cancelando um pedido
    if (cancelOrderId && !cancelReservationId) {
      const order = filterType === 'pedidos'
        ? data.find(order => order.id === cancelOrderId)
        : null;

      return (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Informações do Pagador</Typography>
          <Typography variant="body2">Pedido ID: {cancelOrderId}</Typography>
          <Typography variant="body2">Nome: {order?.detalhesPagamento?.nomePagador || 'Não informado'}</Typography>
          <Typography variant="body2">CPF: {order?.detalhesPagamento?.cpfPagador ? formatCPF(order.detalhesPagamento.cpfPagador) : 'Não informado'}</Typography>
          <Typography variant="body2">RG: {order?.detalhesPagamento?.rgPagador || 'Não informado'}</Typography>
        </Box>
      );
    }

    return null;
  };

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    saveViewType(mode);
  };

  return (
    <Box sx={{ gap: 2, marginBottom: 2, flexWrap: 'wrap' }}>
      <Typography variant="h6" gutterBottom>
        Reservas e Pedidos
        <Box component="span" sx={{ ml: 1 }}>
          {/* <ReceivablesHelp /> */}
        </Box>
      </Typography>
      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="filter-type-label">Visualizar por</InputLabel>
            <Select
              labelId="filter-type-label"
              id="filter-type"
              value={filterType}
              onChange={handleFilterTypeChange}
              label="Visualizar por"
            >
              <MenuItem value="reservas">Reservas</MenuItem>
              <MenuItem value="pedidos">Pedidos</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            label="Buscar"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Digite nome, documento ou ID do pedido"
            InputProps={{
              endAdornment: searchTerm ? (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearch} edge="end">
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ) : null
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="payment-status-filter-label">Status de Pagamento</InputLabel>
            <Select
              labelId="payment-status-filter-label"
              id="payment-status-filter"
              value={paymentStatusFilter}
              onChange={handlePaymentStatusFilterChange}
              label="Status de Pagamento"
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="Pago">Pago</MenuItem>
              <MenuItem value="Pagamento pendente">Pagamento pendente</MenuItem>
              <MenuItem value="Cancelada">Cancelada</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={1} sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => handleViewModeChange('card')}
            color={viewMode === 'card' ? 'primary' : 'default'}
          >
            <ViewModuleIcon />
          </IconButton>
          <IconButton
            onClick={() => handleViewModeChange('table')}
            color={viewMode === 'table' ? 'primary' : 'default'}
          >
            <ViewListIcon />
          </IconButton>
        </Grid>
      </Grid>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <TravelLoading disableAlignItems />
        </Box>
      ) : (
        data.length === 0 ? (
          <Box sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="body1">
              {filterType === 'reservas' ? 'Nenhuma reserva encontrada' : 'Nenhum pedido encontrado'}.
            </Typography>
          </Box>
        ) : filterType === 'reservas' ? (
          viewMode === 'card' ? (
            <Grid container spacing={2}>
              {data.map((reservation) => (
                <Grid item xs={12} md={6} lg={4} key={reservation.id}>
                  <ReservationCard
                    reservation={reservation}
                    passengers={passengers}
                    travel={travels[reservation.travelId]}
                    onEditReservation={handleEditReservation}
                    onCancelReservation={handleCancelReservation}
                    onCardClick={handleOpenModal}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <ReservationList
              reservations={data}
              passengers={passengers}
              onEditReservation={handleEditReservation}
              onCancelReservation={handleCancelReservation}
              onRowClick={handleOpenModal}
            />
          )
        ) : (
          viewMode === 'card' ? (
            <Grid container spacing={2}>
              {data.map((order) => order && (
                <Grid item xs={12} md={6} lg={4} key={order.id}>
                  <OrderCard
                    order={{
                      ...order,
                      reservations: Array.isArray(order.reservations)
                        ? order.reservations.map(reservation => ({
                          ...reservation,
                          passenger: reservation.passenger || {}
                        }))
                        : []
                    }}
                    travel={travels[order.travelId]}
                    onEditOrder={handleEditOrder}
                    onCancelOrder={handleCancelOrder}
                    onCardClick={handleOpenModal}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <OrderList
              orders={data}
              onEditOrder={handleEditOrder}
              onCancelOrder={handleCancelOrder}
              onRowClick={handleOpenModal}
            />
          )
        )
      )}
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        showFirstButton
        showLastButton
        sx={{
          mt: 2,
          display: 'flex',
          justifyContent: 'center'
        }}
      />
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={cancelDialogOpen} onClose={handleCloseCancelDialog}>
        <DialogTitle>Confirmar Cancelamento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {cancelReservationId
              ? 'Tem certeza de que deseja cancelar esta reserva? Esta ação não pode ser desfeita.'
              : 'Tem certeza de que deseja cancelar este pedido? Todas as reservas deste pedido serão canceladas. Esta ação não pode ser desfeita.'}
          </DialogContentText>
          {renderCancelDialogContent()}
          {masterPasswordActive && (
            <TextField
              margin="normal"
              fullWidth
              label="Senha Master"
              type={showPassword ? 'text' : 'password'}
              value={masterPassword}
              onChange={(e) => setMasterPassword(e.target.value)}
              error={!!masterPasswordError}
              helperText={masterPasswordError}
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
              disabled={cancelLoading || isVerifying}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseCancelDialog}
            variant="outlined"
            disabled={cancelLoading || isVerifying}
            sx={{ borderRadius: '50px' }}
          >
            Voltar
          </Button>
          <Button
            onClick={confirmCancelOrder}
            variant="contained"
            color="error"
            autoFocus
            disabled={(masterPasswordActive && !masterPassword) || cancelLoading || isVerifying}
            sx={{ color: 'white', borderRadius: '50px' }}
          >
            {cancelLoading || isVerifying ? (
              <CircularProgress size={24} />
            ) : (
              cancelReservationId ? 'Cancelar reserva' : 'Cancelar pedido'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: '80%', md: '60%' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxHeight: '90vh',
          overflowY: 'auto',
          borderRadius: 2,
        }}>
          <Typography variant="h5" gutterBottom>
            {filterType === 'reservas' ? 'Detalhes da Reserva' : 'Detalhes do Pedido'}
          </Typography>
          {selectedData && (filterType === 'reservas' ? (
            <ReservationDetails reservation={selectedData} passengers={passengers} travel={travels[selectedData.travelId]} />
          ) : (
            <OrderDetails order={selectedData} passengers={passengers} travel={travels[selectedData.travelId]} />
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
            <Button onClick={handleCloseModal} variant="outlined" color="primary" sx={{ borderRadius: '50px' }}>Fechar</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default OrdersAndReservationsListPage;
