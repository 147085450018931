import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatCPF, formatDate, formatTelefone } from '../../../shared/utils/utils';
import logo from '../../../assets/logo-travell-combr.png';
import { auth, db } from '../../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

export const exportAccommodationToPDF = async (hotel, travel) => {
    const user = auth.currentUser;
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    const agencyRef = doc(db, 'agencies', userDoc.data().agencyId);
    const agencyDoc = await getDoc(agencyRef);
    const agencyData = agencyDoc.data();

    const ownerRef = doc(db, 'users', agencyData.ownerId);
    const ownerDoc = await getDoc(ownerRef);
    const ownerPhone = ownerDoc.data().phone;

    const pdfDoc = new jsPDF('landscape', 'pt', 'a4');
    const { width, height } = pdfDoc.internal.pageSize;
    const margins = { top: 40, right: 20, left: 20, bottom: 30 };

    const addHeader = () => {
        pdfDoc.setFontSize(16);
        pdfDoc.setFont('helvetica', 'bold');
        pdfDoc.text(agencyData.name, margins.left, 25);

        pdfDoc.setFontSize(10);
        pdfDoc.text(`Telefone: ${ownerPhone}${agencyData.email ? ` | Email: ${agencyData.email}` : ''}`, margins.left, 40);

        pdfDoc.setFontSize(14);
        pdfDoc.text('Detalhes da Viagem', margins.left, 65);

        pdfDoc.setFontSize(10);
        pdfDoc.setFont('helvetica', 'normal');

        pdfDoc.text(`Identificador: ${travel?.identificador || 'Não informado'} | Origem: ${travel?.origem || 'Não informado'} | Destino: ${travel?.destino || 'Não informado'}`, margins.left, 80);

        pdfDoc.text(`Data Ida: ${formatDate(travel?.dataIda)} às ${travel?.horarioIda || 'Não informado'} | ${travel?.somenteIda ? 'Somente Ida' : `Volta: ${formatDate(travel?.dataRetorno)} às ${travel?.horarioRetorno || 'Não informado'}`}`, margins.left, 95);

        pdfDoc.text(`Hotel: ${hotel.name} | Endereço: ${hotel.address}`, margins.left, 110);

        if (hotel.contactName || hotel.contactPhone) {
            pdfDoc.text(`Contato Hospedagem: ${hotel.contactName || 'Não informado'} - ${hotel.contactPhone || 'Não informado'} | Check-in: ${hotel.checkInTime || 'Não informado'} - Check-out: ${hotel.checkOutTime || 'Não informado'}`, margins.left, 125);
            return 160;
        }
        return 140;
    };

    const addLogo = () => {
        const img = new Image();
        img.src = logo;

        return new Promise((resolve) => {
            img.onload = () => {
                const logoWidth = 100;
                const logoHeight = 30;
                const logoX = width - margins.right - logoWidth;
                const logoY = 15;

                pdfDoc.addImage(img, 'PNG', logoX, logoY, logoWidth, logoHeight);
                pdfDoc.link(logoX, logoY, logoWidth, logoHeight, { url: 'https://travell.com.br' });
                resolve();
            };
        });
    };

    const addRoomTable = (room, startY) => {
        pdfDoc.setDrawColor(25, 118, 210);
        pdfDoc.setFillColor(240, 240, 240);
        pdfDoc.rect(margins.left, startY - 15, width - margins.left - margins.right, 25, 'F');

        pdfDoc.setFont('helvetica', 'bold');
        pdfDoc.setFontSize(12);
        const roomText = `${room.name} (${room.occupiedBeds}/${room.totalBeds} hóspedes)`;
        pdfDoc.text(roomText, margins.left + 10, startY);

        if (room.notes) {
            pdfDoc.setFontSize(9);
            pdfDoc.setFont('helvetica', 'italic');
            pdfDoc.text(`Observações: ${room.notes}`, margins.left + 300, startY);
        }

        const columns = [
            { header: 'Nome', dataKey: 'nome' },
            { header: 'CPF/Passaporte', dataKey: 'documento' },
            { header: 'RG', dataKey: 'rg' },
            { header: 'Data Nascimento', dataKey: 'dataNascimento' },
            { header: 'Idade', dataKey: 'idade' },
            { header: 'Endereço', dataKey: 'endereco' },
            { header: 'Telefone', dataKey: 'telefone' },
            { header: 'Informações', dataKey: 'informacoes' }
        ];

        const wrapText = (text, maxLength) => {
            if (!text) return 'Não informado';
            if (text.length <= maxLength) return text;
            const words = text.split(' ');
            let lines = [''];
            let currentLine = 0;

            words.forEach(word => {
                if ((lines[currentLine] + ' ' + word).length <= maxLength) {
                    lines[currentLine] += (lines[currentLine] ? ' ' : '') + word;
                } else {
                    currentLine++;
                    lines[currentLine] = word;
                }
            });

            return lines.join('\n');
        };

        const calculateAge = (birthDate) => {
            if (!birthDate) return 'Não informado';
            const today = new Date();
            const birth = new Date(birthDate);
            let age = today.getFullYear() - birth.getFullYear();
            const monthDiff = today.getMonth() - birth.getMonth();
            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
                age--;
            }
            return age.toString();
        };

        const rows = room.allocations?.map(allocation => {
            const passenger = allocation.passenger || {};
            const menorDeIdade = passenger.menorDeIdade ? 'Menor de Idade' : '';

            return {
                nome: passenger.nome || 'Não informado',
                documento: passenger.estrangeiro ?
                    `Passaporte: ${passenger.passaporte || 'Não informado'}` :
                    formatCPF(passenger.cpf) || 'Não informado',
                rg: passenger.estrangeiro ? '-' : (passenger.rg || 'Não informado'),
                dataNascimento: formatDate(passenger.dataNascimento),
                idade: calculateAge(passenger.dataNascimento),
                endereco: wrapText(passenger.endereco, 200),
                telefone: formatTelefone(passenger.telefone),
                informacoes: wrapText(menorDeIdade, 50)
            };
        }) || [];

        pdfDoc.autoTable({
            startY: startY + (room.notes ? 25 : 15),
            head: [columns.map(col => col.header)],
            body: rows.map(row => columns.map(col => row[col.dataKey])),
            theme: 'striped',
            margin: margins,
            styles: {
                fontSize: 9,
                cellPadding: 4,
                halign: 'center'
            },
            headStyles: {
                fillColor: [25, 118, 210],
                textColor: [255, 255, 255],
                fontStyle: 'bold'
            },
            alternateRowStyles: {
                fillColor: [240, 240, 240]
            },
            columnStyles: {
                nome: { cellWidth: 120, cellPadding: 6 },
                documento: { cellWidth: 100, cellPadding: 6 },
                rg: { cellWidth: 80, cellPadding: 6 },
                dataNascimento: { cellWidth: 80, cellPadding: 6 },
                idade: { cellWidth: 40, cellPadding: 6 },
                endereco: { cellWidth: 150, cellPadding: 6 },
                telefone: { cellWidth: 80, cellPadding: 6 },
                informacoes: { cellWidth: 80, cellPadding: 6 }
            }
        });

        return pdfDoc.lastAutoTable.finalY + 50;
    };

    const addFooter = () => {
        const pages = pdfDoc.internal.getNumberOfPages();
        pdfDoc.setFontSize(8);
        pdfDoc.setTextColor(40);

        for (let i = 1; i <= pages; i++) {
            pdfDoc.setPage(i);
            let str = `Página ${i}`;
            if (typeof pdfDoc.putTotalPages === 'function') {
                str = `${str} de ${pages}`;
            }
            pdfDoc.text(str, width - margins.right - 5, height - 10, { align: 'right' });

            pdfDoc.text(`${agencyData.name} | Tel: ${ownerPhone}`, margins.left, height - 10);
        }
    };

    try {
        await addLogo();
        const headerHeight = addHeader();

        let currentY = headerHeight;

        for (const room of hotel.rooms) {
            if (currentY + 200 > height - margins.bottom) {
                pdfDoc.addPage();
                currentY = margins.top;
            }
            currentY = addRoomTable(room, currentY);
        }

        addFooter();

        const filename = `Hospedagem - Viagem:${travel?.identificador || 'sem-id'} - Hotel:${hotel.name} - ${agencyData.name}.pdf`;
        pdfDoc.save(filename);
    } catch (error) {
        console.error('Erro ao gerar PDF:', error);
    }
};