import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatDate, normalizeValue } from '../../../shared/utils/utils';
import logo from '../../../assets/logo-travell-combr.png';

export const exportReceivablesToPDF = (receivables) => {
  const doc = new jsPDF('landscape', 'pt');
  
  const truncateText = (text, maxLength) => {
    if (!text) return '-';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  const formatCurrency = (value) => {
    if (!value && value !== 0) return 'R$ 0,00';
    return `R$ ${normalizeValue(value)}`;
  };

  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const getFormattedFilename = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    const hour = String(today.getHours()).padStart(2, '0');
    const minute = String(today.getMinutes()).padStart(2, '0');
    return `contas_a_receber_${day}-${month}-${year}_${hour}-${minute}`;
  };

  const columns = [
    { title: "Descrição", dataKey: "description" },
    { title: "Data", dataKey: "date" },
    { title: "Forma de Pagamento", dataKey: "paymentMethod" },
    { title: "Conta Bancária", dataKey: "bankAccount" },
    { title: "Valor", dataKey: "amount" },
    { title: "Status", dataKey: "status" },
  ];

  const rows = receivables.map(receivable => ({
    date: receivable.date ? formatDate(receivable.date) : '-',
    description: truncateText(receivable.description, 50),
    paymentMethod: receivable.paymentMethod || '-',
    bankAccount: receivable.bankAccount || '-',
    amount: formatCurrency(receivable.amount),
    status: receivable.status || '-',
  }));

  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;

  const img = new Image();
  img.src = logo;

  img.onload = () => {
    const logoX = pageWidth - 150; 
    const logoY = 15; 
    const logoWidth = 130; 
    const logoHeight = 40; 

    doc.addImage(img, 'PNG', logoX, logoY, logoWidth, logoHeight);
    doc.link(logoX, logoY, logoWidth, logoHeight, { url: 'https://travell.com.br' });

    doc.setFontSize(14); // Título menor
    doc.setFont('helvetica', 'bold');
    doc.text('Relatório de Contas a Receber', 40, 25);

    doc.setFontSize(10); // Texto menor
    doc.setFont('helvetica', 'normal');

    const totalAmount = receivables.reduce((acc, curr) => {
      const amount = typeof curr.amount === 'number' ? curr.amount : 0;
      return acc + amount;
    }, 0);

    const statusTotals = receivables.reduce((acc, curr) => {
      const amount = typeof curr.amount === 'number' ? curr.amount : 0;
      acc[curr.status] = (acc[curr.status] || 0) + amount;
      return acc;
    }, {});

    const infoLine = `Data: ${getCurrentDate()} | Registros: ${receivables.length} | Total: ${formatCurrency(totalAmount)}`;
    doc.text(infoLine, 40, 40);

    const statusLine = Object.entries(statusTotals)
      .map(([status, total]) => `${status}: ${formatCurrency(total)}`)
      .join(' | ');
    doc.text(statusLine, 40, 55);

    doc.autoTable({
      head: [columns.map(col => col.title)],
      body: rows.map(row => columns.map(col => row[col.dataKey])),
      startY: 70, // Tabela começa mais próxima do topo
      theme: 'striped',
      margin: { top: 10, bottom: 30, left: 20, right: 20 },
      styles: { fontSize: 10, halign: 'center' },
      headStyles: { fillColor: [25, 118, 210], textColor: [255, 255, 255] },
      alternateRowStyles: { fillColor: [240, 240, 240] },
      columnStyles: {
        description: { cellWidth: 200 },
        amount: { halign: 'right' }
      },
      didDrawPage: function (data) {
        doc.setFontSize(8); // Rodapé menor
        doc.setTextColor(40);
        let str = 'Página ' + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' de ' + doc.internal.getNumberOfPages();
        }
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
    });

    const filename = `${getFormattedFilename()}.pdf`;
    doc.save(filename);
  };
};