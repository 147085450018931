import { db, auth } from '../../../firebaseConfig';
import {
    collection,
    doc,
    addDoc,
    getDocs,
    updateDoc,
    deleteDoc,
    getDoc,
    query,
    where
} from 'firebase/firestore';

const getAgencyRef = async () => {
    const user = auth.currentUser;
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    return doc(db, 'agencies', userDoc.data().agencyId);
};

export const updateTravelBudget = async (travelId, budget) => {
    const agencyRef = await getAgencyRef();
    const travelRef = doc(agencyRef, 'travels', travelId);

    await updateDoc(travelRef, {
        budget: parseFloat(budget),
        'metadata.updatedAt': new Date().toISOString()
    });
};

export const addBudgetPayment = async (travelId, payment) => {
    const agencyRef = await getAgencyRef();
    const paymentsRef = collection(agencyRef, 'budgetPayments');

    const paymentData = {
        amount: payment.amount,
        description: payment.description,
        date: payment.date,
        paymentMethod: payment.paymentMethod, // Adicione esta linha
        bankAccountId: payment.bankAccountId, // Adicione esta linha
        travelId,
        createdAt: new Date().toISOString()
    };

    const docRef = await addDoc(paymentsRef, paymentData);
    return {
        ...paymentData,
        id: docRef.id
    };
};

export const getBudgetPayments = async (travelId) => {
    const agencyRef = await getAgencyRef();
    const paymentsRef = collection(agencyRef, 'budgetPayments');
    const q = query(paymentsRef, where('travelId', '==', travelId));

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
    }));
};

export const updateBudgetPayment = async (travelId, paymentId, payment) => {
    const agencyRef = await getAgencyRef();
    const paymentRef = doc(agencyRef, 'budgetPayments', paymentId);

    const paymentData = {
        amount: payment.amount,
        description: payment.description,
        date: payment.date,
        paymentMethod: payment.paymentMethod, 
        bankAccountId: payment.bankAccountId, 
        travelId,
        updatedAt: new Date().toISOString()
    };

    await updateDoc(paymentRef, paymentData);
    return {
        ...paymentData,
        id: paymentId
    };
};

export const deleteBudgetPayment = async (travelId, paymentId) => {
    const agencyRef = await getAgencyRef();
    const paymentRef = doc(agencyRef, 'budgetPayments', paymentId);
    await deleteDoc(paymentRef);
};