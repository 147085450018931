import React, { useState, useEffect } from 'react';
import {
  Box, Card, Typography, Switch, Dialog, DialogActions,
  DialogContent, DialogContentText, TextField, DialogTitle,
  CircularProgress, Tabs, Tab, Paper, Button
} from '@mui/material';
import { Navigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import SecurityIcon from '@mui/icons-material/Security';
import UpdateProfileForm from './components/UpdateProfileForm';
import ChangePasswordForm from './components/ChangePasswordForm';
import SetMasterPasswordForm from './components/SetMasterPasswordForm';
import MyAccountHelp from './helps/MyAccountHelp';
import { useAuth } from '../../core/contexts/useAuthState';
import {
  getMasterPasswordStatus,
  toggleMasterPasswordActive,
  checkMasterPassword
} from '../MyAccount/services/MasterPasswordService';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`account-tabpanel-${index}`}
      aria-labelledby={`account-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `account-tab-${index}`,
    'aria-controls': `account-tabpanel-${index}`,
  };
}

function MyAccountPage() {
  const { currentUser, isOwner } = useAuth();
  const [tabValue, setTabValue] = useState(0);
  const [masterPasswordActive, setMasterPasswordActive] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [masterPassword, setMasterPassword] = useState('');
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [masterPasswordDefined, setMasterPasswordDefined] = useState(false);

  useEffect(() => {
    const fetchMasterPasswordStatus = async () => {
      if (currentUser?.agency?.id) {
        const { isActive, isDefined } = await getMasterPasswordStatus(currentUser.agency.id);
        setMasterPasswordActive(isActive);
        setMasterPasswordDefined(isDefined);
      }
    };
    fetchMasterPasswordStatus();
  }, [currentUser]);

  // Redirect non-owners when trying to access master password tab
  useEffect(() => {
    if (!isOwner && tabValue === 2) {
      setTabValue(0);
    }
  }, [isOwner, tabValue]);

  const handleTabChange = (event, newValue) => {
    // Prevent non-owners from accessing master password tab
    if (newValue === 2 && !isOwner) {
      return;
    }
    setTabValue(newValue);
  };

  const handleToggleMasterPasswordActive = async (event) => {
    if (!isOwner || !currentUser?.agency?.id) return;

    const isActive = event.target.checked;
    setIsProcessing(true);

    try {
      if (!isActive && masterPasswordDefined) {
        setConfirmDialogOpen(true);
      } else {
        await toggleMasterPasswordActive(currentUser.agency.id, isActive);
        setMasterPasswordActive(isActive);
      }
    } catch (error) {
      console.error('Error toggling master password:', error);
      setError(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleConfirmToggle = async () => {
    if (!isOwner || !currentUser?.agency?.id) return;

    setIsProcessing(true);
    try {
      // Verify master password before deactivating
      await checkMasterPassword(currentUser.agency.id, masterPassword);
      await toggleMasterPasswordActive(currentUser.agency.id, false);
      setMasterPasswordActive(false);
      setMasterPasswordDefined(false);
      setConfirmDialogOpen(false);
      setMasterPassword('');
      setError('');
    } catch (error) {
      setError(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  // If user is not authenticated, redirect to login
  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      p: 3
    }}>
      <Paper elevation={0} sx={{ p: 2 }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%'
        }}>
          <Typography variant="h5" component="h1">
            Minha Conta
          </Typography>
          <MyAccountHelp />
        </Box>
      </Paper>

      <Paper elevation={1} sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="account settings tabs"
            variant="fullWidth"
          >
            <Tab
              icon={<PersonIcon />}
              label="Perfil"
              {...a11yProps(0)}
            />
            <Tab
              icon={<LockIcon />}
              label="Senha"
              {...a11yProps(1)}
            />
            {isOwner && (
              <Tab
                icon={<SecurityIcon />}
                label="Senha Master"
                {...a11yProps(2)}
              />
            )}
          </Tabs>
        </Box>

        <Box sx={{ minHeight: '400px' }}>
          <TabPanel value={tabValue} index={0}>
            <UpdateProfileForm />
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <ChangePasswordForm />
          </TabPanel>

          {isOwner && (
            <TabPanel value={tabValue} index={2}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 3
              }}>
                <Typography variant="h6">
                  Senha Master da Agência
                </Typography>
                <Switch
                  checked={masterPasswordActive}
                  onChange={handleToggleMasterPasswordActive}
                  color="primary"
                  disabled={isProcessing}
                />
              </Box>
              {masterPasswordActive && (
                <SetMasterPasswordForm 
                  masterPasswordActive={masterPasswordActive}
                  agencyId={currentUser.agency.id}
                />
              )}
            </TabPanel>
          )}
        </Box>
      </Paper>

      <Dialog
        open={confirmDialogOpen}
        onClose={() => !isProcessing && setConfirmDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Desativar Senha Master da Agência</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para desativar a senha master da agência, insira a senha master atual.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Senha Master"
            type="password"
            fullWidth
            value={masterPassword}
            onChange={(e) => setMasterPassword(e.target.value)}
            error={!!error}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDialogOpen(false)}
            variant="outlined"
            disabled={isProcessing}
            sx={{ borderRadius: '50px' }}
          >
            Voltar
          </Button>
          <Button
            onClick={handleConfirmToggle}
            variant="contained"
            color="error"
            disabled={!masterPassword || isProcessing}
            sx={{ color: 'white', borderRadius: '50px' }}
          >
            {isProcessing ? <CircularProgress size={24} /> : 'Confirmar'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default MyAccountPage;