import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import InstagramIcon from '@mui/icons-material/Instagram';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import LogoLight from '../../../assets/travell-logo.svg';
import LogoDark from '../../../assets/travell-logo-white.svg';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import myBackgroundSvg from '../../../assets/landingpage/viagens-mockup.png';
import myBackgroundSvg2 from '../../../assets/landingpage/passageiros-mockup.png';
import myBackgroundSvg3 from '../../../assets/landingpage/assentos-mockup.png';
import myBackgroundSvg4 from '../../../assets/landingpage/custos-mockup.png';
import myBackgroundSvg5 from '../../../assets/landingpage/agenda-mockup.png';
import myBackgroundSvg6 from '../../../assets/landingpage/documentação-mockup.png';
import myBackgroundSvg7 from '../../../assets/landingpage/relatorios-mockup.png';

const backgroundImages = [
  myBackgroundSvg,
  myBackgroundSvg2,
  myBackgroundSvg3,
  myBackgroundSvg4,
  myBackgroundSvg5,
  myBackgroundSvg6,
  myBackgroundSvg7,
];

// Função de pré-carregamento
const preloadImages = (images) => {
  images.forEach((src) => {
    const img = new Image();
    img.src = src;
  });
};

const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 400,
  marginTop: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  outline: '6px solid',
  outlineColor: 'hsla(220, 25%, 80%, 0.2)',
  border: '1px solid',
  borderColor: theme.palette.grey[200],
  boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
  backgroundSize: '60% auto',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  transition: 'all 0.5s ease-in-out',
  opacity: 0,
  transform: 'translateY(20px)',
  animation: 'fadeInUp 0.8s forwards',
  '@keyframes fadeInUp': {
    '0%': {
      opacity: 0,
      transform: 'translateY(20px)'
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)'
    }
  },
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(6),
    height: 700,
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
    outlineColor: 'hsla(220, 20%, 42%, 0.1)',
    borderColor: theme.palette.grey[700],
  }),
}));

const AnimatedStack = styled(Stack)(({ theme }) => ({
  opacity: 0,
  transform: 'translateY(20px)',
  animation: 'fadeInUp 0.8s forwards',
  animationDelay: '0.2s',
  '@keyframes fadeInUp': {
    '0%': {
      opacity: 0,
      transform: 'translateY(20px)'
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)'
    }
  }
}));

export default function Hero() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    preloadImages(backgroundImages);

    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleTrialClick = () => {
    navigate('/login');
  };

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 10, sm: 15 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 4,
            width: '100%',
          }}
        >
          <AnimatedStack spacing={2.5} sx={{ maxWidth: { xs: '100%', md: '45%' } }}>
            <div>
              <Typography
                variant="h1"
                sx={{
                  fontSize: 'clamp(2.5rem, 8vw, 3.5rem)',
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                Bem-vindo&nbsp;ao&nbsp;
                <Box
                  component="img"
                  src={theme.palette.mode === 'dark' ? LogoDark : LogoLight}
                  alt="travell Logo"
                  sx={{
                    height: { xs: 80, sm: 75 },
                    maxWidth: '250px',
                    marginTop: 1,
                  }}
                />
              </Typography>
            </div>
            <div>
              <Typography
                sx={{
                  textAlign: { xs: 'center', md: 'left' },
                  color: 'text.secondary',
                }}
              >
                A sua plataforma de gerenciamento de viagens. Organize e acompanhe suas viagens de forma simples e eficiente. Fazemos o melhor para que você possa cuidar do que realmente importa.
              </Typography>
            </div>
            <div>
              <Typography
                variant="subtitle1"
                sx={{
                  textAlign: { xs: 'center', md: 'left' },
                  color: 'primary.main',
                  fontWeight: 'bold',
                }}
              >
                Experimente gratuitamente por 15 dias!
              </Typography>
            </div>
            <div>
              <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' }, gap: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleTrialClick}
                  sx={{
                    minWidth: '200px',
                    maxWidth: '300px',
                    fontSize: '1.1rem',
                    fontWeight: 'bold',
                    borderRadius: '50px',
                    boxShadow: theme.shadows[4],
                    '&:hover': {
                      boxShadow: theme.shadows[8],
                    },
                  }}
                >
                  Começar Teste Grátis
                </Button>
                <Box sx={{ mt: 0, display: 'flex', gap: 2 }}>
                  <Link
                    href="https://wa.me/554288333458?text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20seus%20servi%C3%A7os%21"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
                  >
                    <WhatsAppIcon fontSize="large" />
                  </Link>
                  <Link
                    href="https://www.instagram.com/travell.sys/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
                  >
                    <InstagramIcon fontSize="large" />
                  </Link>
                </Box>
              </Box>
            </div>
          </AnimatedStack>

          <StyledBox
            id="carousel"
            sx={{
              backgroundImage: `url(${backgroundImages[currentImageIndex]})`,
              backgroundSize: '120% auto',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </Box>
      </Container>
    </Box>
  );
}