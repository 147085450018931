import { formatCPF, formatTelefone, formatDate, formatDateForFilename } from './utils';
import jsPDF from 'jspdf';
import logo from '../../assets/logo-travell-combr.png';
import 'jspdf-autotable';

export const exportToPDF = (travel, reservations, passengers, selectedFields, sortOrder) => {
  const doc = new jsPDF('landscape', 'pt');

  const truncateText = (text, maxLength) => {
    if (!text) return 'Não informado';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  const columns = [
    { title: "Assento", dataKey: "numeroAssento" },
    { title: "Nome do Passageiro", dataKey: "passengerNome" },
    { title: "Documentos", dataKey: "passengerDocumentos" },
    { title: "Data Nascimento", dataKey: "dataNascimento" },
    { title: "Idade", dataKey: "passengerIdade" },
    { title: "Endereço", dataKey: "passengerEndereco" },
    { title: "Telefone", dataKey: "passengerTelefone" },
    { title: "Informações Adicionais", dataKey: "informacoesAdicionais" }
  ];

  const filteredColumns = columns.filter(col => selectedFields[col.dataKey]);

  const calculateAge = (birthDate) => {
    if (!birthDate) return 'Não informado';
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age.toString();
  };

  const sortedReservations = reservations
    .filter(reservation => reservation.status !== 'Cancelada')
    .sort((a, b) => {
      if (sortOrder === "numeroAssento") {
        return (a.numeroAssento || 0) - (b.numeroAssento || 0);
      }
      if (sortOrder === "passengerNome") {
        const passengerA = passengers.find(p => p.id === a.passengerId)?.nome || "";
        const passengerB = passengers.find(p => p.id === b.passengerId)?.nome || "";
        return passengerA.localeCompare(passengerB);
      }
      return 0;
    });

  const rows = sortedReservations.map(reservation => {
    const passenger = passengers.find(p => p.id === reservation.passengerId) || {};
    const documentos = passenger.estrangeiro ?
      `Passaporte: ${passenger.passaporte || 'Não informado'}` :
      `CPF: ${passenger.cpf ? formatCPF(passenger.cpf) : 'Não informado'} / RG: ${passenger.rg || 'Não informado'}`;
    const menorDeIdade = passenger.menorDeIdade ? 'Menor de Idade' : '';

    return {
      numeroAssento: reservation.numeroAssento || 'Ocupante de Colo',
      passengerNome: passenger.nome || 'Não informado',
      passengerDocumentos: documentos.trim(),
      dataNascimento: formatDate(passenger.dataNascimento),
      passengerIdade: calculateAge(passenger.dataNascimento),
      passengerEndereco: truncateText(passenger.endereco, 200),
      passengerTelefone: formatTelefone(passenger.telefone),
      informacoesAdicionais: truncateText(`${menorDeIdade} ${reservation.detalhesPagamento?.informacoesAdicionais || ''}`, 50)
    };
  });

  let startY = 70;
  if (travel?.veiculo) {
    startY = 85;
  } else if (travel?.informacoesAdicionais) {
    startY = 85;
  }

  const img = new Image();
  img.src = logo;

  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;

  img.onload = () => {
    const logoX = pageWidth - 150;
    const logoY = 15;
    const logoWidth = 130;
    const logoHeight = 40;

    doc.addImage(img, 'PNG', logoX, logoY, logoWidth, logoHeight);
    doc.link(logoX, logoY, logoWidth, logoHeight, { url: 'https://travell.com.br' });

    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.text('Detalhes da Viagem', 40, 25);

    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');

    const infoLine1 = `Identificador: ${travel?.identificador || 'Não informado'} | Origem: ${travel?.origem || 'Não informado'} | Destino: ${travel?.destino || 'Não informado'}`;
    doc.text(infoLine1, 40, 40);

    const infoLine2 = `Ida: ${formatDate(travel?.dataIda) || 'Não informado'} às ${travel?.horarioIda || 'Não informado'} | ${travel?.somenteIda
      ? 'Somente Ida'
      : `Volta: ${formatDate(travel?.dataRetorno) || 'Não informado'} às ${travel?.horarioRetorno || 'Não informado'}`
      }`;
    doc.text(infoLine2, 40, 55);

    let infoLine3 = '';
    if (travel?.veiculo) {
      infoLine3 = `Veículo: ${travel.veiculo.identificadorVeiculo || 'Não informado'} - ${travel.veiculo.placa || 'Não informado'} (${travel.veiculo.empresa || 'Não informado'})`;
      if (travel?.informacoesAdicionais) {
        infoLine3 += ` | Info: ${truncateText(travel.informacoesAdicionais, 50)}`;
      }
      doc.text(infoLine3, 40, 70);
    }

    doc.autoTable({
      head: [filteredColumns.map(col => col.title)],
      body: rows.map(row => filteredColumns.map(col => row[col.dataKey])),
      startY: startY,
      theme: 'striped',
      margin: { top: 10, bottom: 30, left: 20, right: 20 },
      styles: { fontSize: 10, halign: 'center' },
      headStyles: { fillColor: [25, 118, 210], textColor: [255, 255, 255] },
      alternateRowStyles: { fillColor: [240, 240, 240] },
      didDrawPage: function (data) {
        doc.setFontSize(8);
        doc.setTextColor(40);
        let str = 'Página ' + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' de ' + doc.internal.getNumberOfPages();
        }
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
    });

    // Define o nome do arquivo e salva
    const identifier = travel?.identificador || 'sem-identificador';
    const origem = travel?.origem || 'sem-origem';
    const destino = travel?.destino || 'sem-destino';
    const dataIda = formatDateForFilename(travel?.dataIda || 'sem-data-ida');
    const dataRetorno = travel?.somenteIda ? 'somente-ida' : formatDateForFilename(travel?.dataRetorno || 'sem-data-retorno');
    const filename = `viagem_${identifier}_de_${origem}_na_data_${dataIda}_a_${destino}_com_volta_${dataRetorno}.pdf`;

    doc.save(filename);
  };
};
