import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import logo from '../../../assets/logo-travell-combr.png';

export const exportToPDF = async (data, year) => {
    const doc = new jsPDF('landscape', 'pt');
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const img = new Image();
    img.src = logo;

    await new Promise((resolve) => {
        img.onload = resolve;
    });

    const addHeaderToPage = () => {
        // Logo
        const logoX = pageWidth - 155;
        const logoY = 15;
        const logoWidth = 135;
        const logoHeight = 40;

        doc.addImage(img, 'PNG', logoX, logoY, logoWidth, logoHeight);
        doc.link(logoX, logoY, logoWidth, logoHeight, { url: 'https://travell.com.br' });

        // Título e informações do relatório
        doc.setFontSize(14);
        doc.setFont('helvetica', 'bold');
        doc.text('Relatório Anual', 40, 25);

        doc.setFontSize(10);
        doc.setFont('helvetica', 'normal');

        // Linha 1: Informações gerais
        const summary = data.yearSummary;
        const infoLine1 = `Ano: ${year} | Total de Viagens: ${summary.totalTravels} | Total de Reservas: ${summary.totalReservations}`;
        doc.text(infoLine1, 40, 40);

        // Linha 2: Taxas e médias
        const infoLine2 = `Taxa de Cancelamento: ${summary.cancellationRate}% | Reservas Canceladas: ${summary.reservationCancellationRate}% | Média de Reservas/Viagem: ${summary.averageReservationsPerTravel}`;
        doc.text(infoLine2, 40, 55);

        // Linha 3: Status das viagens
        const infoLine3 = `Viagens Ativas: ${summary.activeTravels} | Viagens Canceladas: ${summary.cancelledTravels}`;
        doc.text(infoLine3, 40, 70);

        return 85; // Retorna a posição Y para iniciar o conteúdo
    };

    // Função auxiliar para adicionar gráficos
    const addChartToPDF = async (element, title, startY) => {
        const canvas = await html2canvas(element, {
            scale: 2,
            backgroundColor: '#ffffff',
            logging: false,
            useCORS: true
        });

        const imgData = canvas.toDataURL('image/png');
        const imgWidth = pageWidth - 40; // 20px margin on each side
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        if (startY + imgHeight > pageHeight - 40) {
            doc.addPage();
            startY = addHeaderToPage();
        }

        doc.addImage(imgData, 'PNG', 20, startY, imgWidth, imgHeight);

        return startY + imgHeight + 20; // Return next Y position with spacing
    };

    // Primeira página
    let startY = addHeaderToPage();
    const charts = document.querySelectorAll('.chart-container');
    let currentPage = 1;

    for (const chart of charts) {
        const title = chart.querySelector('h6').textContent;
        startY = await addChartToPDF(chart, title, startY);

        // Adicionar rodapé em cada página
        doc.setFontSize(8);
        doc.setTextColor(40);
        const pageStr = `Página ${currentPage}`;
        const dateStr = `Gerado em ${new Date().toLocaleDateString()}`;
        doc.text(pageStr, 20, pageHeight - 10);
        doc.text(dateStr, pageWidth - 20 - doc.getTextWidth(dateStr), pageHeight - 10);

        currentPage++;
    }

    // Salvar o PDF com nome formatado
    const filename = `Relatorio_Anual_${year}_${new Date().toISOString().split('T')[0]}.pdf`;
    doc.save(filename);
};