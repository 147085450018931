import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Typography,
    IconButton,
    Tooltip,
    Badge,
    useTheme,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Chip,
    Select,
    MenuItem,
} from '@mui/material';
import {
    format,
    startOfMonth,
    endOfMonth,
    eachDayOfInterval,
    startOfWeek,
    endOfWeek,
    isSameMonth,
    isSameDay,
    addMonths,
    subMonths,
    isWithinInterval
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import InfoIcon from '@mui/icons-material/Info';

import TravelLoading from '../../../shared/common/TravelLoading';
import AvailableVehicles from './AvailableVehicles';

import { useNavigate } from 'react-router-dom';
import { getFleetSchedule } from './../services/FleetScheduleService';

const FleetSchedule = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [scheduleData, setScheduleData] = useState({ vehicles: [], schedules: [] });
    const [selectedDate, setSelectedDate] = useState(null);
    const [hoveredDate, setHoveredDate] = useState(null);
    const [isChangingMonth, setIsChangingMonth] = useState(false);
    const [expandedVehicle, setExpandedVehicle] = useState(null);

    useEffect(() => {
        fetchMonthSchedule();
    }, [currentDate]);

    const fetchMonthSchedule = async () => {
        setIsChangingMonth(true);
        try {
            const monthStart = startOfMonth(currentDate);
            const monthEnd = endOfMonth(currentDate);

            const data = await getFleetSchedule(
                monthStart.toISOString().split('T')[0],
                monthEnd.toISOString().split('T')[0]
            );

            setScheduleData(data);
        } catch (error) {
            console.error('Erro ao buscar agenda da frota:', error);
        } finally {
            setLoading(false);
            setIsChangingMonth(false);
        }
    };

    const handleMonthChange = (newDate) => {
        setLoading(true);
        setCurrentDate(newDate);
        setSelectedDate(null);
        setExpandedVehicle(null);
    };

    const renderCalendarHeader = () => {
        const daysOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

        return (
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', mb: 1 }}>
                {daysOfWeek.map((day) => (
                    <Typography
                        key={day}
                        variant="subtitle2"
                        sx={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            color: theme.palette.text.secondary
                        }}
                    >
                        {day}
                    </Typography>
                ))}
            </Box>
        );
    };

    const getScheduleForDate = (date) => {
        const allSchedules = [];
        scheduleData.schedules.forEach(vehicleSchedule => {
            vehicleSchedule.travels.forEach(travel => {
                const travelDeparture = new Date(`${travel.dataIda}T${travel.horarioIda}`);
                const travelReturn = travel.somenteIda ? null : new Date(`${travel.dataRetorno}T${travel.horarioRetorno}`);

                if (isSameDay(date, travelDeparture) ||
                    (travelReturn && isSameDay(date, travelReturn)) ||
                    (!travel.somenteIda && travelReturn && isWithinInterval(date, {
                        start: travelDeparture,
                        end: travelReturn
                    }))) {
                    allSchedules.push({
                        ...travel,
                        vehicleInfo: vehicleSchedule.vehicleInfo
                    });
                }
            });
        });
        return allSchedules;
    };

    const renderCalendarDays = () => {
        const monthStart = startOfMonth(currentDate);
        const monthEnd = endOfMonth(currentDate);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);

        const dateInterval = eachDayOfInterval({
            start: startDate,
            end: endDate
        });

        return (
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(7, 1fr)',
                gap: 1
            }}>
                {dateInterval.map((date) => {
                    const daySchedule = getScheduleForDate(date);
                    const isCurrentMonth = isSameMonth(date, currentDate);
                    const isSelected = selectedDate && isSameDay(date, selectedDate);
                    const isHovered = hoveredDate && isSameDay(date, hoveredDate);

                    const startingTrips = daySchedule.filter(travel =>
                        isSameDay(date, new Date(`${travel.dataIda}T${travel.horarioIda}`))
                    ).length;

                    const endingTrips = daySchedule.filter(travel =>
                        !travel.somenteIda && travel.dataRetorno &&
                        isSameDay(date, new Date(`${travel.dataRetorno}T${travel.horarioRetorno}`))
                    ).length;

                    const ongoingTrips = daySchedule.length - startingTrips - endingTrips;

                    return (
                        <Paper
                            key={date.toString()}
                            elevation={isSelected || isHovered ? 3 : 1}
                            sx={{
                                position: 'relative',
                                height: '80px',
                                p: 1,
                                cursor: 'pointer',
                                opacity: isCurrentMonth ? 1 : 0.4,
                                bgcolor: 'background.paper',
                                '&:hover': {
                                    bgcolor: theme.palette.action.hover,
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                ...(isSelected && {
                                    border: `1px solid ${theme.palette.primary.main}`,
                                })
                            }}
                            onClick={() => {
                                setSelectedDate(date);
                                setExpandedVehicle(null);
                            }}
                            onMouseEnter={() => setHoveredDate(date)}
                            onMouseLeave={() => setHoveredDate(null)}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    fontWeight: 'medium',
                                    color: theme.palette.text.primary
                                }}
                            >
                                {format(date, 'd')}
                            </Typography>

                            {daySchedule.length > 0 && (
                                <>
                                    <Box sx={{
                                        position: 'absolute',
                                        top: 4,
                                        right: 4,
                                        display: 'flex',
                                        gap: 0.5
                                    }}>
                                        {startingTrips > 0 && (
                                            <Tooltip title={`Iniciando`}>
                                                <Box sx={{
                                                    width: 16,
                                                    height: 16,
                                                    borderRadius: '50%',
                                                    bgcolor: theme.palette.success.main,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    color: 'white',
                                                    fontSize: '0.7rem',
                                                    fontWeight: 'bold'
                                                }}>
                                                    {startingTrips}
                                                </Box>
                                            </Tooltip>
                                        )}
                                        {ongoingTrips > 0 && (
                                            <Tooltip title={`Em andamento`}>
                                                <Box sx={{
                                                    width: 16,
                                                    height: 16,
                                                    borderRadius: '50%',
                                                    bgcolor: theme.palette.info.main,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    color: 'white',
                                                    fontSize: '0.7rem',
                                                    fontWeight: 'bold'
                                                }}>
                                                    {ongoingTrips}
                                                </Box>
                                            </Tooltip>
                                        )}
                                        {endingTrips > 0 && (
                                            <Tooltip title={`Retorno`}>
                                                <Box sx={{
                                                    width: 16,
                                                    height: 16,
                                                    borderRadius: '50%',
                                                    bgcolor: theme.palette.warning.main,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    color: 'white',
                                                    fontSize: '0.7rem',
                                                    fontWeight: 'bold'
                                                }}>
                                                    {endingTrips}
                                                </Box>
                                            </Tooltip>
                                        )}
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
                                        <DirectionsBusIcon color="action" size="small" />
                                        <Typography variant="caption" sx={{ fontSize: '0.6rem' }}>
                                            {daySchedule.length} {daySchedule.length === 1 ? 'veículo' : 'veículos'}
                                        </Typography>
                                    </Box>
                                </>
                            )}
                        </Paper>
                    );
                })}
            </Box>
        );
    };

    const renderScheduleDetails = () => {
        if (!selectedDate) return null;

        const daySchedule = getScheduleForDate(selectedDate);
        if (daySchedule.length === 0) {
            return (
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Agenda para {format(selectedDate, "dd 'de' MMMM 'de' yyyy", { locale: ptBR })}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Nenhuma viagem programada para esta data.
                    </Typography>
                </Box>
            );
        }

        // Agrupar viagens por veículo
        const groupedSchedule = daySchedule.reduce((acc, travel) => {
            const vehicleId = travel.vehicleId;
            if (!acc[vehicleId]) {
                acc[vehicleId] = {
                    vehicleInfo: travel.vehicleInfo,
                    travels: []
                };
            }
            acc[vehicleId].travels.push(travel);
            return acc;
        }, {});

        return (
            <Box sx={{ mt: 3 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Agenda para {format(selectedDate, "dd 'de' MMMM 'de' yyyy", { locale: ptBR })}
                </Typography>

                {Object.entries(groupedSchedule).map(([vehicleId, { vehicleInfo, travels }]) => (
                    <Accordion
                        key={vehicleId}
                        expanded
                        sx={{ mb: 1 }}
                    >
                        <AccordionSummary>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <DirectionsBusIcon />
                                <Typography>
                                    {vehicleInfo.identificadorVeiculo} - {vehicleInfo.placa}
                                </Typography>
                                <Chip
                                    size="small"
                                    label={`${travels.length} ${travels.length === 1 ? 'viagem' : 'viagens'}`}
                                    color="primary"
                                />
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            {travels.map((travel) => {
                                const isStart = isSameDay(selectedDate, new Date(`${travel.dataIda}T${travel.horarioIda}`));
                                const isEnd = !travel.somenteIda && travel.dataRetorno &&
                                    isSameDay(selectedDate, new Date(`${travel.dataRetorno}T${travel.horarioRetorno}`));

                                return (
                                    <Paper
                                        key={travel.id}
                                        sx={{
                                            p: 2,
                                            mb: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 2,
                                            borderLeft: 6,
                                            borderColor: isStart
                                                ? theme.palette.success.main
                                                : isEnd
                                                    ? theme.palette.warning.main
                                                    : theme.palette.info.main
                                        }}
                                    >
                                        <Box sx={{ flex: 1 }}>
                                            <Typography variant="subtitle1">
                                                Viagem #{travel.identificador} - {travel.origem} → {travel.destino}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Saída: {format(new Date(`${travel.dataIda}T${travel.horarioIda}`), "dd/MM 'às' HH:mm")}
                                                {!travel.somenteIda && travel.dataRetorno &&
                                                    ` - Retorno: ${format(new Date(`${travel.dataRetorno}T${travel.horarioRetorno}`), "dd/MM 'às' HH:mm")}`
                                                }
                                            </Typography>
                                            {travel.informacoesAdicionais && (
                                                <Typography variant="body2" color="text.secondary">
                                                    Info: {travel.informacoesAdicionais}
                                                </Typography>
                                            )}
                                        </Box>
                                        <Tooltip title="Ver detalhes da viagem">
                                            <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    navigate(`/viagens/${travel.id}`);
                                                }}
                                            >
                                                <InfoIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Paper>
                                );
                            })}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        );
    };

    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 3
            }}>

                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Select
                        value={format(currentDate, 'M')}
                        onChange={(e) => {
                            const newDate = new Date(currentDate.getFullYear(), parseInt(e.target.value) - 1, 1);
                            handleMonthChange(newDate);
                        }}
                        size="small"
                        sx={{ minWidth: 120 }}
                    >
                        {Array.from({ length: 12 }, (_, i) => {
                            const date = new Date(2024, i, 1);
                            return (
                                <MenuItem key={i + 1} value={i + 1}>
                                    {format(date, 'MMMM', { locale: ptBR })}
                                </MenuItem>
                            );
                        })}
                    </Select>

                    <Select
                        value={format(currentDate, 'yyyy')}
                        onChange={(e) => {
                            const newDate = new Date(parseInt(e.target.value), currentDate.getMonth(), 1);
                            handleMonthChange(newDate);
                        }}
                        size="small"
                        sx={{ minWidth: 100 }}
                    >
                        {Array.from({ length: 10 }, (_, i) => {
                            const year = new Date().getFullYear() - 2 + i;
                            return (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Box>

                <IconButton
                    onClick={() => handleMonthChange(subMonths(currentDate, 1))}
                    disabled={loading || isChangingMonth}
                >
                    <ChevronLeftIcon />
                </IconButton>
                <Typography variant="h6">
                    {format(currentDate, "MMMM 'de' yyyy", { locale: ptBR })}
                </Typography>
                <IconButton
                    onClick={() => handleMonthChange(addMonths(currentDate, 1))}
                    disabled={loading || isChangingMonth}
                >
                    <ChevronRightIcon />
                </IconButton>
            </Box>

            {(loading || isChangingMonth) ? (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 3,
                    minHeight: '400px',
                    alignItems: 'center'
                }}>
                    <TravelLoading disableAlignItems />
                </Box>
            ) : (
                <>
                    {renderCalendarHeader()}
                    {renderCalendarDays()}
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Box sx={{ flex: 2 }}>
                            {renderScheduleDetails()}
                        </Box>
                        <AvailableVehicles
                            selectedDate={selectedDate}
                            scheduleData={scheduleData}
                            navigate={navigate}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
}

export default FleetSchedule;