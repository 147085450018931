// UserManagementService.js
import { db, auth, secondaryAuth } from '../../../firebaseConfig';
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  query,
  where,
  getDocs,
  getDoc,
  writeBatch
} from 'firebase/firestore';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
  updateProfile
} from 'firebase/auth';
import { updateUserStats } from '../../MyAccount/services/AgencyStatistics';

export const UserRoles = {
  OWNER: 'owner',
  ADMIN: 'admin',
  MANAGER: 'manager',
  DRIVER: 'driver'
};

const DEFAULT_PERMISSIONS = {
  admin: {
    travels: { view: false },
    vehicles: { view: false },
    passengers: { view: false },
    orders: { view: false },
    reports: { view: false },
    layouts: { view: false }
  },
  manager: {
    travels: { view: true },
    vehicles: { view: true },
    passengers: { view: true },
    orders: { view: true },
    reports: { view: true },
    layouts: { view: true }
  },
  driver: {
    travels: { view: false },
    vehicles: { view: false },
    passengers: { view: false },
    orders: { view: false },
    reports: { view: false },
    layouts: { view: false }
  }
};

export const createAgencyUser = async (agencyId, userData) => {
  try {
    const agencyRef = doc(db, 'agencies', agencyId);
    const agencyDoc = await getDoc(agencyRef);
    await updateUserStats(1);

    if (!agencyDoc.exists()) {
      throw new Error('Agência não encontrada.');
    }

    const activeUsersQuery = query(
      collection(db, 'users'),
      where('agencyId', '==', agencyId),
      where('type', '==', 'agency_member'),
      where('metadata.status', '==', 'active')
    );
    const activeUsersSnapshot = await getDocs(activeUsersQuery);

    const currentActiveUsers = activeUsersSnapshot.docs.length - 1;

    const userLimit = agencyDoc.data().userLimit || 3;

    if (currentActiveUsers >= userLimit) {
      throw new Error(`Limite de ${userLimit} usuários atingido. Entre em contato com o suporte para aumentar seu limite.`);
    }

    const { email, password, name, phone, role, permissions } = userData;

    const userCredential = await createUserWithEmailAndPassword(secondaryAuth, email, password);
    const newUser = userCredential.user;

    if (!newUser) {
      throw new Error('Falha ao criar usuário.');
    }

    await updateProfile(newUser, { displayName: name });

    await sendEmailVerification(newUser);

    await setDoc(doc(db, 'users', newUser.uid), {
      email: newUser.email,
      name: name,
      displayName: name,
      phone: phone,
      type: 'agency_member',
      agencyId: agencyId,
      isApproved: true,
      masterPassword: null,
      masterPasswordActive: false,
      metadata: {
        createdAt: new Date().toISOString(),
        lastLogin: new Date().toISOString(),
        status: 'active'
      }
    });

    const userPermissions = permissions || DEFAULT_PERMISSIONS[role.toLowerCase()];

    await setDoc(doc(db, `agencies/${agencyId}/members`, newUser.uid), {
      roleId: role,
      customPermissions: userPermissions,
      metadata: {
        addedAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        status: 'active',
        lastAccess: new Date().toISOString()
      }
    });

    await signOut(secondaryAuth);

    return { userId: newUser.uid };
  } catch (error) {
    console.error('Erro ao criar usuário da agência:', error);
    try {
      await signOut(secondaryAuth);
    } catch (signOutError) {
      console.error('Erro ao fazer logout da segunda instância:', signOutError);
    }
    throw new Error(mapFirebaseError(error));
  }
};

export const updateAgencyUser = async (agencyId, userId, updates) => {
  try {
    // Importar writeBatch
    const batch = writeBatch(db);

    // Atualizações do documento do usuário
    const userUpdates = {
      name: updates.name,
      displayName: updates.name,
      phone: updates.phone,
      'metadata.updatedAt': new Date().toISOString()
    };

    // Atualizações do membro da agência
    const memberUpdates = {
      roleId: updates.role,
      customPermissions: updates.permissions,
      'metadata.updatedAt': new Date().toISOString()
    };

    // Criar referências dos documentos
    const userRef = doc(db, 'users', userId);
    const memberRef = doc(db, `agencies/${agencyId}/members`, userId);

    // Adicionar as atualizações ao batch
    batch.update(userRef, userUpdates);
    batch.update(memberRef, memberUpdates);

    // Executar o batch
    await batch.commit();

    // Se o usuário estiver atualmente logado na segunda instância, atualizar o displayName
    const currentUser = secondaryAuth.currentUser;
    if (currentUser && currentUser.uid === userId) {
      await updateProfile(currentUser, { displayName: updates.name });
    }

    return true;
  } catch (error) {
    console.error('Erro ao atualizar usuário:', error);
    throw new Error(mapFirebaseError(error));
  }
};

export const getAgencyLimits = async (agencyId) => {
  try {
    const agencyRef = doc(db, 'agencies', agencyId);
    const agencyDoc = await getDoc(agencyRef);
    
    if (!agencyDoc.exists()) {
      throw new Error('Agência não encontrada.');
    }

    const activeUsersQuery = query(
      collection(db, 'users'),
      where('agencyId', '==', agencyId),
      where('type', '==', 'agency_member'),
      where('metadata.status', '==', 'active')
    );
    const activeUsersSnapshot = await getDocs(activeUsersQuery);
    
    return {
      current: activeUsersSnapshot.docs.length, 
      limit: agencyDoc.data().userLimit || 3
    };
  } catch (error) {
    console.error('Erro ao carregar limites da agência:', error);
    throw new Error('Erro ao carregar limites da agência: ' + error.message);
  }
};

export const getAgencyUsers = async (agencyId) => {
  try {
    const usersRef = collection(db, 'users');
    const q = query(
      usersRef,
      where('agencyId', '==', agencyId),
      where('type', '==', 'agency_member')
    );
    const snapshot = await getDocs(q);

    const users = [];
    for (const userDoc of snapshot.docs) {
      const userData = userDoc.data();
      const memberDocRef = doc(db, `agencies/${agencyId}/members`, userDoc.id);
      const memberDoc = await getDoc(memberDocRef);

      if (memberDoc.exists() && memberDoc.data().roleId !== 'owner') {
        users.push({
          id: userDoc.id,
          ...userData,
          role: memberDoc.data().roleId,
          permissions: memberDoc.data().customPermissions
        });
      }
    }

    return users;
  } catch (error) {
    console.error('Erro ao buscar usuários da agência:', error);
    throw new Error(mapFirebaseError(error));
  }
};

export const getUserById = async (agencyId, userId) => {
  try {
    const agencyRef = doc(db, 'agencies', agencyId);
    const userRef = doc(db, 'users', userId);
    const memberRef = doc(agencyRef, 'members', userId);

    const [userDoc, memberDoc] = await Promise.all([
      getDoc(userRef),
      getDoc(memberRef)
    ]);

    if (!userDoc.exists() || !memberDoc.exists()) {
      throw new Error('Usuário não encontrado');
    }

    const userData = userDoc.data();
    const memberData = memberDoc.data();

    return {
      id: userId,
      name: userData.name,
      email: userData.email,
      phone: userData.phone,
      role: memberData.roleId,
      permissions: memberData.customPermissions || {}
    };
  } catch (error) {
    console.error('Erro ao buscar usuário:', error);
    throw error;
  }
};

export const getUsersForSelection = async () => {
  try {
    const user = auth.currentUser;
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    const agencyId = userDoc.data().agencyId;

    const usersRef = collection(db, 'users');
    const q = query(
      usersRef,
      where('agencyId', '==', agencyId),
      where('metadata.status', '==', 'active'),
      where('type', '==', 'agency_member')
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Erro ao buscar usuários:', error);
    throw error;
  }
};

export const reactivateUser = async (agencyId, userId) => {
  try {
    const batch = writeBatch(db);

    const userRef = doc(db, 'users', userId);
    const memberRef = doc(db, `agencies/${agencyId}/members`, userId);

    const updateData = {
      'metadata.status': 'active',
      'metadata.updatedAt': new Date().toISOString(),
      'metadata.reactivatedAt': new Date().toISOString(),
      isApproved: true
    };

    batch.update(userRef, updateData);
    batch.update(memberRef, {
      'metadata.status': 'active',
      'metadata.updatedAt': new Date().toISOString(),
      'metadata.reactivatedAt': new Date().toISOString()
    });

    await batch.commit();
    return true;
  } catch (error) {
    console.error('Erro ao reativar usuário:', error);
    throw new Error(mapFirebaseError(error));
  }
};

export const deleteAgencyUser = async (agencyId, userId) => {
  try {
    // Criar um novo batch
    const batch = writeBatch(db);

    // Referências dos documentos
    const userRef = doc(db, 'users', userId);
    const memberRef = doc(db, `agencies/${agencyId}/members`, userId);

    // Preparar as atualizações de status com dados adicionais
    const updateData = {
      'metadata.status': 'inactive',
      'metadata.updatedAt': new Date().toISOString(),
      'metadata.deletedAt': new Date().toISOString(),
      isApproved: false
    };

    // Adicionar as operações ao batch
    batch.update(userRef, updateData);
    batch.update(memberRef, {
      'metadata.status': 'inactive',
      'metadata.updatedAt': new Date().toISOString(),
      'metadata.deletedAt': new Date().toISOString()
    });

    // Executar o batch
    await batch.commit();
    return true;
  } catch (error) {
    console.error('Erro ao desativar usuário:', error);
    throw new Error(mapFirebaseError(error));
  }
};

const mapFirebaseError = (error) => {
  switch (error.code || error.message) {
    case 'auth/user-not-found':
      return 'Usuário não encontrado. Verifique suas credenciais e tente novamente.';
    case 'auth/email-already-in-use':
      return 'O endereço de e-mail já está em uso por outra conta.';
    case 'auth/invalid-email':
      return 'O endereço de e-mail não é válido.';
    case 'auth/weak-password':
      return 'A senha é muito fraca. Tente uma senha mais forte.';
    default:
      return error.message || 'Ocorreu um erro inesperado. Tente novamente mais tarde.';
  }
};