import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Divider, CircularProgress, Pagination, Tabs, Tab, Avatar, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import BusinessIcon from '@mui/icons-material/Business';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import VehicleSchedule from './components/VehicleSchedule';
import VehicleLayoutView from './components/VehicleLayoutView';
import VehicleDocumentsInDetails from './components/VehicleDocumentsInDetails';
import TravelCard from '../../components/Travel/TravelCard';
import TravelLoading from '../../shared/common/TravelLoading';

import { getVehicleTravels, getVehicleById } from '../../pages/Vehicle/services/VehicleService';

const VehicleDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [vehicle, setVehicle] = useState(null);
  const [travels, setTravels] = useState([]);
  const [loading, setLoading] = useState(true); // Changed to true by default
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState(0);
  const [totalTravels, setTotalTravels] = useState(0);
  const [lastDoc, setLastDoc] = useState(null);
  const itemsPerPage = 5;
  const theme = useTheme();

  useEffect(() => {
    if (id) {
      Promise.all([
        fetchVehicleData(),
        fetchTravels(1)
      ]).finally(() => {
        setLoading(false);
      });
    }
  }, [id]);

  const fetchVehicleData = async () => {
    try {
      const vehicleData = await getVehicleById(id);
      setVehicle(vehicleData);
    } catch (error) {
      console.error('Erro ao buscar dados do veículo:', error);
    }
  };

  const fetchTravels = async (page) => {
    try {
      const result = await getVehicleTravels(
        id,
        page,
        itemsPerPage,
        page === 1 ? null : lastDoc
      );
      setTravels(result.travels);
      setLastDoc(result.lastDoc);
      setTotalTravels(result.totalCount);
    } catch (error) {
      console.error('Erro ao buscar viagens do veículo:', error);
    }
  };

  const handlePageChange = async (event, value) => {
    setLoading(true);
    setCurrentPage(value);
    await fetchTravels(value);
    setLoading(false);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <TravelLoading />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <IconButton
          onClick={() => navigate('/veiculos')}
          sx={{ mr: 1 }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">
          Detalhes do Veículo
        </Typography>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={currentTab} onChange={handleTabChange} aria-label="vehicle details tabs">
          <Tab label="Detalhes" />
          <Tab label="Agenda" />
          <Tab label="Layout" />
          <Tab label="Documentos" />
        </Tabs>
      </Box>

      {currentTab === 0 && vehicle && (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Avatar sx={{ bgcolor: theme.palette.primary.main, mr: 2 }}>
              <DirectionsBusIcon />
            </Avatar>
            <Typography variant="h6" sx={{ fontWeight: 'bold', ml: 2 }}>
              {vehicle.identificadorVeiculo}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Avatar sx={{ bgcolor: theme.palette.primary.main, mr: 1 }}>
              <BusinessIcon />
            </Avatar>
            <Typography variant="body2" sx={{ mb: 1 }}>
              <strong>Empresa:</strong> {vehicle.empresa}
            </Typography>
            <Typography variant="body2" sx={{ mb: 1, ml: 2 }}>
              <strong>Placa:</strong> {vehicle.placa}
            </Typography>
          </Box>

          <Divider sx={{ mb: 2 }} />

          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              <strong>Layout Associado:</strong> {vehicle.layout ? (vehicle.layout.name || 'Sem nome') : 'Nenhum layout associado'}
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              <strong>Assentos 1° Andar:</strong> {vehicle.layout?.assentosAndar1 || 'N/A'}
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              <strong>Assentos 2° Andar:</strong> {vehicle.layout?.assentosAndar2 || 'N/A'}
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              <strong>Dois Andares:</strong> {vehicle.layout?.doisAndares ? 'Sim' : 'Não'}
            </Typography>
          </Box>

          <Divider sx={{ mb: 2 }} />

          {travels.length > 0 ? (
            <>
              <TravelCard travels={travels} hideActions stacked />
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Pagination
                  count={Math.ceil(totalTravels / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Box>
            </>
          ) : (
            <Typography variant="body2" sx={{ textAlign: 'center', py: 2 }}>
              Nenhuma viagem encontrada para este veículo.
            </Typography>
          )}
        </>
      )}

      {currentTab === 1 && vehicle && (
        <VehicleSchedule vehicleId={vehicle.id} travels={travels} />
      )}

      {currentTab === 2 && vehicle?.layout && <VehicleLayoutView layout={vehicle.layout} />}

      {currentTab === 3 && vehicle && (
        <VehicleDocumentsInDetails vehicleId={vehicle.id} />
      )}
    </Box>
  );
};

export default VehicleDetailsPage;