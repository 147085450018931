export const PUBLIC_ROUTES = [
  '/login',
  '/pendente-aprovacao',
  '/central-ajuda'
];

export const BUSINESS_ROUTES = [
  '/agencia'
];

export const COMMON_ACTIONS = {
  VIEW: 'view',
  CREATE: 'create',
  EDIT: 'edit',
  DELETE: 'delete'
};

import React from 'react';

// Importação das páginas
import PassengerPage from '../pages/Passenger/PassengerPage';
import TravelPage from '../pages/Travel/TravelPage';
import TravelFreightPage from '../pages/TravelFreight/TravelFreightPage';
import TravelDetailsPage from '../pages/TravelDetails/TravelDetailsPage';
import TravelTransactionsPage from '../pages/TravelTransactions/TravelTransactionsPage';
import PassengerAllocationPage from '../pages/Allocation/PassengerAllocationPage';
import TravelOrdersReservationsPage from '../pages/TravelOrdersReservations/TravelOrdersReservationsPage';
import AccommodationPage from '../pages/Accommodation/AccommodationPage';
import VehiclePage from '../pages/Vehicle/VehiclePage';
import VehicleDetailsPage from '../pages/VehicleDetails/VehicleDetailsPage';
import FleetSchedulePage from '../pages/FleetSchedule/FleetSchedulePage';
import VehicleDocumentsPage from '../pages/VehicleDocuments/VehicleDocumentsPage';
import ReportPage from '../pages/Report/ReportPage';
import ReceivablesPage from '../pages/Receivables/ReceivablesPage';
import PayablesPage from '../pages/Payables/PayablesPage';
import OrdersAndReservationsListPage from '../pages/OrdersAndReservations/OrdersAndReservationsListPage';
import LayoutPage from '../pages/LayoutPage/LayoutPage';
import BusLayoutBuilderPage from '../pages/LayoutPage/BusLayoutBuilderPage';
import PaymentsPage from '../pages/Payments/PaymentsPage';
import UserManagementPage from '../pages/UserManagement/UserManagementPage';
import UserFormPage from '../pages/UserManagement/UserFormPage';
import BankAccountPage from '../pages/BankAccount/BankAccountPage';
import MyAccountPage from '../pages/MyAccount/MyAccountPage';

// import MigrationTestPage from '../migrations/MigrationTestPage';

export const PERMISSIONS = {
  TRAVELS: {
    key: 'travels',
    label: 'Viagens',
    description: 'Gerenciamento de viagens e roteiros',
    actions: {
      VIEW: 'view',
      CREATE: 'create',
      EDIT: 'edit',
      DELETE: 'delete',
      CANCEL: 'cancel'
    },
    routes: [
      {
        path: '/viagens',
        component: <TravelPage />,
        requiredActions: ['view'],
        description: 'Lista de viagens'
      },
      {
        path: '/viagens/:travelId',
        component: <TravelDetailsPage />,
        requiredActions: ['view'],
        description: 'Detalhes da viagem'
      },
      {
        path: '/viagens/:travelId/reservas',
        component: <TravelOrdersReservationsPage />,
        requiredActions: ['view'],
        description: 'Reservas da viagem'
      },
    ]
  },

  TRAVELS_FREIGHT: {
    key: 'travels_freight',
    label: 'Viagens Fretadas',
    description: 'Gerenciamento de viagens fretadas',
    actions: {
      VIEW: 'view',
      CREATE: 'create',
      EDIT: 'edit',
      DELETE: 'delete',
      CANCEL: 'cancel'
    },
    routes: [
      {
        path: '/viagens-fretadas',
        component: <TravelFreightPage />,
        requiredActions: ['view'],
        description: 'Lista de viagens fretadas'
      },
      {
        path: '/viagens/:travelId',
        component: <TravelDetailsPage />,
        requiredActions: ['view'],
        description: 'Detalhes da viagem'
      },
      {
        path: '/viagens/:travelId/reservas',
        component: <TravelOrdersReservationsPage />,
        requiredActions: ['view'],
        description: 'Reservas da viagem'
      },
    ]
  },

  TRAVELS_TRANSACTIONS: {
    key: 'travels_transactions',
    label: 'Financeiro das Viagens',
    description: 'Gerenciamento de custos e transações das viagens',
    actions: {
      VIEW: 'view',
      CREATE: 'create',
      EDIT: 'edit',
      DELETE: 'delete',
    },
    routes: [
      {
        path: '/viagens/:travelId/custos',
        component: <TravelTransactionsPage />,
        requiredActions: ['view'],
        description: 'Custos da viagem'
      }
    ]
  },

  TRAVELS_ACCOMMODATIONS: {
    key: 'travels_accommodations',
    label: 'Acomodações',
    description: 'Gerenciamento de acomodações e hospedagens',
    actions: {
      VIEW: 'view',
      CREATE: 'create',
      EDIT: 'edit',
      DELETE: 'delete',
    },
    routes: [
      {
        path: '/viagens/:travelId/hospedagens',
        component: <AccommodationPage />,
        requiredActions: ['view'],
        description: 'Acomodações da viagem'
      }
    ]
  },

  PASSENGERS: {
    key: 'passengers',
    label: 'Passageiros',
    description: 'Gerenciamento de passageiros',
    actions: {
      VIEW: 'view',
      CREATE: 'create',
      EDIT: 'edit',
      DELETE: 'delete'
    },
    routes: [
      {
        path: '/passageiros',
        component: <PassengerPage />,
        requiredActions: ['view'],
        description: 'Lista de passageiros'
      }
    ]
  },

  VEHICLES: {
    key: 'vehicles',
    label: 'Veículos',
    description: 'Gerenciamento de veículos e frota',
    actions: {
      VIEW: 'view',
      CREATE: 'create',
      EDIT: 'edit',
      DELETE: 'delete'
    },
    routes: [
      {
        path: '/veiculos',
        component: <VehiclePage />,
        requiredActions: ['view'],
        description: 'Lista de veículos'
      },
      {
        path: '/veiculos/:id',
        component: <VehicleDetailsPage />,
        requiredActions: ['view'],
        description: 'Detalhes do veículo'
      },
      {
        path: '/veiculos/agenda',
        component: <FleetSchedulePage />,
        requiredActions: ['view'],
        description: 'Agenda da frota'
      },
      {
        path: '/veiculos/documentos',
        component: <VehicleDocumentsPage />,
        requiredActions: ['view'],
        description: 'Documentos dos veículos'
      }
    ]
  },

  LAYOUTS: {
    key: 'layouts',
    label: 'Layouts',
    description: 'Gerenciamento de layouts de veículos',
    actions: {
      VIEW: 'view',
      CREATE: 'create',
      EDIT: 'edit',
      DELETE: 'delete'
    },
    routes: [
      {
        path: '/layouts',
        component: <LayoutPage />,
        requiredActions: ['view'],
        description: 'Lista de layouts'
      },
      {
        path: '/layouts/novo',
        component: <BusLayoutBuilderPage />,
        requiredActions: ['create'],
        description: 'Criar novo layout'
      },
      {
        path: '/layouts/:id',
        component: <BusLayoutBuilderPage />,
        requiredActions: ['edit'],
        description: 'Editar layout'
      }
    ]
  },

  ORDERS: {
    key: 'orders',
    label: 'Pedidos e Reservas',
    description: 'Gerenciamento de pedidos e pagamentos',
    actions: {
      VIEW: 'view',
      CREATE: 'create',
      EDIT: 'edit',
      CANCEL: 'cancel',
    },
    routes: [
      {
        path: '/pagamentos',
        component: <PaymentsPage />,
        requiredActions: ['view'],
        description: 'Lista de pagamentos'
      },
      {
        path: '/pedidos-e-reservas',
        component: <OrdersAndReservationsListPage />,
        requiredActions: ['view'],
        description: 'Lista de pedidos e reservas'
      },
      {
        path: '/viagens/:travelId/pedido/novo',
        component: <PassengerAllocationPage isCreating={true} />,
        requiredActions: ['create'],
        description: 'Novo pedido'
      },
      {
        path: '/viagens/:travelId/pedido/:id',
        component: <PassengerAllocationPage isCreating={false} />,
        requiredActions: ['edit'],
        description: 'Editar pedido'
      },
    ]
  },

  BUSSINESS_ANALYTICS: {
    key: 'bussinessanalytics',
    label: 'Business Analytics',
    description: 'Análise de dados e relatórios',
    actions: {
      VIEW: 'view'
    },
    routes: [
      {
        path: '/ba',
        component: <ReportPage />,
        requiredActions: ['view'],
        description: 'Business Analytics'
      },
    ]
  },

  RECEIVABLES: {
    key: 'receivables',
    label: 'Contas a Receber',
    description: 'Gerenciamento de contas a receber',
    actions: {
      VIEW: 'view'
    },
    routes: [
      {
        path: '/contas/receber',
        component: <ReceivablesPage />,
        requiredActions: ['view'],
        description: 'Contas a receber'
      },
    ]
  },

  PAYABLES: {
    key: 'payables',
    label: 'Contas a Pagar',
    description: 'Gerenciamento de contas a pagar',
    actions: {
      VIEW: 'view'
    },
    routes: [
      {
        path: '/contas/pagar',
        component: <PayablesPage />,
        requiredActions: ['view'],
        description: 'Contas a pagar'
      },
    ]
  },

  BANK_ACCOUNTS: {
    key: 'bank_accounts',
    label: 'Contas Bancárias',
    description: 'Gerenciamento de contas bancárias',
    actions: {
      VIEW: 'view',
      CREATE: 'create',
      EDIT: 'edit',
      DELETE: 'delete',
    },
    routes: [
      {
        path: '/contas-bancarias',
        component: <BankAccountPage />,
        requiredActions: ['view'],
        description: 'Gerenciamento de contas bancárias'
      }
    ]
  },

  SYSTEM: {
    key: 'system',
    label: 'Sistema',
    description: 'Funcionalidades base do sistema',
    actions: {
      VIEW: 'view'
    },
    routes: [
      {
        path: '/conta',
        component: <MyAccountPage />,
        requiredActions: ['view'],
        description: 'Minha conta',
        isBasic: true
      }
    ]
  },

  USERS: {
    key: 'users',
    label: 'Usuários',
    description: 'Gerenciamento de usuários',
    actions: {
      VIEW: 'view',
      CREATE: 'create',
      EDIT: 'edit',
      DELETE: 'delete'
    },
    routes: [
      {
        path: '/usuarios',
        component: <UserManagementPage />,
        requiredActions: ['view'],
        description: 'Gerenciamento de usuários'
      },
      {
        path: '/usuarios/novo',
        component: <UserFormPage />,
        requiredActions: ['create'],
        description: 'Criar novo usuário'
      },
      {
        path: '/usuarios/:userId/editar',
        component: <UserFormPage />,
        requiredActions: ['edit'],
        description: 'Editar usuário'
      },
    ]
  },

  //   MIGRATIONS: {
  //     key: 'migrations',
  //     label: 'Migrações',
  //     description: 'Testes de migração de dados',
  //     actions: {
  //       VIEW: 'view'
  //     },
  //     routes: [
  //       {
  //         path: '/migration',
  //         component: <MigrationTestPage />,
  //         requiredActions: ['view'],
  //         description: 'Testes de migração'
  //       },
  //     ]
  //   },
};

export const ROLES = {
  OWNER: {
    key: 'owner',
    label: 'Proprietário',
    description: 'Acesso total ao sistema',
    inheritsAllPermissions: true
  },
  ADMIN: {
    key: 'admin',
    label: 'Administrador',
    description: 'Acesso administrativo ao sistema',
    defaultPermissions: {
      [PERMISSIONS.TRAVELS.key]: ['view', 'create', 'edit', 'delete', 'cancel'],
      [PERMISSIONS.TRAVELS_FREIGHT.key]: ['view', 'create', 'edit', 'delete', 'cancel'],
      [PERMISSIONS.TRAVELS_TRANSACTIONS.key]: ['view', 'create', 'edit', 'delete'],
      [PERMISSIONS.PASSENGERS.key]: ['view', 'create', 'edit', 'delete'],
      [PERMISSIONS.VEHICLES.key]: ['view', 'create', 'edit', 'delete'],
      [PERMISSIONS.LAYOUTS.key]: ['view', 'create', 'edit', 'delete'],
      [PERMISSIONS.ORDERS.key]: ['view', 'create', 'edit', 'cancel'],
      [PERMISSIONS.BUSSINESS_ANALYTICS.key]: ['view'],
      [PERMISSIONS.RECEIVABLES.key]: ['view'],
      [PERMISSIONS.PAYABLES.key]: ['view'],
      [PERMISSIONS.BANK_ACCOUNTS.key]: ['view', 'create', 'edit', 'delete'],
      [PERMISSIONS.SYSTEM.key]: ['view'],
      [PERMISSIONS.USERS.key]: ['view', 'create', 'edit', 'delete']
    }
  },
  COLABORATOR: {
    key: 'colaborator',
    label: 'Colaborador',
    description: 'Gerenciamento operacional',
    defaultPermissions: {
      [PERMISSIONS.TRAVELS.key]: ['view', 'create', 'edit'],
      [PERMISSIONS.TRAVELS_FREIGHT.key]: ['view', 'create', 'edit'],
      [PERMISSIONS.PASSENGERS.key]: ['view', 'create', 'edit'],
      [PERMISSIONS.VEHICLES.key]: ['view', 'create', 'edit'],
      [PERMISSIONS.ORDERS.key]: ['view'],
    }
  },
  GUIDE: {
    key: 'guide',
    label: 'Guia',
    description: 'Acesso APENAS às viagens designadas',
    defaultPermissions: {
      [PERMISSIONS.TRAVELS.key]: ['view'],
      [PERMISSIONS.TRAVELS_FREIGHT.key]: ['view'],
      [PERMISSIONS.TRAVELS_TRANSACTIONS.key]: ['view', 'create', 'edit', 'delete'],
      [PERMISSIONS.PASSENGERS.key]: ['view', 'create', 'edit'],
      [PERMISSIONS.ORDERS.key]: ['create', 'edit', 'cancel'],
    }
  },
};
