import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button, Divider, CircularProgress, Pagination, Modal, TextField, InputAdornment, IconButton, Snackbar, Alert } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ReservationCard from '../../../components/Reservation/ReservationCard';
import ReservationDetails from '../../../components/Reservation/ReservationDetails';

import { usePermissions } from '../../../hooks/usePermissions';
import { formatCPF, formatDate, formatTelefone } from '../../../shared/utils/utils'
import { getPassengerReservations, getPassengerById } from '../../Passenger/services/PassengerService';
import { cancelReservation } from '../../Allocation/services/OrderService';
import { useMasterPassword } from '../../../shared/hooks/useMasterPassword';

const PassengerDetails = ({ passenger, open, onClose, onEditReservation, onReservationCancel }) => {
  const { checkPermission } = usePermissions();
  const canViewOrders = checkPermission('orders', 'view');
  const [reservationState, setReservationState] = useState({
    reservations: [],
    pagination: {
      currentPage: 1,
      totalPages: 0,
      totalItems: 0,
      pageSize: 5
    }
  });
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [cancelData, setCancelData] = useState({
    reservationId: null,
    orderId: null,
    travelId: null
  });
  const [masterPassword, setMasterPassword] = useState('');
  const [cancelLoading, setCancelLoading] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [responsavel, setResponsavel] = useState(null);
  const {
    isVerifying,
    error: masterPasswordError,
    clearError,
    masterPasswordActive,
    showPassword,
    togglePasswordVisibility,
    verifyMasterPassword
  } = useMasterPassword();
  const navigate = useNavigate();

  useEffect(() => {
    if (open && passenger) {
      fetchReservations(1);
      if (passenger.menorDeIdade && passenger.responsavelId) {
        fetchResponsavel();
      }
    }
  }, [open, passenger]);

  const fetchReservations = async (page) => {
    if (!canViewOrders) {
      return;
    }

    setLoading(true);
    try {
      const result = await getPassengerReservations(passenger.id, page, reservationState.pagination.pageSize);
      setReservationState({
        reservations: result.reservations,
        pagination: result.pagination
      });
    } catch (error) {
      console.error('Erro ao buscar reservas:', error);
      setSnackbarState({
        open: true,
        message: 'Erro ao carregar reservas',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchResponsavel = async () => {
    try {
      const responsavelData = await getPassengerById(passenger.responsavelId);
      setResponsavel(responsavelData);
    } catch (error) {
      console.error('Erro ao buscar informações do responsável:', error);
    }
  };

  const handlePageChange = (_, page) => {
    fetchReservations(page);
  };

  const handleOpenModal = (reservation) => {
    setSelectedReservation(reservation);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedReservation(null);
  };

  const handleEditReservation = (reservation) => {
    navigate(`/viagens/${reservation.travelId}/pedido/${reservation.orderId}`, {
      state: {
        editingReservation: reservation,
        editingOrderId: reservation.orderId,
      },
    });
  };

  const handleCancelReservation = (reservationId, orderId, travelId) => {
    setCancelData({ reservationId, orderId, travelId });
    setCancelDialogOpen(true);
  };

  const confirmCancelReservation = async () => {
    setCancelLoading(true);
    try {
      if (masterPasswordActive) {
        const isValid = await verifyMasterPassword(masterPassword);
        if (!isValid) {
          setSnackbarState({
            open: true,
            message: 'Senha master incorreta',
            severity: 'error'
          });
          setCancelLoading(false);
          return;
        }
      }

      await cancelReservation(
        cancelData.travelId,
        cancelData.orderId,
        cancelData.reservationId
      );

      // Recarrega a página atual de reservas
      await fetchReservations(reservationState.pagination.currentPage);

      setSnackbarState({
        open: true,
        message: 'Reserva cancelada com sucesso',
        severity: 'success'
      });

      if (onReservationCancel) {
        onReservationCancel();
      }

      handleCloseCancelDialog();
    } catch (err) {
      setSnackbarState({
        open: true,
        message: err.message.includes('master') ? 'Senha master incorreta' : 'Erro ao cancelar reserva: ' + err.message,
        severity: 'error'
      });
    } finally {
      setCancelLoading(false);
    }
  };

  const handleCloseCancelDialog = () => {
    setCancelDialogOpen(false);
    setCancelData({
      reservationId: null,
      orderId: null,
      travelId: null
    });
    setMasterPassword('');
    clearError();
  };

  const handleCloseSnackbar = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="passenger-details-title" maxWidth="sm" fullWidth>
      <DialogTitle id="passenger-details-title">Detalhes do Passageiro</DialogTitle>
      <DialogContent>
        {passenger && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Avatar sx={{ bgcolor: '#1976d2', mr: 2 }}>
                <PersonIcon />
              </Avatar>
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {passenger.nome}
                </Typography>
                <Typography variant="body2" sx={{ wordBreak: 'break-word', mb: 1 }}>
                  <strong>CPF:</strong> {passenger.cpf ? formatCPF(passenger.cpf) : 'Não informado'}
                </Typography>
                {passenger.rg && (
                  <Typography variant="body2" sx={{ wordBreak: 'break-word', mb: 1 }}>
                    <strong>RG:</strong> {passenger.rg}
                  </Typography>
                )}
                {passenger.passaporte && (
                  <Typography variant="body2" sx={{ wordBreak: 'break-word', mb: 1 }}>
                    <strong>Passaporte:</strong> {passenger.passaporte}
                  </Typography>
                )}
                <Typography variant="body2" sx={{ wordBreak: 'break-word', mb: 1 }}>
                  <strong>Data de Nascimento:</strong> {formatDate(passenger.dataNascimento)}
                </Typography>
                {passenger.menorDeIdade && (
                  <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', wordBreak: 'break-word', mb: 1 }}>
                    <strong>Menor de Idade</strong> <VerifiedUserIcon sx={{ ml: 1, color: 'green' }} />
                  </Typography>
                )}
                {passenger.estrangeiro && (
                  <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', wordBreak: 'break-word', mb: 1 }}>
                    <strong>Estrangeiro</strong> <VerifiedUserIcon sx={{ ml: 1, color: 'green' }} />
                  </Typography>
                )}
              </Box>
            </Box>

            {passenger.telefone && (
              <>
                <Divider sx={{ mb: 2 }} />
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Avatar sx={{ bgcolor: '#1976d2', mr: 2 }}>
                    <ContactPhoneIcon />
                  </Avatar>
                  <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                    <strong>Telefone:</strong> {formatTelefone(passenger.telefone)}
                  </Typography>
                </Box>
              </>
            )}

            {passenger.endereco && (
              <>
                <Divider sx={{ mb: 2 }} />
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Avatar sx={{ bgcolor: '#1976d2', mr: 2 }}>
                    <HomeIcon />
                  </Avatar>
                  <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                    <strong>Endereço:</strong> {passenger.endereco}
                  </Typography>
                </Box>
              </>
            )}

            {/* Exibição das informações do responsável */}
            {passenger.menorDeIdade && responsavel && (
              <>
                <Divider sx={{ mb: 2 }} />
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Avatar sx={{ bgcolor: '#1976d2', mr: 2 }}>
                    <AccountCircleIcon />
                  </Avatar>
                  <Box>
                    {responsavel.nome && (
                      <Typography variant="body2" sx={{ wordBreak: 'break-word', mb: 1 }}>
                        <strong>Nome do Responsável:</strong> {responsavel.nome}
                      </Typography>
                    )}
                    {responsavel.cpf && (
                      <Typography variant="body2" sx={{ wordBreak: 'break-word', mb: 1 }}>
                        <strong>CPF do Responsável:</strong> {formatCPF(responsavel.cpf)}
                      </Typography>
                    )}
                    {responsavel.estrangeiro ? (
                      <Typography variant="body2" sx={{ wordBreak: 'break-word', mb: 1 }}>
                        <strong>Passaporte do Responsável:</strong> {responsavel.passaporte}
                      </Typography>
                    ) : (
                      <Typography variant="body2" sx={{ wordBreak: 'break-word', mb: 1 }}>
                        <strong>RG do Responsável:</strong> {responsavel.rg}
                      </Typography>
                    )}
                    {responsavel.telefone && (
                      <Typography variant="body2" sx={{ wordBreak: 'break-word', mb: 1 }}>
                        <strong>Telefone do Responsável:</strong> {formatTelefone(responsavel.telefone)}
                      </Typography>
                    )}
                    {responsavel.estrangeiro && (
                      <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', wordBreak: 'break-word', mb: 1 }}>
                        <strong>Responsável Estrangeiro</strong> <VerifiedUserIcon sx={{ ml: 1, color: 'green' }} />
                      </Typography>
                    )}
                  </Box>
                </Box>
              </>
            )}

            {canViewOrders ? (
              loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <CircularProgress size={24} sx={{ mr: 2 }} />
                  <Typography variant="body2">Buscando reservas...</Typography>
                </Box>
              ) : reservationState.reservations.length > 0 ? (
                <>
                  <Divider sx={{ mb: 2 }} />
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Avatar sx={{ bgcolor: '#1976d2', mr: 2 }}>
                      <PermContactCalendarIcon />
                    </Avatar>
                    <Typography variant="body2" sx={{ wordBreak: 'break-word', mb: 1 }}>
                      <strong>Reservas:</strong>
                    </Typography>
                  </Box>
                  {reservationState.reservations.map((reserva) => (
                    <ReservationCard
                      key={reserva.id}
                      reservation={reserva}
                      passengers={[passenger]}
                      travel={reserva.travel}
                      onEditReservation={handleEditReservation}
                      onCancelReservation={handleCancelReservation}
                      onCardClick={() => handleOpenModal(reserva)}
                    />
                  ))}
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Pagination
                      count={reservationState.pagination.totalPages}
                      page={reservationState.pagination.currentPage}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </Box>
                </>
              ) : (
                <Typography variant="body2" sx={{ wordBreak: 'break-word', mt: 2 }}>
                  Nenhuma reserva encontrada.
                </Typography>
              )
            ) : null}
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <Button onClick={onClose} variant="outlined" color="primary" sx={{ borderRadius: '50px' }}>
          Fechar
        </Button>
      </DialogActions>

      <Dialog open={cancelDialogOpen} onClose={handleCloseCancelDialog}>
        <DialogTitle>Confirmar Cancelamento</DialogTitle>
        <DialogContent>
          <Typography>
            Tem certeza de que deseja cancelar esta reserva? Esta ação não pode ser desfeita.
          </Typography>
          {masterPasswordActive && (
            <TextField
              margin="normal"
              fullWidth
              label="Senha Master"
              type={showPassword ? 'text' : 'password'}
              value={masterPassword}
              onChange={(e) => setMasterPassword(e.target.value)}
              error={!!masterPasswordError}
              helperText={masterPasswordError}
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
              disabled={cancelLoading || isVerifying}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseCancelDialog}
            variant="outlined"
            disabled={cancelLoading || isVerifying}
            sx={{ borderRadius: '50px' }}
          >
            Voltar
          </Button>
          <Button
            onClick={confirmCancelReservation}
            variant="contained"
            color="error"
            autoFocus
            disabled={(masterPasswordActive && !masterPassword) || cancelLoading || isVerifying}
            sx={{ color: 'white', borderRadius: '50px' }}
          >
            {cancelLoading || isVerifying ? <CircularProgress size={24} /> : 'Cancelar reserva'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarState.severity} sx={{ width: '100%' }}>
          {snackbarState.message}
        </Alert>
      </Snackbar>

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: '80%', md: '60%' },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            maxHeight: '90vh',
            overflowY: 'auto',
            borderRadius: 2,
          }}
        >
          <Typography variant="h5" gutterBottom>
            Detalhes da Reserva
          </Typography>
          {selectedReservation && (
            <ReservationDetails
              reservation={selectedReservation}
              passengers={[passenger]}
              travel={selectedReservation.travel}
              detalhesPagamento={selectedReservation.detalhesPagamento} // Passando detalhes de pagamento
            />
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={handleCloseModal} variant="outlined" color="primary" sx={{ borderRadius: '50px' }}>
              Fechar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Dialog>
  );
};

export default PassengerDetails;
