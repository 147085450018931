import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Box, IconButton, Typography, Button, Snackbar, Alert, Paper, Grid
} from '@mui/material';
import { ArrowBack, Add as AddIcon, MeetingRoom as RoomIcon, Person as PersonIcon } from '@mui/icons-material';

import HotelForm from './HotelForm';
import RoomForm from './RoomForm';
import HotelList from './components/HotelList';
import RoomAllocationDialog from './components/RoomAllocationDialog';
import AllocationSummary from './components/AllocationSummary';
import AccommodationHelp from './helps/AccommodationHelp';
import TravelLoading from '../../shared/common/TravelLoading';

import { usePermissions } from '../../hooks/usePermissions';
import { getHotelsByTravelId, deleteRoom, updateRoom, getAvailablePassengers, getRoomAllocations } from './services/AccommodationService';
import { getTravelById } from '../Travel/services/TravelService';

const AccommodationPage = () => {
    const { travelId } = useParams();
    const navigate = useNavigate();
    const [hotels, setHotels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedHotel, setSelectedHotel] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [openHotelForm, setOpenHotelForm] = useState(false);
    const [openRoomForm, setOpenRoomForm] = useState(false);
    const [openAllocationDialog, setOpenAllocationDialog] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [travelData, setTravelData] = useState(null);
    const { checkPermission } = usePermissions();

    useEffect(() => {
        fetchData();
    }, [travelId]);

    const fetchData = async () => {
        try {
            setLoading(true);
            const [travelData, hotelsData] = await Promise.all([
                getTravelById(travelId),
                getHotelsByTravelId(travelId)
            ]);
            setTravelData(travelData);
            setHotels(hotelsData);
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            showSnackbar(error.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleHotelFormSubmit = async (hotelData) => {
        try {
            await fetchData();
            setOpenHotelForm(false);
            showSnackbar('Hotel salvo com sucesso!');
        } catch (error) {
            showSnackbar(error.message, 'error');
        }
    };

    const handleDeleteHotel = async (hotel) => {
        try {
            await deleteHotel(hotel.id);
            await fetchData();
            showSnackbar('Hotel excluído com sucesso!');
        } catch (error) {
            showSnackbar(error.message, 'error');
        }
    };

    const handleRoomFormSubmit = async (roomData) => {
        try {
            await fetchData();
            setOpenRoomForm(false);
            showSnackbar('Quarto adicionado com sucesso!');
        } catch (error) {
            showSnackbar(error.message, 'error');
        }
    };

    const handleEditRoom = async (room) => {
        setSelectedRoom(room);
        setOpenRoomForm(true);
    };

    const handleDeleteRoom = async (room) => {
        try {
            await deleteRoom(room.id);
            await fetchData();
            showSnackbar('Quarto excluído com sucesso!');
        } catch (error) {
            showSnackbar(error.message, 'error');
        }
    };

    const handleAllocationSubmit = async () => {
        await fetchData();
        setOpenAllocationDialog(false);
        showSnackbar('Passageiros alocados com sucesso!');
    };

    const showSnackbar = (message, severity = 'success') => {
        setSnackbar({ open: true, message, severity });
    };

    if (loading) return <TravelLoading />;

    return (
        <Box>
            <Box sx={{ p: 0 }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    gap: 2,
                    mb: 3,
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={() => navigate(-1)} sx={{ mr: 1 }}>
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h6">Hospedagem</Typography>
                        <Box component="span" sx={{ ml: 1 }}>
                            <AccommodationHelp />
                        </Box>
                    </Box>

                    {checkPermission('travels_accommodation', 'create') && (
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            sx={{ borderRadius: 50 }}
                            onClick={() => {
                                setSelectedHotel(null);
                                setOpenHotelForm(true);
                            }}
                        >
                            Adicionar
                        </Button>
                    )}
                </Box>
            </Box>

            <AllocationSummary travelId={travelId} />

            <HotelList
                hotels={hotels}
                travel={travelData}
                onEditHotel={(hotel) => {
                    setSelectedHotel(hotel);
                    setOpenHotelForm(true);
                }}
                onDeleteHotel={handleDeleteHotel}
                onAddRoom={(hotel) => {
                    setSelectedHotel(hotel);
                    setSelectedRoom(null);
                    setOpenRoomForm(true);
                }}
                onEditRoom={handleEditRoom}
                onDeleteRoom={handleDeleteRoom}
                onManageAllocation={(room) => {
                    setSelectedRoom(room);
                    setOpenAllocationDialog(true);
                }}
                checkPermission={checkPermission}
            />

            <HotelForm
                open={openHotelForm}
                onClose={() => setOpenHotelForm(false)}
                onSubmit={handleHotelFormSubmit}
                hotel={selectedHotel}
                travelId={travelId}
            />

            <RoomForm
                open={openRoomForm}
                onClose={() => {
                    setOpenRoomForm(false);
                    setSelectedRoom(null);
                }}
                onSubmit={handleRoomFormSubmit}
                hotelId={selectedHotel?.id}
                room={selectedRoom}
            />

            <RoomAllocationDialog
                open={openAllocationDialog}
                onClose={() => {
                    setOpenAllocationDialog(false);
                    fetchData();
                }}
                onSubmit={() => {
                    fetchData();
                    setOpenAllocationDialog(false);
                    showSnackbar('Passageiros alocados com sucesso!');
                }}
                room={selectedRoom}
                travelId={travelId}
            />

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert
                    severity={snackbar.severity}
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AccommodationPage;