import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Box, Typography, IconButton, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

const AccommodationHelp = () => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Tooltip title="Ajuda">
                <IconButton onClick={() => setOpen(true)} sx={{ p: 1 }}>
                    <HelpIcon />
                </IconButton>
            </Tooltip>

            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
                <DialogTitle>Ajuda - Gerenciamento de Hospedagem</DialogTitle>
                <DialogContent>
                    <Box sx={{ maxHeight: '500px', overflowY: 'auto' }}>
                        <Typography variant="body1" paragraph>
                            Esta tela permite gerenciar hotéis e quartos, incluindo alocação de passageiros.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>1. Gerenciamento de Hotéis</h2>
                            <ul>
                                <li>Clique em "Adicionar" para cadastrar um novo hotel</li>
                                <li>Preencha informações como nome, endereço, horários de check-in/out</li>
                                <li>Use o menu de opções para editar ou excluir um hotel existente</li>
                                <li>Exporte dados do hotel para PDF usando o ícone correspondente</li>
                            </ul>
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>2. Gerenciamento de Quartos</h2>
                            <ul>
                                <li>Adicione quartos usando o botão "Quarto" em cada hotel</li>
                                <li>Configure nome/número e capacidade máxima do quarto</li>
                                <li>Use o menu de opções para editar ou excluir quartos</li>
                                <li>Visualize a ocupação atual de cada quarto (ocupados/total)</li>
                            </ul>
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>3. Alocação de Passageiros</h2>
                            <ul>
                                <li>Clique no ícone de pessoa para gerenciar alocações</li>
                                <li>Visualize passageiros atualmente alocados no quarto</li>
                                <li>Adicione novos passageiros da lista de disponíveis</li>
                                <li>Remova alocações existentes conforme necessário</li>
                                <li>Respeite o limite máximo de ocupação do quarto</li>
                            </ul>
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>4. Busca e Filtros</h2>
                            <ul>
                                <li>Use o campo de busca para encontrar quartos específicos</li>
                                <li>A busca é realizada por nome/número do quarto</li>
                                <li>Utilize a paginação para navegar entre os quartos</li>
                            </ul>
                        </Typography>

                        <Typography variant="body1" paragraph>
                            <h2>5. Exportação</h2>
                            <ul>
                                <li>Exporte dados de hospedagem para PDF por hotel</li>
                                <li>O relatório inclui detalhes do hotel e alocações</li>
                                <li>Use para controle interno ou envio ao hotel</li>
                            </ul>
                        </Typography>
                    </Box>

                    <Button
                        onClick={() => setOpen(false)}
                        variant="outlined"
                        sx={{ mt: 2, borderRadius: '50px' }}
                    >
                        Fechar
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default React.memo(AccommodationHelp);