import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Tooltip,
    Box,
    Typography,
    Chip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import TruncatedCell from '../../../shared/common/TruncatedCell';
import { usePermissions } from '../../../hooks/usePermissions'; 

const BankAccountList = ({ accounts, onEdit, onDelete, loading }) => {
    const { checkPermission } = usePermissions();

    const canEditBankAccounts = checkPermission('bank_accounts', 'edit');
    const canDeleteBankAccounts = checkPermission('bank_accounts', 'delete');

    const getAccountTypeLabel = (tipo) => {
        switch (tipo) {
            case 'checking':
                return 'Conta Corrente';
            case 'savings':
                return 'Conta Poupança';
            case 'investment':
                return 'Conta Investimento';
            default:
                return tipo;
        }
    };

    if (accounts.length === 0) {
        return (
            <Box sx={{ textAlign: 'center', py: 3 }}>
                <Typography variant="body1">
                    Nenhuma conta bancária encontrada.
                </Typography>
            </Box>
        );
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell>Banco</TableCell>
                        <TableCell>Agência</TableCell>
                        <TableCell>Conta</TableCell>
                        <TableCell>Tipo</TableCell>
                        <TableCell>Chave PIX</TableCell>
                        {(canEditBankAccounts || canDeleteBankAccounts) && (
                            <TableCell align="right">Ações</TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {accounts.map((account) => (
                        <TableRow key={account.id} hover>
                            <TruncatedCell text={account.name} maxChars={30} />
                            <TruncatedCell text={account.bank} maxChars={20} />
                            <TruncatedCell text={account.agency} maxChars={20} />
                            <TruncatedCell text={account.account} maxChars={20} />
                            <TableCell>
                                <Chip
                                    label={getAccountTypeLabel(account.accountType)}
                                    size="small"
                                    color={
                                        account.accountType === 'checking' ? 'primary' :
                                            account.accountType === 'savings' ? 'success' : 'default'
                                    }
                                    variant="outlined"
                                />
                            </TableCell>
                            <TruncatedCell text={account.pixKey || '-'} maxChars={20} />
                            {(canEditBankAccounts || canDeleteBankAccounts) && (
                                <TableCell align="right">
                                    {canEditBankAccounts && (
                                        <Tooltip title="Editar">
                                            <IconButton
                                                onClick={() => onEdit(account)}
                                                size="small"
                                                disabled={loading}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {canDeleteBankAccounts && (
                                        <Tooltip title="Excluir">
                                            <IconButton
                                                onClick={() => onDelete(account)}
                                                size="small"
                                                disabled={loading}
                                                color="error"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default BankAccountList;