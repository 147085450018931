import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/Star';
import { styled } from '@mui/material/styles';
import ivoImage from '../../../assets/landingpage/ivo.png';
import rafa from '../../../assets/landingpage/rafa.jpg';

const testimonials = [
  {
    name: 'Ivo',
    role: 'Gerente de Âgencia',
    company: '',
    description:
      'Está sendo muito útil, ele oferece praticidade nas vendas de viagens, desde o cadastro dos passageiros, alocação de poltronas e controle financeiro. Facilitando muito o meu dia a dia',
    rating: 5,
    imageLight: ivoImage,
    imageDark: ivoImage,
  },
  {
    name: 'Rafael',
    role: 'Gerente de Âgencia',
    company: '',
    description:
      'É muito prático, fácil de manusear e atende a todos os critérios que precisamos quando planejamos uma viagem. A praticidade de montar uma simulação do veículo facilita muito a alocação de todos os passageiros e garante uma viagem tranquila, principalmente no embarque',
    rating: 5,
    imageLight: rafa,
    imageDark: rafa,
  },
];

const AnimatedContainer = styled(Container)(({ theme }) => ({
  opacity: 0,
  transform: 'translateY(50px)',
  transition: 'opacity 0.6s ease-out, transform 0.6s ease-out',
  '&.visible': {
    opacity: 1,
    transform: 'translateY(0)',
  }
}));

const useScrollReveal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return [ref, isVisible];
};

export default function Testimonials() {
  const [containerRef, isContainerVisible] = useScrollReveal();

  useEffect(() => {
    const allImages = testimonials.flatMap(t => [t.imageLight, t.imageDark]);
    allImages.forEach(src => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  return (
    <AnimatedContainer
      ref={containerRef}
      id="testimonials"
      className={isContainerVisible ? 'visible' : ''}
      sx={{
        py: { xs: 4, sm: 6, md: 8 },
        px: { xs: 2, sm: 3, md: 4 }
      }}
    >
      <Box sx={{ mb: { xs: 4, sm: 6 } }}>
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{
            color: 'text.primary',
            textAlign: 'center',
            fontSize: { xs: '1.5rem', sm: '2rem' }
          }}
        >
          O que nossos clientes dizem
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: 'text.secondary',
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            mb: { xs: 3, sm: 4 },
            fontSize: { xs: '0.875rem', sm: '1rem' }
          }}
        >
          Confira os depoimentos de alguns dos nossos clientes satisfeitos que transformaram suas operações usando nossa plataforma.
        </Typography>
      </Box>

      <Grid
        container
        spacing={{ xs: 2, sm: 3, md: 4 }}
        justifyContent="center"
        sx={{ maxWidth: '1200px', mx: 'auto' }}
      >
        {testimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
            <Card
              variant="outlined"
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: { xs: 2, sm: 3 },
                maxWidth: { xs: '100%', sm: '500px' },
                mx: 'auto'
              }}
            >
              <Box
                sx={{
                  width: { xs: 80, sm: 100, md: 120 },
                  height: { xs: 80, sm: 100, md: 120 },
                  backgroundSize: 'cover',
                  backgroundImage: `url(${testimonial.imageLight})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  borderRadius: '50%',
                  mb: { xs: 1, sm: 2 },
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  color: 'text.primary',
                  mb: 1,
                  fontSize: { xs: '1rem', sm: '1.25rem' }
                }}
              >
                {testimonial.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: 'text.secondary',
                  mb: 1,
                  textAlign: 'center',
                  fontSize: { xs: '0.75rem', sm: '0.875rem' }
                }}
              >
                {testimonial.role}
              </Typography>
              {testimonial.company && (
                <Typography
                  variant="body2"
                  sx={{
                    color: 'text.secondary',
                    mb: 2,
                    textAlign: 'center',
                    fontSize: { xs: '0.75rem', sm: '0.875rem' }
                  }}
                >
                  {testimonial.company}
                </Typography>
              )}
              <Box sx={{ display: 'flex', gap: 0.5, mb: 2 }}>
                {[...Array(testimonial.rating)].map((_, i) => (
                  <StarIcon
                    key={i}
                    sx={{
                      color: 'primary.main',
                      fontSize: { xs: 16, sm: 20 }
                    }}
                  />
                ))}
              </Box>
              <Typography
                variant="body2"
                sx={{
                  color: 'text.secondary',
                  textAlign: 'center',
                  flexGrow: 1,
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: { xs: '0.75rem', sm: '0.875rem' },
                  lineHeight: { xs: 1.4, sm: 1.6 }
                }}
              >
                "{testimonial.description}"
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </AnimatedContainer>
  );
}