import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Box,
    LinearProgress,
    Stack
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';

const TrialNotificationModal = ({ open, onClose, daysRemaining }) => {
    const progress = ((15 - daysRemaining) / 15) * 100;

    const handleWhatsAppClick = () => {
        window.open('https://wa.me/554288333458?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20os%20planos%20de%20assinatura!', '_blank');
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogContent>
                <Stack spacing={3} sx={{ py: 2 }}>
                    <Box sx={{ textAlign: 'center' }}>
                        <TimerOutlinedIcon
                            sx={{
                                fontSize: 48,
                                color: 'primary.main',
                                mb: 2
                            }}
                        />
                        <Typography variant="h5" component="h2" gutterBottom fontWeight="bold">
                            Você está no período de teste
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            Aproveite todos os recursos do sistema gratuitamente por 15 dias
                        </Typography>
                    </Box>

                    <Box sx={{ px: 2 }}>
                        <Box sx={{ mb: 1, display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="body2" color="text.secondary">
                                Dias restantes
                            </Typography>
                            <Typography variant="body2" fontWeight="bold" color="primary">
                                {daysRemaining} dias
                            </Typography>
                        </Box>
                        <LinearProgress
                            variant="determinate"
                            value={progress}
                            sx={{
                                height: 8,
                                borderRadius: 4,
                                backgroundColor: 'action.hover'
                            }}
                        />
                    </Box>

                    <Typography variant="body2" textAlign="center" color="text.secondary">
                        Para continuar utilizando o sistema após o período de teste,
                        entre em contato conosco e conheça nossos planos.
                    </Typography>
                </Stack>
            </DialogContent>

            <DialogActions sx={{ p: 3, pt: 0 }}>
                <Button onClick={onClose} color="inherit">
                    Continuar usando
                </Button>
                <Button
                    variant="contained"
                    startIcon={<WhatsAppIcon />}
                    onClick={handleWhatsAppClick}
                    sx={{ borderRadius: '50px' }}
                >
                    Conhecer planos
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TrialNotificationModal;