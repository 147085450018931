import React from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, IconButton, Tooltip, Box, Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

import TruncatedCell from '../../shared/common/TruncatedCell';

import { useTheme } from '@mui/material/styles';
import { formatCPF, normalizeValue } from '../../shared/utils/utils';
import { usePermissions } from '../../hooks/usePermissions';

const getStatusIcon = (status, color) => {
    switch (status) {
        case 'Pago': return <CheckCircleIcon sx={{ color, fontSize: 20 }} />;
        case 'Cancelada': return <CancelIcon sx={{ color, fontSize: 20 }} />;
        default: return <ErrorIcon sx={{ color, fontSize: 20 }} />;
    }
};

const OrderList = ({ orders, onEditOrder, onCancelOrder, onRowClick }) => {
    const theme = useTheme();
    const { checkPermission } = usePermissions();

    const canEdit = checkPermission('orders', 'edit');
    const canCancel = checkPermission('orders', 'cancel');

    const getStatusColor = (status) => {
        switch (status) {
            case 'Pago': return theme.palette.success.main;
            case 'Cancelada': return theme.palette.error.main;
            default: return theme.palette.warning.main;
        }
    };

    return (
        <TableContainer component={Paper} sx={{ boxShadow: 2 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Pedido</TableCell>
                        <TableCell>N° dos Assentos</TableCell>
                        <TableCell>Pagador</TableCell>
                        <TableCell>Documento</TableCell>
                        <TableCell>Valor Total</TableCell>
                        <TableCell>Valor Pago</TableCell>
                        <TableCell>Valor Restante</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align="center">Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders.map((order) => {
                        const detalhesPagamento = order.detalhesPagamento || {};
                        const valorTotal = Number(detalhesPagamento.valorTotal || 0);
                        const valorPago = Number(detalhesPagamento.valorPago || 0);
                        const valorRestante = valorTotal - valorPago;
                        const sortedSeats = order.reservations
                            ?.map(r => r.numeroAssento)
                            .filter(n => n !== null)
                            .sort((a, b) => a - b)
                            .join(', ') || 'Nenhum assento';
                        const statusColor = getStatusColor(order.status);
                        const documentText = detalhesPagamento.passaportePagador
                            ? `Passaporte: ${detalhesPagamento.passaportePagador}`
                            : `CPF: ${detalhesPagamento.cpfPagador ? formatCPF(detalhesPagamento.cpfPagador) : 'Não informado'}`;

                        return (
                            <TableRow
                                key={order.id}
                                hover
                                onClick={() => onRowClick(order)}
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: theme.palette.action.hover
                                    }
                                }}
                            >
                                <TruncatedCell text={order.id} />
                                <TruncatedCell text={sortedSeats} />
                                <TruncatedCell text={detalhesPagamento.nomePagador || 'Não informado'} />
                                <TruncatedCell text={documentText} />
                                <TruncatedCell text={`R$ ${normalizeValue(valorTotal.toFixed(2))}`} />
                                <TruncatedCell text={`R$ ${normalizeValue(valorPago.toFixed(2))}`} />
                                <TruncatedCell text={`R$ ${normalizeValue(valorRestante.toFixed(2))}`} />
                                <TableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        {getStatusIcon(order.status, statusColor)}
                                        <Typography variant="body2">{order.status}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="center">
                                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                                        {canEdit && onEditOrder && order.status !== 'Cancelada' && (
                                            <Tooltip title="Editar">
                                                <IconButton
                                                    size="small"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onEditOrder(order);
                                                    }}
                                                >
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {canCancel && onCancelOrder && order.status !== 'Cancelada' && (
                                            <Tooltip title="Cancelar">
                                                <IconButton
                                                    size="small"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onCancelOrder(order.id, order.travelId);
                                                    }}
                                                >
                                                    <CancelIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default OrderList;