import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Collapse,
  Typography,
  Divider,
  Paper,
  Grid
} from '@mui/material';

import TravelLoading from '../../shared/common/TravelLoading';

import { db } from '../../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../core/contexts/useAuthState';

const UpdateAgencyPage = () => {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({
    agencyName: '',
    registrationType: '',
    stats: {
      totalUsers: 0,
      totalVehicles: 0,
      totalTravels: 0
    },
    subscription: {
      planId: '',
      status: '',
      startDate: '',
      endDate: null
    }
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    const fetchAgencyData = async () => {
      try {
        if (currentUser?.uid) {
          const agencyDoc = await getDoc(doc(db, 'agencies', currentUser.uid));
          const agencyData = agencyDoc.data();

          if (agencyData) {
            setFormData({
              agencyName: agencyData.name || '',
              registrationType: agencyData.metadata?.registrationType || 'personal',
              stats: {
                totalUsers: agencyData.stats?.totalUsers || 0,
                totalVehicles: agencyData.stats?.totalVehicles || 0,
                totalTravels: agencyData.stats?.totalTravels || 0
              },
              subscription: {
                planId: agencyData.subscription?.planId || '',
                status: agencyData.subscription?.status || '',
                startDate: agencyData.subscription?.startDate || '',
                endDate: agencyData.subscription?.endDate || null
              }
            });
          }
        }
        setInitialLoad(false);
      } catch (error) {
        console.error('Error fetching agency data:', error);
        setError('Erro ao carregar dados da agência');
        setInitialLoad(false);
      }
    };

    fetchAgencyData();
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const validateForm = () => {
    if (!formData.agencyName.trim()) {
      setError('O nome da agência não pode estar vazio');
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    setError('');
    setSuccessMessage('');

    try {
      const agencyRef = doc(db, 'agencies', currentUser.uid);
      await updateDoc(agencyRef, {
        name: formData.agencyName.trim(),
        'metadata.updatedAt': new Date().toISOString()
      });

      setSuccessMessage('Informações da agência atualizadas com sucesso!');
    } catch (error) {
      console.error('Error updating agency:', error);
      setError('Erro ao atualizar informações da agência');
    } finally {
      setLoading(false);
    }
  };

  if (initialLoad) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <TravelLoading />
      </Box>
    );
  }

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '100%' }}>
      <Paper sx={{ p: 3, mb: 3 }} elevation={0}>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Informações da Agência
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="agencyName"
          label="Nome da Agência"
          id="agencyName"
          value={formData.agencyName}
          onChange={handleChange}
          error={!formData.agencyName.trim()}
          helperText={!formData.agencyName.trim() ? 'Nome da agência é obrigatório' : ''}
        />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="registrationType"
          label="Tipo de Registro"
          value={formData.registrationType === 'personal' ? 'Pessoa Física' : 'Empresa'}
          disabled
        />
      </Paper>

      <Paper sx={{ p: 3, mb: 3 }} elevation={0}>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Estatísticas
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Total de Usuários"
              value={formData.stats.totalUsers}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Total de Veículos"
              value={formData.stats.totalVehicles}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Total de Viagens"
              value={formData.stats.totalTravels}
              disabled
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ p: 3, mb: 3 }} elevation={0}>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Assinatura
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Plano"
              value={formData.subscription.planId}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Status"
              value={formData.subscription.status}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Data de Início"
              value={new Date(formData.subscription.startDate).toLocaleDateString()}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Data de Término"
              value={formData.subscription.endDate ? new Date(formData.subscription.endDate).toLocaleDateString() : 'N/A'}
              disabled
            />
          </Grid>
        </Grid>
      </Paper>

      <Button
        type="submit"
        variant="contained"
        sx={{
          mt: 1,
          mb: 2,
          borderRadius: '50px',
          ml: 'auto', // Empurra o botão para a direita
          display: 'block' // Garante que o botão respeite o alinhamento
        }}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : 'Salvar Alterações'}
      </Button>

      <Collapse in={!!error} timeout={500}>
        <Box sx={{ width: '100%', mb: 2 }}>
          {error && <Alert severity="error">{error}</Alert>}
        </Box>
      </Collapse>

      <Collapse in={!!successMessage} timeout={500}>
        <Box sx={{ width: '100%', mb: 2 }}>
          {successMessage && <Alert severity="success">{successMessage}</Alert>}
        </Box>
      </Collapse>
    </Box>
  );
};

export default UpdateAgencyPage;