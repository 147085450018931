import React from 'react';
import { TableCell, Tooltip, Box } from '@mui/material';

const TruncatedCell = ({ text, maxChars = 30 }) => {
  if (!text) return <TableCell>-</TableCell>;
  
  const shouldTruncate = text.length > maxChars;
  const displayText = shouldTruncate ? `${text.substring(0, maxChars)}...` : text;

  return (
    <TableCell>
      <Tooltip title={shouldTruncate ? text : ''} arrow>
        <Box sx={{ 
          maxWidth: 200,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}>
          {displayText}
        </Box>
      </Tooltip>
    </TableCell>
  );
};

export default TruncatedCell;