import React, { useState, useEffect } from 'react';
import { Grid, TextField, MenuItem, InputAdornment, Tooltip, IconButton, Button, Typography, Box, Alert, Autocomplete, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PaymentRecordsHelp from '../helps/PaymentRecordsHelp';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { getBankAccounts } from '../../BankAccount/services/BankAccountService';
import { useCurrencyInput, currencyNames } from './../../../shared/utils/formatCurrency';

const PaymentRecordRow = ({
  record,
  index,
  onValueChange,
  onBlur,
  errors,
  handleRemoveRecord,
  handlePaymentRecordChange,
  bankAccounts,
  loadingAccounts,
  handleFetchAccounts
}) => {
  const theme = useTheme();
  const initialValue = record.valor ?
    String(parseFloat(record.valor.replace(',', '.')).toFixed(2)).replace('.', ',') :
    '0,00';

  const {
    value: valorTemp,
    handleChange: handleValorChange,
    handleBlur: handleValorBlur,
    toNumber: getValorNumber,
    setValue: setValorTemp
  } = useCurrencyInput(initialValue);

  useEffect(() => {
    if (record.valor) {
      const numericValue = parseFloat(record.valor.replace(',', '.'));
      if (!isNaN(numericValue)) {
        setValorTemp(numericValue.toFixed(2).replace('.', ','));
      }
    }
  }, [record.valor]);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={3}>
        <TextField
          label="Data de Pagamento"
          type="date"
          value={record.data}
          onChange={(e) => handlePaymentRecordChange(index, 'data', e.target.value)}
          fullWidth
          required
          error={!!errors[`data-${index}`]}
          helperText={errors[`data-${index}`]}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ backgroundColor: theme.palette.background.paper }}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextField
          label="Valor do Pagamento"
          type="text"
          value={valorTemp}
          onChange={(e) => {
            handleValorChange(e);
            onValueChange(index, e.target.value);
          }}
          onBlur={() => {
            handleValorBlur();
            const numericValue = getValorNumber();
            const formattedValueForDisplay = numericValue.toFixed(2).replace('.', ',');
            const formattedValueForDB = numericValue.toFixed(2);
            onBlur(index, formattedValueForDB);
            setValorTemp(formattedValueForDisplay);
          }}
          fullWidth
          required
          error={!!errors[`valor-${index}`]}
          helperText={errors[`valor-${index}`]}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currencyNames['BRL'].symbol}
              </InputAdornment>
            ),
            inputMode: 'numeric'
          }}
          sx={{ backgroundColor: theme.palette.background.paper }}
        />
      </Grid>

      <Grid item xs={12} sm={2.5}>
        <TextField
          select
          label="Método de Pagamento"
          value={record.metodoPagamento}
          onChange={(e) => handlePaymentRecordChange(index, 'metodoPagamento', e.target.value)}
          fullWidth
          required
          error={!!errors[`metodoPagamento-${index}`]}
          helperText={errors[`metodoPagamento-${index}`]}
          sx={{ backgroundColor: theme.palette.background.paper }}
        >
          <MenuItem value="Dinheiro">Dinheiro</MenuItem>
          <MenuItem value="Pix">Pix</MenuItem>
          <MenuItem value="Cartão">Cartão</MenuItem>
          <MenuItem value="Boleto">Boleto</MenuItem>
          <MenuItem value="Outro">Outro</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={12} sm={3}>
        <Autocomplete
          fullWidth
          options={bankAccounts[index] || []}
          getOptionLabel={(option) => option.name}
          value={bankAccounts[index]?.find(acc => acc.id === record.bankAccountId) || null}
          onChange={(e, value) => handlePaymentRecordChange(index, 'bankAccountId', value?.id || null)}
          onOpen={() => handleFetchAccounts(index)}
          loading={loadingAccounts[index]}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Conta Bancária"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingAccounts[index] ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={0.5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Tooltip title="Remover registro de pagamento">
          <IconButton
            onClick={() => handleRemoveRecord(index)}
            sx={{ ml: 'auto' }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const PaymentRecords = ({
  paymentRecords,
  handlePaymentRecordChange,
  handleRemovePaymentRecord,
  handleAddPaymentRecord,
  detalhesPagamento,
  setErrors,
}) => {
  const theme = useTheme();
  const [localErrors, setLocalErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingAccounts, setLoadingAccounts] = useState({});
  const [bankAccounts, setBankAccounts] = useState({});


  useEffect(() => {
    const loadInitialBankAccounts = async () => {
      try {
        const accounts = await getBankAccounts();
        const initialBankAccounts = {};


        paymentRecords.forEach((_, index) => {
          initialBankAccounts[index] = accounts;
        });

        setBankAccounts(initialBankAccounts);
      } catch (error) {
        console.error('Error loading initial bank accounts:', error);
      }
    };

    loadInitialBankAccounts();
  }, []);

  const handleFetchAccounts = async (index) => {

    if (bankAccounts[index]) return;

    setLoadingAccounts(prev => ({ ...prev, [index]: true }));
    try {
      const accounts = await getBankAccounts();
      setBankAccounts(prev => ({ ...prev, [index]: accounts }));
    } catch (error) {
      console.error('Error fetching bank accounts:', error);
    } finally {
      setLoadingAccounts(prev => ({ ...prev, [index]: false }));
    }
  };


  const handleNewRecordAccounts = async (index) => {
    if (!bankAccounts[index]) {
      try {
        const accounts = await getBankAccounts();
        setBankAccounts(prev => ({ ...prev, [index]: accounts }));
      } catch (error) {
        console.error('Error loading bank accounts for new record:', error);
      }
    }
  };

  useEffect(() => {
    validateAllPayments(paymentRecords);
  }, [paymentRecords]);

  const validatePayment = (index, field, value, updatedRecords) => {
    const activeRecords = updatedRecords.filter(record => !record.removed);

    const numericValue = field === 'valor' ?
      parseFloat(value.replace(',', '.')) || 0 :
      parseFloat(value?.replace(',', '.')) || 0;

    const totalPaid = activeRecords.reduce((total, record, idx) => {
      const recordValue = idx === index && field === 'valor' ?
        numericValue :
        parseFloat(record.valor?.replace(',', '.')) || 0;
      return total + recordValue;
    }, 0);

    const roundedTotalPaid = Number(totalPaid.toFixed(2));
    const valorTotal = Number((parseFloat(detalhesPagamento.valorTotal?.replace(',', '.')) || 0).toFixed(2));

    const exceedsTotal = roundedTotalPaid > valorTotal;

    let error = '';
    if (field === 'valor') {
      error = numericValue <= 0 ? 'O valor do pagamento deve ser maior que zero.' : '';
    } else if (field === 'data' && !value) {
      error = 'A data de pagamento é obrigatória.';
    } else if (field === 'metodoPagamento' && !value) {
      error = 'O método de pagamento é obrigatório.';
    }

    setLocalErrors(prevErrors => ({
      ...prevErrors,
      [`${field}-${index}`]: error || (exceedsTotal ? 'O valor pago não pode exceder o valor total.' : ''),
    }));

    setErrors(prevErrors => ({
      ...prevErrors,
      paymentRecord: exceedsTotal || error,
    }));

    validateAllPayments(activeRecords);
  };

  const validateAllPayments = (updatedRecords) => {
    const activeRecords = updatedRecords.filter(record => !record.removed);

    const hasInvalidPayments = activeRecords.some((record, index) => {
      const numericValue = parseFloat(record.valor?.replace(',', '.')) || 0;
      return (
        !record.data ||
        numericValue <= 0 ||
        !record.metodoPagamento ||
        localErrors[`valor-${index}`] ||
        localErrors[`data-${index}`] ||
        localErrors[`metodoPagamento-${index}`]
      );
    });

    if (hasInvalidPayments) {
      setErrorMessage('Todos os campos de data, valor e método são obrigatórios para cada registro de pagamento.');
    } else {
      setErrorMessage('');
    }
  };

  const handleRemoveRecord = (index) => {

    const updatedRecords = paymentRecords.map((record, idx) =>
      idx === index ? { ...record, removed: true } : record
    );

    handleRemovePaymentRecord(index);
    validateAllPayments(updatedRecords);


    setLocalErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[`valor-${index}`];
      delete newErrors[`data-${index}`];
      delete newErrors[`metodoPagamento-${index}`];
      return newErrors;
    });
  };

  const handleValueChange = (index, value) => {
    validatePayment(index, 'valor', value, paymentRecords);
  };

  const handleBlur = (index, value) => {
    handlePaymentRecordChange(index, 'valor', value);
    validatePayment(index, 'valor', value, paymentRecords);
  };

  return (
    <Box sx={{ mb: 3, p: 3, border: `1px solid ${theme.palette.divider}`, borderRadius: 2, backgroundColor: theme.palette.background.paper }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6" sx={{ color: theme.palette.text.primary, marginRight: 1 }}>
            Registros de Pagamento
          </Typography>
          <PaymentRecordsHelp />
        </Box>
        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={handleAddPaymentRecord}
          sx={{
            borderRadius: '8px',
            color: theme.palette.action,
          }}
        >
          Adicionar Pagamento
        </Button>
      </Box>
      {paymentRecords.map((record, index) => (
        <Box
          key={index}
          sx={{
            mb: 2,
            p: 2,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <PaymentRecordRow
            record={record}
            index={index}
            onValueChange={handleValueChange}
            onBlur={handleBlur}
            errors={localErrors}
            handleRemoveRecord={handleRemoveRecord}
            handlePaymentRecordChange={handlePaymentRecordChange}
            bankAccounts={bankAccounts}
            loadingAccounts={loadingAccounts}
            handleFetchAccounts={handleFetchAccounts}
          />
        </Box>
      ))}
      {errorMessage && (
        <Alert
          severity="error"
          sx={{
            mt: 2,
            backgroundColor: theme.palette.warning.light,
            color: theme.palette.info
          }}
        >
          {errorMessage}
        </Alert>
      )}
    </Box>
  );
};

export default PaymentRecords;