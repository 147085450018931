import { collection, query, getDocs, addDoc, deleteDoc, doc, updateDoc, where, orderBy, getDoc } from 'firebase/firestore';
import { db, auth } from '../../../firebaseConfig';

const getAgencyRef = async () => {
    const user = auth.currentUser;
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    return doc(db, 'agencies', userDoc.data().agencyId);
};

export const getVehicleDocuments = async (vehicleId) => {
    try {
        const agencyRef = await getAgencyRef();
        const documentsRef = collection(agencyRef, 'vehicleDocuments');

        const q = query(
            documentsRef,
            where('vehicleId', '==', vehicleId),
            orderBy('dataVencimento', 'asc')
        );

        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    } catch (error) {
        console.error('Erro ao buscar documentos do veículo:', error);
        throw error;
    }
};

export const addVehicleDocument = async (documentData) => {
    try {
        const agencyRef = await getAgencyRef();
        const documentsRef = collection(agencyRef, 'vehicleDocuments');

        const docRef = await addDoc(documentsRef, {
            ...documentData,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
        });

        return {
            id: docRef.id,
            ...documentData
        };
    } catch (error) {
        console.error('Erro ao adicionar documento:', error);
        throw error;
    }
};

export const updateVehicleDocument = async (documentId, documentData) => {
    try {
        const agencyRef = await getAgencyRef();
        const documentRef = doc(agencyRef, 'vehicleDocuments', documentId);

        await updateDoc(documentRef, {
            ...documentData,
            updatedAt: new Date().toISOString()
        });

        return {
            id: documentId,
            ...documentData
        };
    } catch (error) {
        console.error('Erro ao atualizar documento:', error);
        throw error;
    }
};

export const deleteVehicleDocument = async (documentId) => {
    try {
        const agencyRef = await getAgencyRef();
        const documentRef = doc(agencyRef, 'vehicleDocuments', documentId);

        await deleteDoc(documentRef);
        return true;
    } catch (error) {
        console.error('Erro ao excluir documento:', error);
        throw error;
    }
};

export const getExpiringDocuments = async (daysThreshold = 30) => {
    try {
        const agencyRef = await getAgencyRef();
        const documentsRef = collection(agencyRef, 'vehicleDocuments');

        const today = new Date();
        const thresholdDate = new Date();
        thresholdDate.setDate(today.getDate() + daysThreshold);

        const q = query(
            documentsRef,
            where('dataVencimento', '>=', today.toISOString()),
            where('dataVencimento', '<=', thresholdDate.toISOString()),
            orderBy('dataVencimento', 'asc')
        );

        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    } catch (error) {
        console.error('Erro ao buscar documentos próximos ao vencimento:', error);
        throw error;
    }
};

export const getExpiredDocuments = async () => {
    try {
        const agencyRef = await getAgencyRef();
        const documentsRef = collection(agencyRef, 'vehicleDocuments');

        const today = new Date();

        const q = query(
            documentsRef,
            where('dataVencimento', '<', today.toISOString()),
            orderBy('dataVencimento', 'desc')
        );

        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    } catch (error) {
        console.error('Erro ao buscar documentos vencidos:', error);
        throw error;
    }
};

export const getAllVehicleDocumentsPaginated = async (pageSize = 12, page = 1, filterOptions = {}) => {
    try {
        const agencyRef = await getAgencyRef();
        const documentsRef = collection(agencyRef, 'vehicleDocuments');

        let baseQuery = query(documentsRef);
        const queryConstraints = [];

        if (filterOptions.status === 'vencido') {
            const today = new Date().toISOString();
            queryConstraints.push(where('dataVencimento', '<', today));
        } else if (filterOptions.status === 'proximo') {
            const today = new Date();
            const thirtyDays = new Date();
            thirtyDays.setDate(today.getDate() + 30);

            queryConstraints.push(
                where('dataVencimento', '>=', today.toISOString()),
                where('dataVencimento', '<=', thirtyDays.toISOString())
            );
        } else if (filterOptions.status === 'regular') {
            const thirtyDays = new Date();
            thirtyDays.setDate(thirtyDays.getDate() + 30);
            queryConstraints.push(where('dataVencimento', '>', thirtyDays.toISOString()));
        }

        if (filterOptions.startDate) {
            queryConstraints.push(where('dataVencimento', '>=', filterOptions.startDate));
        }

        if (filterOptions.endDate) {
            queryConstraints.push(where('dataVencimento', '<=', filterOptions.endDate));
        }

        queryConstraints.push(orderBy('dataVencimento', filterOptions.sortOrder || 'asc'));
        baseQuery = query(documentsRef, ...queryConstraints);

        const snapshot = await getDocs(baseQuery);
        let documents = [];

        for (const docSnapshot of snapshot.docs) {
            const documentData = docSnapshot.data();

            const vehicleRef = doc(agencyRef, 'vehicles', documentData.vehicleId);
            const vehicleDoc = await getDoc(vehicleRef);

            if (vehicleDoc.exists()) {
                const vehicleData = vehicleDoc.data();

                if (filterOptions.searchTerm) {
                    const searchTerm = filterOptions.searchTerm.toLowerCase();
                    const searchableFields = [
                        documentData.nome,
                        documentData.numero,
                        vehicleData.identificadorVeiculo,
                        vehicleData.placa,
                    ].map(field => field?.toLowerCase() || '');

                    if (!searchableFields.some(field => field.includes(searchTerm))) {
                        continue;
                    }
                }

                documents.push({
                    id: docSnapshot.id,
                    ...documentData,
                    vehicle: {
                        id: vehicleDoc.id,
                        ...vehicleData
                    }
                });
            }
        }

        documents = documents.sort((a, b) => {
            const dateA = new Date(a.dataVencimento);
            const dateB = new Date(b.dataVencimento);
            return filterOptions.sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
        });

        const totalCount = documents.length;
        const totalPages = Math.ceil(totalCount / pageSize);
        const startIndex = (page - 1) * pageSize;
        const paginatedDocuments = documents.slice(startIndex, startIndex + pageSize);

        return {
            documents: paginatedDocuments,
            totalPages,
            totalCount,
            currentPage: page
        };
    } catch (error) {
        console.error('Erro ao buscar documentos dos veículos:', error);
        throw error;
    }
};