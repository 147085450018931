export const adaptPayablesData = (rawData, bankAccounts, pageSize = 15, currentPage = 1, filters = {}, getAllRecords = false) => {
  if (!Array.isArray(rawData)) return { items: [], totalItems: 0, totalPages: 0 };

  const uniqueRecordsMap = new Map();

  const isDateInRange = (dateStr) => {
    if (!filters.startDate && !filters.endDate) return true;
    const date = new Date(dateStr);
    const start = filters.startDate ? new Date(filters.startDate) : null;
    const end = filters.endDate ? new Date(filters.endDate) : null;

    if (start && end) {
      return date >= start && date <= end;
    } else if (start) {
      return date >= start;
    } else if (end) {
      return date <= end;
    }
    return true;
  };

  const matchesSearchTerm = (description) => {
    if (!filters.searchTerm) return true;
    const searchTerm = filters.searchTerm.toLowerCase().trim();
    const descriptionStr = description?.toLowerCase() || '';
    return descriptionStr.includes(searchTerm);
  };

  rawData.forEach(payable => {
    if (Array.isArray(payable.costs)) {
      payable.costs
        .filter(cost => cost.type === 'Custo' && cost.estaAtivo)
        .forEach(cost => {
          const costMatches = matchesSearchTerm(cost.description || 'Pagamento');

          if (isDateInRange(cost.date) && costMatches) {
            const key = `cost-${cost.id}`;
            if (!uniqueRecordsMap.has(key)) {
              uniqueRecordsMap.set(key, {
                id: cost.id,
                description: `${cost.description || 'Pagamento'} - Viagem ${payable.identificador}`,
                date: cost.date,
                paymentMethod: cost.paymentMethod || '-',
                bankAccount: bankAccounts?.find(acc => acc.id === cost.bankAccountId)?.name || '-',
                amount: parseFloat(cost.amount || 0),
                status: 'Pago',
                type: 'Pagamento'
              });
            }
          }
        });
    }
  });

  const matchesFilters = (record) => {
    if (filters.searchTerm) {
      const searchTerm = filters.searchTerm.toLowerCase().trim();
      const descriptionStr = record.description?.toLowerCase() || '';

      if (!descriptionStr.includes(searchTerm)) {
        return false;
      }
    }

    if (filters.status && record.status !== filters.status) {
      return false;
    }

    if (filters.bankAccountId) {
      const accountId = bankAccounts.find(acc => acc.name === record.bankAccount)?.id;
      if (accountId !== filters.bankAccountId) {
        return false;
      }
    }

    if (!isDateInRange(record.date)) {
      return false;
    }

    return true;
  };

  const filteredRecords = Array.from(uniqueRecordsMap.values())
    .filter(matchesFilters);

  const sortedRecords = filteredRecords.sort((a, b) => new Date(a.date) - new Date(b.date));

  const totalItems = sortedRecords.length;

  if (getAllRecords) {
    return {
      items: sortedRecords,
      totalItems,
      totalPages: 1
    };
  }

  const startIndex = (currentPage - 1) * pageSize;
  const paginatedItems = sortedRecords.slice(startIndex, startIndex + pageSize);

  return {
    items: paginatedItems,
    totalItems,
    totalPages: Math.max(1, Math.ceil(totalItems / pageSize))
  };
};