import React from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, IconButton, Tooltip, Box, Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

import TruncatedCell from '../../shared/common/TruncatedCell';

import { useTheme } from '@mui/material/styles';
import { formatCPF } from '../../shared/utils/utils';
import { usePermissions } from '../../hooks/usePermissions';

const getStatusIcon = (status, color) => {
    switch (status) {
        case 'Pago': return <CheckCircleIcon sx={{ color, fontSize: 20 }} />;
        case 'Cancelada': return <CancelIcon sx={{ color, fontSize: 20 }} />;
        default: return <ErrorIcon sx={{ color, fontSize: 20 }} />;
    }
};

const ReservationList = ({ reservations, passengers, onEditReservation, onCancelReservation, onRowClick }) => {
    const theme = useTheme();
    const { checkPermission } = usePermissions();

    const canEdit = checkPermission('orders', 'edit');
    const canCancel = checkPermission('orders', 'cancel');

    const getStatusColor = (status) => {
        switch (status) {
            case 'Pago': return theme.palette.success.main;
            case 'Cancelada': return theme.palette.error.main;
            default: return theme.palette.warning.main;
        }
    };

    return (
        <TableContainer component={Paper} sx={{ boxShadow: 2 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Assento</TableCell>
                        <TableCell>Passageiro</TableCell>
                        <TableCell>Documentos</TableCell>
                        <TableCell>Pedido</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align="center">Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reservations.map((reservation) => {
                        const passenger = passengers.find(p => p.id === reservation.passengerId) || {};
                        const statusColor = getStatusColor(reservation.status);
                        const documentText = passenger.estrangeiro
                            ? `Passaporte: ${passenger.passaporte || 'Não informado'}`
                            : `CPF: ${passenger.cpf ? formatCPF(passenger.cpf) : 'Não informado'}\nRG: ${passenger.rg || 'Não informado'}`;

                        return (
                            <TableRow
                                key={reservation.id}
                                hover
                                onClick={() => onRowClick(reservation)}
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: theme.palette.action.hover
                                    }
                                }}
                            >
                                <TruncatedCell text={reservation.numeroAssento || 'Ocupante de Colo'} />
                                <TruncatedCell text={passenger.nome || 'Não informado'} />
                                <TruncatedCell text={documentText} />
                                <TruncatedCell text={reservation.orderId} />
                                <TableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        {getStatusIcon(reservation.status, statusColor)}
                                        <Typography variant="body2">{reservation.status}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align="center">
                                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                                        {canEdit && onEditReservation && reservation.status !== 'Cancelada' && (
                                            <Tooltip title="Editar">
                                                <IconButton
                                                    size="small"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onEditReservation(reservation, reservation.orderId);
                                                    }}
                                                >
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {canCancel && onCancelReservation && reservation.status !== 'Cancelada' && (
                                            <Tooltip title="Cancelar">
                                                <IconButton
                                                    size="small"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onCancelReservation(reservation.id, reservation.orderId, reservation.travelId);
                                                    }}
                                                >
                                                    <CancelIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ReservationList;