import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography, Button, Box, Grid, CircularProgress, Snackbar, Alert, Collapse, FormControl, InputLabel, Select, MenuItem, TextField, Pagination, InputAdornment
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';

import { usePermissions } from '../../hooks/usePermissions';
import LayoutCard from './components/LayoutCard';
import LayoutList from './components/LayoutList';
import ManageBusLayoutPageHelp from './helps/ManageBusLayoutPageHelp';
import TravelLoading from '../../shared/common/TravelLoading';

import { getSavedViewType, saveViewType } from '../../shared/utils/localStorageUtils';
import { getPaginatedLayouts, deleteLayout } from './services/LayoutService';

const LayoutPage = () => {
  const { checkPermission } = usePermissions();
  const [viewMode, setViewMode] = useState(getSavedViewType());
  const [layouts, setLayouts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const [totalItems, setTotalItems] = useState(0);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const navigate = useNavigate();

  const canCreateLayout = checkPermission('layouts', 'create');
  const canEditLayout = checkPermission('layouts', 'edit');
  const canDeleteLayout = checkPermission('layouts', 'delete');

  const fetchLayouts = useCallback(async (page = 1) => {
    setLoading(true);
    try {
      const startAfterDoc = page > 1 ? lastDoc : null;
      const result = await getPaginatedLayouts(
        itemsPerPage,
        startAfterDoc,
        sortOrder,
        searchTerm.toLowerCase(),
        page
      );

      setLayouts(result.layouts);
      setTotalItems(result.total);
      setLastDoc(result.lastDoc);
      setHasMore(result.hasMore);
    } catch (err) {
      setSnackbarMessage('Erro ao buscar layouts');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  }, [itemsPerPage, sortOrder, searchTerm, lastDoc]);

  useEffect(() => {
    fetchLayouts(1);
  }, [sortOrder, searchTerm]);

  const handleDeleteLayout = async (layoutId) => {
    if (!canDeleteLayout) {
      setSnackbarMessage('Você não tem permissão para excluir layouts');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    try {
      await deleteLayout(layoutId);
      fetchLayouts(currentPage);
      setSnackbarMessage('Layout excluído com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      setSnackbarMessage('Erro ao excluir layout');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const goToLayoutBuilder = (layout = null) => {
    if (layout && !canEditLayout) {
      setSnackbarMessage('Você não tem permissão para editar layouts');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (!layout && !canCreateLayout) {
      setSnackbarMessage('Você não tem permissão para criar layouts');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (layout) {
      navigate(`/layouts/${layout.id}`, { state: { layout } });
    } else {
      navigate('/layouts/novo');
    }
  };

  // Rest of the component functionality remains the same
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const executeSearch = () => {
    setSearchTerm(searchInput);
    setCurrentPage(1);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      executeSearch();
    }
  };

  const handleClearSearch = () => {
    setSearchInput('');
    setSearchTerm('');
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    fetchLayouts(value);
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
    setCurrentPage(1);
  };

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    saveViewType(mode);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2, alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="h6" component="div">
            Layout de Veículos
            <Box component="span" sx={{ ml: 1 }}>
              <ManageBusLayoutPageHelp />
            </Box>
          </Typography>
          {canCreateLayout && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => goToLayoutBuilder()}
              sx={{ borderRadius: '50px' }}
            >
              Adicionar
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            startIcon={<FilterListIcon />}
            onClick={() => setFiltersVisible(!filtersVisible)}
            sx={{ borderRadius: '50px' }}
          >
            {filtersVisible ? 'Ocultar Filtros' : 'Mostrar Filtros'}
          </Button>
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton
            onClick={() => handleViewModeChange('card')}
            color={viewMode === 'card' ? 'primary' : 'default'}
          >
            <ViewModuleIcon />
          </IconButton>
          <IconButton
            onClick={() => handleViewModeChange('table')}
            color={viewMode === 'table' ? 'primary' : 'default'}
          >
            <ViewListIcon />
          </IconButton>
        </Box>
      </Box>

      {/* Filters section */}
      <Collapse in={filtersVisible}>
        <Box sx={{ display: 'flex', gap: 2, marginBottom: 2, alignItems: 'center', flexWrap: 'wrap' }}>
          <FormControl variant="outlined" sx={{ minWidth: 200 }}>
            <InputLabel id="sort-order-label">Ordenar por</InputLabel>
            <Select
              labelId="sort-order-label"
              value={sortOrder}
              onChange={handleSortChange}
              label="Ordenar por Nome"
            >
              <MenuItem value="asc">Ordem Alfabética</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Buscar"
            variant="outlined"
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Buscar por ID ou Nome"
            sx={{ flexGrow: 1 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchInput && (
                    <IconButton onClick={handleClearSearch} edge="end" size="small">
                      <ClearIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={executeSearch} edge="end" size="small">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Collapse>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <TravelLoading disableAlignItems />
        </Box>
      ) : (
        <>
          {layouts.length === 0 ? (
            <Box sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="body1">Nenhum layout encontrado.</Typography>
            </Box>
          ) : viewMode === 'card' ? (
            <Grid container spacing={2} sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {layouts.map((layout) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={layout.id}>
                  <LayoutCard
                    layout={layout}
                    totalSeats={layout.assentosTotais}
                    isTwoFloors={layout.doisAndares}
                    onEdit={() => canEditLayout && goToLayoutBuilder(layout)}
                    onDelete={canDeleteLayout ? handleDeleteLayout : null}
                    canEdit={canEditLayout}
                    canDelete={canDeleteLayout}
                    sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <LayoutList
              layouts={layouts}
              onEdit={canEditLayout ? goToLayoutBuilder : null}
              onDelete={canDeleteLayout ? handleDeleteLayout : null}
              canEdit={canEditLayout}
              canDelete={canDeleteLayout}
            />
          )}

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Pagination
              count={Math.ceil(totalItems / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              showFirstButton
              showLastButton
            />
          </Box>
        </>
      )}

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LayoutPage;