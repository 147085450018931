import { db, auth } from '../../../firebaseConfig';
import { collection, doc, addDoc, getDocs, updateDoc, getDoc, query, where, orderBy, limit, startAfter  } from 'firebase/firestore';

const getAgencyRef = async () => {
  const user = auth.currentUser;
  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  return doc(db, 'agencies', userDoc.data().agencyId);
};

export const addBankAccount = async (bankAccount) => {
  try {
    const agencyRef = await getAgencyRef();
    const bankAccountsRef = collection(agencyRef, 'bankAccounts');
    
    const timestamp = new Date().toISOString();
    const accountData = {
      ...bankAccount,
      isActive: true,
      metadata: {
        createdAt: timestamp,
        updatedAt: timestamp,
        deletedAt: null
      }
    };

    const docRef = await addDoc(bankAccountsRef, accountData);
    return { id: docRef.id, ...accountData };
  } catch (error) {
    console.error('Erro ao adicionar conta bancária:', error);
    throw error;
  }
};

export const updateBankAccount = async (accountId, bankAccount) => {
  try {
    const agencyRef = await getAgencyRef();
    const accountRef = doc(agencyRef, 'bankAccounts', accountId);
    
    const timestamp = new Date().toISOString();
    const accountData = {
      ...bankAccount,
      metadata: {
        ...bankAccount.metadata,
        updatedAt: timestamp
      }
    };

    await updateDoc(accountRef, accountData);
    return { id: accountId, ...accountData };
  } catch (error) {
    console.error('Erro ao atualizar conta bancária:', error);
    throw error;
  }
};

export const deleteBankAccount = async (accountId) => {
  try {
    const agencyRef = await getAgencyRef();
    const accountRef = doc(agencyRef, 'bankAccounts', accountId);
    
    const timestamp = new Date().toISOString();
    await updateDoc(accountRef, {
      isActive: false,
      metadata: {
        updatedAt: timestamp,
        deletedAt: timestamp
      }
    });
    
    return accountId;
  } catch (error) {
    console.error('Erro ao excluir conta bancária:', error);
    throw error;
  }
};

export const getPaginatedBankAccounts = async (pageSize = 12, lastDoc = null, filterOptions = {}) => {
  try {
    const agencyRef = await getAgencyRef();
    const bankAccountsRef = collection(agencyRef, 'bankAccounts');

    let baseQuery = query(
      bankAccountsRef,
      where('isActive', '==', true),
      orderBy('name', filterOptions.sortOrder || 'asc')
    );

    if (filterOptions.searchTerm) {
      const searchTerm = filterOptions.searchTerm.toLowerCase().trim();
      const snapshot = await getDocs(baseQuery);

      const filteredDocs = snapshot.docs.filter(doc => {
        const data = doc.data();
        return (
          (data.name || '').toLowerCase().includes(searchTerm) ||
          (data.bank || '').toLowerCase().includes(searchTerm) ||
          (data.agency || '').toLowerCase().includes(searchTerm) ||
          (data.account || '').toLowerCase().includes(searchTerm)
        );
      });

      return {
        accounts: filteredDocs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })),
        lastVisible: filteredDocs[filteredDocs.length - 1],
        hasMore: filteredDocs.length === pageSize,
        totalCount: filteredDocs.length
      };
    }

    if (lastDoc) {
      baseQuery = query(baseQuery, startAfter(lastDoc));
    }

    baseQuery = query(baseQuery, limit(pageSize));
    const snapshot = await getDocs(baseQuery);

    const totalSnapshot = await getDocs(
      query(bankAccountsRef, where('isActive', '==', true))
    );

    return {
      accounts: snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })),
      lastVisible: snapshot.docs[snapshot.docs.length - 1],
      hasMore: snapshot.docs.length === pageSize,
      totalCount: totalSnapshot.size
    };
  } catch (error) {
    console.error('Erro ao buscar contas bancárias:', error);
    throw error;
  }
};

export const getAllBankAccounts = async () => {
  try {
    const agencyRef = await getAgencyRef();
    const bankAccountsRef = collection(agencyRef, 'bankAccounts');
    
    const q = query(
      bankAccountsRef,
      where('isActive', '==', true),
      orderBy('name', 'asc')
    );
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Erro ao buscar todas as contas bancárias:', error);
    throw error;
  }
};

export const getBankAccounts = async () => {
    try {
      const agencyRef = await getAgencyRef();
      const bankAccountsRef = collection(agencyRef, 'bankAccounts');
      
      const q = query(
        bankAccountsRef,
        where('isActive', '==', true),
        orderBy('name', 'asc')
      );
      
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error('Erro ao buscar contas bancárias:', error);
      throw error;
    }
  };

export const getBankAccountById = async (accountId) => {
  try {
    const agencyRef = await getAgencyRef();
    const accountRef = doc(agencyRef, 'bankAccounts', accountId);
    
    const accountDoc = await getDoc(accountRef);
    if (!accountDoc.exists()) {
      throw new Error('Conta bancária não encontrada');
    }

    return {
      id: accountDoc.id,
      ...accountDoc.data()
    };
  } catch (error) {
    console.error('Erro ao buscar conta bancária:', error);
    throw error;
  }
};