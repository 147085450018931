import React from 'react';
import { Alert, Box, Button, AlertTitle, Stack, Divider } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const SubscriptionExpiredAlert = () => {
    const handleWhatsAppClick = () => {
        window.open('https://wa.me/554288333458?text=Olá,%20minha%20assinatura%20expirou%20e%20gostaria%20de%20renovar!', '_blank');
    };

    return (
        <Alert
            severity="warning"
            variant="outlined"
            sx={{
                '& .MuiAlert-message': {
                    width: '100%'
                }
            }}
        >
            <AlertTitle sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                Sua assinatura expirou
            </AlertTitle>

            <Stack spacing={2}>
                <Box sx={{ color: 'text.secondary', fontSize: '0.875rem' }}>
                    Entre em contato com nosso suporte para renovar sua assinatura e continuar aproveitando todos os recursos.
                </Box>

                <Divider sx={{ my: 1 }} />

                <Stack direction="row" spacing={1.5} sx={{ justifyContent: 'flex-end' }}>
                    <Button
                        variant="contained"
                        size="medium"
                        startIcon={<WhatsAppIcon />}
                        endIcon={<ArrowForwardIcon />}
                        onClick={handleWhatsAppClick}
                        color="primary"
                        sx={{
                            borderRadius: '50px',
                            textTransform: 'none',
                            fontWeight: 500,
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                            }
                        }}
                    >
                        Renovar agora
                    </Button>
                </Stack>
            </Stack>
        </Alert>
    );
};

export default SubscriptionExpiredAlert;