import React, { useState, useEffect, useCallback } from 'react';
import {
    Typography,
    Button,
    Box,
    CircularProgress,
    Snackbar,
    Alert,
    Modal,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Pagination,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    InputAdornment,
    Grid,
    Card,
    CardContent,
    CardActions,
    Tooltip,
    Collapse
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import BankAccountForm from './BankAccountForm';
import BankAccountList from './components/BankAccountList';
import TravelLoading from '../../shared/common/TravelLoading';

import { usePermissions } from '../../hooks/usePermissions';
import { useMasterPassword } from '../../shared/hooks/useMasterPassword';
import { addBankAccount, updateBankAccount, deleteBankAccount, getPaginatedBankAccounts } from './services/BankAccountService';

const BankAccountPage = () => {
    const { checkPermission } = usePermissions();
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [editingAccount, setEditingAccount] = useState(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [accountToDelete, setAccountToDelete] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(12);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [lastDocument, setLastDocument] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [tempSearchTerm, setTempSearchTerm] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const [masterPassword, setMasterPassword] = useState('');

    const canViewBankAccounts = checkPermission('bank_accounts', 'view');
    const canCreateBankAccounts = checkPermission('bank_accounts', 'create');

    const {
        isVerifying,
        error: masterPasswordError,
        clearError,
        masterPasswordActive,
        showPassword,
        togglePasswordVisibility,
        verifyMasterPassword
    } = useMasterPassword();

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = async () => {
        setLoading(true);
        try {
            await fetchAccounts();
        } catch (err) {
            setError('Erro ao buscar contas bancárias');
            setSnackbarMessage('Erro ao buscar contas bancárias');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const fetchAccounts = async (resetData = false) => {
        try {
            const filterOptions = {
                searchTerm: searchTerm,
                sortOrder: sortOrder
            };

            if (resetData) {
                setCurrentPage(1);
                setLastDocument(null);
            }

            const result = await getPaginatedBankAccounts(
                itemsPerPage,
                resetData ? null : lastDocument,
                filterOptions
            );

            setAccounts(result.accounts);
            setLastDocument(result.lastVisible);
            setHasMore(result.hasMore);
            setTotalCount(result.totalCount);
        } catch (err) {
            setError('Erro ao buscar contas bancárias');
            setSnackbarMessage('Erro ao buscar contas bancárias');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleSearchChange = (event) => {
        setTempSearchTerm(event.target.value);
    };

    const executeSearch = async () => {
        setSearchLoading(true);
        try {
            setSearchTerm(tempSearchTerm);
            await fetchAccounts(true);
        } finally {
            setSearchLoading(false);
        }
    };

    const clearSearch = async () => {
        setSearchLoading(true);
        try {
            setTempSearchTerm('');
            setSearchTerm('');
            await fetchAccounts(true);
        } finally {
            setSearchLoading(false);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            executeSearch();
        }
    };

    const handlePageChange = async (event, value) => {
        if (value !== currentPage) {
            setLoading(true);
            try {
                if (value < currentPage) {
                    let lastDoc = null;
                    for (let i = 1; i < value; i++) {
                        const result = await getPaginatedBankAccounts(itemsPerPage, lastDoc, {
                            searchTerm,
                            sortOrder
                        });
                        lastDoc = result.lastVisible;
                    }
                    const finalResult = await getPaginatedBankAccounts(itemsPerPage, lastDoc, {
                        searchTerm,
                        sortOrder
                    });
                    setAccounts(finalResult.accounts);
                    setLastDocument(finalResult.lastVisible);
                    setHasMore(finalResult.hasMore);
                } else {
                    const result = await getPaginatedBankAccounts(itemsPerPage, lastDocument, {
                        searchTerm,
                        sortOrder
                    });
                    setAccounts(result.accounts);
                    setLastDocument(result.lastVisible);
                    setHasMore(result.hasMore);
                }
                setCurrentPage(value);
            } catch (error) {
                setError('Erro ao mudar de página');
                setSnackbarMessage('Erro ao mudar de página');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleSortChange = (event) => {
        setSortOrder(event.target.value);
        fetchAccounts(true);
    };

    const handleAddAccount = async (accountData) => {
        try {
            await addBankAccount(accountData);
            await fetchAccounts(true);
            setSnackbarMessage('Conta bancária adicionada com sucesso!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            setOpenModal(false);
        } catch (err) {
            setError('Erro ao adicionar conta bancária');
            setSnackbarMessage('Erro ao adicionar conta bancária');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleEditAccount = async (accountData) => {
        try {
            await updateBankAccount(accountData.id, accountData);
            await fetchAccounts(true);
            setSnackbarMessage('Conta bancária atualizada com sucesso!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            setOpenModal(false);
            setEditingAccount(null);
        } catch (err) {
            setError('Erro ao atualizar conta bancária');
            setSnackbarMessage('Erro ao atualizar conta bancária');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleDeleteAccount = async () => {
        setLoading(true);
        try {
            if (masterPasswordActive) {
                const isValid = await verifyMasterPassword(masterPassword);
                if (!isValid) {
                    setSnackbarMessage('Senha master incorreta');
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                    setLoading(false);
                    return;
                }
            }

            await deleteBankAccount(accountToDelete.id);
            await fetchAccounts(true);
            setSnackbarMessage('Conta bancária excluída com sucesso!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            setConfirmDeleteOpen(false);
            setAccountToDelete(null);
            setMasterPassword('');
        } catch (err) {
            setError('Erro ao excluir conta bancária');
            setSnackbarMessage('Erro ao excluir conta bancária');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const startEditing = (account) => {
        setEditingAccount(account);
        setOpenModal(true);
    };

    const openConfirmDeleteDialog = (account) => {
        setAccountToDelete(account);
        setConfirmDeleteOpen(true);
    };

    const closeConfirmDeleteDialog = () => {
        setConfirmDeleteOpen(false);
        setAccountToDelete(null);
        setMasterPassword('');
        clearError();
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
        setSnackbarMessage('');
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', gap: 2, marginBottom: 2, alignItems: 'center', flexWrap: 'wrap' }}>
                <Typography variant="h6" component="div">
                    Contas Bancárias
                </Typography>
                {canCreateBankAccounts && (
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => setOpenModal(true)}
                        sx={{ borderRadius: '50px' }}
                    >
                        Adicionar
                    </Button>
                )}
                {canViewBankAccounts && (
                    <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<FilterListIcon />}
                        onClick={() => setFiltersVisible(!filtersVisible)}
                        sx={{ borderRadius: '50px' }}
                    >
                        {filtersVisible ? 'Ocultar Filtros' : 'Mostrar Filtros'}
                    </Button>
                )}
            </Box>

            <Collapse in={filtersVisible}>
                <Box sx={{ display: 'flex', gap: 2, marginBottom: 2, alignItems: 'center', flexWrap: 'wrap' }}>
                    <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                        <InputLabel id="sort-order-label">Ordenar por</InputLabel>
                        <Select
                            labelId="sort-order-label"
                            value={sortOrder}
                            onChange={handleSortChange}
                            label="Ordenar por Nome"
                            disabled={searchLoading}
                        >
                            <MenuItem value="asc">Ordem Alfabética</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        fullWidth
                        label="Busca"
                        variant="outlined"
                        value={tempSearchTerm}
                        onChange={handleSearchChange}
                        onKeyPress={handleKeyPress}
                        placeholder="Busque por nome, banco, agência ou conta"
                        disabled={searchLoading}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {tempSearchTerm && (
                                        <IconButton onClick={clearSearch} disabled={searchLoading} size="small">
                                            <ClearIcon />
                                        </IconButton>
                                    )}
                                    <IconButton onClick={executeSearch} disabled={searchLoading} size="small">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Collapse>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                    <TravelLoading disableAlignItems />
                </Box>
            ) : (
                <>
                    <BankAccountList
                        accounts={accounts}
                        onEdit={startEditing}
                        onDelete={openConfirmDeleteDialog}
                        loading={loading}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Pagination
                            count={Math.ceil(totalCount / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                            disabled={loading}
                        />
                    </Box>
                </>
            )}

            <Modal
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                    setEditingAccount(null);
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '20px',
                        maxWidth: '90vw',
                        maxHeight: '90vh',
                        overflow: 'auto'
                    }}
                >
                    <BankAccountForm
                        onSave={editingAccount ? handleEditAccount : handleAddAccount}
                        initialAccount={editingAccount}
                        onCancel={() => {
                            setOpenModal(false);
                            setEditingAccount(null);
                        }}
                    />
                </Box>
            </Modal>

            <Dialog open={confirmDeleteOpen} onClose={closeConfirmDeleteDialog}>
                <DialogTitle>Confirmar Exclusão</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Tem certeza que deseja excluir a conta {accountToDelete?.name}? Esta ação não pode ser desfeita.
                    </DialogContentText>
                    {masterPasswordActive && (
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Senha Master"
                            type={showPassword ? 'text' : 'password'}
                            value={masterPassword}
                            onChange={(e) => setMasterPassword(e.target.value)}
                            error={!!masterPasswordError}
                            helperText={masterPasswordError}
                            InputProps={{
                                autoComplete: 'new-password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={togglePasswordVisibility}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            autoComplete="off"
                            disabled={loading || isVerifying}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeConfirmDeleteDialog} variant="outlined" disabled={loading} sx={{ borderRadius: '50px' }}>
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleDeleteAccount}
                        variant="contained"
                        color="error"
                        autoFocus
                        disabled={(masterPasswordActive && !masterPassword) || loading || isVerifying}
                        sx={{ color: 'white', borderRadius: '50px' }}
                    >
                        {loading || isVerifying ? <CircularProgress size={24} /> : 'Excluir'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default BankAccountPage;