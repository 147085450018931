import React, { useState, useEffect } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Button, Grid, CircularProgress
} from '@mui/material';
import { addHotel, updateHotel } from './services/AccommodationService';

const HotelForm = ({ open, onClose, onSubmit, hotel, travelId }) => {
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        checkInTime: '',
        checkOutTime: '',
        contactName: '',
        contactPhone: '',
        notes: ''
    });

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (hotel) {
            const { rooms, ...hotelData } = hotel;
            setFormData(hotelData);
        } else {
            setFormData({
                name: '',
                address: '',
                checkInTime: '',
                checkOutTime: '',
                contactName: '',
                contactPhone: '',
                notes: ''
            });
        }
    }, [hotel, open]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const result = hotel
                ? await updateHotel(hotel.id, { ...formData, travelId })
                : await addHotel(travelId, formData);

            onSubmit(result);
        } catch (error) {
            console.error('Erro ao salvar hotel:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogTitle>
                    {hotel ? 'Editar Hotel' : 'Novo Hotel'}
                </DialogTitle>

                <DialogContent>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Nome do Hotel"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Endereço"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Horário Check-in"
                                name="checkInTime"
                                type="time"
                                value={formData.checkInTime}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Horário Check-out"
                                name="checkOutTime"
                                type="time"
                                value={formData.checkOutTime}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Nome do Contato"
                                name="contactName"
                                value={formData.contactName}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Telefone do Contato"
                                name="contactPhone"
                                value={formData.contactPhone}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Observações do Hotel"
                                name="notes"
                                value={formData.notes}
                                onChange={handleChange}
                                rows={3}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        disabled={loading}
                        sx={{ borderRadius: '50px' }}
                    >
                        Voltar
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={loading}
                        sx={{ borderRadius: '50px' }}
                    >
                        {loading ? (
                            <CircularProgress size={24} />
                        ) : hotel ? (
                            'Salvar'
                        ) : (
                            'Adicionar'
                        )}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default HotelForm;