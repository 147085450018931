import React from 'react';
import { Box, Typography } from '@mui/material';
import FleetSchedule from './components/FleetSchedule';
import FleetScheduleLegend from './components/FleetScheduleLegend';

const FleetSchedulePage = () => {

  return (
    <Box sx={{ gap: 2, marginBottom: 2, alignItems: 'center', flexWrap: 'wrap' }}>
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 3,
          flexWrap: 'wrap',
          gap: 2
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 3
        }}>
          <Typography variant="h6" component="div">
            Agenda de Veículos
          </Typography>
          <FleetScheduleLegend />
        </Box>
      </Box>

      <FleetSchedule />
    </Box>
  );
};

export default FleetSchedulePage;