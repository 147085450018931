import { db, auth } from '../../../firebaseConfig';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';

const validatePassword = (password) => {
  const errors = [];
  if (password.length < 6) {
    errors.push('A senha deve ter pelo menos 6 caracteres.');
  }
  if (!/[A-Z]/.test(password)) {
    errors.push('A senha deve conter pelo menos uma letra maiúscula.');
  }
  if (!/[a-z]/.test(password)) {
    errors.push('A senha deve conter pelo menos uma letra minúscula.');
  }
  if (!/[0-9]/.test(password)) {
    errors.push('A senha deve conter pelo menos um número.');
  }
  return errors;
};

const verifyOwnerAccess = async (agencyId, userId) => {
  const memberRef = doc(db, `agencies/${agencyId}/members`, userId);
  const memberDoc = await getDoc(memberRef);

  if (!memberDoc.exists()) {
    throw new Error('Usuário não encontrado na agência');
  }

  if (memberDoc.data().roleId !== 'owner') {
    throw new Error('Apenas o proprietário pode gerenciar a senha master');
  }

  return true;
};

export const getMasterPasswordStatus = async (agencyId) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      return { isActive: false, isDefined: false };
    }

    const agencyDoc = await getDoc(doc(db, 'agencies', agencyId));

    if (!agencyDoc.exists()) {
      return { isActive: false, isDefined: false };
    }

    const agencyData = agencyDoc.data();

    return {
      isActive: Boolean(agencyData.masterPasswordActive),
      isDefined: Boolean(agencyData.masterPassword)
    };
  } catch (error) {
    console.error('Erro ao obter status da senha master:', error);
    throw error;
  }
};

export const toggleMasterPasswordActive = async (agencyId, isActive) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('Usuário não está autenticado');

    await verifyOwnerAccess(agencyId, user.uid);

    const agencyRef = doc(db, 'agencies', agencyId);
    await updateDoc(agencyRef, { 
      masterPasswordActive: isActive,
      'metadata.updatedAt': new Date().toISOString()
    });

    return true;
  } catch (error) {
    console.error('Erro ao alternar status da senha master:', error);
    throw error;
  }
};

export const updateMasterPassword = async (agencyId, { currentMasterPassword, newMasterPassword }) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('Usuário não está autenticado');

    await verifyOwnerAccess(agencyId, user.uid);

    // Validate new password
    const passwordErrors = validatePassword(newMasterPassword);
    if (passwordErrors.length > 0) {
      throw new Error(passwordErrors.join(' '));
    }

    const agencyRef = doc(db, 'agencies', agencyId);
    const agencyDoc = await getDoc(agencyRef);

    if (!agencyDoc.exists()) {
      throw new Error('Agência não encontrada');
    }

    const agencyData = agencyDoc.data();

    // Verify current master password if it exists
    if (agencyData.masterPassword && agencyData.masterPassword !== currentMasterPassword) {
      throw new Error('Senha master atual incorreta');
    }

    // Update the master password
    await updateDoc(agencyRef, {
      masterPassword: newMasterPassword,
      masterPasswordActive: true,
      'metadata.updatedAt': new Date().toISOString(),
      'metadata.masterPasswordUpdatedAt': new Date().toISOString()
    });

    return true;
  } catch (error) {
    console.error('Erro ao atualizar senha master:', error);
    throw error;
  }
};

export const checkMasterPassword = async (agencyId, masterPassword) => {
  try {
    const agencyDoc = await getDoc(doc(db, 'agencies', agencyId));

    if (!agencyDoc.exists()) {
      throw new Error('Agência não encontrada');
    }

    const agencyData = agencyDoc.data();

    if (!agencyData.masterPasswordActive) {
      throw new Error('Senha master está desativada');
    }

    if (agencyData.masterPassword !== masterPassword) {
      throw new Error('Senha master incorreta');
    }

    return true;
  } catch (error) {
    console.error('Erro ao verificar senha master:', error);
    throw error;
  }
};

export const checkMasterPasswordRequired = async (agencyId) => {
  try {
    const agencyDoc = await getDoc(doc(db, 'agencies', agencyId));

    if (!agencyDoc.exists()) {
      return false;
    }

    const agencyData = agencyDoc.data();
    return Boolean(agencyData.masterPasswordActive && agencyData.masterPassword);
  } catch (error) {
    console.error('Erro ao verificar necessidade de senha master:', error);
    return false;
  }
};