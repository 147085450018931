import React, { useEffect, useState, useContext, createContext } from 'react';
import { auth, db } from '../../firebaseConfig';
import { signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    currentUser: null,
    loading: true,
    initialized: false
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        setAuthState({
          currentUser: null,
          loading: false,
          initialized: true
        });
        return;
      }

      try {
        if (!user.emailVerified) {
          await signOut(auth);
          setAuthState({
            currentUser: null,
            loading: false,
            initialized: true
          });
          return;
        }

        // Primeiro, busca os dados do usuário
        const userDoc = await getDoc(doc(db, 'users', user.uid));

        if (!userDoc.exists()) {
          await signOut(auth);
          setAuthState({
            currentUser: null,
            loading: false,
            initialized: true
          });
          return;
        }

        const userData = userDoc.data();
        const agencyId = userData.agencyId; // Usa o agencyId do usuário

        // Depois busca os dados da agência e do membro usando o agencyId correto
        const [agencyDoc, memberDoc] = await Promise.all([
          getDoc(doc(db, 'agencies', agencyId)),
          getDoc(doc(db, `agencies/${agencyId}/members`, user.uid))
        ]);

        if (!agencyDoc.exists() || !memberDoc.exists()) {
          await signOut(auth);
          setAuthState({
            currentUser: null,
            loading: false,
            initialized: true
          });
          return;
        }

        const agencyData = agencyDoc.data();
        const memberData = memberDoc.data();

        // Verifica as condições
        const isValid = userData.isApproved &&
          agencyData.status === 'active' &&
          agencyData.subscription.status !== 'expired' &&
          memberData.metadata.status === 'active';

        if (!isValid) {
          await signOut(auth);
          setAuthState({
            currentUser: null,
            loading: false,
            initialized: true
          });
          return;
        }

        setAuthState({
          currentUser: {
            ...user,
            userData: {
              ...userData,
              isApproved: userData.isApproved,
              agency: {
                ...agencyData,
                id: agencyId,
                subscription: agencyData.subscription
              },
              role: memberData.roleId,
              permissions: memberData.customPermissions
            },
            role: memberData.roleId,
            permissions: memberData.customPermissions
          },
          loading: false,
          initialized: true
        });
      } catch (error) {
        console.error('Erro ao verificar autenticação:', error);
        await signOut(auth);
        setAuthState({
          currentUser: null,
          loading: false,
          initialized: true
        });
      }
    });

    return () => unsubscribe();
  }, []);

  const hasPermission = (resource, action) => {
    if (!authState.currentUser) return false;
    if (authState.currentUser.role === 'owner') return true;
    if (authState.currentUser.permissions?.[resource]?.[action]) return true;
    return false;
  };

  return (
    <AuthContext.Provider value={{
      currentUser: authState.currentUser,
      loading: authState.loading,
      initialized: authState.initialized,
      hasPermission: (resource, action) => {
        if (!authState.currentUser) return false;
        if (authState.currentUser.role === 'owner') return true;
        return authState.currentUser.permissions?.[resource]?.actions?.[action] === true;
      },
      isOwner: authState.currentUser?.role === 'owner'
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};