import React from 'react';
import { Box, Typography, Tooltip, Paper } from '@mui/material';
import { TrendingUp, TrendingDown, AccountBalanceWallet, Payment, MonetizationOn } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { usePermissions } from '../../../hooks/usePermissions';
import { normalizeValue } from '../../../shared/utils/utils';
import { TRAVEL_TYPES } from '../../Travel/services/TravelService';

const TransacitionSummary = ({
  totalCosts = 0,
  totalReceivings = 0,
  totalOrderReceived = 0,
  totalOrderReceivable = 0,
  travelType,
  isGuide
}) => {
  const { checkPermission } = usePermissions();
  const canViewTransactions = checkPermission('travels_transactions', 'view');
  const canViewOrders = checkPermission('orders', 'view');
  const balance = (totalReceivings || 0) - (totalCosts || 0);
  const theme = useTheme();

  const commonPaperStyles = {
    p: 2,
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-around',
    gap: 2,
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.paper,
  };

  const canShowOrderInfo = () => {
    if (travelType === TRAVEL_TYPES.FREIGHT) {
      return isGuide;
    }
    return true;
  };

  const canShowTransactionInfo = () => {
    if (travelType === TRAVEL_TYPES.FREIGHT) {
      return isGuide;
    }
    return true;
  };

  if (!canViewTransactions) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'inherit', gap: 2 }}>
      {canShowOrderInfo() && (
        <Paper elevation={3} sx={commonPaperStyles}>
          <Tooltip title="Valor ainda a receber dos pedidos" arrow>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Payment sx={{ fontSize: 24, color: theme.palette.info.main }} />
              <Box>
                <Typography variant="body2" color="textSecondary">
                  A Receber
                </Typography>
                <Typography variant="body1" >
                  R$ {normalizeValue(totalOrderReceivable)}
                </Typography>
              </Box>
            </Box>
          </Tooltip>

          <Tooltip title="Valor já recebido dos pedidos" arrow>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <MonetizationOn sx={{ fontSize: 24, color: theme.palette.success.main }} />
              <Box>
                <Typography variant="body2" color="textSecondary">
                  Recebido dos Pedidos
                </Typography>
                <Typography variant="body1">
                  R$ {normalizeValue(totalOrderReceived)}
                </Typography>
              </Box>
            </Box>
          </Tooltip>
        </Paper>
      )}

      {canShowTransactionInfo() && (
        <Paper elevation={3} sx={commonPaperStyles}>
          <Tooltip title="Soma total dos custos da viagem (Incluindo pedidos)" arrow>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TrendingDown sx={{ fontSize: 24, color: theme.palette.error.main }} />
              <Box>
                <Typography variant="body2" color="textSecondary">
                  Custos Totais
                </Typography>
                <Typography variant="body1" color="error">
                  R$ {normalizeValue(totalCosts)}
                </Typography>
              </Box>
            </Box>
          </Tooltip>

          <Tooltip title="Soma total dos recebimentos da viagem (Incluindo pedidos)" arrow>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TrendingUp sx={{ fontSize: 24, color: theme.palette.success.main }} />
              <Box>
                <Typography variant="body2" color="textSecondary">
                  Recebimentos Totais
                </Typography>
                <Typography variant="body1" color="success.main">
                  R$ {normalizeValue(totalReceivings)}
                </Typography>
              </Box>
            </Box>
          </Tooltip>

          <Tooltip title="Saldo total (Recebimentos - Custos)" arrow>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <AccountBalanceWallet sx={{ fontSize: 24, color: theme.palette.info.main }} />
              <Box>
                <Typography variant="body2" color="textSecondary">
                  Saldo Total
                </Typography>
                <Typography variant="body1">
                  R$ {normalizeValue(balance)}
                </Typography>
              </Box>
            </Box>
          </Tooltip>
        </Paper>
      )}
    </Box>
  );
};

export default TransacitionSummary;