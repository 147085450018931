import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    Box,
    Typography,
    Button,
    Grid,
    TextField,
    InputAdornment,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Collapse,
    Pagination,
    Snackbar,
    Alert,
    Paper,
    Dialog,
    DialogTitle, DialogContent, DialogActions, DialogContentText,
    useTheme
} from '@mui/material';
import { format, isAfter, addDays, parseISO } from 'date-fns';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

import VehicleDocumentsList from './components/VehicleDocumentsList';
import VehicleDocumentForm from './VehicleDocumentForm';
import TravelLoading from '../../shared/common/TravelLoading';

import { getAllVehicleDocumentsPaginated, addVehicleDocument, updateVehicleDocument, deleteVehicleDocument } from './services/VehicleDocumentsService';

const VehicleDocumentsPage = () => {
    const theme = useTheme();
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [editingDocument, setEditingDocument] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState({
        open: false,
        documentId: null,
        documentName: ''
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [filters, setFilters] = useState({
        searchTerm: '',
        startDate: '',
        endDate: '',
        status: '',
        sortOrder: 'asc'
    });
    const [tempSearchTerm, setTempSearchTerm] = useState('');
    const pageSize = 12;

    const fetchDocuments = useCallback(async (page = 1) => {
        setLoading(true);
        try {
            const result = await getAllVehicleDocumentsPaginated(pageSize, page, filters);
            setDocuments(result.documents);
            setTotalPages(result.totalPages);
            setCurrentPage(result.currentPage);
        } catch (error) {
            console.error('Erro ao buscar documentos:', error);
            handleSnackbar('Erro ao carregar documentos', 'error');
        } finally {
            setLoading(false);
        }
    }, [filters]);

    useEffect(() => {
        fetchDocuments(1);
    }, [fetchDocuments]);

    const handleSnackbar = (message, severity = 'success') => {
        setSnackbar({
            open: true,
            message,
            severity
        });
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({
            ...prev,
            open: false
        }));
    };

    const handleSearch = () => {
        setFilters(prev => ({ ...prev, searchTerm: tempSearchTerm.trim() }));
    };

    const clearSearch = () => {
        setTempSearchTerm('');
        setFilters(prev => ({ ...prev, searchTerm: '' }));
    };

    const handleFilterChange = (name, value) => {
        setFilters(prev => ({ ...prev, [name]: value }));
    };

    const handlePageChange = (event, newPage) => {
        if (newPage !== currentPage) {
            fetchDocuments(newPage);
        }
    };

    const handleOpenFormDialog = (document = null) => {
        setEditingDocument(document);
        setOpenFormDialog(true);
    };

    const handleCloseFormDialog = () => {
        setOpenFormDialog(false);
        setEditingDocument(null);
    };

    const handleSubmit = async (formData) => {
        setLoading(true);
        try {
            const documentData = {
                ...formData,
                dataVencimento: parseISO(formData.dataVencimento).toISOString(),
            };

            if (editingDocument) {
                await updateVehicleDocument(editingDocument.id, documentData);
                handleSnackbar('Documento atualizado com sucesso!');
            } else {
                await addVehicleDocument(documentData);
                handleSnackbar('Documento cadastrado com sucesso!');
            }

            await fetchDocuments(currentPage);
            handleCloseFormDialog();
        } catch (error) {
            console.error('Erro ao salvar documento:', error);
            handleSnackbar(
                `Erro ao ${editingDocument ? 'atualizar' : 'cadastrar'} documento`,
                'error'
            );
        } finally {
            setLoading(false);
        }
    };

    const handleOpenDeleteDialog = (document) => {
        setDeleteDialog({
            open: true,
            documentId: document.id,
            documentName: document.nome
        });
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialog({
            open: false,
            documentId: null,
            documentName: ''
        });
    };

    const handleConfirmDelete = async () => {
        setLoading(true);
        try {
            await deleteVehicleDocument(deleteDialog.documentId);
            handleSnackbar('Documento excluído com sucesso!');
            await fetchDocuments(currentPage);
        } catch (error) {
            console.error('Erro ao excluir documento:', error);
            handleSnackbar('Erro ao excluir documento', 'error');
        } finally {
            setLoading(false);
            handleCloseDeleteDialog();
        }
    };

    return (
        <Box sx={{ gap: 2, marginBottom: 2, alignItems: 'center', flexWrap: 'wrap' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 3,
                    flexWrap: 'wrap',
                    gap: 2
                }}
            >
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Typography variant="h6">Documentos dos Veículos</Typography>
                    <Button
                        variant="outlined"
                        startIcon={<FilterListIcon />}
                        onClick={() => setFiltersVisible(!filtersVisible)}
                        sx={{ borderRadius: '50px' }}
                    >
                        {filtersVisible ? 'Ocultar Filtros' : 'Mostrar Filtros'}
                    </Button>
                    {/* <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => handleOpenFormDialog()}
                        sx={{ borderRadius: '50px' }}
                    >
                        Adicionar
                    </Button> */}
                </Box>

            </Box>

            <Collapse in={filtersVisible}>
                <Paper sx={{ p: 2, mb: 3, width: '100%' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl fullWidth>
                                <InputLabel>Ordenação</InputLabel>
                                <Select
                                    value={filters.sortOrder}
                                    onChange={(e) => handleFilterChange('sortOrder', e.target.value)}
                                    label="Ordenação"
                                >
                                    <MenuItem value="asc">Mais Próximo do Vencimento</MenuItem>
                                    <MenuItem value="desc">Mais Distante do Vencimento</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                fullWidth
                                label="Buscar"
                                value={tempSearchTerm}
                                onChange={(e) => setTempSearchTerm(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {tempSearchTerm && (
                                                <IconButton onClick={clearSearch} size="small">
                                                    <ClearIcon />
                                                </IconButton>
                                            )}
                                            <IconButton onClick={handleSearch} size="small">
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <TextField
                                fullWidth
                                type="date"
                                label="Data Inicial"
                                value={filters.startDate}
                                onChange={(e) => handleFilterChange('startDate', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <TextField
                                fullWidth
                                type="date"
                                label="Data Final"
                                value={filters.endDate}
                                onChange={(e) => handleFilterChange('endDate', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl fullWidth>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    value={filters.status}
                                    onChange={(e) => handleFilterChange('status', e.target.value)}
                                    label="Status"
                                >
                                    <MenuItem value="">Todos</MenuItem>
                                    <MenuItem value="vencido">Vencidos</MenuItem>
                                    <MenuItem value="proximo">Próximos ao Vencimento</MenuItem>
                                    <MenuItem value="regular">Regulares</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </Collapse>

            {loading && documents.length === 0 ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                    <TravelLoading disableAlignItems/>
                </Box>
            ) : (
                <>
                    <VehicleDocumentsList
                        documents={documents}
                        onEdit={handleOpenFormDialog}
                        onDelete={handleOpenDeleteDialog}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                            disabled={loading}
                        />
                    </Box>
                </>
            )}

            <VehicleDocumentForm
                open={openFormDialog}
                onClose={handleCloseFormDialog}
                onSubmit={handleSubmit}
                initialData={editingDocument}
                loading={loading}
            />

            <Dialog
                open={deleteDialog.open}
                onClose={handleCloseDeleteDialog}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Confirmar exclusão de documento</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Tem certeza que deseja excluir o documento "{deleteDialog.documentName}"? Esta ação não poderá ser desfeita.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} variant="outlined" sx={{ borderRadius: '50px' }}>
                        Voltar
                    </Button>
                    <Button onClick={handleConfirmDelete} variant="contained" color="error" sx={{ borderRadius: '50px' }}>
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default VehicleDocumentsPage;