import { db, auth } from '../../../firebaseConfig';
import { collection, doc, getDocs, getDoc, query, where, orderBy, limit, startAfter } from 'firebase/firestore';
import { getBankAccounts } from '../../BankAccount/services/BankAccountService';
import { adaptReceivablesData, adaptBudgetPayments } from '../adapters/ReceivablesAdapter';

const getAgencyRef = async () => {
  const user = auth.currentUser;
  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  return doc(db, 'agencies', userDoc.data().agencyId);
};

export const getPaginatedReceivables = async (pageSize = 5, lastDoc = null, filters = {}, currentPage = 1, getAllRecords = false) => {
  try {
    const agencyRef = await getAgencyRef();
    const bankAccounts = await getBankAccounts();

    // Get travels data
    const travelsRef = collection(agencyRef, 'travels');
    const queryConstraints = [
      where('estaAtivo', '==', true),
      where('status', '!=', 'Cancelada')
    ];

    const baseQuery = query(travelsRef, ...queryConstraints);
    const travelsSnapshot = await getDocs(baseQuery);

    // Get travels with orders and costs
    const travelsData = await Promise.all(
      travelsSnapshot.docs.map(async (travelDoc) => {
        const travel = { id: travelDoc.id, ...travelDoc.data() };

        const ordersRef = collection(agencyRef, 'orders');
        const ordersQuery = query(ordersRef, where('travelId', '==', travel.id));
        const ordersSnapshot = await getDocs(ordersQuery);
        travel.orders = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        const costsRef = collection(agencyRef, 'costs');
        const costsQuery = query(costsRef, where('travelId', '==', travel.id));
        const costsSnapshot = await getDocs(costsQuery);
        travel.costs = costsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        return travel;
      })
    );

    // Get budget payments
    const budgetPayments = await adaptBudgetPayments(agencyRef, bankAccounts);

    // Combine and adapt all data
    const adaptedData = adaptReceivablesData(
      {
        travels: travelsData,
        budgetPayments
      },
      bankAccounts,
      pageSize,
      currentPage,
      filters,
      getAllRecords
    );

    return {
      receivables: adaptedData.items,
      totalCount: adaptedData.totalItems,
      totalPages: adaptedData.totalPages
    };
  } catch (error) {
    console.error('Error fetching receivables:', error);
    throw error;
  }
};
