import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { getUserById, createAgencyUser, updateAgencyUser } from '../UserManagement/services/UserManagementService';
import {
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Grid,
    Checkbox,
    Typography,
    Paper,
    CircularProgress,
    Alert,
    AlertTitle,
    FormHelperText,
    Card,
    CardContent,
    IconButton,
    Tooltip,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Snackbar,
    SnackbarContent
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import { formatTelefone } from '../../shared/utils/utils';
import { useTheme } from '@mui/material/styles';
import TravelLoading from '../../shared/common/TravelLoading';
import { PERMISSIONS, ROLES } from '../../config/permissions';

const roleDescriptions = {
    admin: 'Acesso total a todos os módulos e funcionalidades administrativas.',
    manager: 'Acesso à gestão de viagens, veículos e passageiros.',
    guide: 'Acesso limitado às viagens designadas.',
    driver: 'Acesso limitado às informações de veículos e viagens designadas.'
};

const UserFormPage = ({ onSubmitComplete = () => { } }) => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const isEditing = Boolean(userId);
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [error, setError] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const formatPermissionsForDisplay = useCallback(() => {
        return Object.values(PERMISSIONS)
            .filter(module => module.key !== 'system')
            .reduce((acc, module) => {
                acc[module.key] = {
                    label: module.label,
                    description: module.description,
                    actions: Object.values(module.actions).reduce((actionAcc, action) => {
                        actionAcc[action] = false;
                        return actionAcc;
                    }, {})
                };
                return acc;
            }, {});
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        role: '',
        password: '',
        confirmPassword: '',
        permissions: formatPermissionsForDisplay()
    });

    const updatePermissionsByRole = useCallback((role) => {
        const selectedRole = ROLES[role.toUpperCase()];
        if (!selectedRole) return;

        if (selectedRole.inheritsAllPermissions) {
            const fullPermissions = Object.values(PERMISSIONS)
                .filter(module => module.key !== 'system')
                .reduce((acc, module) => {
                    acc[module.key] = {
                        label: module.label,
                        description: module.description,
                        actions: Object.values(module.actions).reduce((actionAcc, action) => {
                            actionAcc[action] = true;
                            return actionAcc;
                        }, {})
                    };
                    return acc;
                }, {});

            setFormData(prev => ({
                ...prev,
                permissions: fullPermissions
            }));
        } else if (selectedRole.defaultPermissions) {
            // Aplica as permissões padrão do cargo
            const defaultPerms = Object.entries(selectedRole.defaultPermissions).reduce((acc, [moduleKey, actions]) => {
                const moduleData = Object.values(PERMISSIONS).find(m => m.key === moduleKey);

                if (moduleData) {
                    acc[moduleKey] = {
                        label: moduleData.label,
                        description: moduleData.description,
                        actions: Object.values(moduleData.actions).reduce((actionAcc, action) => {
                            actionAcc[action] = actions.includes(action);
                            return actionAcc;
                        }, {})
                    };
                }

                return acc;
            }, formatPermissionsForDisplay());

            setFormData(prev => ({
                ...prev,
                permissions: defaultPerms
            }));
        }
    }, [formatPermissionsForDisplay]);

    const [validations, setValidations] = useState({
        name: true,
        email: true,
        phone: true,
        role: true,
        password: true,
        confirmPassword: true,
        permissions: true
    });

    useEffect(() => {
        const loadUserData = async () => {
            if (isEditing) {
                try {
                    setInitialLoading(true);
                    const currentUser = auth.currentUser;

                    if (!currentUser?.uid) {
                        throw new Error('Usuário não autenticado');
                    }

                    const userRef = doc(db, 'users', currentUser.uid);
                    const userDoc = await getDoc(userRef);
                    const agencyId = userDoc.data()?.agencyId;

                    if (!agencyId) {
                        throw new Error('ID da agência não encontrado');
                    }

                    const userData = await getUserById(agencyId, userId);

                    setFormData({
                        name: userData.name || '',
                        email: userData.email || '',
                        phone: userData.phone || '',
                        role: userData.role || '',
                        permissions: userData.permissions
                            ? Object.keys(userData.permissions).reduce((acc, moduleKey) => {
                                const moduleData = Object.values(PERMISSIONS).find(m => m.key === moduleKey);
                                if (moduleData) {
                                    acc[moduleKey] = {
                                        label: moduleData.label,
                                        description: moduleData.description,
                                        actions: userData.permissions[moduleKey].actions ||
                                            Object.values(moduleData.actions).reduce((actionAcc, action) => {
                                                actionAcc[action] = false;
                                                return actionAcc;
                                            }, {})
                                    };
                                }
                                return acc;
                            }, formatPermissionsForDisplay())
                            : formatPermissionsForDisplay(),
                        password: '',
                        confirmPassword: ''
                    });
                } catch (err) {
                    console.error('Erro ao carregar dados do usuário:', err);
                    setError('Erro ao carregar dados do usuário: ' + err.message);
                    navigate('/usuarios');
                } finally {
                    setInitialLoading(false);
                }
            } else {
                setInitialLoading(false);
            }
        };

        loadUserData();
    }, [userId, isEditing]);

    const validateField = (name, value) => {
        let isValid = true;
        switch (name) {
            case 'name':
                isValid = value.trim().length >= 3 && value.length <= 255;
                break;
            case 'email':
                if (!isEditing) {
                    isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
                }
                break;
            case 'phone':
                isValid = value.replace(/\D/g, '').length >= 10;
                break;
            case 'password':
                if (!isEditing) {
                    isValid = value.length >= 6 && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$/.test(value);
                }
                break;
            case 'confirmPassword':
                if (!isEditing) {
                    isValid = value === formData.password;
                }
                break;
            case 'role':
                isValid = !!value;
                break;
            case 'permissions':
                isValid = Object.values(value).some(module =>
                    Object.values(module.actions).some(action => action)
                );
                break;
            default:
                break;
        }
        return isValid;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        if (name === 'role') {
            updatePermissionsByRole(value);
        }

        setValidations(prev => ({
            ...prev,
            [name]: validateField(name, value)
        }));
    };

    const handlePhoneChange = (e) => {
        const rawValue = e.target.value.replace(/\D/g, '');
        const formattedValue = formatTelefone(rawValue);

        setFormData(prev => ({
            ...prev,
            phone: formattedValue
        }));

        setValidations(prev => ({
            ...prev,
            phone: validateField('phone', rawValue)
        }));
    };

    const handlePermissionChange = (moduleKey, actionKey) => (e) => {
        const newPermissions = {
            ...formData.permissions,
            [moduleKey]: {
                ...formData.permissions[moduleKey],
                actions: {
                    ...formData.permissions[moduleKey].actions,
                    [actionKey]: e.target.checked
                }
            }
        };

        setFormData(prev => ({
            ...prev,
            permissions: newPermissions
        }));

        setValidations(prev => ({
            ...prev,
            permissions: validateField('permissions', newPermissions)
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const fieldsToValidate = isEditing
            ? ['name', 'phone', 'role', 'permissions']
            : ['name', 'email', 'phone', 'role', 'password', 'confirmPassword', 'permissions'];

        const newValidations = {};
        fieldsToValidate.forEach(key => {
            newValidations[key] = validateField(key, formData[key]);
        });

        setValidations(newValidations);

        if (Object.values(newValidations).every(Boolean)) {
            setLoading(true);
            try {
                const userRef = doc(db, 'users', auth.currentUser.uid);
                const userDoc = await getDoc(userRef);
                const agencyId = userDoc.data()?.agencyId;

                if (!agencyId) {
                    throw new Error('Agência não encontrada');
                }

                if (isEditing) {
                    const { password, confirmPassword, ...updateData } = formData;
                    await updateAgencyUser(agencyId, userId, updateData);
                    onSubmitComplete(true, 'Usuário atualizado com sucesso!');
                } else {
                    await createAgencyUser(agencyId, formData);
                    onSubmitComplete(true, 'Usuário criado com sucesso!');
                }
                navigate('/usuarios');
            } catch (err) {
                setSnackbarMessage(err.message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                onSubmitComplete(false, err.message);
            } finally {
                setLoading(false);
            }
        }
    };

    const actionTranslations = {
        view: 'Visualizar',
        create: 'Criar',
        edit: 'Editar',
        cancel: 'Cancelar',
        delete: 'Excluir',
    };

    const renderPermissions = () => (
        <TableContainer component={Paper} sx={{ width: '100%' }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>Módulo</TableCell>
                        {Object.entries(actionTranslations).map(([key, label]) => (
                            <TableCell key={key} align="center" sx={{ fontWeight: 'bold' }}>
                                {label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(PERMISSIONS)
                        .filter(([key]) => key !== 'SYSTEM')
                        .map(([moduleKey, moduleData]) => {
                            const modulePermission = formData.permissions[moduleData.key];

                            if (!modulePermission) return null;

                            return (
                                <TableRow key={moduleKey}>
                                    <TableCell component="th" scope="row">
                                        <Tooltip title={moduleData.description} placement="right">
                                            <Typography variant="body2">
                                                {moduleData.label}
                                            </Typography>
                                        </Tooltip>
                                    </TableCell>
                                    {Object.keys(actionTranslations).map((actionKey) => {
                                        // Only render checkbox if the action exists in the module's actions
                                        const hasAction = moduleData.actions.hasOwnProperty(actionKey.toUpperCase());
                                        return (
                                            <TableCell key={actionKey} align="center">
                                                {hasAction && (
                                                    <Checkbox
                                                        checked={modulePermission.actions[actionKey] || false}
                                                        onChange={handlePermissionChange(moduleData.key, actionKey)}
                                                        size="small"
                                                    />
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const showSnackbar = (message, severity = 'success') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    if (initialLoading) {
        return (
            <TravelLoading />
        );
    }

    return (
        <Box sx={{ p: 2 }}>
            {/* Header */}
            <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
                <IconButton onClick={() => navigate('/usuarios')} sx={{ p: 1 }}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6" component="h1">
                    {isEditing ? 'Editar Usuário' : 'Novo Usuário'}
                </Typography>
            </Box>

            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    {/* Dados Básicos */}
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Dados Básicos
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Nome Completo"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                            error={!validations.name}
                                            helperText={!validations.name && "Nome deve ter pelo menos 3 caracteres"}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="E-mail"
                                            name="email"
                                            type="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            error={!validations.email}
                                            helperText={!validations.email && "Email inválido"}
                                            required
                                            disabled={isEditing}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Telefone"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handlePhoneChange}
                                            error={!validations.phone}
                                            helperText={!validations.phone && "Telefone inválido"}
                                            placeholder="(99) 99999-9999"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth required error={!validations.role}>
                                            <InputLabel>Cargo</InputLabel>
                                            <Select
                                                name="role"
                                                value={formData.role}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                {Object.values(ROLES)
                                                    .filter(role => role.key !== 'owner')
                                                    .map((role) => (
                                                        <MenuItem key={role.key} value={role.key}>
                                                            <Box>
                                                                <Typography variant="subtitle2" component="span">
                                                                    {role.label}
                                                                </Typography>
                                                                <Typography variant="body2" color="textSecondary" sx={{ fontSize: '0.8rem' }}>
                                                                    {role.description}
                                                                </Typography>
                                                            </Box>
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                            {!validations.role && (
                                                <FormHelperText>Selecione um cargo</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Senha */}
                    {!isEditing && (
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Senha
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Senha"
                                                name="password"
                                                type="password"
                                                value={formData.password}
                                                onChange={handleInputChange}
                                                error={!validations.password}
                                                helperText={!validations.password && "A senha deve ter pelo menos 6 caracteres, uma letra maiúscula, uma minúscula e um número"}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Confirmar Senha"
                                                name="confirmPassword"
                                                type="password"
                                                value={formData.confirmPassword}
                                                onChange={handleInputChange}
                                                error={!validations.confirmPassword}
                                                helperText={!validations.confirmPassword && "As senhas não coincidem"}
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                    {/* Permissões */}
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                                    <Typography variant="h6">
                                        Permissões de Acesso
                                    </Typography>
                                    <Tooltip title="Configure as permissões de cada módulo">
                                        <InfoIcon fontSize="small" color="action" />
                                    </Tooltip>
                                </Box>
                                {!validations.permissions && (
                                    <Alert severity="error" sx={{ mb: 2 }}>
                                        Selecione pelo menos uma permissão
                                    </Alert>
                                )}
                                {renderPermissions()}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                {/* Botões de Ação */}
                <Box sx={{ mt: 3, display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                    <Button
                        variant="outlined"
                        onClick={() => navigate('/usuarios')}
                        disabled={loading}
                        sx={{ borderRadius: '50px' }}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={loading}
                        startIcon={loading && <CircularProgress size={20} />}
                        sx={{ borderRadius: '50px' }}
                    >
                        {isEditing ? 'Atualizar' : 'Criar'} Usuário
                    </Button>
                </Box>
            </form>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default UserFormPage;