import { db, auth } from '../../../firebaseConfig';
import { collection, doc, addDoc, getDocs, updateDoc, query, where, getDoc, writeBatch } from 'firebase/firestore';
import { formatCPF } from '../../../shared/utils/utils';

const getAgencyRef = async () => {
    const user = auth.currentUser;
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    return doc(db, 'agencies', userDoc.data().agencyId);
};

const createMetadata = (existingMetadata = {}) => {
    const timestamp = new Date().toISOString();
    return {
        createdAt: existingMetadata.createdAt || timestamp,
        updatedAt: timestamp,
        deletedAt: existingMetadata.deletedAt || null
    };
};

export const addHotel = async (travelId, hotelData) => {
    try {
        const agencyRef = await getAgencyRef();
        const hotelsRef = collection(agencyRef, 'hotels');
        const timestamp = new Date().toISOString();

        const hotelWithMetadata = {
            ...hotelData,
            travelId,
            estaAtivo: true,
            metadata: {
                createdAt: timestamp,
                updatedAt: timestamp,
                deletedAt: null
            }
        };

        const hotelDoc = await addDoc(hotelsRef, hotelWithMetadata);
        return { id: hotelDoc.id, ...hotelWithMetadata };
    } catch (error) {
        console.error('Error adding hotel:', error);
        throw error;
    }
};

export const updateHotel = async (hotelId, hotelData) => {
    try {
        const agencyRef = await getAgencyRef();
        const hotelRef = doc(agencyRef, 'hotels', hotelId);
        const hotelDoc = await getDoc(hotelRef);

        const hotelWithMetadata = {
            ...hotelData,
            metadata: createMetadata(hotelDoc.data()?.metadata)
        };

        await updateDoc(hotelRef, hotelWithMetadata);
        return { id: hotelId, ...hotelWithMetadata };
    } catch (error) {
        console.error('Erro ao atualizar hotel:', error);
        throw error;
    }
};

export const deleteHotel = async (hotelId) => {
    try {
        const agencyRef = await getAgencyRef();
        const batch = writeBatch(db);

        // Delete the hotel document
        const hotelRef = doc(agencyRef, 'hotels', hotelId);
        batch.delete(hotelRef);

        // Delete associated rooms
        const roomsRef = collection(agencyRef, 'rooms');
        const roomsQuery = query(roomsRef, where('hotelId', '==', hotelId));
        const roomsSnapshot = await getDocs(roomsQuery);

        roomsSnapshot.forEach(doc => {
            batch.delete(doc.ref);
        });

        await batch.commit();
        return hotelId;
    } catch (error) {
        console.error('Error deleting hotel:', error);
        throw error;
    }
};

export const addRoom = async (hotelId, roomData) => {
    try {
        const agencyRef = await getAgencyRef();
        const roomsRef = collection(agencyRef, 'rooms');

        const roomWithMetadata = {
            ...roomData,
            hotelId,
            occupiedBeds: 0,
            estaAtivo: true,
            price: roomData.price || 0,
            metadata: createMetadata()
        };

        const roomDoc = await addDoc(roomsRef, roomWithMetadata);
        return { id: roomDoc.id, ...roomWithMetadata };
    } catch (error) {
        console.error('Error adding room:', error);
        throw error;
    }
};

export const updateRoom = async (roomId, roomData) => {
    try {
        const agencyRef = await getAgencyRef();
        const roomRef = doc(agencyRef, 'rooms', roomId);
        const roomDoc = await getDoc(roomRef);

        const updatedRoom = {
            ...roomData,
            metadata: createMetadata(roomDoc.data()?.metadata)
        };

        await updateDoc(roomRef, updatedRoom);
        return { id: roomId, ...updatedRoom };
    } catch (error) {
        console.error('Error updating room:', error);
        throw error;
    }
};

export const deleteRoom = async (roomId) => {
    try {
        const agencyRef = await getAgencyRef();
        const roomRef = doc(agencyRef, 'rooms', roomId);
        await deleteDoc(roomRef);
        return roomId;
    } catch (error) {
        console.error('Error deleting room:', error);
        throw error;
    }
};

export const allocatePassenger = async (roomId, passengerIds, reservationIds) => {
    try {
        const agencyRef = await getAgencyRef();
        const allocationsRef = collection(agencyRef, 'accommodationAllocations');

        // Ensure passengerIds is an array
        const passengers = Array.isArray(passengerIds) ? passengerIds : [passengerIds];
        const reservations = Array.isArray(reservationIds) ? reservationIds : [reservationIds];

        // Validate inputs
        if (passengers.length !== reservations.length) {
            throw new Error('Número de passageiros e reservas não correspondem');
        }

        // Check if any passenger is already allocated
        const existingAllocationsQuery = query(
            allocationsRef,
            where('passengerId', 'in', passengers),
            where('status', '==', 'allocated')
        );
        const existingAllocationsSnap = await getDocs(existingAllocationsQuery);

        if (!existingAllocationsSnap.empty) {
            throw new Error('Um ou mais passageiros já estão alocados em outro quarto');
        }

        const roomRef = doc(agencyRef, 'rooms', roomId);
        const roomDoc = await getDoc(roomRef);

        if (!roomDoc.exists()) {
            throw new Error('Quarto não encontrado');
        }

        const roomData = roomDoc.data();

        // Validate room capacity
        const currentAllocationsQuery = query(
            allocationsRef,
            where('roomId', '==', roomId),
            where('status', '==', 'allocated')
        );
        const currentAllocationsSnap = await getDocs(currentAllocationsQuery);
        const currentOccupiedBeds = currentAllocationsSnap.size;

        if (currentOccupiedBeds + passengers.length > roomData.totalBeds) {
            throw new Error('Capacidade do quarto excedida');
        }

        // Validate reservations
        const batch = writeBatch(db);

        for (let i = 0; i < passengers.length; i++) {
            const passengerId = passengers[i];
            const reservationId = reservations[i];

            const reservationRef = doc(agencyRef, 'reservations', reservationId);
            const reservationDoc = await getDoc(reservationRef);

            if (!reservationDoc.exists() || reservationDoc.data().status === 'Cancelada') {
                throw new Error(`Reserva ${reservationId} não disponível ou cancelada`);
            }

            const newAllocationRef = doc(allocationsRef);
            batch.set(newAllocationRef, {
                roomId,
                passengerId,
                reservationId,
                checkInDate: null,
                checkOutDate: null,
                status: 'allocated',
                metadata: createMetadata()
            });
        }

        // Update room with total occupied beds
        batch.update(roomRef, {
            occupiedBeds: currentOccupiedBeds + passengers.length,
            metadata: createMetadata(roomData.metadata)
        });

        await batch.commit();
        return passengers.map((_, index) => `Allocation ${index + 1}`);
    } catch (error) {
        console.error('Erro ao alocar passageiros:', error);
        throw error;
    }
};

export const deallocatePassenger = async (roomId, allocationId) => {
    try {
        const agencyRef = await getAgencyRef();
        const batch = writeBatch(db);

        const roomRef = doc(agencyRef, 'rooms', roomId);
        const roomDoc = await getDoc(roomRef);

        if (!roomDoc.exists()) {
            throw new Error('Quarto não encontrado');
        }

        const roomData = roomDoc.data();
        batch.update(roomRef, {
            occupiedBeds: Math.max(0, roomData.occupiedBeds - 1),
            metadata: createMetadata(roomData.metadata)
        });

        const allocationRef = doc(agencyRef, 'accommodationAllocations', allocationId);
        batch.delete(allocationRef);

        await batch.commit();
    } catch (error) {
        console.error('Erro ao remover alocação:', error);
        throw error;
    }
};

export const getHotelsByTravelId = async (travelId) => {
    try {
        const agencyRef = await getAgencyRef();
        const hotelsRef = collection(agencyRef, 'hotels');
        const q = query(
            hotelsRef,
            where('travelId', '==', travelId),
            where('estaAtivo', '==', true)
        );

        const snapshot = await getDocs(q);
        const hotels = [];

        for (const hotelDoc of snapshot.docs) {
            const hotel = { id: hotelDoc.id, ...hotelDoc.data() };

            // Get rooms for each hotel
            const roomsRef = collection(agencyRef, 'rooms');
            const roomsQuery = query(
                roomsRef,
                where('hotelId', '==', hotelDoc.id),
                where('estaAtivo', '==', true)
            );
            const roomsSnapshot = await getDocs(roomsQuery);

            hotel.rooms = roomsSnapshot.docs.map(roomDoc => ({
                id: roomDoc.id,
                ...roomDoc.data()
            }));

            hotels.push(hotel);
        }

        return hotels;
    } catch (error) {
        console.error('Error getting hotels:', error);
        throw error;
    }
};

export const getAvailablePassengers = async (travelId) => {
    try {
        const agencyRef = await getAgencyRef();

        // Get all current allocations
        const allocationsRef = collection(agencyRef, 'accommodationAllocations');
        const allocationsQuery = query(allocationsRef, where('status', '==', 'allocated'));
        const [allocationsSnapshot, reservations] = await Promise.all([
            getDocs(allocationsQuery),
            getReservationsByTravelId(travelId)
        ]);

        // Filter out allocated passengers
        const allocatedPassengerIds = new Set(
            allocationsSnapshot.docs.map(doc => doc.data().passengerId)
        );

        const availablePassengers = reservations
            .filter(reservation =>
                !allocatedPassengerIds.has(reservation.passengerId) &&
                reservation.status !== 'Cancelada' &&
                reservation.passenger
            )
            .map(reservation => ({
                id: reservation.passengerId,
                reservationId: reservation.id,
                nome: reservation.passenger?.nome || 'Nome não disponível',
                cpf: reservation.passenger?.cpf,
                rg: reservation.passenger?.rg,
                passaporte: reservation.passenger?.passaporte,
                estrangeiro: reservation.passenger?.estrangeiro,
                allReservations: []
            }));

        // Group by passenger ID
        const groupedPassengers = availablePassengers.reduce((acc, passenger) => {
            if (!acc[passenger.id]) {
                acc[passenger.id] = {
                    ...passenger,
                    allReservations: [{ id: passenger.reservationId }]
                };
            } else {
                acc[passenger.id].allReservations.push({ id: passenger.reservationId });
            }
            return acc;
        }, {});

        return Object.values(groupedPassengers);
    } catch (error) {
        console.error('Erro ao buscar passageiros disponíveis:', error);
        throw error;
    }
};

export const getRoomAllocations = async (roomId) => {
    try {
        const agencyRef = await getAgencyRef();
        const allocationsRef = collection(agencyRef, 'accommodationAllocations');
        const q = query(allocationsRef, where('roomId', '==', roomId));
        const snapshot = await getDocs(q);
        const allocations = [];

        for (const allocDoc of snapshot.docs) {
            const allocation = { id: allocDoc.id, ...allocDoc.data() };

            // Check reservation status
            const reservationRef = doc(agencyRef, 'reservations', allocation.reservationId);
            const [reservationSnap, passengerSnap] = await Promise.all([
                getDoc(reservationRef),
                getDoc(doc(agencyRef, 'passengers', allocation.passengerId))
            ]);

            // If reservation is cancelled, deallocate passenger
            if (reservationSnap.exists() && reservationSnap.data().status === 'Cancelada') {
                await deallocatePassenger(roomId, allocation.id);
                continue;
            }

            if (passengerSnap.exists()) {
                allocation.passenger = {
                    id: allocation.passengerId,
                    ...passengerSnap.data()
                };
            }
            allocations.push(allocation);
        }

        return allocations;
    } catch (error) {
        console.error('Erro ao buscar alocações:', error);
        throw error;
    }
};

export const getReservationsByTravelId = async (travelId) => {
    try {
        const agencyRef = await getAgencyRef();
        const reservationsRef = collection(agencyRef, 'reservations');
        const reservationsQuery = query(
            reservationsRef,
            where('travelId', '==', travelId),
            where('status', '!=', 'Cancelada')
        );

        const reservationsSnapshot = await getDocs(reservationsQuery);
        const allReservations = [];

        for (const reservationDoc of reservationsSnapshot.docs) {
            const reservationData = reservationDoc.data();

            const [orderSnap, passengerSnap] = await Promise.all([
                getDoc(doc(agencyRef, 'orders', reservationData.orderId)),
                getDoc(doc(agencyRef, 'passengers', reservationData.passengerId))
            ]);

            const orderData = orderSnap.data();
            const passengerData = passengerSnap.exists() ? passengerSnap.data() : null;

            if (orderData?.status !== 'Cancelada') {
                allReservations.push({
                    id: reservationDoc.id,
                    ...reservationData,
                    orderId: reservationData.orderId,
                    detalhesPagamento: orderData?.detalhesPagamento,
                    status: reservationData.status || orderData?.status,
                    passenger: passengerData ? { id: passengerSnap.id, ...passengerData } : null
                });
            }
        }

        return allReservations;
    } catch (error) {
        console.error('Erro ao buscar reservas por ID de viagem:', error);
        throw error;
    }
};

export const getUnallocatedPassengersCount = async (travelId) => {
    try {
        const agencyRef = await getAgencyRef();
        const [allocationsSnapshot, reservationsSnapshot] = await Promise.all([
            getDocs(query(
                collection(agencyRef, 'accommodationAllocations'),
                where('status', '==', 'allocated')
            )),
            getDocs(query(
                collection(agencyRef, 'reservations'),
                where('travelId', '==', travelId),
                where('status', '!=', 'Cancelada')
            ))
        ]);

        const allocatedIds = new Set(allocationsSnapshot.docs.map(doc => doc.data().passengerId));
        const totalValidReservations = reservationsSnapshot.docs.filter(doc => {
            const data = doc.data();
            return data.passengerId && !allocatedIds.has(data.passengerId);
        }).length;

        return totalValidReservations;
    } catch (error) {
        console.error('Error getting unallocated count:', error);
        throw error;
    }
};

 export const getAllocatedPassengersCount = async (travelId) => {
    try {
        const agencyRef = await getAgencyRef();
        const allocationsSnapshot = await getDocs(query(
            collection(agencyRef, 'accommodationAllocations'),
            where('status', '==', 'allocated')
        ));

        return allocationsSnapshot.size;
    } catch (error) {
        console.error('Error getting allocated count:', error);
        throw error;
    }
};