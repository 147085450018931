import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Container, Box, Alert, Grid, CircularProgress, Collapse, Zoom, IconButton, InputAdornment, Avatar, ToggleButtonGroup, ToggleButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import RegisterButton from './components/RegisterButton';
import SubscriptionExpiredAlert from './components/SubscriptionExpiredAlert';

import LogoLight from '../../assets/travell-logo.svg';
import LogoDark from '../../assets/travell-logo-white.svg';
import { useAuth } from '../../core/contexts/useAuthState';
import { useTheme } from '@mui/material/styles';
import { loginWithEmailPassword, registerWithEmailPassword, resetPassword, logout, RegistrationType, checkEmailExists } from '../Login/services/LoginService';
import { formatTelefone } from '../../shared/utils/utils';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [nome, setNome] = useState('');
  const [erro, setErro] = useState('');
  const [telefone, setTelefone] = useState('');
  const [nomeEmpresa, setNomeEmpresa] = useState('');
  const [tipoRegistro, setTipoRegistro] = useState(RegistrationType.PERSONAL);
  const [successMessage, setSuccessMessage] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [erroContent, setErroContent] = useState(null);
  const theme = useTheme();
  const currentLogo = theme.palette.mode === 'dark' ? LogoDark : LogoLight;

  const { initialized } = useAuth();
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const validateFields = () => {
    if (isLogin) {
      if (!email || !senha) {
        setErro("Por favor, preencha todos os campos.");
        return false;
      }
    } else {
      if (!email || !senha || !nome || !telefone) {
        setErro("Por favor, preencha todos os campos.");
        return false;
      }

      if (tipoRegistro === RegistrationType.BUSINESS && !nomeEmpresa) {
        setErro("Por favor, informe o nome da empresa.");
        return false;
      }

      if (senha.length < 6) {
        setErro("A senha deve ter pelo menos 6 caracteres.");
        return false;
      }

      const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$/;
      if (!strongPasswordRegex.test(senha)) {
        setErro("A senha deve ter pelo menos uma letra maiúscula, uma letra minúscula e um número.");
        return false;
      }
    }

    return true;
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    setErro('');
    setSuccessMessage('');
    if (!validateFields()) return;
    setLoading(true);

    try {
      // Primeiro verificamos se o email já existe
      const emailExists = await checkEmailExists(email);
      if (emailExists) {
        setErro('Este email já está registrado. Por favor, utilize outro email ou faça login.');
        setLoading(false);
        return;
      }

      const registrationData = {
        email,
        password: senha,
        name: nome,
        phone: telefone,
        type: tipoRegistro,
        agencyName: tipoRegistro === RegistrationType.BUSINESS ? nomeEmpresa : nome
      };

      const { user } = await registerWithEmailPassword(registrationData);

      setSuccessMessage("Cadastro realizado com sucesso. Verifique seu e-mail para confirmar a conta.");
      setTimeout(() => {
        setIsLogin(true);
        setSuccessMessage("");
        // Limpar os campos
        setEmail('');
        setSenha('');
        setNome('');
        setTelefone('');
        setNomeEmpresa('');
        setTipoRegistro(RegistrationType.PERSONAL);
      }, 2000);
    } catch (error) {
      setErro(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setErro('');
    setErroContent(null);
    setSuccessMessage('');

    if (!validateFields()) return;
    setLoading(true);

    try {
      const result = await loginWithEmailPassword(email, senha);

      switch (result.status) {
        case 'unverified_email':
          setErro('Por favor, verifique seu e-mail antes de fazer login.');
          setErroContent(null);
          break;
        case 'pending_approval':
          setErro('Sua conta está pendente de aprovação. Em breve entraremos em contato.');
          setErroContent(null);
          break;
        case 'agency_inactive':
          setErro('Sua agência está inativa. Entre em contato com o suporte.');
          setErroContent(null);
          break;
        case 'subscription_expired':
          setErro('');
          setErroContent(<SubscriptionExpiredAlert />);
          break;
        case 'success':
          navigate('/');
          break;
        default:
          setErro('Erro desconhecido ao fazer login.');
          setErroContent(null);
      }
    } catch (error) {
      setErro(error.message);
      setErroContent(null);
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setErro("Por favor, insira seu email para redefinir a senha.");
      return;
    }
    try {
      await resetPassword(email);
      setErro("Link para redefinição de senha enviado. Verifique seu e-mail.");
    } catch (error) {
      setErro(error.message);
    }
  };

  return (
    <Box>
      <Container component="main" maxWidth="xs">
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 1, mb: 3 }}>
            <Typography component="h1" variant="h5">
              Bem-vindo ao
            </Typography>
            <img
              alt="Logo"
              src={currentLogo}
              style={{ width: '150px', height: 'auto' }}
            />
          </Box>
          <Typography variant="body1" align="center" sx={{ mb: 2 }}>
            {isLogin ? 'Por favor, faça login para continuar.' : 'Registre-se para começar.'}
          </Typography>
          <Zoom in timeout={500}>
            <Typography component="h1" variant="h5">
              {isLogin ? 'Entrar' : 'Registrar'}
            </Typography>
          </Zoom>
          <Box component="form" onSubmit={isLogin ? handleLogin : handleRegister} noValidate sx={{ mt: 1, width: '100%' }}>
            <Collapse in={isLogin} timeout={1000}>
              <div>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Endereço de Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="senha"
                  label="Senha"
                  type={showPassword ? 'text' : 'password'}
                  id="senha"
                  autoComplete="current-password"
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div style={{ textAlign: 'right' }}>
                  <Typography variant="caption" color="textSecondary">* Campos Obrigatórios</Typography>
                </div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, borderRadius: '50px' }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : 'Entrar'}
                </Button>
                <Collapse in={!!erro || !!erroContent} timeout={500}>
                  <Box sx={{ width: '100%', mb: 2 }}>
                    {erroContent || (erro && <Alert severity="error">{erro}</Alert>)}
                  </Box>
                </Collapse>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Button
                      onClick={handleForgotPassword}
                      fullWidth
                      variant="text"
                    >
                      Esqueceu sua senha?
                    </Button>
                  </Grid>
                  <RegisterButton
                    onClick={() => {
                      setIsLogin(false);
                      setErro('');
                      setSuccessMessage('');
                    }}
                  />
                </Grid>
              </div>
            </Collapse>
            <Collapse in={!isLogin} timeout={1000}>
              <div>
                {/* Tipo de Registro */}
                <Box sx={{ mb: 2 }}>
                  <ToggleButtonGroup
                    value={tipoRegistro}
                    exclusive
                    onChange={(e, newValue) => {
                      if (newValue !== null) {
                        setTipoRegistro(newValue);
                      }
                    }}
                    fullWidth
                    color="primary"
                  >
                    <ToggleButton value={RegistrationType.PERSONAL}>
                      Pessoa Física
                    </ToggleButton>
                    <ToggleButton value={RegistrationType.BUSINESS}>
                      Empresa
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="nome"
                  label="Nome Completo"
                  name="nome"
                  autoComplete="name"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="telefone"
                  label="Telefone"
                  name="telefone"
                  autoComplete="tel"
                  value={telefone}
                  onChange={(e) => setTelefone(formatTelefone(e.target.value))}
                />

                {tipoRegistro === RegistrationType.BUSINESS && (
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="nomeEmpresa"
                    label="Nome da Empresa"
                    name="nomeEmpresa"
                    value={nomeEmpresa}
                    onChange={(e) => setNomeEmpresa(e.target.value)}
                  />
                )}

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Endereço de Email"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="senha"
                  label="Senha"
                  type={showPassword ? 'text' : 'password'}
                  id="senha"
                  autoComplete="new-password"
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography variant="body2" color="textSecondary">
                  A senha deve ter pelo menos 6 caracteres, incluindo uma letra maiúscula, uma letra minúscula e um número.
                </Typography>
                <div style={{ textAlign: 'right' }}>
                  <Typography variant="caption" color="textSecondary">* Campos Obrigatórios</Typography>
                </div>
                {erro && (
                  <Collapse in={!!erro} timeout={500}>
                    <Box sx={{ width: '100%', mb: 2 }}>
                      <Alert severity="error">{erro}</Alert>
                    </Box>
                  </Collapse>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, borderRadius: '50px' }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : 'Registrar'}
                </Button>
                <Collapse in={!!successMessage} timeout={500}>
                  <Box sx={{ width: '100%', mb: 2 }}>
                    {successMessage && <Alert severity="success">{successMessage}</Alert>}
                  </Box>
                </Collapse>
                <Grid container spacing={2}>
                  <RegisterButton
                    onClick={() => {
                      setIsLogin(!isLogin);
                      setErro('');
                      setSuccessMessage('');
                      setEmail('');
                      setSenha('');
                      setNome('');
                      setTelefone('');
                      setNomeEmpresa('');
                      setTipoRegistro(RegistrationType.PERSONAL);
                    }}
                  />
                </Grid>
              </div>
            </Collapse>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default LoginPage;
