import React from 'react';
import { Grid, Button, Box, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

const RegisterButton = ({ onClick }) => {
    return (
        <Grid item xs={12}>
            <Box
                sx={{
                    position: 'relative',
                    mt: 2,
                    mb: 2,
                    '&:hover .trial-badge': {
                        transform: 'scale(1.05)',
                    }
                }}
            >
                <Box
                    className="trial-badge"
                    sx={{
                        position: 'absolute',
                        top: -22,
                        right: 0,
                        backgroundColor: 'primary.main',
                        color: 'white',
                        padding: '4px 12px',
                        borderRadius: '12px',
                        fontSize: '0.75rem',
                        fontWeight: 'bold',
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                        transition: 'transform 0.2s ease-in-out',
                        boxShadow: 2,
                    }}
                >
                    <StarIcon sx={{ fontSize: '0.875rem' }} />
                    <span>15 dias grátis</span>
                </Box>
                <Button
                    fullWidth
                    variant="outlined"
                    onClick={onClick}
                    sx={{
                        py: 1.5,
                        borderWidth: 2,
                        borderStyle: 'solid',
                        borderColor: 'primary.main',
                        '&:hover': {
                            borderWidth: 2,
                            backgroundColor: 'primary.main',
                            color: 'white',
                        },
                        transition: 'all 0.2s ease-in-out',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="button" sx={{ fontWeight: 'bold' }}>
                            Não tem uma conta? Registrar
                        </Typography>
                        <Typography variant="caption" sx={{ opacity: 0.9 }}>
                            Experimente nosso sistema sem compromisso
                        </Typography>
                    </Box>
                </Button>
            </Box>
        </Grid>
    );
};

export default RegisterButton;