import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    IconButton,
    Chip,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Snackbar,
    Alert
} from '@mui/material';
import { format, isAfter, addDays, parseISO } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';

import { usePermissions } from '../../../hooks/usePermissions';
import TravelLoading from '../../../shared/common/TravelLoading';
import TruncatedCell from '../../../shared/common/TruncatedCell';
import VehicleDocumentForm from '../../VehicleDocuments/VehicleDocumentForm';

import {
    getVehicleDocuments,
    addVehicleDocument,
    updateVehicleDocument,
    deleteVehicleDocument
} from '../../VehicleDocuments/services/VehicleDocumentsService';

const VehicleDocuments = ({ vehicleId }) => {
    const { checkPermission } = usePermissions();
    const canEdit = checkPermission('vehicles', 'edit');
    const canDelete = checkPermission('vehicles', 'delete');
    const canCreate = checkPermission('vehicles', 'create');

    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [editingDocument, setEditingDocument] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [deleteDialog, setDeleteDialog] = useState({
        open: false,
        documentId: null,
        documentName: ''
    });

    useEffect(() => {
        if (vehicleId) {
            fetchDocuments();
        }
    }, [vehicleId]);

    const handleSnackbar = (message, severity = 'success') => {
        setSnackbar({
            open: true,
            message,
            severity
        });
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({
            ...prev,
            open: false
        }));
    };

    const fetchDocuments = async () => {
        setLoading(true);
        try {
            const data = await getVehicleDocuments(vehicleId);
            setDocuments(data);
        } catch (error) {
            console.error('Erro ao buscar documentos:', error);
            handleSnackbar('Erro ao carregar documentos', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleOpenDialog = (document = null) => {
        setEditingDocument(document);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setEditingDocument(null);
    };

    const handleSubmit = async (formData) => {
        setLoading(true);
        try {
            const documentData = {
                ...formData,
                vehicleId,
                dataVencimento: parseISO(formData.dataVencimento).toISOString(),
            };

            if (editingDocument) {
                await updateVehicleDocument(editingDocument.id, documentData);
                handleSnackbar('Documento atualizado com sucesso!');
            } else {
                await addVehicleDocument(documentData);
                handleSnackbar('Documento cadastrado com sucesso!');
            }

            await fetchDocuments();
            handleCloseDialog();
        } catch (error) {
            console.error('Erro ao salvar documento:', error);
            handleSnackbar(
                `Erro ao ${editingDocument ? 'atualizar' : 'cadastrar'} documento`,
                'error'
            );
        } finally {
            setLoading(false);
        }
    };

    const handleOpenDeleteDialog = (document) => {
        setDeleteDialog({
            open: true,
            documentId: document.id,
            documentName: document.nome
        });
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialog({
            open: false,
            documentId: null,
            documentName: ''
        });
    };

    const handleConfirmDelete = async () => {
        setLoading(true);
        try {
            await deleteVehicleDocument(deleteDialog.documentId);
            handleSnackbar('Documento excluído com sucesso!');
            await fetchDocuments();
        } catch (error) {
            console.error('Erro ao excluir documento:', error);
            handleSnackbar('Erro ao excluir documento', 'error');
        } finally {
            setLoading(false);
            handleCloseDeleteDialog();
        }
    };

    const DeleteConfirmationDialog = ({
        open,
        onClose,
        onConfirm,
        title = 'Confirmar exclusão',
        message = 'Tem certeza que deseja excluir este item?'
    }) => {
        return (
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <Typography>{message}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="outlined" sx={{ borderRadius: '50px' }}>
                        Voltar
                    </Button>
                    <Button onClick={onConfirm} variant="contained" color="error" sx={{ borderRadius: '50px' }}>
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const getStatusChip = (dataVencimento) => {
        const hoje = new Date();
        const data = new Date(dataVencimento);
        const trintaDias = addDays(hoje, 30);

        if (isAfter(hoje, data)) {
            return <Chip label="Vencido" color="error" icon={<WarningIcon />} />;
        } else if (isAfter(trintaDias, data)) {
            return <Chip label="Próximo ao vencimento" color="warning" icon={<WarningIcon />} />;
        } else {
            return <Chip label="Regular" color="success" />;
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <TravelLoading />
            </Box>
        );
    }

    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h6">Documentos do Veículo</Typography>
                {canCreate && (
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => handleOpenDialog()}
                        sx={{
                            borderRadius: '50px',
                        }}
                    >
                        Novo Documento
                    </Button>
                )}
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>Número</TableCell>
                            <TableCell>Vencimento</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Observações</TableCell>
                            {(canEdit || canDelete) && <TableCell align="right">Ações</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documents.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    Nenhum documento cadastrado
                                </TableCell>
                            </TableRow>
                        ) : (
                            documents.map((doc) => (
                                <TableRow key={doc.id}>
                                    <TruncatedCell text={doc.nome} maxChars={30} />
                                    <TruncatedCell text={doc.numero} maxChars={20} />
                                    <TableCell>{format(new Date(doc.dataVencimento), 'dd/MM/yyyy')}</TableCell>
                                    <TableCell>{getStatusChip(doc.dataVencimento)}</TableCell>
                                    <TruncatedCell text={doc.observacoes} maxChars={40} />
                                    {(canEdit || canDelete) && (
                                        <TableCell align="right">
                                            <Stack direction="row" spacing={1} justifyContent="flex-end">
                                                {canEdit && (
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleOpenDialog(doc)}
                                                        color="primary"
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                )}
                                                {canDelete && (
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleOpenDeleteDialog(doc)}
                                                        color="error"
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                )}
                                            </Stack>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <DeleteConfirmationDialog
                open={deleteDialog.open}
                onClose={handleCloseDeleteDialog}
                onConfirm={handleConfirmDelete}
                title="Confirmar exclusão de documento"
                message={`Tem certeza que deseja excluir o documento "${deleteDialog.documentName}"? Esta ação não poderá ser desfeita.`}
            />

            <VehicleDocumentForm
                open={openDialog}
                onClose={handleCloseDialog}
                onSubmit={handleSubmit}
                initialData={editingDocument}
                loading={loading}
            />
            <Snackbar
                open={snackbar.open}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default VehicleDocuments;