import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormGroup, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const PDFOptionsDialog = ({ open, onClose, onGenerate }) => {
  // Mapeamento entre os nomes internos dos campos (dataKey) e os rótulos exibidos ao usuário
  const fieldLabels = {
    numeroAssento: "Número do Assento",
    passengerNome: "Nome do Passageiro",
    passengerDocumentos: "Documentos",
    dataNascimento: "Data de Nascimento",
    passengerIdade: "Idade",
    passengerEndereco: "Endereço",
    passengerTelefone: "Telefone",
    informacoesAdicionais: "Informações Adicionais",
  };

  const [selectedFields, setSelectedFields] = useState({
    numeroAssento: true,
    passengerNome: true,
    passengerDocumentos: true,
    dataNascimento: true,
    passengerIdade: false,
    passengerEndereco: true,
    passengerTelefone: true,
    informacoesAdicionais: true,
  });

  // Estado para critério de ordenação
  const [sortOrder, setSortOrder] = useState("numeroAssento");

  const handleFieldChange = (field) => {
    setSelectedFields((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Escolha os campos e a ordenação</DialogTitle>
      <DialogContent>
        <FormGroup>
          {Object.keys(selectedFields).map((field) => (
            <FormControlLabel
              key={field}
              control={
                <Checkbox
                  checked={selectedFields[field]}
                  onChange={() => handleFieldChange(field)}
                />
              }
              label={fieldLabels[field]} // Exibe o rótulo mapeado
            />
          ))}
        </FormGroup>

        {/* Seletor para critério de ordenação */}
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="sort-order-label">Ordenar por</InputLabel>
          <Select
            labelId="sort-order-label"
            value={sortOrder}
            onChange={handleSortOrderChange}
          >
            <MenuItem value="numeroAssento">Número do Assento</MenuItem>
            <MenuItem value="passengerNome">Nome do Passageiro</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" sx={{ borderRadius: '50px' }}>Voltar</Button>
        <Button
          onClick={() => onGenerate(selectedFields, sortOrder)} // Inclui o critério de ordenação
          variant="contained"
          color="primary"
          sx={{ borderRadius: '50px' }}
        >
          Gerar PDF
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PDFOptionsDialog;
