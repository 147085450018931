import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';

export const getAgencyType = async (userId) => {
  try {
    const agencyDoc = await getDoc(doc(db, 'agencies', userId));
    if (!agencyDoc.exists()) {
      return null;
    }
    return agencyDoc.data()?.metadata?.registrationType || 'personal';
  } catch (error) {
    console.error('Error fetching agency type:', error);
    return null;
  }
};

export const isBusinessAgency = async (userId) => {
  const type = await getAgencyType(userId);
  return type === 'business';
};