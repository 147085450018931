import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  MenuItem,
  Pagination,
  Collapse,
  InputAdornment,
  IconButton,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  CardContent,
  Tooltip,
  useTheme,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';

import ReceivablesList from './components/ReceivablesList';
import TravelLoading from '../../shared/common/TravelLoading';

import { getBankAccounts } from '../BankAccount/services/BankAccountService';
import { getPaginatedReceivables } from './services/ReceivablesService';
import { exportReceivablesToPDF } from './components/ReceivablesPDF';

const ReceivablesPage = () => {
  const theme = useTheme();
  const [receivables, setReceivables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [filters, setFilters] = useState({
    searchTerm: '',
    startDate: '',
    endDate: '',
    status: '',
    bankAccountId: '',
    sortBy: 'date'
  });
  const [tempSearchTerm, setTempSearchTerm] = useState('');

  const fetchReceivables = useCallback(async (newPage = 1) => {
    setLoading(true);
    try {
      setReceivables([]);

      const cleanFilters = Object.entries(filters).reduce((acc, [key, value]) => {
        if (value && value.trim() !== '') {
          acc[key] = value;
        }
        return acc;
      }, {});

      const result = await getPaginatedReceivables(
        15,
        null,
        cleanFilters,
        newPage
      );

      setReceivables(result.receivables);
      setTotalPages(result.totalPages);
      setCurrentPage(newPage);
    } catch (err) {
      console.error('Erro ao carregar dados:', err);
      setError('Erro ao carregar dados');
    } finally {
      setLoading(false);
    }
  }, [filters]);

  const fetchAllReceivables = async () => {
    try {
      setLoading(true);

      const cleanFilters = Object.entries(filters).reduce((acc, [key, value]) => {
        if (value && value.trim() !== '') {
          acc[key] = value;
        }
        return acc;
      }, {});

      const result = await getPaginatedReceivables(
        9999,
        null,
        cleanFilters,
        1,
        true
      );

      return result.receivables;
    } catch (error) {
      console.error('Erro ao buscar todos os registros:', error);
      throw error;
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchReceivables(1);
    }, 500);

    return () => clearTimeout(timer);
  }, [filters, fetchReceivables]);

  useEffect(() => {
    const loadBankAccounts = async () => {
      try {
        const accounts = await getBankAccounts();
        setBankAccounts(accounts);
      } catch (error) {
        console.error('Erro ao carregar contas bancárias:', error);
      }
    };

    loadBankAccounts();
  }, []);

  const handleSearch = () => {
    setFilters(prev => ({
      ...prev,
      searchTerm: tempSearchTerm.trim()
    }));
  };

  const clearSearch = () => {
    setTempSearchTerm('');
    setFilters(prev => ({
      ...prev,
      searchTerm: ''
    }));
  };

  const handleFilterChange = (name, value) => {
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePageChange = (event, newPage) => {
    if (newPage !== currentPage) {
      fetchReceivables(newPage);
    }
  };

  const handleExportToPDF = async () => {
    try {
      const allReceivables = await fetchAllReceivables();

      if (allReceivables.length === 0) {
        setError('Não há dados para exportar');
        return;
      }

      exportReceivablesToPDF(allReceivables);
    } catch (error) {
      setError('Erro ao exportar dados');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
          <Typography variant="h6" component="div">
            Contas a Receber
          </Typography>
          <Button
            variant="outlined"
            startIcon={<FilterListIcon />}
            onClick={() => setFiltersVisible(!filtersVisible)}
            sx={{ borderRadius: '50px' }}
          >
            {filtersVisible ? 'Ocultar Filtros' : 'Mostrar Filtros'}
          </Button>
        </Box>
        <Button
          variant="contained"
          startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <DownloadIcon />}
          onClick={handleExportToPDF}
          disabled={loading}
          sx={{ borderRadius: '50px' }}
        >
          {loading ? 'Exportando...' : 'Exportar PDF'}
        </Button>
      </Box>

      <Collapse in={filtersVisible}>
        <Paper sx={{ p: 2, mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Buscar"
                value={tempSearchTerm}
                onChange={(e) => setTempSearchTerm(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {tempSearchTerm && (
                        <IconButton
                          onClick={clearSearch}
                          size="small"
                          aria-label="Limpar busca"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={handleSearch}
                        size="small"
                        aria-label="Buscar"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                type="date"
                label="Data Inicial"
                value={filters.startDate}
                onChange={(e) => handleFilterChange('startDate', e.target.value)}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: filters.startDate && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleFilterChange('startDate', '')}
                        size="small"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                type="date"
                label="Data Final"
                value={filters.endDate}
                onChange={(e) => handleFilterChange('endDate', e.target.value)}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: filters.endDate && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleFilterChange('endDate', '')}
                        size="small"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel>Conta Bancária</InputLabel>
                <Select
                  value={filters.bankAccountId}
                  onChange={(e) => handleFilterChange('bankAccountId', e.target.value)}
                  label="Conta Bancária"
                >
                  <MenuItem value="">Todas</MenuItem>
                  {bankAccounts.map((account) => (
                    <MenuItem key={account.id} value={account.id}>
                      {account.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  value={filters.status}
                  onChange={(e) => handleFilterChange('status', e.target.value)}
                  label="Status"
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="Pago">Pagos</MenuItem>
                  <MenuItem value="Pendente">Pendentes</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </Collapse>

      {error && (
        <Snackbar open autoHideDuration={6000} onClose={() => setError('')}>
          <Alert onClose={() => setError('')} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}

      {loading && receivables.length === 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <TravelLoading disableAlignItems />
        </Box>
      ) : (
        <>
          <ReceivablesList receivables={receivables} />

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              disabled={loading}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ReceivablesPage;