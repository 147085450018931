import React, { useState } from 'react';
import {
    Card, CardContent, Avatar, Box, Typography, Divider, IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, CircularProgress, TextField, InputAdornment
} from '@mui/material';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useMasterPassword } from '../../../shared/hooks/useMasterPassword';

const LayoutCard = ({ layout, onEdit, onDelete, canEdit = false, canDelete = false }) => {
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [masterPassword, setMasterPassword] = useState('');
    const {
        isVerifying,
        error: masterPasswordError,
        clearError,
        masterPasswordActive,
        showPassword,
        togglePasswordVisibility,
        verifyMasterPassword
    } = useMasterPassword();

    const handleDelete = async () => {
        if (!canDelete) return;

        setLoading(true);
        try {
            if (masterPasswordActive) {
                const isValid = await verifyMasterPassword(masterPassword);
                if (!isValid) {
                    setLoading(false);
                    return;
                }
            }
            await onDelete(layout.id);
            handleCloseDialog();
        } catch (err) {
            console.error("Erro ao excluir layout:", err);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseDialog = () => {
        setConfirmDeleteOpen(false);
        setMasterPassword('');
        clearError();
    };

    const handleCardClick = () => {
        if (canEdit && onEdit) {
            onEdit();
        }
    };

    return (
        <>
            <Card
                onClick={handleCardClick}
                sx={{
                    cursor: canEdit ? 'pointer' : 'default',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    position: 'relative',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    '&:hover': canEdit ? {
                        transform: 'scale(1.02)',
                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)'
                    } : {},
                    mb: 2,
                    borderRadius: 2,
                }}
            >
                <CardContent sx={{ padding: '8px !important' }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        mb: 1,
                        flexWrap: 'nowrap',
                        minWidth: 0
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            minWidth: 0,
                            flex: 1
                        }}>
                            <Avatar sx={{
                                bgcolor: 'primary.main',
                                mr: 1,
                                width: 28,
                                height: 28,
                                flexShrink: 0
                            }}>
                                <DirectionsBusIcon fontSize="small" />
                            </Avatar>
                            <Box sx={{ minWidth: 0 }}>
                                <Tooltip title={layout.name} arrow placement="top" disableHoverListener={layout.name.length <= 20}>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: 'primary.main',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: { xs: '120px', sm: '150px', md: '200px' },
                                        }}
                                    >
                                        {layout.name || 'Sem nome'}
                                    </Typography>
                                </Tooltip>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    ID: {layout.id}
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                    {layout?.metadata?.createdAt && (
                                        `Criado em: ${new Date(layout.metadata.createdAt).toLocaleDateString()}`
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            ml: 1,
                            flexShrink: 0
                        }}>
                            {canEdit && (
                                <Tooltip title="Editar">
                                    <IconButton
                                        size="small"
                                        edge="end"
                                        aria-label="edit"
                                        onClick={(e) => { e.stopPropagation(); onEdit(); }}
                                        sx={{ mr: 0.5 }}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {canDelete && (
                                <Tooltip title="Excluir">
                                    <IconButton
                                        size="small"
                                        edge="end"
                                        aria-label="delete"
                                        onClick={(e) => { e.stopPropagation(); setConfirmDeleteOpen(true); }}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                    <Divider sx={{ mb: 1 }} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <EventSeatIcon fontSize="small" />
                            <Typography variant="caption">Assentos Totais: {layout.assentosTotais}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <DoubleArrowIcon fontSize="small" />
                            <Typography variant="caption">Dois Andares: {layout.doisAndares ? 'Sim' : 'Não'}</Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>

            <Dialog open={confirmDeleteOpen} onClose={handleCloseDialog}>
                <DialogTitle>Confirmar Exclusão</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Tem certeza que deseja excluir o layout <strong>{layout.name || 'Sem nome'}</strong> com ID <strong>{layout.id}</strong>?
                        Este layout tem <strong>{layout.assentosTotais}</strong> assentos e é {layout.doisAndares ? 'um layout de dois andares' : 'um layout de um andar'}.
                        Veículos que tenham esse layout atribuído poderão ficar sem layout. Esta ação não pode ser desfeita.
                    </DialogContentText>
                    {masterPasswordActive && (
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Senha Master"
                            type={showPassword ? 'text' : 'password'}
                            value={masterPassword}
                            onChange={(e) => setMasterPassword(e.target.value)}
                            error={!!masterPasswordError}
                            helperText={masterPasswordError}
                            InputProps={{
                                autoComplete: 'new-password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={togglePasswordVisibility}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            autoComplete="off"
                            disabled={loading || isVerifying}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDialog}
                        variant="outlined"
                        disabled={loading || isVerifying}
                        sx={{ borderRadius: '50px' }}
                    >
                        Voltar
                    </Button>
                    <Button
                        onClick={handleDelete}
                        variant="contained"
                        color="error"
                        disabled={(masterPasswordActive && !masterPassword) || loading || isVerifying}
                        sx={{ color: 'white', borderRadius: '50px' }}
                    >
                        {loading || isVerifying ? <CircularProgress size={24} /> : 'Excluir'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default LayoutCard;