import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Box,
    Divider,
    Alert,
    TextField,
    Tooltip
} from '@mui/material';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Warning as WarningIcon } from '@mui/icons-material';

import { allocatePassenger, getRoomAllocations, deallocatePassenger, getAvailablePassengers } from '../services/AccommodationService';
import { formatCPF } from '../../../shared/utils/utils';

const RoomAllocationDialog = ({ open, onClose, room, travelId, onSubmit }) => {
    const [loading, setLoading] = useState(false);
    const [allocations, setAllocations] = useState([]);
    const [availablePassengers, setAvailablePassengers] = useState([]);
    const [selectedPassengers, setSelectedPassengers] = useState([]);
    const [error, setError] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (open && room) {
            fetchData();
        }
    }, [open, room, travelId]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const [currentAllocations, availablePassengers] = await Promise.all([
                getRoomAllocations(room.id),
                getAvailablePassengers(travelId)
            ]);

            setAllocations(currentAllocations);
            setAvailablePassengers(availablePassengers);
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handlePassengerSelect = (passenger) => {
        if (selectedPassengers.length + allocations.length >= room.totalBeds) {
            setError('Quarto está com capacidade máxima');
            return;
        }
        if (passenger.allReservations.length > 1) {
            const activeReservation = passenger.allReservations.find(r => r.status !== 'Cancelada');
            if (activeReservation) {
                passenger.reservationId = activeReservation.id;
            }
        }
        setSelectedPassengers(prev => [...prev, passenger]);
        setAvailablePassengers(prev => prev.filter(p => p.id !== passenger.id));
        setError('');
    };

    const handlePassengerRemove = (passenger) => {
        setSelectedPassengers(prev => prev.filter(p => p.id !== passenger.id));
        setAvailablePassengers(prev => [...prev, passenger]);
    };

    const handleAllocationRemove = async (allocation) => {
        setLoading(true);
        try {
            await deallocatePassenger(room.id, allocation.id);
            await fetchData();
        } catch (error) {
            console.error('Erro ao remover alocação:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const passengerIds = selectedPassengers.map(passenger => passenger.id);
            const reservationIds = selectedPassengers.map(passenger => passenger.reservationId);

            await allocatePassenger(room.id, passengerIds, reservationIds);

            onClose();
            onSubmit();
        } catch (error) {
            console.error('Erro ao alocar passageiros:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const filterPassengers = (passengers) => {
        return passengers.filter(passenger =>
            passenger?.nome?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            passenger?.cpf?.includes(searchTerm) ||
            passenger?.rg?.includes(searchTerm) ||
            passenger?.passaporte?.includes(searchTerm)
        );
    };

    const renderPassengerDetails = (passenger) => (
        <ListItemText
            primary={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <Typography variant="body2">
                        {passenger?.nome || passenger?.name || 'Nome não disponível'}
                    </Typography>
                    {passenger?.menorDeIdade && (
                        <Tooltip title="Menor de idade" placement="top">
                            <WarningIcon sx={{ fontSize: 16, color: 'warning.main' }} />
                        </Tooltip>
                    )}
                </Box>
            }
            secondary={
                <Box component="span" sx={{ display: 'flex', flexDirection: 'column' }}>
                    {passenger?.cpf && (
                        <Typography variant="body2" component="span">
                            CPF: {formatCPF(passenger.cpf)}
                        </Typography>
                    )}
                    <Typography variant="body2" component="span">
                        {passenger?.estrangeiro ?
                            `Passaporte: ${passenger?.passaporte || 'Não informado'}` :
                            `RG: ${passenger?.rg || 'Não informado'}`}
                    </Typography>
                </Box>
            }
        />
    );

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Alocação de Quarto - {room?.name}
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Capacidade: {allocations.length + selectedPassengers.length}/{room?.totalBeds} hóspedes
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                Observações: {room?.notes}
                            </Typography>
                        </Box>
                        <Divider />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>
                            Alocações Atuais ({allocations.length + selectedPassengers.length})
                        </Typography>
                        <List>
                            {allocations.map((allocation) => (
                                <ListItem key={allocation.id} dense>
                                    {renderPassengerDetails(allocation.passenger)}
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge="end"
                                            onClick={() => handleAllocationRemove(allocation)}
                                            color="error"
                                            disabled={loading}
                                            size="small"
                                        >
                                            <PersonRemoveIcon fontSize="small" />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                            {selectedPassengers.map((passenger) => (
                                <ListItem key={passenger.id} dense>
                                    {renderPassengerDetails(passenger)}
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge="end"
                                            onClick={() => handlePassengerRemove(passenger)}
                                            color="error"
                                            size="small"
                                        >
                                            <PersonRemoveIcon fontSize="small" />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>
                            Passageiros Disponíveis ({availablePassengers.length})
                        </Typography>
                        <TextField
                            fullWidth
                            size="small"
                            placeholder="Buscar por nome, CPF, RG ou passaporte..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            sx={{ mb: 2 }}
                        />
                        <List>
                            {filterPassengers(availablePassengers).map((passenger) => (
                                <ListItem
                                    key={passenger.id}
                                    button
                                    dense
                                    onClick={() => handlePassengerSelect(passenger)}
                                    disabled={selectedPassengers.length + allocations.length >= room?.totalBeds}
                                >
                                    {renderPassengerDetails(passenger)}
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge="end"
                                            onClick={() => handlePassengerSelect(passenger)}
                                            disabled={selectedPassengers.length + allocations.length >= room?.totalBeds}
                                            color="primary"
                                            size="small"
                                        >
                                            <PersonAddIcon fontSize="small" />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                            {filterPassengers(availablePassengers).length === 0 && (
                                <Typography color="textSecondary" variant="body2">
                                    Não há passageiros disponíveis para alocar
                                </Typography>
                            )}
                        </List>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
                <Button
                    onClick={onClose}
                    variant="outlined"
                    disabled={loading}
                    sx={{ borderRadius: '50px' }}
                >
                    Voltar
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={loading || selectedPassengers.length === 0}
                    sx={{ borderRadius: '50px' }}
                >
                    {loading ? <CircularProgress size={24} /> : 'Salvar Alocações'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RoomAllocationDialog;