// src/hooks/usePermissions.js
import { useCallback } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useAuth } from '../core/contexts/useAuthState';
import { PERMISSIONS, ROLES, PUBLIC_ROUTES, BUSINESS_ROUTES } from '../config/permissions';

export const usePermissions = () => {
    const { currentUser } = useAuth();
    const location = useLocation();

    const checkPermission = useCallback((resource, action) => {
        if (!currentUser) return false;

        if (currentUser.role === ROLES.OWNER.key) return true;

        if (resource === PERMISSIONS.SYSTEM.key) return true;

        const userPermissions = currentUser.permissions || {};

        const modulePermissions = userPermissions[resource];
        if (!modulePermissions) return false;

        return modulePermissions.actions &&
            (modulePermissions.actions[action] === true);
    }, [currentUser]);

    const canAccessCurrentRoute = useCallback(() => {
        if (PUBLIC_ROUTES.includes(location.pathname)) return true;
        if (!currentUser) return false;

        if (BUSINESS_ROUTES.includes(location.pathname)) {
            return currentUser.userData?.agency?.metadata?.registrationType === 'business';
        }

        const match = Object.values(PERMISSIONS).find(module =>
            module.routes.find(route => matchPath(route.path, location.pathname))
        );

        if (!match) return false;

        const matchedRoute = match.routes.find(route => matchPath(route.path, location.pathname));
        if (!matchedRoute) return false;

        if (matchedRoute.isBasic) return true;

        const isTravelRoute = location.pathname.startsWith('/viagens/') && location.pathname !== '/viagens';
        if (isTravelRoute) {
            return (
                checkPermission('travels', 'view') ||
                checkPermission('travels_freight', 'view')
            );
        }

        return matchedRoute.requiredActions.every(action =>
            checkPermission(match.key, action)
        );
    }, [location.pathname, currentUser, checkPermission]);

    const getFirstAccessibleRoute = useCallback(() => {
        for (const module of Object.values(PERMISSIONS)) {
            for (const route of module.routes) {
                if (route.requiredActions.every(action =>
                    checkPermission(module.key, action))
                ) {
                    return route.path;
                }
            }
        }
        return '/login';
    }, [checkPermission]);

    return {
        checkPermission,
        canAccessCurrentRoute,
        getFirstAccessibleRoute
    };
};