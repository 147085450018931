import * as React from 'react';
import { styled, alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import TermsAndConditionsModal from './TermsAndConditionsModal';
import LogoLight from '../../../assets/logo-travell-combr.svg';
import LogoDark from '../../../assets/logo-travell-combr-white.svg';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
      {'Copyright © '}
      <Link color="text.secondary" href="https://travell.com.br">
        travell
      </Link>
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
  }
};

export default function Footer() {
  const theme = useTheme(); // Access the current theme
  const currentLogo = theme.palette.mode === 'dark' ? LogoDark : LogoLight;

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'row', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '50%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
            <img
              src={currentLogo}
              alt="travell Logo"
              style={{
                width: '240px',
              }}
            />
          </Box>
          <Box sx={{ mt: 0, display: 'flex', gap: 2 }}>
            <Link
              href="https://wa.me/554288333458?text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20seus%20servi%C3%A7os%21"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
            >
              <WhatsAppIcon fontSize="large" />
            </Link>
            <Link
              href="https://www.instagram.com/travell.sys/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
            >
              <InstagramIcon fontSize="large" />
            </Link>
          </Box>
        </Box>

        {/* Product Links Section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            minWidth: { xs: '10%', sm: '40%' },
            textAlign: 'left',
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            Produto
          </Typography>
          <Link
            color="text.secondary"
            variant="body2"
            onClick={() => scrollToSection('sobre-nos')}
            sx={{ cursor: 'pointer' }}
          >
            Sobre nós
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            onClick={() => scrollToSection('clientes')}
            sx={{ cursor: 'pointer' }}
          >
            Clientes
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            onClick={() => scrollToSection('destaques')}
            sx={{ cursor: 'pointer' }}
          >
            Destaques
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            onClick={() => scrollToSection('contato')}
            sx={{ cursor: 'pointer' }}
          >
            Contato
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            onClick={() => scrollToSection('faq')}
            sx={{ cursor: 'pointer' }}
          >
            Perguntas Frequentes
          </Link>
        </Box>
      </Box>

      {/* Back to Top Button */}
      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => scrollToSection('hero')}
          sx={{
            borderRadius: '20px',
            minWidth: '150px',
          }}
        >
          Voltar ao Topo
        </Button>
      </Box>

      {/* Footer Links and Copyright */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <PrivacyPolicyModal />
          <TermsAndConditionsModal />
        </Box>
        <Copyright />
      </Box>

      {/* Illustration Credits */}
      <Box sx={{ mt: 2, width: '100%', textAlign: 'center' }}>
        <Typography variant="caption">
          <a href="https://storyset.com/business" target="_blank" rel="noopener noreferrer">
            Business illustrations by Storyset
          </a>
        </Typography>
      </Box>
    </Container>
  );
}
