import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatCPF, formatTelefone, formatDate, normalizeValue } from './utils';
import logo from '../../assets/logo-travell-combr.png';
import { getPassengerById } from '../../pages/Passenger/services/PassengerService';

// Configuration options for PDF styling
const pdfConfig = {
  colors: {
    primary: [25, 118, 210],
    secondary: [30, 30, 30],
    background: {
      light: [248, 248, 248],
      medium: [245, 245, 245],
      dark: [242, 242, 242]
    },
    borders: [220, 220, 220],
    text: {
      primary: [51, 51, 51],
      secondary: [102, 102, 102],
      alert: [220, 53, 69],
      info: [0, 102, 204],
      footer: [150, 150, 150]
    }
  },
  fonts: {
    sizes: {
      title: 16,
      subtitle: 13,
      body: 11,
      small: 10
    }
  },
  spacing: {
    margin: 15,
    padding: 10,
    lineHeight: 5,
    sectionGap: 8
  },
  sections: {
    width: {
      full: 186,
      half: 90
    },
    padding: {
      x: 12,
      y: 8
    }
  }
};

class PDFGenerator {
  constructor(doc) {
    this.doc = doc;
    this.currentY = 0;
  }

  addWrappedText(text, x, y, maxWidth, lineHeight) {
    const words = text.split(' ');
    let line = '';
    let lines = [];

    words.forEach((word) => {
      const testLine = line + word + ' ';
      const testWidth = this.doc.getTextWidth(testLine);

      if (testWidth > maxWidth) {
        lines.push(line.trim());
        line = word + ' ';
      } else {
        line = testLine;
      }
    });

    lines.push(line.trim());
    lines.forEach((line, index) => {
      this.doc.text(line, x, y + index * lineHeight);
    });

    return y + lines.length * lineHeight;
  }

  drawSectionBorder(startX, startY, width, height, bgColor = pdfConfig.colors.background.dark) {
    this.doc.setFillColor(...bgColor);
    this.doc.rect(startX, startY, width, height, 'F');
    this.doc.setDrawColor(...pdfConfig.colors.borders);
    this.doc.setLineWidth(0.5);
    this.doc.roundedRect(startX, startY, width, height, 3, 3, 'S');
  }

  async addHeaderSection(logo, pageWidth) {
    // Add logo
    const logoConfig = {
      x: pageWidth - 65,
      y: 10,
      width: 60,
      height: 15
    };

    this.doc.addImage(logo, 'PNG', logoConfig.x, logoConfig.y, logoConfig.width, logoConfig.height);
    this.doc.link(logoConfig.x, logoConfig.y, logoConfig.width, logoConfig.height, { url: 'https://travell.com.br' });

    // Add title
    this.doc.setFontSize(pdfConfig.fonts.sizes.title);
    this.doc.setTextColor(...pdfConfig.colors.secondary);
    this.doc.text('Detalhes da Reserva', 60, 20);
    this.doc.setLineWidth(0.2);

    this.currentY = 36;
  }

  addSeatSection(reservation) {
    this.drawSectionBorder(12, this.currentY, 180, 18);
    this.doc.setFontSize(pdfConfig.fonts.sizes.subtitle);
    this.doc.text('Assento', 14, this.currentY + 8);
    this.doc.setFontSize(pdfConfig.fonts.sizes.body);
    this.currentY = this.addWrappedText(
      `Número do Assento: ${reservation.numeroAssento || 'Ocupante de Colo'}`,
      14,
      this.currentY + 12,
      180,
      pdfConfig.spacing.lineHeight
    );
  }

  addTravelSection(travel) {
    this.currentY += 10;
    const sectionHeight = travel.somenteIda ? 36 : 44;
    this.drawSectionBorder(12, this.currentY, 180, sectionHeight, pdfConfig.colors.background.light);
    
    this.doc.setFontSize(pdfConfig.fonts.sizes.subtitle);
    this.doc.text('Informações da Viagem', 14, this.currentY + 8);
    this.doc.setFontSize(pdfConfig.fonts.sizes.body);

    const travelInfo = [
      `ID: ${travel.identificador}`,
      `Origem: ${travel.origem}`,
      `Destino: ${travel.destino}`,
      `Data de Ida: ${formatDate(travel.dataIda)} ${travel.horarioIda}`
    ];

    let infoY = this.currentY + 14;
    travelInfo.forEach(info => {
      infoY = this.addWrappedText(info, 14, infoY, 88, pdfConfig.spacing.lineHeight);
    });

    if (!travel.somenteIda) {
      this.addWrappedText(
        `Data de Retorno: ${formatDate(travel.dataRetorno)} ${travel.horarioRetorno}`,
        14,
        infoY,
        88,
        pdfConfig.spacing.lineHeight
      );
    } else {
      this.doc.setTextColor(...pdfConfig.colors.text.info);
      this.addWrappedText('Somente Ida', 14, infoY, 88, pdfConfig.spacing.lineHeight);
      this.doc.setTextColor(...pdfConfig.colors.text.primary);
    }

    this.currentY += sectionHeight + 14;
  }

  async addPassengerSection(passenger) {
    this.drawSectionBorder(12, this.currentY, 88, 80);
    this.doc.setFontSize(pdfConfig.fonts.sizes.subtitle);
    this.doc.text('Informações do Passageiro', 14, this.currentY + 8);
    this.doc.setFontSize(pdfConfig.fonts.sizes.body);

    let startY = this.currentY + 14;
    const passengerInfo = [
      `Nome: ${passenger.nome}`,
      `CPF: ${passenger.cpf ? formatCPF(passenger.cpf) : 'Não informado'}`,
      `${passenger.estrangeiro ? 'Passaporte' : 'RG'}: ${passenger.estrangeiro ? 
        passenger.passaporte || 'Não informado' : 
        passenger.rg || 'Não informado'}`
    ];

    if (passenger.telefone) {
      passengerInfo.push(`Telefone: ${formatTelefone(passenger.telefone)}`);
    }
    
    if (passenger.endereco) {
      passengerInfo.push(`Endereço: ${passenger.endereco}`);
    }

    passengerInfo.forEach(info => {
      startY = this.addWrappedText(info, 14, startY, 88, pdfConfig.spacing.lineHeight);
    });

    if (passenger.menorDeIdade) {
      this.doc.setTextColor(...pdfConfig.colors.text.alert);
      startY = this.addWrappedText('Menor de Idade', 14, startY, 88, pdfConfig.spacing.lineHeight);
      this.doc.setTextColor(...pdfConfig.colors.text.primary);
    }

    if (passenger.estrangeiro) {
      this.doc.setTextColor(...pdfConfig.colors.text.info);
      startY = this.addWrappedText('Estrangeiro', 14, startY, 88, pdfConfig.spacing.lineHeight);
      this.doc.setTextColor(...pdfConfig.colors.text.primary);
    }

    // Add responsible person section if passenger is underage
    if (passenger.responsavelId) {
      await this.addResponsibleSection(passenger.responsavelId);
    }

    this.currentY += 92;
  }

  async addResponsibleSection(responsavelId) {
    const responsavel = await getPassengerById(responsavelId);
    if (!responsavel) return;

    this.drawSectionBorder(105, this.currentY, 88, 80, pdfConfig.colors.background.medium);
    this.doc.setFontSize(pdfConfig.fonts.sizes.subtitle);
    this.doc.text('Responsável', 107, this.currentY + 8);
    this.doc.setFontSize(pdfConfig.fonts.sizes.body);

    let respY = this.currentY + 14;
    const responsibleInfo = [
      `Nome: ${responsavel.nome}`,
      `CPF: ${responsavel.cpf ? formatCPF(responsavel.cpf) : 'Não informado'}`,
      `${responsavel.estrangeiro ? 'Passaporte' : 'RG'}: ${
        responsavel.estrangeiro ? 
        responsavel.passaporte || 'Não informado' : 
        responsavel.rg || 'Não informado'
      }`,
      `Telefone: ${formatTelefone(responsavel.telefone)}`
    ];

    responsibleInfo.forEach(info => {
      respY = this.addWrappedText(info, 107, respY, 88, pdfConfig.spacing.lineHeight);
    });

    if (responsavel.estrangeiro) {
      this.doc.setTextColor(...pdfConfig.colors.text.info);
      this.addWrappedText('Estrangeiro', 107, respY, 88, pdfConfig.spacing.lineHeight);
      this.doc.setTextColor(...pdfConfig.colors.text.primary);
    }
  }

  addPaymentSection(reservation) {
    this.drawSectionBorder(12, this.currentY, 180, 60);
    this.doc.setFontSize(pdfConfig.fonts.sizes.subtitle);
    this.doc.text('Informações de Pagamento e Status', 14, this.currentY + 8);
    this.doc.setFontSize(pdfConfig.fonts.sizes.body);

    let pagadorY = this.currentY + 14;
    const paymentInfo = [
      `Nome do Pagador: ${reservation.detalhesPagamento?.nomePagador || 'Não informado'}`,
      `Método de Pagamento: ${reservation.detalhesPagamento?.metodoPagamento || 'Não informado'}`,
      `Valor Total: R$ ${normalizeValue(reservation.detalhesPagamento?.valorTotal || 0)}`,
      `Valor Pago: R$ ${normalizeValue(reservation.detalhesPagamento?.valorPago || 0)}`,
      `Status: ${reservation.status}`
    ];

    paymentInfo.forEach(info => {
      pagadorY = this.addWrappedText(info, 14, pagadorY, 180, pdfConfig.spacing.lineHeight);
    });
  }

  addFooter() {
    this.doc.setFontSize(pdfConfig.fonts.sizes.small);
    this.doc.setTextColor(...pdfConfig.colors.text.secondary);
    this.doc.text('Obrigado por viajar conosco!', 14, 285);
  }
}

const exportReservationToPDF = async (reservation, passenger, travel) => {
  try {
    const doc = new jsPDF();
    doc.setFont('helvetica', 'normal');
    
    const generator = new PDFGenerator(doc);
    const pageWidth = doc.internal.pageSize.width;

    const img = new Image();
    img.src = logo;

    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
    });

    // Generate PDF sections
    await generator.addHeaderSection(img, pageWidth);
    generator.addSeatSection(reservation);
    generator.addTravelSection(travel);
    await generator.addPassengerSection(passenger);
    generator.addPaymentSection(reservation);
    generator.addFooter();

    // Save the PDF
    const fileName = passenger.nome ? 
      `Reserva_${passenger.nome.replace(/[^a-zA-Z0-9]/g, '_')}.pdf` : 
      `Reserva_${reservation.id}.pdf`;
    
    doc.save(fileName);
    return true;
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw new Error('Failed to generate reservation PDF');
  }
};

export default exportReservationToPDF;