import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Grid,
    InputAdornment,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    CircularProgress
} from '@mui/material';
import { useCurrencyInput } from '../../../shared/utils/formatCurrency';

const BudgetPaymentForm = ({
    open,
    editingPayment,
    onClose,
    onSave,
    paymentMethods = [],
    bankAccounts = [],
}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const {
        value: paymentValue,
        handleChange: handlePaymentChange,
        handleBlur: handlePaymentBlur,
        setValue: setPaymentValue
    } = useCurrencyInput('0,00');

    const [formData, setFormData] = React.useState({
        description: '',
        date: new Date().toISOString().split('T')[0],
        paymentMethod: '',
        bankAccountId: ''
    });

    const [errors, setErrors] = React.useState({});
    const [touched, setTouched] = React.useState({});

    React.useEffect(() => {
        if (editingPayment) {
            setFormData({
                description: editingPayment.description || '',
                date: editingPayment.date ? editingPayment.date.split('T')[0] : new Date().toISOString().split('T')[0],
                paymentMethod: editingPayment.paymentMethod || '',
                bankAccountId: editingPayment.bankAccountId || '',
            });
            setPaymentValue((editingPayment.amount || 0).toFixed(2).replace('.', ','));
        }
        setErrors({});
        setTouched({});
    }, [editingPayment, open, setPaymentValue]);

    const validateField = (name, value) => {
        switch (name) {
            case 'description':
                return !value.trim() ? 'Campo obrigatório' : '';
            case 'amount':
                return value === '0,00' ? 'Valor deve ser maior que zero' : '';
            case 'paymentMethod':
                return !value ? 'Selecione um método de pagamento' : '';
            case 'date':
                return !value ? 'Selecione uma data' : '';
            default:
                return '';
        }
    };

    const handleFieldBlur = (name, value) => {
        setTouched(prev => ({ ...prev, [name]: true }));
        setErrors(prev => ({
            ...prev,
            [name]: validateField(name, value)
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        if (touched[name]) {
            handleFieldBlur(name, value);
        }
    };

    const handleSave = async () => {
        const fieldValidation = {
            date: validateField('date', formData.date),
            description: validateField('description', formData.description),
            amount: validateField('amount', paymentValue),
            paymentMethod: validateField('paymentMethod', formData.paymentMethod),
        };

        setErrors(fieldValidation);
        setTouched({
            date: true,
            description: true,
            amount: true,
            paymentMethod: true,
        });

        const hasErrors = Object.values(fieldValidation).some(error => error);

        if (!hasErrors) {
            setIsLoading(true);
            try {
                const numericValue = parseFloat(
                    paymentValue
                        .replace(/\./g, '')
                        .replace(',', '.')
                );

                const payment = {
                    amount: numericValue,
                    description: formData.description,
                    date: new Date(formData.date).toISOString().split('T')[0],
                    paymentMethod: formData.paymentMethod,
                    bankAccountId: formData.bankAccountId || null,
                    id: editingPayment?.id
                };
                await onSave(payment);
                handleCloseDialog();
            } catch (error) {
                console.error('Error saving payment:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleCloseDialog = () => {
        setPaymentValue('0,00');
        setFormData({
            description: '',
            date: new Date().toISOString().split('T')[0],
            paymentMethod: '',
            bankAccountId: ''
        });
        setErrors({});
        setTouched({});
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleCloseDialog}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                {editingPayment ? 'Editar Pagamento' : 'Novo Pagamento'}
            </DialogTitle>
            <DialogContent>
                <Box component="form" sx={{ mt: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Descrição"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                onBlur={() => handleFieldBlur('description', formData.description)}
                                error={touched.description && !!errors.description}
                                helperText={touched.description && errors.description}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Valor"
                                name="amount"
                                value={paymentValue}
                                onChange={handlePaymentChange}
                                onBlur={() => {
                                    handlePaymentBlur();
                                    handleFieldBlur('amount', paymentValue);
                                }}
                                error={touched.amount && !!errors.amount}
                                helperText={touched.amount && errors.amount}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                }}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Data"
                                type="date"
                                name="date"
                                value={formData.date}
                                onChange={handleChange}
                                onBlur={() => handleFieldBlur('date', formData.date)}
                                error={touched.date && !!errors.date}
                                helperText={touched.date && errors.date}
                                InputLabelProps={{ shrink: true }}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl
                                fullWidth
                                error={touched.paymentMethod && !!errors.paymentMethod}
                                required
                            >
                                <InputLabel>Método de Pagamento</InputLabel>
                                <Select
                                    name="paymentMethod"
                                    value={formData.paymentMethod}
                                    onChange={handleChange}
                                    onBlur={() => handleFieldBlur('paymentMethod', formData.paymentMethod)}
                                    label="Método de Pagamento"
                                >
                                    {paymentMethods.map((method) => (
                                        <MenuItem key={method} value={method}>
                                            {method}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {touched.paymentMethod && errors.paymentMethod && (
                                    <FormHelperText>{errors.paymentMethod}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Conta Bancária</InputLabel>
                                <Select
                                    name="bankAccountId"
                                    value={formData.bankAccountId}
                                    onChange={handleChange}
                                    label="Conta Bancária"
                                >
                                    <MenuItem value="">
                                        <em>Nenhuma</em>
                                    </MenuItem>
                                    {bankAccounts.map((account) => (
                                        <MenuItem key={account.id} value={account.id}>
                                            {account.name} - {account.bank}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
                <Button
                    onClick={handleCloseDialog}
                    variant="outlined"
                    sx={{ borderRadius: '50px' }}
                    disabled={isLoading}
                >
                    Voltar
                </Button>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    disabled={isLoading}
                    sx={{ borderRadius: '50px' }}
                >
                    {isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        editingPayment ? 'Salvar' : 'Adicionar'
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BudgetPaymentForm;