import React, { useEffect, useState } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  BarChart, Bar, ResponsiveContainer, PieChart, Pie, Cell
} from 'recharts';
import {
  Box, Typography, Paper, useMediaQuery, useTheme,
  FormControl, InputLabel, Select, MenuItem, Button,
  Grid, Card, CardContent
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import TravelLoading from '../../shared/common/TravelLoading';
import ReportPageHelp from './helps/ReportPageHelp';

import { getTravelReportData } from './services/ReportService';
import { exportToPDF } from './components/ReportPDF';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A569BD', '#5499C7'];

const StatCard = ({ title, value, subtitle }) => (
  <Card sx={{ height: '100%' }}>
    <CardContent>
      <Typography color="textSecondary" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h4" component="div">
        {value}
      </Typography>
      {subtitle && (
        <Typography color="textSecondary">
          {subtitle}
        </Typography>
      )}
    </CardContent>
  </Card>
);

const ReportPage = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    monthlyTrips: [],
    monthlyCancelledTrips: [],
    monthlyReservations: [],
    popularDestinations: [],
    yearSummary: {}
  });
  const [year, setYear] = useState(new Date().getFullYear());
  const [availableYears, setAvailableYears] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const reportData = await getTravelReportData(year);
        setData({
          monthlyTrips: reportData.monthlyTrips,
          monthlyCancelledTrips: reportData.monthlyCancelledTrips,
          monthlyReservations: reportData.monthlyReservations,
          popularDestinations: reportData.popularDestinations,
          yearSummary: reportData.yearSummary
        });
        setAvailableYears(reportData.availableYears);
      } catch (error) {
        console.error('Erro ao buscar dados do relatório:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [year]);

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleExportPDF = async () => {
    try {
      await exportToPDF(data, year);
    } catch (error) {
      console.error('Erro ao exportar PDF:', error);
    }
  };

  if (loading) {
    return <TravelLoading />;
  }

  return (
    <Box>
      <Box sx={{ gap: 2, marginBottom: 2, alignItems: 'center', flexWrap: 'wrap' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h6">
            Relatórios
            <Box component="span" sx={{ ml: 1 }}>
              <ReportPageHelp />
            </Box>
          </Typography>

          <Box sx={{ display: 'flex', gap: 2 }}>
            <FormControl variant="outlined" sx={{ minWidth: 120 }}>
              <InputLabel id="select-year-label">Ano</InputLabel>
              <Select
                labelId="select-year-label"
                value={year}
                onChange={handleYearChange}
                label="Ano"
              >
                {availableYears.map((y) => (
                  <MenuItem key={y} value={y}>{y}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <DownloadIcon />}
              color="primary"
              onClick={handleExportPDF}
              sx={{ borderRadius: '50px' }}
            >
              Exportar PDF
            </Button>
          </Box>
        </Box>

        {/* Summary Cards */}
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="Total de Viagens"
              value={data.yearSummary.totalTravels}
              subtitle={`${data.yearSummary.cancellationRate}% de cancelamento`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="Total de Reservas"
              value={data.yearSummary.totalReservations}
              subtitle={`${data.yearSummary.reservationCancellationRate}% canceladas`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="Média de Reservas por Viagem"
              value={data.yearSummary.averageReservationsPerTravel}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="Viagens Ativas"
              value={data.yearSummary.activeTravels}
              subtitle={`${data.yearSummary.cancelledTravels} canceladas`}
            />
          </Grid>
        </Grid>

        {/* Charts */}
        <Paper elevation={3} sx={{ p: 2, mb: 3 }} className="chart-container">
          <Typography variant="h6" gutterBottom>Viagens por Mês</Typography>
          <ResponsiveContainer width="100%" height={isMobile ? 300 : 400}>
            <LineChart data={data.monthlyTrips}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="viagens"
                name="Viagens Ativas"
                stroke="#8884d8"
              />
              <Line
                type="monotone"
                dataKey="viagensCanceladas"
                name="Viagens Canceladas"
                stroke="#ff0000"
              />
            </LineChart>
          </ResponsiveContainer>
        </Paper>

        <Paper elevation={3} sx={{ p: 2, mb: 3 }} className="chart-container">
          <Typography variant="h6" gutterBottom>Reservas por Mês</Typography>
          <ResponsiveContainer width="100%" height={isMobile ? 300 : 400}>
            <BarChart data={data.monthlyReservations}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="reservas"
                name="Reservas Ativas"
                fill="#82ca9d"
              />
              <Bar
                dataKey="reservasCanceladas"
                name="Reservas Canceladas"
                fill="#ff0000"
              />
            </BarChart>
          </ResponsiveContainer>
        </Paper>

        <Paper elevation={3} sx={{ p: 2, mb: 3 }} className="chart-container">
          <Typography variant="h6" gutterBottom>Destinos Populares</Typography>
          {data.popularDestinations.length === 0 ? (
            <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'center', mt: 3 }}>
              Nenhum destino popular encontrado.
            </Typography>
          ) : (
            <ResponsiveContainer width="100%" height={isMobile ? 300 : 400}>
              <PieChart>
                <Pie
                  data={data.popularDestinations}
                  dataKey="count"
                  nameKey="destination"
                  cx="50%"
                  cy="50%"
                  outerRadius={isMobile ? 80 : 150}
                  fill="#8884d8"
                  label={({ name, value }) => `${name}: ${value}`}
                >
                  {data.popularDestinations.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default ReportPage;