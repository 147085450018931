import { db, auth } from '../../../firebaseConfig';
import {
  collection,
  doc,
  getDocs,
  getDoc,
  query,
  where,
  orderBy
} from 'firebase/firestore';

const MONTHS = [
  "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
  "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
];

const getAgencyRef = async () => {
  const user = auth.currentUser;
  if (!user) throw new Error('Usuário não autenticado');

  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  if (!userDoc.exists()) throw new Error('Usuário não encontrado');

  return doc(db, 'agencies', userDoc.data().agencyId);
};

export const getTravelReportData = async (year) => {
  try {
    const agencyRef = await getAgencyRef();

    // Buscar todas as viagens da agência
    const travelsRef = collection(agencyRef, 'travels');
    const travelsSnapshot = await getDocs(query(
      travelsRef,
      where('estaAtivo', '==', true),
      orderBy('dataIda')
    ));

    const allTravels = travelsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    // Filtrar viagens do ano selecionado
    const travels = allTravels.filter(travel => {
      const travelYear = new Date(travel.dataIda).getFullYear();
      return travelYear === year;
    });

    // Buscar todas as reservas das viagens filtradas
    const reservationsRef = collection(agencyRef, 'reservations');
    const travelIds = travels.map(travel => travel.id);

    let reservations = [];
    // Buscar em lotes de 10 devido à limitação do Firebase
    for (let i = 0; i < travelIds.length; i += 10) {
      const batchIds = travelIds.slice(i, i + 10);
      if (batchIds.length > 0) {
        const reservationsSnapshot = await getDocs(query(
          reservationsRef,
          where('travelId', 'in', batchIds)
        ));

        reservations = [
          ...reservations,
          ...reservationsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
        ];
      }
    }

    // Processar dados para os relatórios
    const reportData = {
      monthlyTrips: aggregateMonthlyTrips(travels),
      monthlyCancelledTrips: aggregateCancelledTrips(travels),
      monthlyReservations: aggregateMonthlyReservations(reservations),
      popularDestinations: aggregatePopularDestinations(travels, reservations),
      yearSummary: generateYearSummary(travels, reservations),
      availableYears: getAvailableYears(allTravels) // Usando todas as viagens para os anos disponíveis
    };

    return reportData;
  } catch (error) {
    console.error('Erro ao gerar relatório:', error);
    throw new Error(`Erro ao gerar relatório: ${error.message}`);
  }
};

const aggregateMonthlyTrips = (travels) => {
  const monthlyData = MONTHS.map(month => ({ month, viagens: 0 }));

  travels.forEach(travel => {
    if (travel.dataIda && !travel.status?.includes('Cancelada')) {
      const month = new Date(travel.dataIda).getMonth();
      monthlyData[month].viagens++;
    }
  });

  return monthlyData;
};

const aggregateCancelledTrips = (travels) => {
  const monthlyData = MONTHS.map(month => ({ month, viagensCanceladas: 0 }));

  travels.forEach(travel => {
    if (travel.dataIda && travel.status?.includes('Cancelada')) {
      const month = new Date(travel.dataIda).getMonth();
      monthlyData[month].viagensCanceladas++;
    }
  });

  return monthlyData;
};

const aggregateMonthlyReservations = (reservations) => {
  const monthlyData = MONTHS.map(month => ({
    month,
    reservas: 0,
    reservasCanceladas: 0
  }));

  reservations.forEach(reservation => {
    if (!reservation.criadoEm) return;

    const date = new Date(reservation.criadoEm);
    const month = date.getMonth();

    if (reservation.status === 'Cancelada') {
      monthlyData[month].reservasCanceladas++;
    } else {
      monthlyData[month].reservas++;
    }
  });

  return monthlyData;
};

const aggregatePopularDestinations = (travels, reservations) => {
  const destinationMap = new Map();

  travels.forEach(travel => {
    if (!travel.status?.includes('Cancelada')) {
      const travelReservations = reservations.filter(
        res => res.travelId === travel.id && res.status !== 'Cancelada'
      );

      if (destinationMap.has(travel.destino)) {
        destinationMap.set(travel.destino, destinationMap.get(travel.destino) + travelReservations.length);
      } else {
        destinationMap.set(travel.destino, travelReservations.length);
      }
    }
  });

  return Array.from(destinationMap.entries())
    .map(([destination, count]) => ({ destination, count }))
    .sort((a, b) => b.count - a.count)
    .slice(0, 6);
};

const generateYearSummary = (travels, reservations) => {
  const activeReservations = reservations.filter(r => r.status !== 'Cancelada');
  const cancelledReservations = reservations.filter(r => r.status === 'Cancelada');
  const activeTravels = travels.filter(t => !t.status?.includes('Cancelada'));
  const cancelledTravels = travels.filter(t => t.status?.includes('Cancelada'));

  return {
    totalTravels: travels.length,
    activeTravels: activeTravels.length,
    cancelledTravels: cancelledTravels.length,
    totalReservations: reservations.length,
    activeReservations: activeReservations.length,
    cancelledReservations: cancelledReservations.length,
    cancellationRate: travels.length > 0 ? (cancelledTravels.length / travels.length * 100).toFixed(2) : '0',
    reservationCancellationRate: reservations.length > 0 ? (cancelledReservations.length / reservations.length * 100).toFixed(2) : '0',
    averageReservationsPerTravel: activeTravels.length > 0 ? (activeReservations.length / activeTravels.length).toFixed(2) : '0'
  };
};

const getAvailableYears = (travels) => {
  const years = new Set();

  travels.forEach(travel => {
    if (travel.dataIda) {
      const year = new Date(travel.dataIda).getFullYear();
      years.add(year);
    }
  });

  return Array.from(years).sort((a, b) => b - a);
};