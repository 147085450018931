import React, { useState, useEffect } from 'react';
import {
    Box,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Chip
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { useAuth } from '../../core/contexts/useAuthState';

const ActionInfo = ({ metadata }) => {
    const { currentUser } = useAuth();
    const [open, setOpen] = useState(false);
    const [userInfo, setUserInfo] = useState({
        created: null,
        updated: null,
        canceled: null
    });
    const [loading, setLoading] = useState(false);

    // Verificar se o usuário tem acesso (owner ou admin)
    const hasAccess = currentUser?.role === 'owner' || currentUser?.role === 'admin';

    // Se não houver acesso ou metadata, não renderizar nada
    if (!hasAccess || !metadata) return null;

    const handleOpen = () => {
        setOpen(true);
        fetchUsersInfo();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const fetchUsersInfo = async () => {
        setLoading(true);
        try {
            const usersToFetch = [
                metadata?.createdBy,
                metadata?.lastUpdatedBy,
                metadata?.cancellationInfo?.canceledBy
            ].filter(Boolean);

            const uniqueUsers = [...new Set(usersToFetch)];
            const usersData = {};

            await Promise.all(
                uniqueUsers.map(async (userId) => {
                    const userDoc = await getDoc(doc(db, 'users', userId));
                    if (userDoc.exists()) {
                        usersData[userId] = userDoc.data();
                    }
                })
            );

            setUserInfo({
                created: metadata?.createdBy ? usersData[metadata.createdBy] : null,
                updated: metadata?.lastUpdatedBy ? usersData[metadata.lastUpdatedBy] : null,
                canceled: metadata?.cancellationInfo?.canceledBy ?
                    usersData[metadata.cancellationInfo.canceledBy] : null
            });
        } catch (error) {
            console.error('Erro ao buscar informações dos usuários:', error);
        } finally {
            setLoading(false);
        }
    };

    const formatDateTime = (date) => {
        return new Date(date).toLocaleString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const getActionChip = (type) => {
        const chipStyles = {
            created: { color: 'success', icon: <PersonIcon sx={{ mr: 0.5 }} /> },
            updated: { color: 'primary', icon: <EditIcon sx={{ mr: 0.5 }} /> },
            canceled: { color: 'error', icon: <BlockIcon sx={{ mr: 0.5 }} /> },
        };

        const labels = {
            created: 'Criação',
            updated: 'Atualização',
            canceled: 'Cancelamento'
        };

        return (
            <Chip
                icon={chipStyles[type].icon}
                label={labels[type]}
                color={chipStyles[type].color}
                size="small"
                variant="outlined"
            />
        );
    };

    if (!metadata) return null;
    
    return (
        <>
            <Tooltip title="Histórico de ações">
                <IconButton onClick={handleOpen} size="small">
                    <HistoryIcon />
                </IconButton>
            </Tooltip>

            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>Histórico de Ações</DialogTitle>
                <DialogContent>
                    {loading ? (
                        <Box display="flex" justifyContent="center" my={2}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Ação</TableCell>
                                        <TableCell>Usuário</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Data/Hora</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {metadata.createdBy && userInfo.created && (
                                        <TableRow>
                                            <TableCell>
                                                {getActionChip('created')}
                                            </TableCell>
                                            <TableCell>{userInfo.created.name}</TableCell>
                                            <TableCell>{userInfo.created.email}</TableCell>
                                            <TableCell>{formatDateTime(metadata.createdAt)}</TableCell>
                                        </TableRow>
                                    )}

                                    {metadata.lastUpdatedBy && userInfo.updated && (
                                        <TableRow>
                                            <TableCell>
                                                {getActionChip('updated')}
                                            </TableCell>
                                            <TableCell>{userInfo.updated.name}</TableCell>
                                            <TableCell>{userInfo.updated.email}</TableCell>
                                            <TableCell>{formatDateTime(metadata.updatedAt)}</TableCell>
                                        </TableRow>
                                    )}

                                    {metadata.cancellationInfo && userInfo.canceled && (
                                        <TableRow>
                                            <TableCell>
                                                {getActionChip('canceled')}
                                            </TableCell>
                                            <TableCell>{userInfo.canceled.name}</TableCell>
                                            <TableCell>{userInfo.canceled.email}</TableCell>
                                            <TableCell>
                                                {formatDateTime(metadata.cancellationInfo.canceledAt)}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        sx={{ borderRadius: '50px', m: 2 }}
                    >
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ActionInfo;