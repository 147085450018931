import React, { useState } from 'react';
import {
  Typography,
  Box,
  TextField,
  IconButton,
  Tooltip,
  Grid,
  Paper,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import {
  Edit as EditIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { useCurrencyInput } from '../../../shared/utils/formatCurrency';
import { normalizeValue } from '../../../shared/utils/utils';
import BudgetPaymentForm from './BudgetPaymentForm';
import BudgetPaymentList from './BudgetPaymentList';

const BudgetPanel = ({
  budget,
  onBudgetChange,
  budgetPayments = [],
  onAddPayment,
  onEditPayment,
  onDeletePayment,
  bankAccounts,
  paymentMethods,
  canView = false,
  canEdit = false,
  canCreate = false,
  canDelete = false
}) => {
  const [isEditingBudget, setIsEditingBudget] = useState(false);
  const [openPaymentForm, setOpenPaymentForm] = useState(false);
  const [editingPayment, setEditingPayment] = useState(null);
  const [loading, setLoading] = useState(false);

  if (!canView) {
    return null;
  }

  const {
    value: budgetValue,
    handleChange: handleBudgetChange,
    handleBlur: handleBudgetBlur,
    toNumber: getBudgetNumber,
    setValue: setBudgetValue
  } = useCurrencyInput(budget?.toString() || '0');

  const remainingBudget = budget - budgetPayments.reduce((acc, payment) =>
    acc + parseFloat(payment.amount), 0
  );

  const handleSaveBudget = async () => {
    const numericValue = getBudgetNumber();
    if (!isNaN(numericValue)) {
      setLoading(true);
      try {
        await onBudgetChange(numericValue);
      } finally {
        setLoading(false);
        setIsEditingBudget(false);
      }
    }
  };

  const handleCancelBudget = () => {
    setBudgetValue(budget?.toString() || '0');
    setIsEditingBudget(false);
  };

  const handleOpenPaymentForm = (payment = null) => {
    setEditingPayment(payment);
    setOpenPaymentForm(true);
  };

  const handleClosePaymentForm = () => {
    setOpenPaymentForm(false);
    setEditingPayment(null);
  };

  const handleSavePayment = (payment) => {
    if (editingPayment) {
      onEditPayment(payment);
    } else {
      onAddPayment(payment);
    }
    handleClosePaymentForm();
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
          <Typography variant="h6" sx={{ whiteSpace: 'nowrap' }}>
            Orçamento
          </Typography>
          {isEditingBudget && canEdit ? (
            <>
              <TextField
                value={budgetValue}
                onChange={handleBudgetChange}
                onBlur={handleBudgetBlur}
                size="small"
                sx={{ width: '150px' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      R$
                    </InputAdornment>
                  ),
                }}
              />
              <Tooltip title="Salvar">
                <IconButton
                  onClick={handleSaveBudget}
                  color="primary"
                  size="small"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={20} /> : <CheckIcon />}
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancelar">
                <IconButton
                  onClick={handleCancelBudget}
                  color="default"
                  size="small"
                  disabled={loading}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <Typography variant="h6">
                R$ {normalizeValue(budget)}
              </Typography>
              {canEdit && (
                <Tooltip title="Editar Orçamento">
                  <IconButton
                    onClick={() => setIsEditingBudget(true)}
                    color="primary"
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </Box>
      </Box>

      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={6}>
          <Paper elevation={1} sx={{ p: 2, bgcolor: 'background.default' }}>
            <Typography variant="subtitle2" color="text.secondary">
              Total Recebido dos Pagamentos
            </Typography>
            <Typography variant="h6">
              R$ {normalizeValue(budgetPayments.reduce((acc, payment) => acc + parseFloat(payment.amount), 0))}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={1} sx={{ p: 2, bgcolor: 'background.default' }}>
            <Typography variant="subtitle2" color="text.secondary">
              Orçamento Restante
            </Typography>
            <Typography variant="h6" color={remainingBudget < 0 ? 'error.main' : 'inherit'}>
              R$ {normalizeValue(remainingBudget)}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <BudgetPaymentList
        payments={budgetPayments}
        onEdit={canEdit ? handleOpenPaymentForm : undefined}
        onDelete={canDelete ? onDeletePayment : undefined}
        onAdd={canCreate ? () => handleOpenPaymentForm() : undefined}
        bankAccounts={bankAccounts}
        paymentMethods={paymentMethods}
      />

      {(canCreate || canEdit) && (
        <BudgetPaymentForm
          open={openPaymentForm}
          editingPayment={editingPayment}
          onClose={handleClosePaymentForm}
          onSave={handleSavePayment}
          bankAccounts={bankAccounts}
          paymentMethods={paymentMethods}
        />
      )}
    </Box>
  );
};

export default BudgetPanel;