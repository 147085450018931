import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, TextField, Snackbar, Alert, Pagination, InputAdornment, FormControl, InputLabel, Select, MenuItem, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, IconButton, Grid, Button, Card } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import OrderCard from '../../components/Order/OrderCard';
import OrderDetails from '../../components/Order/OrderDetails';
import TravelLoading from '../../shared/common/TravelLoading';
import ReceivablesHelp from './helps/ReceivablesHelp';

import { normalizeValue } from '../../shared/utils/utils';
import { getPaginatedPendingOrders, getPaginatedPaidOrders } from '../Payments/services/PaymentService';
import { cancelOrder, cancelReservation } from '../Allocation/services/OrderService';
import { useMasterPassword } from '../../shared/hooks/useMasterPassword';

const PaymentsPage = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [passengers, setPassengers] = useState([]);
  const [travels, setTravels] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [filteredPending, setFilteredPending] = useState([]);
  const [filteredPaid, setFilteredPaid] = useState([]);
  const [monthFilter, setMonthFilter] = useState('');
  const [yearFilter, setYearFilter] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [cancelOrderId, setCancelOrderId] = useState(null);
  const [cancelReservationId, setCancelReservationId] = useState(null);
  const [travelIdState, setTravelIdState] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [masterPassword, setMasterPassword] = useState('');
  const [cancelLoading, setCancelLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [availableYears, setAvailableYears] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [paginationLoading, setPaginationLoading] = useState({
    pending: false,
    paid: false
  });
  const [isInitialized, setIsInitialized] = useState(false);
  const [totals, setTotals] = useState({
    totalReceivable: 0,
    totalReceived: 0
  });
  const [pageInfo, setPageInfo] = useState({
    pendingPage: 1,
    paidPage: 1,
    totalPendingPages: 1,
    totalPaidPages: 1
  });
  const {
    isVerifying,
    error: masterPasswordError,
    clearError,
    masterPasswordActive,
    showPassword,
    togglePasswordVisibility,
    verifyMasterPassword
  } = useMasterPassword();

  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const years = [currentYear - 2, currentYear - 1, currentYear, currentYear + 1, currentYear + 2];
    setAvailableYears(years);

    // Define os filtros iniciais
    setMonthFilter(today.getMonth() + 1);
    setYearFilter(currentYear);
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!isInitialized) return;

      setInitialLoading(true);
      try {
        const [pendingResult, paidResult] = await Promise.all([
          getPaginatedPendingOrders({
            page: pageInfo.pendingPage,
            month: monthFilter,
            year: yearFilter
          }),
          getPaginatedPaidOrders({
            page: pageInfo.paidPage,
            month: monthFilter,
            year: yearFilter
          })
        ]);

        setFilteredPending(pendingResult.orders);
        setFilteredPaid(paidResult.orders);

        setPageInfo(prev => ({
          ...prev,
          totalPendingPages: Math.max(1, pendingResult.totalPages),
          totalPaidPages: Math.max(1, paidResult.totalPages)
        }));

        setTotalItems(pendingResult.totalItems + paidResult.totalItems);
        setTotals({
          totalReceivable: pendingResult.totalReceivable || 0,
          totalReceived: paidResult.totalReceived || 0
        });

      } catch (err) {
        setError('Falha ao carregar pedidos: ' + err.message);
      } finally {
        setInitialLoading(false);
      }
    };

    fetchData();
  }, [monthFilter, yearFilter, isInitialized]);

  const handleMonthFilterChange = e => {
    const newValue = e.target.value === '' ? '' : parseInt(e.target.value, 10);
    setMonthFilter(newValue);
    setPageInfo(prev => ({
      ...prev,
      pendingPage: 1,
      paidPage: 1
    }));
  };

  const handleYearFilterChange = e => {
    const newValue = e.target.value === '' ? '' : parseInt(e.target.value, 10);
    setYearFilter(newValue);
    setPageInfo(prev => ({
      ...prev,
      pendingPage: 1,
      paidPage: 1
    }));
  };

  const handleEditOrder = (order) => {
    // Redireciona para a edição do pedido com o ID do pedido e travelId na URL, passando estado
    navigate(`/viagens/${order.travelId}/pedido/${order.id}`, {
      state: {
        editingOrderId: order.id, // Inclui o ID do pedido atual para edição
        travelId: order.travelId, // Inclui o ID da viagem atual
        orderDetails: order, // Inclui os detalhes do pedido, caso necessário
      },
    });
  };

  const handlePendingPageChange = async (event, value) => {
    setPaginationLoading(prev => ({ ...prev, pending: true }));
    try {
      const pendingResult = await getPaginatedPendingOrders({
        page: value,
        month: monthFilter || null,
        year: yearFilter || null
      });

      setFilteredPending(pendingResult.orders);
      setPageInfo(prev => ({
        ...prev,
        pendingPage: value,
        totalPendingPages: pendingResult.totalPages
      }));

      // Atualiza apenas o totalReceivable nos totais
      setTotals(prev => ({
        ...prev,
        totalReceivable: pendingResult.totalReceivable || 0
      }));
    } catch (error) {
      setError('Falha ao carregar pedidos pendentes: ' + error.message);
    } finally {
      setPaginationLoading(prev => ({ ...prev, pending: false }));
    }
  };

  const handlePaidPageChange = async (event, value) => {
    setPaginationLoading(prev => ({ ...prev, paid: true }));
    try {
      const paidResult = await getPaginatedPaidOrders({
        page: value,
        month: monthFilter || null,
        year: yearFilter || null
      });

      setFilteredPaid(paidResult.orders);
      setPageInfo(prev => ({
        ...prev,
        paidPage: value,
        totalPaidPages: paidResult.totalPages
      }));

      // Atualiza apenas o totalReceived nos totais
      setTotals(prev => ({
        ...prev,
        totalReceived: paidResult.totalReceived || 0
      }));
    } catch (error) {
      setError('Falha ao carregar pedidos pagos: ' + error.message);
    } finally {
      setPaginationLoading(prev => ({ ...prev, paid: false }));
    }
  };

  const handleCancelOrder = (orderId, travelId) => {
    setCancelOrderId(orderId);
    setCancelReservationId(null);
    setTravelIdState(travelId);
    setCancelDialogOpen(true);
  };

  const confirmCancelOrder = async () => {
    setCancelLoading(true);
    try {
      if (masterPasswordActive) {
        const isValid = await verifyMasterPassword(masterPassword);
        if (!isValid) {
          setSnackbarMessage('Senha master incorreta');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setCancelLoading(false);
          return;
        }
      }

      if (cancelReservationId) {
        await cancelReservation(travelIdState, cancelOrderId, cancelReservationId);
        setSnackbarMessage('Reserva cancelada com sucesso.');
      } else {
        await cancelOrder(travelIdState, cancelOrderId);
        setSnackbarMessage('Pedido cancelado com sucesso.');
      }

      setSnackbarSeverity('success');
      setSnackbarOpen(true);

      // Recarrega os dados
      const [pendingResult, paidResult] = await Promise.all([
        getPaginatedPendingOrders({
          page: pageInfo.pendingPage,
          month: monthFilter || null,
          year: yearFilter || null
        }),
        getPaginatedPaidOrders({
          page: pageInfo.paidPage,
          month: monthFilter || null,
          year: yearFilter || null
        })
      ]);

      setFilteredPending(pendingResult.orders);
      setFilteredPaid(paidResult.orders);
      setPageInfo(prev => ({
        ...prev,
        totalPendingPages: pendingResult.totalPages,
        totalPaidPages: paidResult.totalPages,
      }));
      setTotalItems(pendingResult.totalItems + paidResult.totalItems);

      handleCloseCancelDialog();
    } catch (err) {
      setSnackbarMessage(err.message.includes('master') ? 'Senha master incorreta' : 'Erro ao cancelar: ' + err.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setCancelLoading(false);
    }
  };

  const handleCloseCancelDialog = () => {
    setCancelDialogOpen(false);
    setCancelOrderId(null);
    setCancelReservationId(null);
    setTravelIdState(null);
    setMasterPassword('');
    clearError();
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  const handleOpenModal = order => {
    setSelectedOrder(order);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedOrder(null);
  };

  const handleClickShowMasterPassword = () =>
    setShowMasterPassword(!showMasterPassword);

  const renderCancelDialogContent = () => {
    if (cancelOrderId) {
      // Procura o pedido tanto nos pendentes quanto nos pagos
      const order = [...filteredPending, ...filteredPaid].find(o => o.id === cancelOrderId);

      if (!order) return null;

      return (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Informações do Pagador</Typography>
          <Typography variant="body2">Pedido ID: {cancelOrderId}</Typography>
          <Typography variant="body2">
            Nome: {order.detalhesPagamento?.nomePagador || 'Não informado'}
          </Typography>
          <Typography variant="body2">
            CPF: {order.detalhesPagamento?.cpfPagador || 'Não informado'}
          </Typography>
          <Typography variant="body2">
            RG: {order.detalhesPagamento?.rgPagador || 'Não informado'}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box sx={{ gap: 2, marginBottom: 2, flexWrap: 'wrap' }}>
      <Typography variant="h6" gutterBottom>
        Pagamentos
        <Box component="span" sx={{ ml: 1 }}>
          <ReceivablesHelp />
        </Box>
      </Typography>
      {/* Filtros permanecem iguais */}
      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={6} sm={3} md={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="month-filter-label">Filtrar por Mês</InputLabel>
            <Select
              labelId="month-filter-label"
              id="month-filter"
              value={monthFilter === '' ? '' : monthFilter}
              onChange={handleMonthFilterChange}
              label="Filtrar por Mês"
            >
              <MenuItem value="">Todos</MenuItem>
              {monthNames.map((month, index) => (
                <MenuItem key={index + 1} value={index + 1}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="year-filter-label">Filtrar por Ano</InputLabel>
            <Select
              labelId="year-filter-label"
              id="year-filter"
              value={yearFilter}
              onChange={handleYearFilterChange}
              label="Filtrar por Ano"
            >
              <MenuItem value="">Todos</MenuItem>
              {availableYears.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Cards de totais */}
      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} md={6}>
          <Card
            variant="outlined"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 2,
              borderRadius: '50px'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <MonetizationOnIcon color="info" sx={{ mr: 1 }} />
              <Typography variant="h6">Total a Receber</Typography>
            </Box>
            <Typography variant="h6">
              R$ {normalizeValue(totals.totalReceivable.toFixed(2))}
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            variant="outlined"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 2,
              borderRadius: '50px'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircleOutlineIcon color="success" sx={{ mr: 1 }} />
              <Typography variant="h6">Total Recebido</Typography>
            </Box>
            <Typography variant="h6">
              R$ {normalizeValue(totals.totalReceived.toFixed(2))}
            </Typography>
          </Card>
        </Grid>
      </Grid>

      {/* Conteúdo principal com loading inicial */}
      {initialLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <TravelLoading disableAlignItems />
        </Box>
      ) : filteredPending.length === 0 && filteredPaid.length === 0 ? (
        <Box sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Typography variant="body1">
            Nenhum pedido a receber encontrado.
          </Typography>
        </Box>
      ) : (
        <Box>
          {/* Seção de Pedidos Pendentes */}
          <Typography variant="h6" sx={{ mb: 2 }}>
            Pedidos com Pagamento Pendente
          </Typography>
          {filteredPending.length > 0 ? (
            <Box sx={{ position: 'relative' }}>
              {paginationLoading.pending && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    bgcolor: 'rgba(255, 255, 255, 0.7)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1,
                  }}
                >
                  <CircularProgress size={24} />
                </Box>
              )}
              {filteredPending.map(order => (
                <OrderCard
                  key={order.id}
                  order={order}
                  travel={order.travel}
                  onEditOrder={handleEditOrder}
                  onCancelOrder={handleCancelOrder}
                  onCardClick={handleOpenModal}
                />
              ))}
              <Pagination
                count={pageInfo.totalPendingPages}
                page={pageInfo.pendingPage}
                onChange={handlePendingPageChange}
                color="primary"
                sx={{ mt: 2 }}
                disabled={paginationLoading.pending}
              />
            </Box>
          ) : (
            <Box sx={{
              p: 3,
              textAlign: 'center',
              bgcolor: 'background.paper',
              borderRadius: 1,
              mb: 4
            }}>
              <Typography variant="body1" color="text.secondary">
                Nenhum pedido pendente encontrado para o período selecionado.
              </Typography>
            </Box>
          )}

          {/* Seção de Pedidos Pagos */}
          <Typography variant="h6" sx={{ mb: 2 }}>
            Pedidos Pagos
          </Typography>
          {filteredPaid.length > 0 ? (
            <Box sx={{ position: 'relative' }}>
              {paginationLoading.paid && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    bgcolor: 'rgba(255, 255, 255, 0.7)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1,
                  }}
                >
                  <CircularProgress size={24} />
                </Box>
              )}
              {filteredPaid.map(order => (
                <OrderCard
                  key={order.id}
                  order={order}
                  travel={order.travel}
                  onEditOrder={handleEditOrder}
                  onCancelOrder={handleCancelOrder}
                  onCardClick={handleOpenModal}
                />
              ))}
              <Pagination
                count={pageInfo.totalPaidPages}
                page={pageInfo.paidPage}
                onChange={handlePaidPageChange}
                color="primary"
                sx={{ mt: 2 }}
                disabled={paginationLoading.paid}
              />
            </Box>
          ) : (
            <Box sx={{
              p: 3,
              textAlign: 'center',
              bgcolor: 'background.paper',
              borderRadius: 1
            }}>
              <Typography variant="body1" color="text.secondary">
                Nenhum pedido pago encontrado para o período selecionado.
              </Typography>
            </Box>
          )}
        </Box>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={cancelDialogOpen} onClose={handleCloseCancelDialog}>
        <DialogTitle>Confirmar Cancelamento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {cancelReservationId
              ? 'Tem certeza de que deseja cancelar esta reserva? Esta ação não pode ser desfeita.'
              : 'Tem certeza de que deseja cancelar este pedido? Todas as reservas deste pedido serão canceladas. Esta ação não pode ser desfeita.'}
          </DialogContentText>
          {renderCancelDialogContent()}
          {masterPasswordActive && (
            <TextField
              margin="normal"
              fullWidth
              label="Senha Master"
              type={showPassword ? 'text' : 'password'}
              value={masterPassword}
              onChange={(e) => setMasterPassword(e.target.value)}
              error={!!masterPasswordError}
              helperText={masterPasswordError}
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
              disabled={cancelLoading || isVerifying}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseCancelDialog}
            variant="outlined"
            disabled={cancelLoading || isVerifying}
            sx={{ borderRadius: '50px' }}
          >
            Voltar
          </Button>
          <Button
            onClick={confirmCancelOrder}
            variant="contained"
            color="error"
            autoFocus
            disabled={(masterPasswordActive && !masterPassword) || cancelLoading || isVerifying}
            sx={{ color: 'white', borderRadius: '50px' }}
          >
            {cancelLoading || isVerifying ? (
              <CircularProgress size={24} />
            ) : (
              cancelReservationId ? 'Cancelar reserva' : 'Cancelar pedido'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: '80%', md: '60%' },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            maxHeight: '90vh',
            overflowY: 'auto',
            borderRadius: 2,
          }}
        >
          <Typography variant="h5" gutterBottom>
            Detalhes do Pedido
          </Typography>
          {selectedOrder && selectedOrder.reservations && (
            <OrderDetails
              order={selectedOrder}
              passengers={selectedOrder.reservations.map(res => ({
                id: res.passengerId,
                ...res.passenger
              })).filter(passenger => passenger.id)}
              travel={selectedOrder.travel}
            />
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
            <Button
              onClick={handleCloseModal}
              variant="outlined"
              color="primary"
              sx={{ borderRadius: '50px' }}
            >
              Fechar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default PaymentsPage;
