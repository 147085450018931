import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../core/contexts/useAuthState';

const SidebarItem = ({ to, icon, text, permission, sx = {} }) => {
  const location = useLocation();
  const { hasPermission, currentUser } = useAuth();
  const isSelected = location.pathname === to;

  const isHomeRoute = to === '/';
  const shouldHideHome = isHomeRoute && currentUser?.role !== 'owner';

  const showItem = !shouldHideHome && (!permission || 
      currentUser?.role === 'owner' || 
      hasPermission(permission, 'view'));

  if (!showItem) return null;

  return (
      <ListItem
          button
          component={RouterLink}
          to={to}
          selected={isSelected}
          sx={{
              borderRadius: '8px',
              transition: 'background-color 0.3s, color 0.3s',
              '&:hover': {
                  backgroundColor: 'action.hover',
              },
              ...(isSelected && {
                  backgroundColor: '#e0e0e0',
                  color: '#000',
              }),
              ...sx
          }}
      >
          {icon && (
              <ListItemIcon sx={{ color: isSelected ? '#000' : 'inherit' }}>
                  {icon}
              </ListItemIcon>
          )}
          <ListItemText 
              secondary={text}
              secondaryTypographyProps={{
                  fontWeight: isSelected ? 500 : 400
              }}
          />
      </ListItem>
  );
};

export default SidebarItem;