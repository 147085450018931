import React, { useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, IconButton, Tooltip, Box, Typography, Chip, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, Button, CircularProgress,
    TextField, InputAdornment
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import TruncatedCell from '../../../shared/common/TruncatedCell';

import { useTheme } from '@mui/material/styles';
import { useMasterPassword } from '../../../shared/hooks/useMasterPassword';

const LayoutList = ({ layouts, onEdit, onDelete, canEdit = false, canDelete = false }) => {
    const theme = useTheme();
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [masterPassword, setMasterPassword] = useState('');
    const [selectedLayout, setSelectedLayout] = useState(null);

    const {
        isVerifying,
        error: masterPasswordError,
        clearError,
        masterPasswordActive,
        showPassword,
        togglePasswordVisibility,
        verifyMasterPassword
    } = useMasterPassword();

    const handleRowClick = (layout) => {
        if (canEdit && onEdit) {
            onEdit(layout);
        }
    };

    const handleDeleteClick = (e, layout) => {
        e.stopPropagation();
        setSelectedLayout(layout);
        setConfirmDeleteOpen(true);
    };

    const handleDelete = async () => {
        if (!canDelete || !selectedLayout) return;

        setLoading(true);
        try {
            if (masterPasswordActive) {
                const isValid = await verifyMasterPassword(masterPassword);
                if (!isValid) {
                    setLoading(false);
                    return;
                }
            }
            await onDelete(selectedLayout.id);
            handleCloseDialog();
        } catch (err) {
            console.error("Erro ao excluir layout:", err);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseDialog = () => {
        setConfirmDeleteOpen(false);
        setMasterPassword('');
        setSelectedLayout(null);
        clearError();
    };

    return (
        <>
            <TableContainer component={Paper} sx={{ boxShadow: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Assentos</TableCell>
                            <TableCell>Configuração</TableCell>
                            <TableCell>Data de Criação</TableCell>
                            {(canEdit || canDelete) && <TableCell align="center">Ações</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {layouts.map((layout) => (
                            <TableRow
                                key={layout.id}
                                hover
                                onClick={() => handleRowClick(layout)}
                                sx={{
                                    cursor: canEdit ? 'pointer' : 'default',
                                    '&:hover': canEdit ? {
                                        backgroundColor: theme.palette.action.hover
                                    } : {}
                                }}
                            >
                                <TruncatedCell text={layout.name || 'Sem nome'} maxChars={20} />
                                <TruncatedCell text={layout.id} />
                                <TableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <EventSeatIcon fontSize="small" color="action" />
                                        {layout.assentosTotais}
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Chip
                                        label={layout.doisAndares ? 'Dois Andares' : 'Um Andar'}
                                        size="small"
                                        color={layout.doisAndares ? 'primary' : 'default'}
                                        variant="outlined"
                                    />
                                </TableCell>
                                <TruncatedCell
                                    text={layout?.metadata?.createdAt ?
                                        new Date(layout.metadata.createdAt).toLocaleDateString() :
                                        'Data não disponível'
                                    }
                                />
                                {(canEdit || canDelete) && (
                                    <TableCell align="center">
                                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                                            {canEdit && (
                                                <Tooltip title="Editar">
                                                    <IconButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            onEdit(layout);
                                                        }}
                                                    >
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {canDelete && (
                                                <Tooltip title="Excluir">
                                                    <IconButton
                                                        size="small"
                                                        onClick={(e) => handleDeleteClick(e, layout)}
                                                    >
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </Box>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={confirmDeleteOpen} onClose={handleCloseDialog}>
                <DialogTitle>Confirmar Exclusão</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {selectedLayout && (
                            <>
                                Tem certeza que deseja excluir o layout <strong>{selectedLayout.name || 'Sem nome'}</strong> com ID <strong>{selectedLayout.id}</strong>?
                                Este layout tem <strong>{selectedLayout.assentosTotais}</strong> assentos e é {selectedLayout.doisAndares ? 'um layout de dois andares' : 'um layout de um andar'}.
                                Veículos que tenham esse layout atribuído poderão ficar sem layout. Esta ação não pode ser desfeita.
                            </>
                        )}
                    </DialogContentText>
                    {masterPasswordActive && (
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Senha Master"
                            type={showPassword ? 'text' : 'password'}
                            value={masterPassword}
                            onChange={(e) => setMasterPassword(e.target.value)}
                            error={!!masterPasswordError}
                            helperText={masterPasswordError}
                            InputProps={{
                                autoComplete: 'new-password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={togglePasswordVisibility}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            autoComplete="off"
                            disabled={loading || isVerifying}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDialog}
                        variant="outlined"
                        disabled={loading || isVerifying}
                        sx={{ borderRadius: '50px' }}
                    >
                        Voltar
                    </Button>
                    <Button
                        onClick={handleDelete}
                        variant="contained"
                        color="error"
                        disabled={(masterPasswordActive && !masterPassword) || loading || isVerifying}
                        sx={{ color: 'white', borderRadius: '50px' }}
                    >
                        {loading || isVerifying ? <CircularProgress size={24} /> : 'Excluir'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default LayoutList;