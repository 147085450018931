import { formatCPF, formatDate, formatDateForFilename, normalizeValue } from './utils';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../../assets/logo-travell-combr.png';

export const exportOrdersToPDF = (travel, orders, passengers) => {
  const doc = new jsPDF('landscape', 'pt');

  const truncateText = (text, maxLength) => {
    if (!text) return 'Não informado';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  const columns = [
    { title: "Pedido ID", dataKey: "orderId" },
    { title: "Assentos", dataKey: "numerosAssentos" },
    { title: "Nome do Pagador", dataKey: "nomePagador" },
    { title: "CPF do Pagador", dataKey: "cpfPagador" },
    { title: "RG do Pagador", dataKey: "rgPagador" },
    { title: "Valor Total", dataKey: "valorTotal" },
    { title: "Método de Pagamento", dataKey: "metodoPagamento" },
    { title: "Status", dataKey: "status" },
    { title: "Informações Adicionais", dataKey: "informacoesAdicionais" }
  ];

  const filteredOrders = orders.filter(order => order.status !== 'Cancelada');

  const rows = filteredOrders.map(order => {
    const assentos = order.reservations
      .map(reservation => reservation.numeroAssento)
      .filter(numeroAssento => numeroAssento !== null)
      .join(', ');
    const informacoesAdicionais = order.reservations
      .map(reservation => reservation.detalhesPagamento?.informacoesAdicionais)
      .filter(info => info)
      .join('; ');

    const nomePagador = order.detalhesPagamento?.nomePagador || 'Não informado';
    const cpfPagador = order.detalhesPagamento?.passaportePagador
      ? `Passaporte: ${order.detalhesPagamento.passaportePagador}`
      : order.detalhesPagamento?.cpfPagador
        ? formatCPF(order.detalhesPagamento.cpfPagador)
        : 'Não informado';

    const rgPagador = order.detalhesPagamento?.passaportePagador
      ? 'Não aplicável'
      : order.detalhesPagamento?.rgPagador || 'Não informado';

    const valorTotal = order.detalhesPagamento?.valorTotal ? `R$ ${normalizeValue(order.detalhesPagamento.valorTotal)}` : 'Não informado';
    const metodoPagamento = order.detalhesPagamento?.metodoPagamento || 'Não informado';

    return {
      orderId: order.id,
      numerosAssentos: assentos,
      nomePagador: nomePagador,
      cpfPagador: cpfPagador,
      rgPagador: rgPagador,
      valorTotal: valorTotal,
      metodoPagamento: metodoPagamento,
      status: order.status,
      informacoesAdicionais: truncateText(informacoesAdicionais, 50)
    };
  });

  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;

  let startY = 70;
    if (travel?.veiculo) {
      startY = 85;
    } else if (travel?.informacoesAdicionais) {
      startY = 85;
    }

  const img = new Image();
  img.src = logo;

  img.onload = () => {
    const logoX = pageWidth - 150;
    const logoY = 15;
    const logoWidth = 130;
    const logoHeight = 40;

    doc.addImage(img, 'PNG', logoX, logoY, logoWidth, logoHeight);
    doc.link(logoX, logoY, logoWidth, logoHeight, { url: 'https://travell.com.br' });

    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.text('Detalhes da Viagem', 40, 25);

    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');

    const infoLine1 = `Identificador: ${travel?.identificador || 'Não informado'} | Origem: ${travel?.origem || 'Não informado'} | Destino: ${travel?.destino || 'Não informado'}`;
    doc.text(infoLine1, 40, 40);

    const infoLine2 = `Ida: ${formatDate(travel?.dataIda) || 'Não informado'} às ${travel?.horarioIda || 'Não informado'} | ${travel?.somenteIda
        ? 'Somente Ida'
        : `Volta: ${formatDate(travel?.dataRetorno) || 'Não informado'} às ${travel?.horarioRetorno || 'Não informado'}`
      }`;
    doc.text(infoLine2, 40, 55);

    let infoLine3 = '';
    if (travel?.veiculo) {
      infoLine3 = `Veículo: ${travel.veiculo.identificadorVeiculo || 'Não informado'} - ${travel.veiculo.placa || 'Não informado'} (${travel.veiculo.empresa || 'Não informado'})`;
      if (travel?.informacoesAdicionais) {
        infoLine3 += ` | Info: ${truncateText(travel.informacoesAdicionais, 50)}`;
      }
      doc.text(infoLine3, 40, 70);
    }

    doc.autoTable({
      head: [columns.map(col => col.title)],
      body: rows.map(row => columns.map(col => row[col.dataKey])),
      startY: startY,
      theme: 'striped',
      margin: { top: 10, bottom: 30, left: 20, right: 20 },
      styles: { fontSize: 10, halign: 'center' },
      headStyles: { fillColor: [25, 118, 210], textColor: [255, 255, 255] },
      alternateRowStyles: { fillColor: [240, 240, 240] },
      columnStyles: {
        orderId: { cellWidth: 50 },
      },
      didDrawPage: function (data) {
        doc.setFontSize(8);
        doc.setTextColor(40);
        let str = 'Página ' + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' de ' + doc.internal.getNumberOfPages();
        }
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
    });

    const identifier = travel?.identificador || 'sem-identificador';
    const origem = travel?.origem || 'sem-origem';
    const destino = travel?.destino || 'sem-destino';
    const dataIda = formatDateForFilename(travel?.dataIda || 'sem-data-ida');
    const dataRetorno = travel?.somenteIda ? 'somente-ida' : formatDateForFilename(travel?.dataRetorno || 'sem-data-retorno');
    const filename = `pedidos_${identifier}_de_${origem}_na_data_${dataIda}_a_${destino}_com_volta_${dataRetorno}.pdf`;

    doc.save(filename);
  };
};
