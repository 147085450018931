import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Button, CircularProgress, Snackbar, Alert, TextField, InputAdornment
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PassengerDetails from './PassengerDetails';
import { formatCPF, formatDate } from '../../../shared/utils/utils';
import { useTheme } from '@mui/material/styles';
import { useMasterPassword } from '../../../shared/hooks/useMasterPassword';

const PassengerTable = ({
  passengers,
  setPassengers,
  startEditing,
  handleDeletePassenger,
  loading,
  canEdit = false,
  canDelete = false
}) => {
  const {
    isVerifying,
    error,
    clearError,
    masterPasswordActive,
    showPassword,
    togglePasswordVisibility,
    verifyMasterPassword
  } = useMasterPassword();

  const [open, setOpen] = useState(false);
  const [passengerToDelete, setPassengerToDelete] = useState(null);
  const [selectedPassenger, setSelectedPassenger] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [masterPassword, setMasterPassword] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const theme = useTheme();

  const handleRowClick = (passenger) => {
    setSelectedPassenger(passenger);
  };

  const handleCloseModal = () => {
    setSelectedPassenger(null);
  };

  const handleEdit = (e, passenger) => {
    e.stopPropagation();
    if (!canEdit) {
      setSnackbarMessage('Você não tem permissão para editar passageiros');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }
    startEditing(passenger);
  };

  const handleClickOpen = (e, passenger) => {
    e.stopPropagation();
    if (!canDelete) {
      setSnackbarMessage('Você não tem permissão para excluir passageiros');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }
    setPassengerToDelete(passenger);
    setOpen(true);
    clearError();
  };

  const handleClose = () => {
    if (!isDeleting) {
      setOpen(false);
      setPassengerToDelete(null);
      setMasterPassword('');
      clearError();
    }
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      if (masterPasswordActive) {
        const isValid = await verifyMasterPassword(masterPassword);
        if (!isValid) {
          setSnackbarMessage('Senha master incorreta');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setIsDeleting(false);
          return;
        }
      }

      await handleDeletePassenger(passengerToDelete.id);
      setPassengers((prevPassengers) =>
        prevPassengers.filter((passenger) => passenger.id !== passengerToDelete.id)
      );

      handleClose();
      setSnackbarMessage('Passageiro excluído com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Erro ao excluir passageiro: ", error);
      setSnackbarMessage('Erro ao excluir passageiro: ' + error.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  return (
    <TableContainer component={Paper} sx={{ backgroundColor: theme.palette.background.paper }}>
      <Table>
        <TableHead sx={{ backgroundColor: theme.palette.background.default }}>
          <TableRow>
            <TableCell><strong>Nome</strong></TableCell>
            <TableCell><strong>CPF</strong></TableCell>
            <TableCell><strong>Data de Nascimento</strong></TableCell>
            <TableCell><strong>RG/Passaporte</strong></TableCell>
            {(canEdit || canDelete) && <TableCell align="right"><strong>Ações</strong></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading && passengers.length === 0 ? (
            <TableRow>
              <TableCell colSpan={canEdit || canDelete ? 5 : 4} align="center">
                Nenhum passageiro encontrado.
              </TableCell>
            </TableRow>
          ) : (
            passengers.map((passenger) => (
              <TableRow
                key={passenger.id}
                hover
                onClick={() => handleRowClick(passenger)}
                sx={{ cursor: 'pointer', backgroundColor: theme.palette.background.paper }}
              >
                <TableCell>{passenger.nome}</TableCell>
                <TableCell>{passenger.cpf ? formatCPF(passenger.cpf) : 'Não informado'}</TableCell>
                <TableCell>{formatDate(passenger.dataNascimento)}</TableCell>
                <TableCell>
                  {passenger.estrangeiro
                    ? `Passaporte: ${passenger.passaporte || 'Não informado'}`
                    : `RG: ${passenger.rg || 'Não informado'}`}
                </TableCell>
                {(canEdit || canDelete) && (
                  <TableCell align="right">
                    {canEdit && (
                      <Tooltip title="Editar">
                        <IconButton size="small" edge="end" aria-label="edit" onClick={(e) => handleEdit(e, passenger)}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                    {canDelete && (
                      <Tooltip title="Excluir">
                        <IconButton size="small" edge="end" aria-label="delete" onClick={(e) => handleClickOpen(e, passenger)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza de que deseja excluir o passageiro {passengerToDelete?.nome}? Esta ação não pode ser desfeita.
          </DialogContentText>
          {masterPasswordActive && (
            <TextField
              margin="normal"
              fullWidth
              label="Senha Master"
              type={showPassword ? 'text' : 'password'}
              value={masterPassword}
              onChange={(e) => setMasterPassword(e.target.value)}
              error={!!error}
              helperText={error}
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
              disabled={isDeleting}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" disabled={isDeleting} sx={{ borderRadius: '50px' }}>
            Voltar
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
            disabled={(masterPasswordActive && !masterPassword) || isDeleting || isVerifying}
            autoFocus
            sx={{ color: 'white', borderRadius: '50px' }}
          >
            {isDeleting || isVerifying ? <CircularProgress size={24} /> : 'Excluir'}
          </Button>
        </DialogActions>
      </Dialog>

      <PassengerDetails
        passenger={selectedPassenger}
        open={Boolean(selectedPassenger)}
        onClose={handleCloseModal}
      />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </TableContainer>
  );
};

export default PassengerTable;