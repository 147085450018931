import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

const FleetScheduleLegend = () => {
  const theme = useTheme();

  const legendItems = [
    { color: theme.palette.success.main, label: 'Iniciando' },
    { color: theme.palette.info.main, label: 'Em andamento' },
    { color: theme.palette.warning.main, label: 'Retorno' },
  ];

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      backgroundColor: theme.palette.background.paper,
      p: 1.5,
      borderRadius: 1,
      boxShadow: 1
    }}>
      {legendItems.map((item, index) => (
        <Box
          key={item.label}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Box sx={{
            width: 16,
            height: 16,
            borderRadius: '50%',
            bgcolor: item.color,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontSize: '0.7rem',
            fontWeight: 'bold'
          }}>
          </Box>
          <Typography
            variant="caption"
            sx={{
              whiteSpace: 'nowrap',
              color: theme.palette.text.secondary
            }}
          >
            {item.label}
          </Typography>
          {index < legendItems.length - 1 && (
            <Box
              sx={{
                height: '15px',
                borderRight: 1,
                borderColor: 'divider',
                ml: 1
              }}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

export default FleetScheduleLegend;