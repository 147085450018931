import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
    Box,
    Divider
} from '@mui/material';

const RoomDetails = ({ open, onClose, room }) => {
    if (!room) return null;

    const roomTypeLabels = {
        single: 'Individual',
        double: 'Duplo',
        suite: 'Suíte'
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                Detalhes do Quarto - {room.name}
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Ocupação: {room.occupiedBeds}/{room.totalBeds} leitos
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Tipo: {roomTypeLabels[room.roomType]} | Andar: {room.floor || 'Não especificado'}
                            </Typography>
                        </Box>
                        <Divider />
                    </Grid>

                    {room.notes && (
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" gutterBottom>
                                Observações
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {room.notes}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>

            <DialogActions sx={{ p: 2 }}>
                <Button
                    onClick={onClose}
                    variant="outlined"
                    sx={{ borderRadius: '50px' }}
                >
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RoomDetails;