import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Paper,
    Typography,
    IconButton,
    Tooltip,
    Badge,
    useTheme
} from '@mui/material';
import {
    format,
    startOfMonth,
    endOfMonth,
    eachDayOfInterval,
    startOfWeek,
    endOfWeek,
    isSameMonth,
    isSameDay,
    addMonths,
    subMonths,
    isWithinInterval,
    parseISO
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import InfoIcon from '@mui/icons-material/Info';

import TravelLoading from '../../../shared/common/TravelLoading';

import { getVehicleSchedule } from '../services/VehicleScheduleService';

const VehicleSchedule = ({ vehicleId }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [loading, setLoading] = useState(true); 
    const [scheduleData, setScheduleData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [hoveredDate, setHoveredDate] = useState(null);
    const [isChangingMonth, setIsChangingMonth] = useState(false); 

    useEffect(() => {
        fetchMonthSchedule();
    }, [currentDate, vehicleId]);

    const fetchMonthSchedule = async () => {
        if (!vehicleId) return;

        setIsChangingMonth(true); // Set changing month state
        try {
            const monthStart = startOfMonth(currentDate);
            const monthEnd = endOfMonth(currentDate);

            const data = await getVehicleSchedule(
                vehicleId,
                monthStart.toISOString().split('T')[0],
                monthEnd.toISOString().split('T')[0]
            );

            const processedData = data.map(travel => {
                const departureDate = new Date(`${travel.dataIda}T${travel.horarioIda}`);
                const returnDate = travel.somenteIda ? null : new Date(`${travel.dataRetorno}T${travel.horarioRetorno}`);

                return {
                    ...travel,
                    departureDate,
                    returnDate
                };
            }).filter(travel => {
                const startsInMonth = isSameMonth(travel.departureDate, currentDate);
                const endsInMonth = travel.returnDate && isSameMonth(travel.returnDate, currentDate);
                const spansMonth = travel.returnDate &&
                    travel.departureDate <= endOfMonth(currentDate) &&
                    travel.returnDate >= startOfMonth(currentDate);

                return startsInMonth || endsInMonth || spansMonth;
            });

            setScheduleData(processedData);
        } catch (error) {
            console.error('Erro ao buscar agenda:', error);
        } finally {
            setLoading(false);
            setIsChangingMonth(false);
        }
    };

    const handleMonthChange = (newDate) => {
        setLoading(true);
        setCurrentDate(newDate);
        setSelectedDate(null);
    };

    const renderCalendarHeader = () => {
        const daysOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

        return (
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', mb: 1 }}>
                {daysOfWeek.map((day) => (
                    <Typography
                        key={day}
                        variant="subtitle2"
                        sx={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            color: theme.palette.text.secondary
                        }}
                    >
                        {day}
                    </Typography>
                ))}
            </Box>
        );
    };

    const getScheduleForDate = (date) => {
        return scheduleData.filter(travel => {
            const travelDeparture = new Date(`${travel.dataIda}T${travel.horarioIda}`);
            const travelReturn = travel.somenteIda ? null : new Date(`${travel.dataRetorno}T${travel.horarioRetorno}`);

            // Se for o dia da partida, sempre deve mostrar
            if (isSameDay(date, travelDeparture)) {
                return true;
            }

            // Se for o dia do retorno E a viagem não for somente ida, deve mostrar
            if (!travel.somenteIda && travelReturn && isSameDay(date, travelReturn)) {
                return true;
            }

            // Se estiver entre a partida e o retorno E a viagem não for somente ida, deve mostrar
            if (!travel.somenteIda && travelReturn) {
                return isWithinInterval(date, {
                    start: travelDeparture,
                    end: travelReturn
                });
            }

            return false;
        });
    };

    const renderCalendarDays = () => {
        const monthStart = startOfMonth(currentDate);
        const monthEnd = endOfMonth(currentDate);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);

        const dateInterval = eachDayOfInterval({
            start: startDate,
            end: endDate
        });

        return (
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(7, 1fr)',
                gap: 1
            }}>
                {dateInterval.map((date) => {
                    const daySchedule = getScheduleForDate(date);
                    const isCurrentMonth = isSameMonth(date, currentDate);
                    const isSelected = selectedDate && isSameDay(date, selectedDate);
                    const isHovered = hoveredDate && isSameDay(date, hoveredDate);

                    // Verifica viagens que começam, terminam ou estão em andamento neste dia
                    const hasStartingTrips = daySchedule.some(travel =>
                        isSameDay(date, new Date(`${travel.dataIda}T${travel.horarioIda}`))
                    );
                    const hasEndingTrips = daySchedule.some(travel =>
                        !travel.somenteIda && travel.dataRetorno &&
                        isSameDay(date, new Date(`${travel.dataRetorno}T${travel.horarioRetorno}`))
                    );
                    const hasOngoingTrips = daySchedule.length > 0 && !hasStartingTrips && !hasEndingTrips;

                    return (
                        <Paper
                            key={date.toString()}
                            elevation={isSelected || isHovered ? 3 : 1}
                            sx={{
                                position: 'relative',
                                height: '80px',
                                p: 1,
                                cursor: 'pointer',
                                opacity: isCurrentMonth ? 1 : 0.4,
                                bgcolor: isSelected
                                    ? theme.palette.primary.light
                                    : hasStartingTrips
                                        ? theme.palette.success.light
                                        : hasEndingTrips
                                            ? theme.palette.warning.light
                                            : hasOngoingTrips
                                                ? theme.palette.info.light
                                                : 'background.paper',
                                '&:hover': {
                                    bgcolor: theme.palette.action.hover,
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                            onClick={() => setSelectedDate(date)}
                            onMouseEnter={() => setHoveredDate(date)}
                            onMouseLeave={() => setHoveredDate(null)}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    fontWeight: 'medium',
                                    color: isSelected
                                        ? theme.palette.primary.contrastText
                                        : theme.palette.text.primary
                                }}
                            >
                                {format(date, 'd')}
                            </Typography>
                            {daySchedule.length > 0 && (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
                                    <Badge
                                        badgeContent={daySchedule.length}
                                        color="primary"
                                        sx={{ mt: 1 }}
                                    >
                                        <DirectionsBusIcon
                                            color={hasStartingTrips ? "success" : hasEndingTrips ? "warning" : "action"}
                                        />
                                    </Badge>
                                    <Tooltip title={
                                        hasStartingTrips
                                            ? "Início de viagem"
                                            : hasEndingTrips
                                                ? "Fim de viagem"
                                                : "Viagem em andamento"
                                    }>
                                        <Typography variant="caption" sx={{ fontSize: '0.6rem' }}>
                                            {hasStartingTrips
                                                ? "Partida"
                                                : hasEndingTrips
                                                    ? "Retorno"
                                                    : "Em viagem"}
                                        </Typography>
                                    </Tooltip>
                                </Box>
                            )}
                        </Paper>
                    );
                })}
            </Box>
        );
    };

    const renderScheduleDetails = () => {
        if (!selectedDate) return null;

        const daySchedule = getScheduleForDate(selectedDate);
        const isSelectedDateStart = (travel) =>
            isSameDay(selectedDate, new Date(`${travel.dataIda}T${travel.horarioIda}`));
        const isSelectedDateEnd = (travel) =>
            !travel.somenteIda && travel.dataRetorno &&
            isSameDay(selectedDate, new Date(`${travel.dataRetorno}T${travel.horarioRetorno}`));

        return (
            <Box sx={{ mt: 3 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Agenda para {format(selectedDate, "dd 'de' MMMM 'de' yyyy", { locale: ptBR })}
                </Typography>
                {daySchedule.length > 0 ? (
                    daySchedule.map((travel) => (
                        <Paper
                            key={travel.id}
                            sx={{
                                p: 2,
                                mb: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                                borderLeft: 6,
                                borderColor: isSelectedDateStart(travel)
                                    ? theme.palette.success.main
                                    : isSelectedDateEnd(travel)
                                        ? theme.palette.warning.main
                                        : theme.palette.info.main
                            }}
                        >
                            <DirectionsBusIcon
                                color={
                                    isSelectedDateStart(travel)
                                        ? "success"
                                        : isSelectedDateEnd(travel)
                                            ? "warning"
                                            : "info"
                                }
                            />
                            <Box sx={{ flex: 1 }}>
                                <Typography variant="subtitle1">
                                    Viagem #{travel.identificador} - {travel.origem} → {travel.destino}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Saída: {format(new Date(`${travel.dataIda}T${travel.horarioIda}`), "dd/MM 'às' HH:mm")}
                                    {!travel.somenteIda && travel.dataRetorno &&
                                        ` - Retorno: ${format(new Date(`${travel.dataRetorno}T${travel.horarioRetorno}`), "dd/MM 'às' HH:mm")}`
                                    }
                                </Typography>
                                {travel.informacoesAdicionais && (
                                    <Typography variant="body2" color="text.secondary">
                                        Info: {travel.informacoesAdicionais}
                                    </Typography>
                                )}
                            </Box>
                            <Tooltip title="Ver detalhes da viagem">
                                <IconButton
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(`/viagens/${travel.id}`);
                                    }}
                                >
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </Paper>
                    ))
                ) : (
                    <Typography variant="body2" color="text.secondary">
                        Nenhuma viagem programada para esta data.
                    </Typography>
                )}
            </Box>
        );
    };

    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 3
            }}>
                <IconButton
                    onClick={() => handleMonthChange(subMonths(currentDate, 1))}
                    disabled={loading || isChangingMonth}
                >
                    <ChevronLeftIcon />
                </IconButton>
                <Typography variant="h6">
                    {format(currentDate, "MMMM 'de' yyyy", { locale: ptBR })}
                </Typography>
                <IconButton
                    onClick={() => handleMonthChange(addMonths(currentDate, 1))}
                    disabled={loading || isChangingMonth}
                >
                    <ChevronRightIcon />
                </IconButton>
            </Box>

            {(loading || isChangingMonth) ? (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 3,
                    minHeight: '400px', // Add minimum height to prevent layout shift
                    alignItems: 'center'
                }}>
                    <TravelLoading disableAlignItems />
                </Box>
            ) : (
                <>
                    {renderCalendarHeader()}
                    {renderCalendarDays()}
                    {renderScheduleDetails()}
                </>
            )}
        </Box>
    );
};

export default VehicleSchedule;