import React, { useState } from 'react';
import {
    Box,
    Typography,
    IconButton,
    Tooltip,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    Add as AddIcon,
    MonetizationOn as MonetizationOnIcon
} from '@mui/icons-material';
import { normalizeValue, formatDate } from '../../../shared/utils/utils';
import TruncatedCell from '../../../shared/common/TruncatedCell';

const BudgetPaymentList = ({
    payments = [],
    onEdit,
    onDelete,
    onAdd,
    loading = false,
    bankAccounts = []
}) => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);

    const getBankAccountName = (bankAccountId) => {
        if (!bankAccountId) return 'Nenhuma';
        const account = bankAccounts.find(account => account.id === bankAccountId);
        return account ? `${account.name} - ${account.bank}` : 'Não encontrada';
    };

    const handleOpenDeleteDialog = (payment) => {
        setSelectedPayment(payment);
        setDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setSelectedPayment(null);
    };

    const handleConfirmDelete = () => {
        if (selectedPayment) {
            onDelete(selectedPayment.id);
        }
        handleCloseDeleteDialog();
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mb: 3,
                flexWrap: 'wrap',
                gap: 2
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6">Pagamentos do Orçamento</Typography>
                </Box>

                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={onAdd}
                        sx={{ borderRadius: '50px' }}
                    >
                        Adicionar
                    </Button>
                </Box>
            </Box>

            {payments.length === 0 ? (
                <Paper elevation={1} sx={{ p: 3, textAlign: 'center' }}>
                    <Typography variant="h6" color="textSecondary">
                        Nenhum pagamento registrado
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        Adicione um novo pagamento para começar.
                    </Typography>
                </Paper>
            ) : (
                <TableContainer component={Paper} elevation={2}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {['Descrição', 'Valor', 'Data', 'Método de Pagamento', 'Conta Bancária', 'Ações'].map((header) => (
                                    <TableCell
                                        key={header}
                                        align={header === 'Ações' ? 'center' : 'left'}
                                        sx={{ fontWeight: 'bold' }}
                                    >
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {payments.map((payment) => (
                                <TableRow
                                    key={payment.id}
                                    sx={{
                                        '&:hover': { backgroundColor: 'action.hover' },
                                        transition: 'background-color 0.2s'
                                    }}
                                >
                                    <TruncatedCell text={payment.description} maxChars={30} />
                                    <TableCell>R$ {normalizeValue(payment.amount)}</TableCell>
                                    <TableCell>{formatDate(payment.date)}</TableCell>
                                    <TableCell>{payment.paymentMethod}</TableCell>
                                    <TruncatedCell text={getBankAccountName(payment.bankAccountId)} maxChars={30} />
                                    <TableCell align="center">
                                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                                            <Tooltip title="Editar" arrow>
                                                <IconButton
                                                    onClick={() => onEdit(payment)}
                                                    disabled={loading}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Excluir" arrow>
                                                <IconButton
                                                    onClick={() => handleOpenDeleteDialog(payment)}
                                                    disabled={loading}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <Dialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
            >
                <DialogTitle>Confirmar Exclusão</DialogTitle>
                <DialogContent>
                    <Typography>
                        Tem certeza que deseja excluir este pagamento?
                    </Typography>
                    {selectedPayment && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body2">
                                <strong>Data:</strong> {formatDate(selectedPayment.date)}
                            </Typography>
                            <Typography variant="body2">
                                <strong>Valor:</strong> R$ {normalizeValue(selectedPayment.amount)}
                            </Typography>
                            <Typography variant="body2">
                                <strong>Descrição:</strong> {selectedPayment.description}
                            </Typography>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDeleteDialog}
                        variant="outlined"
                        sx={{ borderRadius: '50px' }}
                        disabled={loading}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleConfirmDelete}
                        variant="contained"
                        color="error"
                        sx={{ borderRadius: '50px', color: 'white' }}
                        disabled={loading}
                    >
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default BudgetPaymentList;