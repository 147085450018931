// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/useAuthState';
import { usePermissions } from '../../hooks/usePermissions';
import TravelLoading from '../../shared/common/TravelLoading';

export const ProtectedRoute = ({ children }) => {
  const { currentUser, loading, initialized } = useAuth();
  const { canAccessCurrentRoute, getFirstAccessibleRoute } = usePermissions();
  const location = useLocation();

  if (!initialized || loading) {
    return <TravelLoading />;
  }

  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!currentUser.userData?.isApproved) {
    return <Navigate to="/pendente-aprovacao" replace />;
  }

  if (!canAccessCurrentRoute()) {
    return <Navigate to={getFirstAccessibleRoute()} replace />;
  }

  return children;
};