import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Stack,
    Box,
    CircularProgress
} from '@mui/material';
import { format } from 'date-fns';

const VehicleDocumentForm = ({
    open,
    onClose,
    onSubmit,
    initialData = null,
    loading = false
}) => {
    const MAX_OBSERVACOES_LENGTH = 100;
    const [formData, setFormData] = useState({
        nome: '',
        numero: '',
        dataVencimento: format(new Date(), 'yyyy-MM-dd'),
        observacoes: ''
    });

    const [errors, setErrors] = useState({
        nome: false,
        dataVencimento: false
    });

    useEffect(() => {
        if (initialData) {
            setFormData({
                nome: initialData.nome,
                numero: initialData.numero,
                dataVencimento: format(new Date(initialData.dataVencimento), 'yyyy-MM-dd'),
                observacoes: initialData.observacoes || ''
            });
        } else {
            setFormData({
                nome: '',
                numero: '',
                dataVencimento: format(new Date(), 'yyyy-MM-dd'),
                observacoes: ''
            });
        }
        setErrors({ nome: false, dataVencimento: false });
    }, [initialData]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'observacoes' && value.length > MAX_OBSERVACOES_LENGTH) {
            return;
        }

        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: false
            }));
        }
    };

    const handleSubmit = () => {
        const newErrors = {
            nome: !formData.nome,
            dataVencimento: !formData.dataVencimento
        };

        setErrors(newErrors);

        if (!newErrors.nome && !newErrors.dataVencimento) {
            onSubmit(formData);
        }
    };

    const getRemainingChars = () => {
        return MAX_OBSERVACOES_LENGTH - (formData.observacoes?.length || 0);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                {initialData ? 'Editar Documento' : 'Novo Documento'}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} sx={{ mt: 2 }}>
                    <TextField
                        label="Nome do Documento"
                        name="nome"
                        value={formData.nome}
                        onChange={handleChange}
                        fullWidth
                        required
                        error={errors.nome}
                        helperText={errors.nome ? "Campo obrigatório" : ""}
                        disabled={loading}
                    />
                    <TextField
                        label="Número do Documento"
                        name="numero"
                        value={formData.numero}
                        onChange={handleChange}
                        fullWidth
                        disabled={loading}
                    />
                    <TextField
                        label="Data de Vencimento"
                        name="dataVencimento"
                        type="date"
                        value={formData.dataVencimento}
                        onChange={handleChange}
                        fullWidth
                        required
                        error={errors.dataVencimento}
                        helperText={errors.dataVencimento ? "Campo obrigatório" : ""}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={loading}
                    />
                    <Box>
                        <TextField
                            label="Observações"
                            name="observacoes"
                            value={formData.observacoes}
                            onChange={handleChange}
                            fullWidth
                            inputProps={{
                                maxLength: MAX_OBSERVACOES_LENGTH
                            }}
                            helperText={`${getRemainingChars()} caracteres restantes`}
                            disabled={loading}
                        />
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', px: 3, pb: 2 }}>
                <Button
                    onClick={onClose}
                    disabled={loading}
                    variant="outlined"
                    sx={{ borderRadius: '50px' }}
                >
                    Voltar
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={loading}
                    sx={{ borderRadius: '50px' }}
                >
                    {loading ? (
                        <>
                            <CircularProgress size={20} sx={{ mr: 1 }} />
                            Salvando...
                        </>
                    ) : 'Salvar'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default VehicleDocumentForm;