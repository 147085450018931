import React, { useState, useEffect } from 'react';
import {
  Box, Button, TextField, Typography, Grid, Snackbar, Alert, IconButton, InputAdornment, Tooltip, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import VehicleFormHelp from './helps/VehicleFormHelp';
import { checkVehiclePlateUnique } from './services/VehicleService';
import { getAllLayouts } from '../LayoutPage/services/LayoutService';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const VehicleForm = ({ onSave, onCancel, initialVehicle }) => {
  const [vehicle, setVehicle] = useState({
    identificadorVeiculo: '',
    placa: '',
    empresa: '',
    layoutId: '',
  });
  const [isSaving, setIsSaving] = useState(false);
  const [layouts, setLayouts] = useState([]);
  const [filteredLayouts, setFilteredLayouts] = useState([]);
  const [errors, setErrors] = useState({});
  const [isUnique, setIsUnique] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    if (initialVehicle) {
      setVehicle(initialVehicle);
    }
    fetchLayouts();
  }, [initialVehicle]);

  const fetchLayouts = async () => {
    try {
      setLoading(true);
      const fetchedLayouts = await getAllLayouts();
      setLayouts(fetchedLayouts);
      setFilteredLayouts(fetchedLayouts);
      setLoading(false);
    } catch (err) {
      console.error("Erro ao carregar layouts:", err);
      setLoading(false);
    }
  };

  const handleLayoutSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const filtered = layouts.filter(
      (layout) =>
        layout.name.toLowerCase().includes(searchTerm) ||
        layout.id.toLowerCase().includes(searchTerm)
    );
    setFilteredLayouts(filtered);
  };

  const handleLayoutChange = (event, value) => {
    setVehicle((prevVehicle) => ({
      ...prevVehicle,
      layoutId: value ? value.id : ''
    }));
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setVehicle((prevVehicle) => ({
      ...prevVehicle,
      [name]: value
    }));

    validateField(name, value);

    if (name === 'placa') {
      const unique = await checkVehiclePlateUnique(value, initialVehicle?.id);
      setIsUnique(unique);
      if (!unique) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          placa: 'Veículo já cadastrado com esta placa'
        }));
      } else {
        setErrors((prevErrors) => {
          const { placa, ...rest } = prevErrors;
          return rest;
        });
      }
    }
  };

  const validateField = (name, value) => {
    let error = '';

    switch (name) {
      case 'identificadorVeiculo':
        if (!value.trim() || value.length > 255) {
          error = 'Identificador do veículo é obrigatório e deve ter no máximo 255 caracteres.';
        }
        break;
      case 'placa':
        if (!value.trim() || value.length > 55) {
          error = 'Placa do veículo é obrigatória e deve ter no máximo 55 caracteres.';
        }
        break;
      case 'empresa':
        if (!value.trim() || value.length > 255) {
          error = 'Empresa é obrigatória e deve ter no máximo 255 caracteres.';
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error
    }));
  };

  const isFormValid = () => {
    return (
      Object.values(errors).every((error) => !error) &&
      vehicle.identificadorVeiculo.trim() &&
      vehicle.placa.trim() &&
      vehicle.empresa.trim() &&
      isUnique
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      setSnackbarMessage("Por favor, preencha todos os campos obrigatórios corretamente.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    try {
      setIsSaving(true);
      await onSave(vehicle);
      onCancel();
    } catch (error) {
      setSnackbarMessage(`Erro ao ${initialVehicle ? 'atualizar' : 'adicionar'} veículo: ${error.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsSaving(false);
    }
  };

  const openConfirmModal = () => {
    setConfirmModalOpen(true);
  };

  const handleConfirmRedirect = () => {
    setConfirmModalOpen(false);
    navigate('layouts/novo');
  };

  const handleCancelRedirect = () => {
    setConfirmModalOpen(false);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" component="div" color={theme.palette.text.primary}>
          {initialVehicle ? 'Editar Veículo' : 'Adicionar Novo Veículo'}
          <Box component="span" sx={{ ml: 1 }}>
            <VehicleFormHelp />
          </Box>
        </Typography>
        <Typography variant="caption" display="block" gutterBottom color={theme.palette.text.secondary}>
          * Campos Obrigatórios
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Identificador do Veículo"
            name="identificadorVeiculo"
            value={vehicle.identificadorVeiculo}
            onChange={handleInputChange}
            error={!!errors.identificadorVeiculo}
            helperText={errors.identificadorVeiculo}
            fullWidth
            required
            InputLabelProps={{
              style: { color: theme.palette.text.primary }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Placa do Veículo"
            name="placa"
            value={vehicle.placa}
            onChange={handleInputChange}
            error={!!errors.placa}
            helperText={errors.placa}
            fullWidth
            required
            InputLabelProps={{
              style: { color: theme.palette.text.primary }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Empresa do Veículo"
            name="empresa"
            value={vehicle.empresa}
            onChange={handleInputChange}
            error={!!errors.empresa}
            helperText={errors.empresa}
            fullWidth
            required
            InputLabelProps={{
              style: { color: theme.palette.text.primary }
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            id="layout-select"
            options={filteredLayouts}
            getOptionLabel={(option) => `${option.name} (ID: ${option.id}) - ${option.assentosTotais} assentos`}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Associar Layout ao Veículo (Opcional)"
                placeholder={loading ? "Carregando layouts..." : "Busque por nome ou ID do Layout"}
                onChange={handleLayoutSearch}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <InputAdornment position="start">
                          <Tooltip title="Criar Novo Layout">
                            <IconButton onClick={openConfirmModal} edge="start" sx={{ marginRight: 1 }}>
                              <AddIcon
                                sx={{
                                  backgroundColor: theme.palette.primary.main,
                                  borderRadius: '50%',
                                  color: theme.palette.common.white,
                                  padding: 0.5,
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
            )}
            value={layouts.find((layout) => layout.id === vehicle.layoutId) || null}
            onChange={handleLayoutChange}
            noOptionsText="Nenhuma opção encontrada."
            disabled={loading}
          />
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, mt: 2 }}>
          <Button
            onClick={onCancel}
            variant="outlined"
            sx={{ borderRadius: '50px' }}
            disabled={isSaving}
          >
            {initialVehicle ? 'Descartar Alterações' : 'Voltar'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{ borderRadius: '50px' }}
            disabled={isSaving}
          >
            {isSaving ? (
              <>
                <CircularProgress size={20} sx={{ mr: 1 }} />
                {initialVehicle ? 'Salvando...' : 'Adicionando...'}
              </>
            ) : (
              initialVehicle ? 'Salvar Alterações' : 'Adicionar Veículo'
            )}
          </Button>
        </Grid>
      </Grid>

      <Dialog open={confirmModalOpen} onClose={handleCancelRedirect}>
        <DialogTitle>Confirmação de Redirecionamento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Você será redirecionado para a área de criação de layout.<br />
            Todos os dados preenchidos serão perdidos. Deseja continuar?
            <br />
            <br />
            Você pode adicionar um layout posteriormente ao editar um veículo.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button
            onClick={handleCancelRedirect}
            variant="outlined"
            sx={{ borderRadius: '50px', color: theme.palette.primary.grey }}
          >
            Voltar
          </Button>
          <Button
            onClick={handleConfirmRedirect}
            variant="contained"
            sx={{ borderRadius: '50px' }}
            autoFocus
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%', backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default VehicleForm;
