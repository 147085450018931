import React, { useState, useEffect } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Button, Grid, CircularProgress, Box
} from '@mui/material';
import { addRoom, updateRoom } from './services/AccommodationService';

const RoomForm = ({ open, onClose, onSubmit, hotelId, room }) => {
    const [formData, setFormData] = useState({
        name: '',
        totalBeds: 1,
        notes: ''
    });

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (room) {
            setFormData({
                name: room.name || '',
                totalBeds: room.totalBeds || 1,
                notes: room.notes || ''
            });
        } else {
            setFormData({
                name: '',
                totalBeds: 1,
                notes: ''
            });
        }
    }, [room, open]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const result = room
                ? await updateRoom(room.id, { ...formData, hotelId: room.hotelId })
                : await addRoom(hotelId, formData);

            onSubmit(result);
        } catch (error) {
            console.error('Erro ao salvar quarto:', error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogTitle>
                    {room ? 'Editar Quarto' : 'Novo Quarto'}
                </DialogTitle>

                <DialogContent>
                    <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 1, mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Nome/Número do Quarto"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Quantidade de Hóspedes"
                                    name="totalBeds"
                                    type="number"
                                    value={formData.totalBeds}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    InputProps={{ inputProps: { min: 1 } }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Observações do Quarto"
                                    name="notes"
                                    value={formData.notes}
                                    onChange={handleChange}
                                    fullWidth
                                    rows={2}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>

                <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        disabled={loading}
                        sx={{ borderRadius: '50px' }}
                    >
                        Voltar
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={loading}
                        sx={{ borderRadius: '50px' }}
                    >
                        {loading ? <CircularProgress size={24} /> : room ? 'Salvar' : 'Adicionar'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default RoomForm;