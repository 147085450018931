import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Typography, Box, Grid, Tooltip, CircularProgress } from '@mui/material';
import { AirlineSeatReclineNormal, Wc, Stairs, Kitchen, Block } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import TravelLoading from './../../../shared/common/TravelLoading';

const SeatChangeDialog = ({ open, onClose, currentSeat, layoutAndar1 = [], layoutAndar2 = [], reservedSeats = [], allocatedSeats = [], onSeatChange }) => {
  const [selectedSeat, setSelectedSeat] = useState(currentSeat);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    if (open) {
      setSelectedSeat(currentSeat);
      setLoading(true);
    }
  }, [currentSeat, open]);

  useEffect(() => {
    if (open && (layoutAndar1.length > 0 || layoutAndar2.length > 0)) {
      setLoading(false);
    }
  }, [layoutAndar1, layoutAndar2, open]);

  const handleSeatSelection = (seat) => {
    setSelectedSeat(seat.number);
  };

  const unflattenLayout = (flatLayout) => {
    const rows = Math.max(...flatLayout.map((cell) => cell.row)) + 1;
    const cols = Math.max(...flatLayout.map((cell) => cell.col)) + 1;

    const layout = Array.from({ length: rows }, () => Array(cols).fill({ type: 'empty', number: null }));

    flatLayout.forEach((cell) => {
      layout[cell.row][cell.col] = {
        type: cell.type || 'empty',
        number: cell.number ?? null,
      };
    });

    return layout;
  };

  const renderLayout = (layout) => {
    if (!Array.isArray(layout) || layout.length === 0) return null;

    return layout.map((row, rowIndex) => (
      <Grid container key={rowIndex} justifyContent="center" sx={{ marginBottom: '8px' }}>
        {row.map((cell, colIndex) => {
          const reservedSeat = reservedSeats.find((reserved) => reserved.number === cell.number);
          const isReserved = reservedSeat && reservedSeat.status !== 'Cancelada';
          const isAllocated = allocatedSeats.includes(cell.number) && cell.number !== currentSeat;

          let tooltipText = '';
          if (cell.type === 'seat' && cell.number) {
            tooltipText = isReserved ? 'Reservado' : `Assento ${cell.number}`;
          } else if (cell.type === 'bathroom') {
            tooltipText = 'Banheiro';
          } else if (cell.type === 'stair') {
            tooltipText = 'Escada';
          } else if (cell.type === 'fridge') {
            tooltipText = 'Frigobar';
          }

          return (
            <React.Fragment key={colIndex}>
              {colIndex === 2 && (
                <Grid item key={`aisle-${rowIndex}`} sx={{ width: '20px' }} />
              )}
              <Grid item>
                <Tooltip title={tooltipText} arrow>
                  <span>
                    <Button
                      variant={selectedSeat === cell.number ? 'contained' : 'outlined'}
                      onClick={() => handleSeatSelection(cell)}
                      disabled={isReserved || isAllocated || cell.type !== 'seat'}
                      sx={{
                        width: '48px',
                        height: '48px',
                        margin: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: selectedSeat === cell.number
                          ? theme.palette.primary.main
                          : isReserved
                            ? theme.palette.action.disabledBackground
                            : theme.palette.background.paper,
                        color: selectedSeat === cell.number
                          ? theme.palette.primary.contrastText
                          : isReserved
                            ? theme.palette.action.disabled
                            : theme.palette.text.primary,
                        cursor: isReserved || cell.type !== 'seat' ? 'not-allowed' : 'pointer',
                        visibility: cell.type === 'empty' ? 'hidden' : 'visible',
                      }}
                    >
                      {renderCellContent(cell)}
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    ));
  };

  const renderCellContent = (cell) => {
    switch (cell.type) {
      case 'seat':
        return cell.number ? `${cell.number}` : <AirlineSeatReclineNormal />;
      case 'bathroom':
        return <Wc />;
      case 'stair':
        return <Stairs />;
      case 'fridge':
        return <Kitchen />;
      case 'empty':
      default:
        return <Block />;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <Typography variant="h6" sx={{ marginBottom: '16px', color: theme.palette.text.primary }}>
          Trocar assento
        </Typography>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <TravelLoading disableAlignItems />
          </Box>
        ) : (
          <>
            <Box sx={{ border: `1px solid ${theme.palette.divider}`, padding: '16px', borderRadius: '8px', backgroundColor: theme.palette.background.default }}>
              <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary }}>1° Andar</Typography>
              {renderLayout(unflattenLayout(layoutAndar1))}
            </Box>
            {layoutAndar2.length > 0 && (
              <Box sx={{ border: `1px solid ${theme.palette.divider}`, padding: '16px', borderRadius: '8px', backgroundColor: theme.palette.background.default, mt: 2 }}>
                <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary }}>2° Andar</Typography>
                {renderLayout(unflattenLayout(layoutAndar2))}
              </Box>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={onClose} variant="outlined" sx={{ borderRadius: '50px', color: theme.palette.text.primary }}>
          Voltar
        </Button>
        <Button
          onClick={() => {
            onSeatChange(selectedSeat);
            onClose();
          }}
          variant="contained"
          color="primary"
          disabled={!selectedSeat || selectedSeat === currentSeat}
          sx={{ borderRadius: '50px' }}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SeatChangeDialog;
