import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AnimatedContainer = styled(Container)(({ theme }) => ({
  opacity: 0,
  transform: 'translateY(50px)',
  transition: 'opacity 0.6s ease-out, transform 0.6s ease-out',
  '&.visible': {
    opacity: 1,
    transform: 'translateY(0)',
  }
}));

const useScrollReveal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return [ref, isVisible];
};

export default function FAQ() {
  const [expanded, setExpanded] = useState(false);
  const [containerRef, isContainerVisible] = useScrollReveal();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const faqItems = [
    {
      panel: 'panel1',
      question: 'Como entro em contato com o suporte em caso de problemas?',
      answer: (
        <>
          Você pode entrar em contato com nosso suporte enviando um e-mail para
          <Link href="mailto:travell.suporte@gmail.com"> travell.suporte@gmail.com </Link>
          ou ligando para nosso número de atendimento. Estamos prontos para ajudar.
        </>
      )
    },
    {
      panel: 'panel2',
      question: 'Posso cancelar uma viagem ou alterar a reserva?',
      answer: 'Sim, você pode cancelar ou alterar a sua reserva de viagem diretamente na plataforma, dentro do prazo estabelecido. Caso tenha dúvidas sobre o processo, entre em contato com nossa equipe de suporte.'
    },
    {
      panel: 'panel3',
      question: 'Como funciona o acompanhamento das minhas viagens?',
      answer: 'Nosso sistema permite que você acompanhe suas viagens em tempo real. Basta acessar o painel de controle, onde você verá informações detalhadas sobre os horários, rotas e status de cada viagem.'
    },
    {
      panel: 'panel4',
      question: 'O que devo fazer se encontrar um problema durante a viagem?',
      answer: 'Caso encontre algum problema durante sua viagem, entre em contato diretamente com nosso suporte via o aplicativo ou por telefone. Nossa equipe está disponível 24 horas para ajudá-lo a resolver qualquer situação.'
    }
  ];

  return (
    <AnimatedContainer
      ref={containerRef}
      id="faq"
      className={isContainerVisible ? 'visible' : ''}
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: 'text.primary',
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Perguntas Frequentes
      </Typography>
      <Box sx={{ width: '100%' }}>
        {faqItems.map((item, index) => (
          <Accordion
            key={item.panel}
            expanded={expanded === item.panel}
            onChange={handleChange(item.panel)}
            sx={{
              opacity: isContainerVisible ? 1 : 0,
              transform: isContainerVisible ? 'translateY(0)' : 'translateY(20px)',
              transition: `opacity 0.6s ease-out ${0.2 + index * 0.1}s, transform 0.6s ease-out ${0.2 + index * 0.1}s`
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${item.panel}d-content`}
              id={`${item.panel}d-header`}
            >
              <Typography component="h3" variant="subtitle2">
                {item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' } }}
              >
                {item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </AnimatedContainer>
  );
}