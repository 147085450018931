import { useState, useCallback, useEffect } from 'react';
import { useAuth } from '../../core/contexts/useAuthState';
import { 
  getMasterPasswordStatus, 
  checkMasterPassword, 
  checkMasterPasswordRequired 
} from '../../pages/MyAccount/services/MasterPasswordService';

export const useMasterPassword = () => {
  const { currentUser } = useAuth();
  const [isVerifying, setIsVerifying] = useState(false);
  const [error, setError] = useState('');
  const [masterPasswordActive, setMasterPasswordActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Busca o status inicial da senha master
  useEffect(() => {
    const fetchMasterPasswordStatus = async () => {
      if (currentUser?.agency?.id) {
        try {
          const status = await getMasterPasswordStatus(currentUser.agency.id);
          setMasterPasswordActive(status.isActive);
        } catch (error) {
          console.error('Erro ao buscar status da senha master:', error);
        }
      }
    };
    fetchMasterPasswordStatus();
  }, [currentUser]);

  // Verifica se a senha master é necessária
  const checkIfMasterPasswordRequired = useCallback(async () => {
    if (!currentUser?.agency?.id) return false;
    try {
      return await checkMasterPasswordRequired(currentUser.agency.id);
    } catch (error) {
      console.error('Erro ao verificar necessidade de senha master:', error);
      return false;
    }
  }, [currentUser]);

  // Verifica a senha master fornecida
  const verifyMasterPassword = useCallback(async (password) => {
    if (!currentUser?.agency?.id || !password) return false;
    
    setIsVerifying(true);
    setError('');
    
    try {
      await checkMasterPassword(currentUser.agency.id, password);
      return true;
    } catch (error) {
      setError(error.message);
      return false;
    } finally {
      setIsVerifying(false);
    }
  }, [currentUser]);

  // Função auxiliar para alternar visibilidade da senha
  const togglePasswordVisibility = useCallback(() => {
    setShowPassword(prev => !prev);
  }, []);

  // Função para limpar o estado de erro
  const clearError = useCallback(() => {
    setError('');
  }, []);

  return {
    isVerifying,
    error,
    setError,
    clearError,
    masterPasswordActive,
    showPassword,
    togglePasswordVisibility,
    verifyMasterPassword,
    checkIfMasterPasswordRequired
  };
};