import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip,
  Box,
  useTheme
} from '@mui/material';
import { format, isValid, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import TruncatedCell from '../../../shared/common/TruncatedCell';

const formatCurrency = (value) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value || 0);
};

const formatDate = (dateString) => {
  if (!dateString) return '-';
  try {
    const date = parseISO(dateString);
    if (!isValid(date)) return '-';
    return format(date, 'dd/MM/yyyy', { locale: ptBR });
  } catch (error) {
    return '-';
  }
};

const PayablesList = ({ payables }) => {
  const theme = useTheme();

  const getStatusColor = (status) => {
    switch (status) {
      case 'Pago':
        return 'success';
      case 'Pendente':
        return 'warning';
      case 'Atrasado':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Descrição</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Forma de Pagamento</TableCell>
            <TableCell>Conta Bancária</TableCell>
            <TableCell align="right">Valor</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!payables || payables.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <Typography variant="body1">
                  Nenhuma conta a pagar encontrada
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            payables.map((payable) => (
              <TableRow key={payable.id || Math.random()}>
                <TruncatedCell
                  text={payable.description || '-'}
                  maxChars={30}
                />
                <TableCell>
                  {formatDate(payable.date)}
                </TableCell>
                <TableCell>
                  {payable.paymentMethod || '-'}
                </TableCell>
                <TableCell>
                  {payable.bankAccount || '-'}
                </TableCell>
                <TableCell align="right">
                  <Box sx={{
                    fontWeight: 'medium'
                  }}>
                    {formatCurrency(payable.amount)}
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Chip
                    label={payable.status}
                    color={getStatusColor(payable.status)}
                    size="small"
                  />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PayablesList;