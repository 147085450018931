import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Tooltip,
    Box,
    Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import { useTheme } from '@mui/material/styles';
import { formatPlate } from '../../shared/utils/utils';
import { usePermissions } from '../../hooks/usePermissions';
import { useNavigate } from 'react-router-dom';
import TruncatedCell from '../../shared/common/TruncatedCell';

const VehicleList = ({ vehicles, onEdit, onDelete }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { checkPermission } = usePermissions();

    const canEdit = checkPermission('vehicles', 'edit');
    const canDelete = checkPermission('vehicles', 'delete');

    return (
        <TableContainer component={Paper} sx={{ boxShadow: 2 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Identificador</TableCell>
                        <TableCell>Placa</TableCell>
                        <TableCell>Empresa</TableCell>
                        <TableCell>Layout</TableCell>
                        <TableCell>Viagens Alocadas</TableCell>
                        <TableCell align="center">Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {vehicles.map((vehicle) => (
                        <TableRow
                            key={vehicle.id}
                            hover
                            onClick={() => navigate(`/veiculos/${vehicle.id}`)}
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover
                                }
                            }}
                        >
                            <TruncatedCell text={vehicle.identificadorVeiculo} maxChars={20} />
                            <TruncatedCell text={formatPlate(vehicle.placa)} />
                            <TruncatedCell text={vehicle.empresa} />
                            <TableCell>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                    <EventSeatIcon fontSize="small" color="action" />
                                    <Typography variant="body2">
                                        {vehicle.layout ? (
                                            `${vehicle.layout.name || 'Sem nome'} - ${vehicle.layout.assentosTotais} assentos`
                                        ) : (
                                            'Nenhum layout associado'
                                        )}
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TruncatedCell text={String(vehicle.numTravels || 0)} />
                            <TableCell align="center">
                                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                                    {canEdit && onEdit && (
                                        <Tooltip title="Editar">
                                            <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onEdit(vehicle);
                                                }}
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {canDelete && onDelete && (
                                        <Tooltip title="Excluir">
                                            <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onDelete(vehicle);
                                                }}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default VehicleList;